import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`mutation($id : ID!, $input: AdditionalFeesInput!){
  workSite_editFees(id: $id, input: $input){
    id
  }
}`;
const useWorkSiteFees = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: MUTATION }));
};
export default useWorkSiteFees;
