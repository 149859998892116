import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    Modal,
    Select,
    Form,
    Row,
    Col,
    Button,
    Input,
    Space,
} from 'antd';


import { generateFullName, replaceVariables } from 'shared/services/Utility';
import './index.scss';
// @ts-ignore
import useSuppliers from '../../../../hooks/suppliers/useSuppliers';
import { useState } from 'react';

// @ts-ignore
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// @ts-ignore
import draftToHtml from 'draftjs-to-html';
import { useEffect } from 'react';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { REGEX_STRING_VERIFY } from '../../../../constants/AppConstants';

type Props = {
    onSubmit?: any,
    workSite?: any,
    selectedQuote?: any,
    loading?: any,
    onBack?: any,
    onCancel?: any,
}

const SendEmailClient: React.FunctionComponent<Props> = ({ loading, workSite, selectedQuote, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [editorError, setEditorError] = useState('');
    const [defaultContact, setDefaultContact] = useState(selectedQuote.customer && selectedQuote.customer.contacts && !selectedQuote.customer.contacts.length);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const { currentUser } = useCurrentUser();
    const [disableCheck, setDisableCheck] = useState(false);
    useEffect(() => {
        const cU = currentUser ? JSON.parse(JSON.stringify(currentUser)) : null;
        if (cU && cU.company && cU.company.quoteTemplate) {
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(replaceVariables(cU.company.quoteTemplate.template, {
                        title: selectedQuote.title,
                        name: workSite.name,
                        company: currentUser,
                    }))),
            ));
            form.setFieldsValue({
                subject: replaceVariables(cU.company.quoteTemplate.object, {
                    title: selectedQuote.title,
                    name: workSite.name,
                    company: currentUser,
                }),
            });
            const rawContentState = convertToRaw(editorState.getCurrentContent());
            const message = draftToHtml(
                rawContentState,
            );
            form.setFieldsValue({ message });
        }
		
		if(selectedQuote.customer && selectedQuote.customer.contacts && 
			selectedQuote.customer.contacts.length === 0)
		{
			form.setFieldsValue({ defaultContact: true });
			setDefaultContact(true);
			setDisableCheck(true);
		}
    }, [currentUser]);


    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
        setEditorError('');
    };


    return (<Modal
            title={t('workSite.quotes.sendEmailClient.title')}
            visible={true}
            width={1000}
            maskClosable={false}
            afterClose={
                onCancel
            }

            onCancel={onCancel}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}>
            <div>
                <Form
                    size="large"
                    form={form}
                    onFinish={(values) => {
                        setEditorError('');
                        const rawContentState = convertToRaw(editorState.getCurrentContent());
                        const message = draftToHtml(
                            rawContentState,
                        );
                        if (!message || (message.indexOf('<p></p>') > -1 && message.length === 8)) {
                            setEditorError(t('workSite.quotes.sendEmailClient.input.message.required'));
                            return;
                        }

                        onSubmit({
                            receiverEmail: defaultContact ? selectedQuote.customer.mail : values.contactId,
                            workSiteId: workSite.id,
                            documentId: selectedQuote.id,
                            subject: values.subject,
                            message,
                        });
                    }}
                    onFinishFailed={() => {
                        setEditorError('');
                        const rawContentState = convertToRaw(editorState.getCurrentContent());
                        const message = draftToHtml(
                            rawContentState,
                        );
                        if (!message || (message.indexOf('<p></p>') > -1 && message.length === 8)) {
                            setEditorError(t('workSite.quotes.sendEmailClient.input.message.required'));
                            return;
                        }
                    }}
                    initialValues={
                        {
                            customerId: selectedQuote.customer.id,
                            contactId: selectedQuote.customer.mail,
                        }
                    }
                    layout="vertical"
                    requiredMark={true}>
                    <Row gutter={16} style={{ padding: '10px 20px 0px 20px' }}>
                        <Col md={selectedQuote.customer.type !== 'priv' ? 12 : 24} sm={24} xs={24}>
                            <Form.Item
                                label={t('workSite.quotes.sendEmailClient.input.customer.label')}
                                name={'customerId'}
                                rules={[
                                    {
                                        required: true,
                                        message: t('workSite.quotes.sendEmailClient.input.customer.required'),
                                    },
                                ]}
                            >
                                <Select disabled={true} placeholder={t('genDict.Select')}>
                                    {
                                        [selectedQuote.customer].map((a: any) => {
                                            return <Select.Option
                                                value={a.id}>{generateFullName(a)} {a.mail ? `- ${a.mail}` : ''}</Select.Option>;

                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            selectedQuote.customer.type !== 'priv' &&
                            <Col md={12} sm={24} style={{ paddingTop: '34px' }}>
                                <Form.Item
                                    valuePropName="checked"
                                    name={'defaultContact'}>
                                    <Checkbox
                                        disabled={disableCheck}
                                        onChange={(e) => {
                                            setDefaultContact(e.target.checked);
                                        }}>
                                        <span
                                            className={'__theme-checkbox'}>{t('workSite.quotes.sendEmailClient.input.useDefaultContact.label')}</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                    {
                        !defaultContact && selectedQuote.customer.type !== 'priv' ?
                            <Row gutter={24} style={{ padding: '0px 20px 0px 20px' }}>
                                <Col md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('workSite.quotes.sendEmailClient.input.contact.label')}
                                        name={'contactId'}
                                        rules={[
                                            {
                                                required: !defaultContact,
                                                message: t('workSite.quotes.sendEmailClient.input.contact.required'),
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder={t('genDict.Select')}>
                                            {
                                                selectedQuote.customer.contacts.map((a: any) => {
                                                    return <Select.Option
                                                        value={a.email}>{`${generateFullName(a)} - ${a.isMain ? (t('genDict.contactPrinciple') + ' - ') : ''} ${a.email}`}</Select.Option>;

                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            : ''
                    }
                    <Row gutter={16} style={{ padding: '0px 20px 0px 20px' }}>
                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={t('workSite.quotes.sendEmailClient.input.subject.label')}
                                name={'subject'}
                                rules={[
                                    {
                                        required: true,
                                        message: t('workSite.quotes.sendEmailClient.input.subject.required'),
                                    },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ padding: '0 20px' }}>
                        <Col sm={24}>
                            <div className="ant-form-item __custom-form-label">
                                <div className="ant-form-item-required __lable-font-500">
                                    <label className={'ant-form-item-required'}><span
                                        className={'__required-mark'}>*</span>{t('workSite.quotes.sendEmailClient.input.message.label')}
                                    </label>
                                </div>
                            </div>
                            <Editor editorState={editorState} onEditorStateChange={onEditorStateChange}/>
                            <span className={'__form-error'}>
                            {
                                editorError
                            }
                           </span>

                        </Col>

                    </Row>

                    <Row gutter={16} style={{ padding: '15px 20px 0 20px' }}>
                        <Col sm={24}>

                            <span className={'__document-name'}>
                                {t('genDict.documentTitle')}:&nbsp;
                            </span>
                            <span className={'__document-name'}>
                                {selectedQuote.title}.pdf
                            </span>
                        </Col>

                    </Row>
                    <Form.Item>
                        <Space direction={'horizontal'} className={'__form-footer-parent'}
                               style={{ marginTop: '15px' }}>
                            <div/>
                            <Space direction={'horizontal'} size={20} className={'__form-footer'}
                                   style={{ padding: '20px' }}>
                                <Button
                                    type="default"
                                    disabled={loading}
                                    onClick={async () => {
                                        onCancel();
                                    }}
                                    shape="round"
                                    size={'large'}
                                    className="submit">
                                    {t('genDict.Cancel')}
                                </Button>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    style={{ width: 'auto' }}
                                    shape="round"
                                    size={'large'}
                                    className="submit"
                                >
                                    {t('workSite.quotes.sendEmailClient.button.next')}
                                </Button>
                            </Space>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
export default SendEmailClient;
