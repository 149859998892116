import AXIOS from 'axios';
import Parameters from 'shared/parameters';
import { getAuthToken } from 'shared/contexts/AuthContext';
import { client } from './ApolloClient';

function getPublicInstance() {
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': Parameters.ApiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

function getProtectedGQLInstance() {
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': Parameters.ApiUrl + '/graphql',
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': getAuthToken() ? getAuthToken().value : null,
        },
    });
}

function handleErrors(error: any) {
    let message = 'Something went wrong!!';
    if (error && error.response && error.response.data && error.response.data.detail) {
        message = error.response.data.detail;
    }
    if (error && error.response && error.response.data && error.response.data.title) {
        message = error.response.data.title;
    }
    if (error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
    }
    return message;
}

async function checkIfEmailExists(email: string) {
    const instance = getPublicInstance();
    return await instance.get('user/email/check?email=' + email);
}

async function login(login: string, password: string) {
    const instance = getPublicInstance();
    return await instance.post('auth-tokens', { login, password });
}

async function subscriptionPrices() {
    const instance = getPublicInstance();
    return await instance.get('subscription-prices');
}

async function forgetPassword(email: string) {
    const instance = getPublicInstance();
    return await instance.post('reset-password-request', { email });
}

async function resetPassword(email: string, plainPassword: string, validationCode: string) {
    const instance = getPublicInstance();
    return await instance.post('change-password', { email, plainPassword, validationCode });
}

async function registerUser(user: any) {
    const instance = getPublicInstance();
    return await instance.post('user/register', user);
}

const API_SERVICE = {
    login,
    subscriptionPrices,
    registerUser,
    resetPassword,
    checkIfEmailExists,
    forgetPassword,
    handleErrors,
    getProtectedGQLInstance,
};
export default API_SERVICE;
