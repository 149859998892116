import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import {
    createNewProfessionalCustomerContact,
    deleteContactById,
    fetchClientById,
    updateProfessionalCustomerContact,
} from 'shared/hooks/useClients';
import { Button as BaseButton, Col, Form, Input, Row, Select, Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Modal, notification } from 'antd';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import { ExclamationCircleOutlined } from '@ant-design/icons';


import './index.scss';
import { useEffect, useState } from 'react';
import {
    dotToObject,
    generateFullName,
    getDefaultPageSize,
    getPhoneText,
    paginationParams,
} from 'shared/services/Utility';
import {
    ConstantTitles,
    REGEX_STRING_VERIFY,
    REGEX_PHONE_VERIFY,
    REGEX_EMAIL_VERIFY,
} from 'shared/constants/AppConstants';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { Customer } from '../../types/customer.type';

const { Text } = Typography;
type Props = {
    customer: Customer,
    onChange?: Function
}

const { confirm } = Modal;

function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const ContactsList: React.FunctionComponent<Props> = (p) => {
    const { t } = useTranslation();
    const [customer, setCustomer] = useState(p.customer);
    const [fields, setFields] = useState([] as any[]);
    const [deletingContact, setDeletingContact] = useState(false);
    const [saving, setSaving] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const refresh = () => {
        if (!customer || !customer.id) {
            return;
        }
        fetchClientById(customer.id).then((response) => {
            if (response && response.data && response.data && response.data.customer) {
                setCustomer(response.data.customer);
            }
        }).catch((e: any) => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        });
    };
    useEffect(refresh, []);

    const [initialValues, setInitialValues] = useState({} as any);
    const handleCancel = () => {
        setCreateModalVisible(false);
        setFields([]);
        setInitialValues(null);
    };
    const columns = [
        {
            title: t('contact.listTitle.contact'),
            //TODO filteredValue: searchValue || null,
            //onFilter: (value: string, record: Client) => generateFullName(record).includes(value),
            render: (a: any) => (<span
                className={'__theme-text __primary-blue'}>{t(`genDict.${a.title}_short`)} {generateFullName(a)}</span>),
            sorter: (a: any, b: any) => sortAlpha(generateFullName(a), generateFullName(b)),
        },
        {
            title: t('contact.listTitle.email'),
            dataIndex: 'email',
            render: (email: string) => (<a href={`mailto:${email}`}>{email}</a>),
        },
        {
            title: t('contact.listTitle.phone'),
            dataIndex: 'phone',
            render: (phone: string | null) => (phone && <a href={`tel:${phone}`}>{getPhoneText(phone)}</a>),
        },
        {
            title: t('contact.listTitle.mainContact'),
            render: (a: any) => <span
                className={'__theme-text __disabled'}>{a.isMain ? t('contact.text.mainContact') : ''}</span>,

        },
        {
            fixed: 'right',
            title: t('contact.listTitle.actions'),
            render: (a: any) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('contact.tooltip.editContact')}>
                            <div/>
                            <EditButton onClick={() => {
                                setInitialValues(JSON.parse(JSON.stringify(a)));
                                setCreateModalVisible(true);
                            }}/>
                        </Tooltip>

                        <Tooltip placement={'top'} title={!a.isMain ? t('contact.tooltip.deleteContact') : ''}>
                            <div/>
                            <DeleteButton disabled={a.isMain} onClick={() =>
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('contact.confirmation.msg'),
                                    okText: t('contact.button.deleteContact'),
                                    cancelText: t('genDict.Cancel'),
                                    content: t('contact.confirmation.deleteContact'),// TODO
                                    okButtonProps: { loading: deletingContact, danger: true },
                                    onOk: async () => {
                                        setDeletingContact(true);
                                        deleteContactById(a.id).then((success) => {
                                            notification.success({
                                                message: t('genDict.success'),
                                                description: t('contact.notification.contactDeletedSuccess'),
                                            });
                                            refresh();
                                            if (p.onChange) {
                                                p.onChange();
                                            }
                                        }).catch(e => {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }).finally(() => {
                                            setDeletingContact(false);
                                        });
                                    },
                                })}/>
                        </Tooltip>
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];

    return (
        <ListView
            title={''}
            pageTitle={t('genDict.page-title', { title: t('pageTitles.viewClient') })}
            noP={true}
            searchBar={
                (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h2 className={'__form-header-title'} style={{
                        width: '100%',
                        display: 'flex',
                        marginBottom: 0,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <div className={'__form-header-title'}>
                            {t('genDict.Contacts')}</div>
                        <span className="__button">
                            <BaseButton type="primary"
                                        shape="round"
                                        size={'middle'} onClick={() => {
                                setInitialValues(JSON.parse(JSON.stringify({ isMain: customer.contacts.length === 0 })));
                                setCreateModalVisible(true);
                            }}>{t('contact.button.addContactMenu')}</BaseButton>
                        </span>
                    </h2>
                </div>)
            }>
            {
                createModalVisible && initialValues && <Modal
                    title={initialValues && initialValues.id ? t('contact.button.modifyContact') : t('contact.button.addContactMenu')}
                    visible={createModalVisible}
                    width={800}
                    maskClosable={false}
                    onCancel={handleCancel}
                    destroyOnClose={true}
                    bodyStyle={{ padding: 0 }}
                    footer={null}
                    className="contact-list__contact-modal"
                >
                    <Form
                        size="large"
                        fields={fields}
                        initialValues={initialValues}
                        onFinish={async (formValues) => {
                            const reqObject = dotToObject(formValues);
                            setSaving(true);
                            if (!initialValues || !initialValues.id) {
                                createNewProfessionalCustomerContact(reqObject, customer.id).then((response) => {
                                    notification.success({
                                        message: t('genDict.success'),
                                        description: t('contact.notification.contactCreatedSuccess'),
                                    });
                                    refresh();
                                    setCreateModalVisible(false);
                                }).catch(e => {
                                    notification.error({
                                        message: t(e),
                                    });
                                }).finally(() => {
                                    setSaving(false);
                                    if (p.onChange) {
                                        p.onChange();
                                    }
                                });
                            } else if (initialValues && initialValues.id) {
                                updateProfessionalCustomerContact(reqObject, initialValues.id).then((response) => {
                                    notification.success({
                                        message: t('genDict.success'),
                                        description: t('contact.notification.contactCreatedSuccess'),
                                    });
                                    refresh();
                                    setCreateModalVisible(false);
                                }).catch(e => {
                                    notification.error({
                                        message: t(e),
                                    });
                                }).finally(() => {
                                    setSaving(false);
                                });

                            }


                        }}
                        layout="vertical"
                    >
                        <Row gutter={16} className={'__add-contacts-wrapper'}>
                            <Col span={24}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('contact.input.civility.label')}
                                            name={'title'}
                                            initialValue={ConstantTitles[0]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('contact.input.civility.required'),
                                                },
                                            ]}>
                                            <Select>
                                                {
                                                    ConstantTitles.map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(`genDict.${option}`)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('contact.input.firstName.label')}
                                            name={'firstName'}
                                            key="contacts.firstName"
                                            rules={[
                                                { required: true, message: t('contact.input.firstName.required') },
                                                {
                                                    pattern: REGEX_STRING_VERIFY,
                                                    message: t('errorMessage.stringWrongFormat'),
                                                },
                                            ]}
                                        >
                                            <Input
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" md={8} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('contact.input.lastName.label')}
                                            name={'lastName'}
                                            rules={[
                                                { required: true, message: t('contact.input.lastName.required') },
                                                {
                                                    pattern: REGEX_STRING_VERIFY,
                                                    message: t('errorMessage.stringWrongFormat'),
                                                },
                                            ]}>
                                            <Input/>
                                        </Form.Item>

                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('contact.input.emailAddress.label')}
                                            name={'email'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('contact.input.emailAddress.required'),
                                                },
                                                {
                                                    pattern: REGEX_EMAIL_VERIFY,
                                                    message: t('contact.input.emailAddress.wrongFormat'),
                                                },
                                            ]}>
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('contact.input.phone.label')}
                                            name={'phone'}
                                            rules={[
                                                {
                                                    pattern: REGEX_PHONE_VERIFY,
                                                    message: t('contact.input.phone.wrongFormat'),
                                                },
                                            ]}
                                        >
                                            <Input
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item
                                            name={'isMain'}
                                            valuePropName="checked">
                                            <Checkbox><span
                                                className={'__text-grey text-regular'}>{t('contact.input.isMain.label')}</span></Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Space direction={'horizontal'} className={'__form-footer'}>
                                    <Form.Item>
                                        <BaseButton
                                            type="default"
                                            onClick={() => {
                                                setCreateModalVisible(false);
                                                setInitialValues({});
                                            }}
                                            disabled={saving}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {t('genDict.Cancel')}
                                        </BaseButton>
                                    </Form.Item>
                                    <Form.Item>
                                        <BaseButton
                                            type="primary"
                                            htmlType="submit"
                                            loading={saving}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {initialValues && initialValues.id ? t('contact.button.saveContact') : t('contact.button.addContact')}
                                        </BaseButton>
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
            <div className="__table-pagination-padded">
                <Table
                    locale={{
                        emptyText: (
                            <>
                                <Space style={{ padding: '10px' }}>
                                    <Text className={'primary-black'}>{t('contact.table.emptyMessage')}</Text>
                                    <div className={'flat-button primary'} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setInitialValues(JSON.parse(JSON.stringify({ isMain: customer.contacts.length === 0 })));
                                        setCreateModalVisible(true);
                                    }}>
                                        {t('contact.button.addContactMenu')}
                                    </div>
                                </Space>
                            </>
                        ),
                    }}
					showSorterTooltip ={false}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (currentPage, pageSize) => {
                            paginationParams(pageSize);
                        },
                        defaultPageSize: getDefaultPageSize(),                    }}
                    // @ts-ignore
                    columns={columns}
                    dataSource={customer.contacts}>
                </Table>
            </div>
        </ListView>
    );
};
export default ContactsList;
