import * as React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-use';

import {
    ExclamationCircleOutlined,
} from '@ant-design/icons';

import { notification, Modal, Tabs, Card } from 'antd';

const { confirm } = Modal;

import WorkSiteWorkTypes from './WorkTypes/index';
import PurchaseOrders from './PurchaseOrders/index';
import { useHistory } from 'react-router-dom';
import useDeleteWorkSiteById from '../../../hooks/work-sites/useDeleteWorkSiteById';
import Quotes from './Quotes';
import NewSubSidebarLayout from '../../../components/layout/NewSubSidebarLayout/NewSubSidebarLayout';
import { useLayoutMedia } from '../../../services/Utility';
import CreateQuote from './Quotes/CreateQuote';
import { useState } from 'react';
import CreatePurchaseOrder from './PurchaseOrders/CreatePurchaseOrder';

const { TabPane } = Tabs;
const SecondRow: React.FunctionComponent<any> = ({ workSite, setUpdating, refresh, loading }) => {
    const { t } = useTranslation();
    const [deletingItem, setDeletingItem] = React.useState(false);
    const RouteParams: any = useParams();
    const location = useLocation();
    const history = useHistory();
    const isViewMode = RouteParams['pageMode'] && RouteParams['pageMode'].toLowerCase() === 'view';
    let aI = 0;
    const [selectedQuote, setSelectedQuote] = useState(null as any);
    const [shouldShowQuoteCreate, setShouldShowQuoteCreate] = useState(-1);
    const [selectedPO, setSelectedPO] = useState(null as any);
    const [shouldShowPOCreate, setShouldShowPOCreate] = useState(-1);
    const callQuoteCreate = (i = 0) => {
        setShouldShowQuoteCreate(i);
        setTimeout(() => {
            setShouldShowQuoteCreate(-1);
        }, 1000);
    };
    const callPOCreate = (i = 0) => {
        setShouldShowPOCreate(i);
        setTimeout(() => {
            setShouldShowPOCreate(-1);
        }, 1000);
    };
    const data = [
        {
            name: 'genDict.WorkType',
            hash: '#work-types',
            count: null,
            component: <WorkSiteWorkTypes setUpdating={setUpdating}
                                          callPOCreate={callPOCreate}
                                          callQuoteCreate={callQuoteCreate}
                                          refresh={refresh} workSite={workSite}/>,
        },
        {
            name: 'genDict.PurchaseOrder',
            count: null,
            hash: '#purchase-orders',
            component: <PurchaseOrders refresh={refresh} workSite={workSite}
                                       callCreatePO={callPOCreate}
                                       setSelectedPO={
                                           (e: any) => {
                                               setSelectedPO(e);
                                               callPOCreate(2);
                                           }
                                       }
            />,
        },
        {
            name: 'genDict.Quote',
            hash: '#quotes',
            count: null,
            component: <Quotes callQuoteCreate={callQuoteCreate}
                               setSelectedQuote={(e: any) => {
                                   setSelectedQuote(e);
                                   callQuoteCreate(1);
                               }}
                               workSite={workSite}/>,
        },
    ];
    if (location['hash']) {
        aI = data.map((d) => d.hash).indexOf(location['hash'].toLowerCase());
    }
    const tabsPosition = useLayoutMedia(['Desktop', 'TabletLandscape']) ? 'left' : 'top';

    return <NewSubSidebarLayout
        pageTitle={t('genDict.page-title', { title: t(isViewMode ? 'pageTitles.viewClient' : 'pageTitles.editClient') })}
        loading={loading}
    >
        <CreatePurchaseOrder workSite={workSite} refresh={refresh} shouldShowPOCreate={shouldShowPOCreate}
                             selectedPO={selectedPO} setSelectedPO={setSelectedPO}/>
        <CreateQuote workSite={workSite} refresh={refresh} shouldShowQuoteCreate={shouldShowQuoteCreate}
                     selectedQuote={selectedQuote} setSelectedQuote={setSelectedQuote}/>
        <Tabs tabPosition={tabsPosition} activeKey={aI + ''} onChange={(e: any) => {
            history.push(data[e].hash);
        }}>
            {
                data.map((item: any, index: any) => {
                    return <TabPane tab={t(item.name)} key={index}>
                        <Card className={`transparent-content transparent-background`}>
                            {item.component}
                        </Card>
                        <div style={{ padding: '38px 0' }}>
                            <div className={'flat-button primary'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('workSite.confirmation.msg'),
                                    okText: t('workSite.button.deleteWorkSite'),
                                    cancelText: t('genDict.Cancel'),
                                    content: t('workSite.confirmation.deleteWorkSite'),// TODO
                                    okButtonProps: { loading: deletingItem, danger: true },
                                    onOk: async () => {

                                        setDeletingItem(true);
                                        useDeleteWorkSiteById(workSite.id).then((success) => {
                                            notification.success({
                                                message: t('genDict.success'),
                                                description: t('workSite.notification.workSiteDeletedSuccess'),
                                            });
                                            history.push('/chantiers');
                                        }).catch(e => {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }).finally(() => {
                                            setDeletingItem(false);
                                        });
                                    },
                                });
                            }}>
                                {t('workSite.button.deleteWorkSite')}
                            </div>
                        </div>
                    </TabPane>;
                })
            }
        </Tabs>
    </NewSubSidebarLayout>;
};
export default SecondRow;
