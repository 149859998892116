import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Button as BaseButton, Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import ListSearchBar from 'shared/components/Ant/ListSearchBar/ListSearchBar';
import { Table, Tooltip, Modal, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { getDefaultPageSize, paginationParams, useLayoutMedia } from 'shared/services/Utility';
import './index.scss';
import { useEffect, useState } from 'react';
import { filteredList, generateFullName, getMainContact, getPhoneText } from 'shared/services/Utility';
import SupplierCreateModal from './SupplierCreateModal';
import { deleteSupplierById, fetchSuppliers } from 'shared/hooks/useSuppliers';
import { Supplier } from './Supplier.model';
import { useLocation } from 'react-use';

const { Text } = Typography;

type Props = {
    t: Function,
}
const { confirm } = Modal;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const List: React.FunctionComponent<Props> = () => {
    const [items, setItems] = useState([]);
    const [createModalVisible, setCreateModalVisible] = React.useState(false);
    const [searchKey, setSearchKey] = React.useState('');
    const [fetchingItems, setFetchingItems] = React.useState(false);
    const [deletingItem, setDeletingItem] = React.useState(false);
    const isDesktop = useLayoutMedia(['Desktop']);
    const history = useHistory();
    const { t } = useTranslation();
    const refresh = () => {
        setFetchingItems(true);
        fetchSuppliers().then((response) => {
            if (response && response.data && response.data.providers) {
                setItems(response.data.providers);
                setCreateModalVisible(false);
            }
        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        }).finally(() => {
            setFetchingItems(false);
        });
    };
	// eslint-disable-next-line
    useEffect(refresh, []);
    const location = useLocation();

    useEffect(() => {
        if (location['hash'] && location['hash'].toLowerCase() && location['hash'].toLowerCase() === '#add') {
            setCreateModalVisible(true);
        }
    }, [items, location]);

    const handleCancel = () => {
        setCreateModalVisible(false);
        history.push('/fournisseurs');
    };
    const columns = [
        {
            title: t('supplier.listTitle.name'),
            //TODO filteredValue: searchValue || null,
            //onFilter: (value: string, record: Client) => generateFullName(record).includes(value),
            render: (a: Supplier) => (<Link to={`/fournisseurs/${a.id}/view`}
                                            className={'__theme-text __medium'}>{generateFullName(a)}</Link>),
            sorter: (a: Supplier, b: Supplier) => sortAlpha(generateFullName(a), generateFullName(b)),
        },
        {
            title: t('supplier.listTitle.mainContact'),
            render: (a: Supplier) => (
                <Link to={`/fournisseurs/${a.id}/view#contacts`}>{getMainContact(a.contacts, t)}</Link>),
            sorter: (a: Supplier, b: Supplier) => sortAlpha(getMainContact(a.contacts, t), getMainContact(b.contacts, t)),

        },
        {
            title: t('supplier.listTitle.address'),
            render: (a: any) => (<span className={'__theme-text __disabled'}>{a.address.address}</span>),
            sorter: (a: Supplier, b: Supplier) => sortAlpha(a.address.address, b.address.address),
        },
        {
            title: t('supplier.listTitle.pinCode'),
            render: (a: any) => (<span>{a.address.postalCode}</span>),
            sorter: (a: Supplier, b: Supplier) => sortAlpha(a.address.postalCode, b.address.postalCode),
        },
        {

            title: t('supplier.listTitle.email'),
            dataIndex: 'mail',
            render: (email: string) => (<a href={`mailto:${email}`}>{email}</a>),
        },
        {

            title: t('supplier.listTitle.phone'),
            dataIndex: 'phone',
            render: (phone: string) => (<a href={`tel:${phone}`}>{getPhoneText(phone)}</a>),
        },
        {
            fixed: 'right',
            title: t('supplier.listTitle.actions'),
            render: (a: Supplier) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('supplier.tooltip.editSupplier')}>
                            <div/>
                            <EditButton onClick={() => history.push('/fournisseurs/' + a.id + '/edit')}/>
                        </Tooltip>

                        <Tooltip placement={'top'} title={t('supplier.tooltip.deleteSupplier')}>
                            <div/>
                            <DeleteButton disabled={!a.isDeletable} onClick={() =>
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('supplier.confirmation.msg'),
                                    okText: t('supplier.button.deleteSupplier'),
                                    cancelText: t('genDict.Cancel'),
                                    content: t('supplier.confirmation.deleteSupplier'),// TODO
                                    okButtonProps: { loading: deletingItem, danger: true },
                                    onOk: async () => {
                                        setDeletingItem(true);
                                        deleteSupplierById(a.id).then(() => {
                                            notification.success({
                                                message: t('genDict.success'),
                                                description: t('supplier.notification.supplierDeletedSuccess'),
                                            });
                                            refresh();
                                        }).catch(e => {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }).finally(() => {
                                            setDeletingItem(false);
                                        });
                                    },
                                })}/>
                        </Tooltip>
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];


    const [filterByType] = useState('');
    return (
        <ListView
            title={t('genDict.Suppliers')}
            paddingBottomRequired={true}
            pageTitle={t('genDict.page-title', { title: t('pageTitles.listSuppliers') })}
            searchBar={
                (<Space className={'filter-wrapper'} direction={'horizontal'} size={20}>
                    <div className="filter-item">
                        <ListSearchBar onChange={(e: string) => {
                            setSearchKey(e);
                        }} text={t('genDict.Search')}/>
                    </div>
                </Space>)
            }
            loading={fetchingItems}
            createButton={(
                <BaseButton type="primary"
                            shape="round"
                            size={'large'} onClick={() => {
                    setCreateModalVisible(true);
                }}>
                    {isDesktop ? t('supplier.button.addSupplierMenu') : t('genDict.add')}
                </BaseButton>
            )}
        >

            <Modal
                title={t('supplier.button.addSupplierMenu')}
                visible={createModalVisible}
                width={800}
                maskClosable={false}
                onCancel={handleCancel}
                destroyOnClose={true}
                afterClose={handleCancel}

                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <SupplierCreateModal onCancel={(reload) => {
                    if (reload) {
                        refresh();
                    } else {
                        setCreateModalVisible(false);
                    }
                    handleCancel();
                }} mode={'CREATE'}/>
            </Modal>
            <Table
                locale={{
                    emptyText: (
                        searchKey || filterByType ? <>
                                <Space style={{ padding: '10px' }}>
                                    <Text className={'primary-black'}>{t('supplier.table.noSearchResultFound')}</Text>
                                </Space>
                            </>
                            : <>
                                <Space style={{ padding: '10px' }}>
                                    <Text className={'primary-black'}>{t('supplier.table.emptyMessage')}</Text>
                                    <div className={'flat-button primary'} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setCreateModalVisible(true);
                                    }}>
                                        {t('supplier.button.addSupplierMenu')}
                                    </div>
                                </Space>
                            </>
                    ),
                }}
				
				showSorterTooltip ={false}
                pagination={{
                    showSizeChanger: true,
                    onShowSizeChange: (currentPage, pageSize) => {
                        paginationParams(pageSize);
                    },
                    defaultPageSize: getDefaultPageSize(),                }}
                // @ts-ignore
                columns={columns}
                dataSource={filteredList(searchKey, items, { type: filterByType })}
            >
            </Table>
        </ListView>
    );
};
export default List;
