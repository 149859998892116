import { useMedia } from 'react-use';

export const pad = (n: any, width: any, z?: any) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const replaceVariables = (str: string, { title, name, company }: any) => {
    if (!str) {
        return '';
    }
    return str.replace('{REFERENCE}', title).replace('{CHANTIER}', name).replace('{SOCIETE}', (company && company.company && company.company.name ? company.company.name : '')).replace('{NOM_PRENOM}', generateFullName(company));
};
export const paginationParams = (pageSize: number) => {
    localStorage.setItem(window.location.pathname, JSON.stringify(pageSize));
};
export const getDefaultPageSize = () => {
    return parseInt(localStorage.getItem(window.location.pathname) || '20');
};
export const useLayoutMedia = (layoutsToVerify: string[]) => {
    let layoutMode: 'Mobile' | 'TabletPortrait' | 'TabletLandscape' | 'Desktop' = 'Desktop';
    if (useMedia('(max-width: 1024px)')) {
        layoutMode = 'TabletLandscape';
    }
    if (useMedia('(max-width: 768px)')) {
        layoutMode = 'TabletPortrait';
    }
    if (useMedia('(max-width: 576px)')) {
        layoutMode = 'Mobile';
    }
    return layoutsToVerify.indexOf(layoutMode) > -1;
};
export const filteredList = (searchKey: string, items: any[], options?: any) => {
    if (options) {
        for (let option in options) {
            if (options[option] !== '' && options[option] !== 'undefined' && options[option] !== undefined && options[option] !== null) {
                items = items.filter((item) => {
                    let matched = true;
                    let optionParts = option.split('.');
                    let itemShadow = JSON.parse(JSON.stringify(item));
                    if (optionParts.length > 1) {
                        optionParts.forEach((part: string) => {
                            if (!itemShadow[part]) {
                                matched = false;
                            } else {
                                itemShadow = itemShadow[part];
                            }
                        });
                        return matched && itemShadow === options[option];
                    }
                    return (option in item) && item[option] === options[option];
                });
            }
        }
    }
    if (!searchKey) {
        return items;
    }
    return items.filter((i: any) => {
        let passed = false;
        const c = objectToDot(i);
        for (const key in c) {
            const value = c[key];
            if (value && typeof value === 'string' && value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1) {
                passed = true;
            }
        }
        return passed;
    });
};

function parseDotNotation(str: string, val: any, obj: any) {
    let currentObj = obj,
        keys = str.split('.'),
        i, l = Math.max(1, keys.length - 1),
        key;

    for (i = 0; i < l; ++i) {
        key = keys[i];
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
    }

    if (val !== 'undefined' && val !== undefined && val !== null && val !== 'null') {
        if (typeof val === 'string') {
            val = val.trim();
        }
        currentObj[keys[i]] = val;
    }
    delete obj[str];
}

export function dotToObject(obj: any) {
    for (let key in obj) {
        if (key.indexOf('.') !== -1) {
            parseDotNotation(key, obj[key], obj);
        }
        if (obj[key] === 'undefined' || obj[key] === undefined || obj[key] === 'null' || obj[key] === null) {
            obj[key] = '';
        } else if (typeof obj[key] === 'string') {
            obj[key] = obj[key].trim();
        }
    }
    return obj;
}

export function objectToDot(obj: any) {
    let res: any = {};
    (function recurse(obj, current) {
        for (let key in obj) {
            let value = obj[key];
            let newKey = (current ? current + '.' + key : key);
            if (value && typeof value === 'object') {
                // @ts-ignore
                recurse(value, newKey);
            } else {
                if (value !== undefined && value !== 'undefined' && value !== null) {
                    if (typeof value === 'string') {
                        value = value.trim();
                    }
                    res[newKey] = value;
                }
            }
        }
    })(obj);
    return res;
}

export function generateFullName(a: any) {
    if (!a)
        return 'NA';
    return a.name || (a.firstName + ' ' + a.lastName);
}

export function getPhoneText(phone: any) {
    if (!phone)
        return '';
    return phone.replace(/(\d{2})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
}


export function getMainContact(contacts: any, t: any = null) {
    let contact: { name: string };
    if (contacts && contacts.length) {
        contacts.forEach((c: any) => {
            if (c.isMain) {
                contact = c;
            }
        });
    }
    // @ts-ignore
    return contact ? (t ? t(`genDict.${contact.title}_short`) : contact.title) + ' ' + generateFullName(contact) : '';
}

export function sortPrice(a: string, b: string) {
    const lowerAmountA = a === null ? 0 : parseFloat(a.split('-')[0]);
    const lowerAmountB = b === null ? 0 : parseFloat(b.split('-')[0]);
    if (lowerAmountA < lowerAmountB) return -1;
    if (lowerAmountA > lowerAmountB) return 1;
    return 0;
}

export function flatArticles(articles: any[], options?: any) {
    let result: any = [];
    for (let articlesKey in articles) {
        if (!articles[articlesKey].variations || !articles[articlesKey].variations.length) {
            result.push({ ...articles[articlesKey], isArticle: true });
        } else {
            articles[articlesKey].variations.forEach((variation: any) => {
                if (!variation.variations || !variation.variations.length) {
                    result.push({
                        ...variation,
                        isHeightSensitive: articles[articlesKey].isHeightSensitive,
                        categoryId: articles[articlesKey].category.id,
                        pictureUrl: articles[articlesKey].pictureUrl,
                        unit: articles[articlesKey].unit,
                        category: articles[articlesKey].category,
                    });
                } else {
                    result = [...result, ...variation.variations.map((v: any) => {
                        return {
                            ...v,
                            isHeightSensitive: articles[articlesKey].isHeightSensitive,
                            categoryId: articles[articlesKey].category.id,
                            pictureUrl: articles[articlesKey].pictureUrl,
                            unit: articles[articlesKey].unit,
                            category: articles[articlesKey].category,
                        };
                    })];
                }
            });
        }
    }
    return result;
}
