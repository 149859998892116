// @ts-ignore
import React, { useState } from 'react';

import {
    Form,
    Input,
    Modal,
    Button,
    Button as BaseButton,
    Row,
    Col,
    Select,
    Space,
    notification,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { dotToObject, objectToDot } from 'shared/services/Utility';
import { CopyOutlined } from '@ant-design/icons';
import {
    ConstantTitles,
    REGEX_STRING_VERIFY,
    REGEX_PHONE_VERIFY,
    REGEX_POSTALCODE_VERIFY,
    REGEX_EMAIL_VERIFY,
} from 'shared/constants/AppConstants';
import { UserCompany } from '../../types/user.type';
import { FileZipOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { deleteUserFromAdmin } from '../../services/userService';
import { useHistory } from 'react-router';
import Parameters from 'shared/parameters';
import { getAuthToken } from '../../contexts/AuthContext';

interface Props {
    userCompany?: UserCompany;
    loading?: boolean;
}

const { confirm } = Modal;

const { Text } = Typography;

export default function UserDetails({
                                        userCompany,
                                    }: Props) {
    const { t } = useTranslation();
    const optionValues = ConstantTitles;
    const history = useHistory();

    const [deletingUser, setDeletingUser] = useState(false);
    const [initialValues, setInitialValues] = useState(userCompany ? objectToDot(userCompany) : {});
    return (
        <Form
            className="client-create-modal"
            size="large"
            requiredMark={false}
            onValuesChange={(changedValue, formValues) => {
                setInitialValues({ ...formValues, id: initialValues.id });
            }}
            initialValues={initialValues}
            layout="vertical">
            <div style={{ padding: '20px' }}>
                <>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.firstName.label')}
                                name="primaryUser.firstName"
                                key="firstName"
                                rules={[
                                    { required: true, message: t('users.input.firstName.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['primaryUser.firstName'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.lastName.label')}
                                name="primaryUser.lastName"
                                rules={[
                                    { required: true, message: t('users.input.lastName.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
										  text: initialValues['primaryUser.lastName'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.emailAddress.label')}
                                name="primaryUser.email"
                                rules={[
                                    { required: true, message: t('users.input.emailAddress.required') },
                                    {
                                        pattern: REGEX_EMAIL_VERIFY,
                                        message: t('user.input.emailAddress.wrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={true}
                                />

                            </Form.Item>

                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['primaryUser.email'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.phone.label')}
                                name="primaryUser.phoneNumber"
                                rules={[
                                    { required: true, message: t('users.input.phone.required') }, {
                                        pattern: REGEX_PHONE_VERIFY,
                                        message: t('users.input.phone.wrongFormat'),
                                    },

                                ]}>
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>
                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['primaryUser.phoneNumber'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.company.label')}
                                name="name"
                                rules={[
                                    { required: true, message: t('users.input.company.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={true}
                                />

                            </Form.Item>

                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['name'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.IBAN.label')}
                                name="IBAN"
                                rules={[]}>
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>
                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['IBAN'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.address.label')}
                                name="billingAddress.address"
                                rules={[
                                    { required: true, message: t('users.input.address.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={true}/>
                            </Form.Item>
                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['billingAddress.address'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }

                        </Col>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.additionalAddress.label')}
                                name="billingAddress.additional"
                                rules={[]}>
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>
                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['billingAddress.additional'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.postalCode.label')}
                                name="billingAddress.postalCode"
                                rules={[
                                    { required: true, message: t('users.input.postalCode.required') },
                                    {
                                        pattern: REGEX_POSTALCODE_VERIFY,
                                        message: t('users.input.postalCode.wrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['billingAddress.postalCode'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('users.input.city.label')}
                                name="billingAddress.city"
                                rules={[
                                    { required: true, message: t('users.input.city.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['billingAddress.city'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>
                </>
            </div>
            {
                <Row>
                    <Col span={24}>
                        <Row gutter={20} align={'middle'} className={'__form-footer-parent-row __from-sm'}>
                            <Col>
                                <div className={'flat-button primary p-16'} style={{ fontSize: '16px !important' }}
                                     onClick={() =>
                                         confirm({
                                             icon: <ExclamationCircleOutlined/>,
                                             title: t('users.confirmation.msg'),
                                             okText: t('users.button.deleteUser'),
                                             cancelText: t('genDict.Cancel'),
                                             content: t('users.confirmation.deleteUser'),// TODO
                                             okButtonProps: { loading: deletingUser, danger: true },
                                             onOk: async () => {
                                                 setDeletingUser(true);
                                                 if (userCompany)
                                                     deleteUserFromAdmin(userCompany.primaryUser.id).then((success) => {
                                                         notification.success({
                                                             message: t('genDict.success'),
                                                             description: t('users.notification.userDeletedSuccess'),
                                                         });
                                                         history.push('/utilisateurs');
                                                     }).catch(e => {
                                                         notification.error({
                                                             message: t(e),
                                                         });
                                                     }).finally(() => {
                                                         setDeletingUser(false);
                                                     });
                                             },
                                         })}>
                                    {t('users.button.deleteUser')}
                                </div>
                            </Col>
                            <Col className={'__form-footer-row __from-sm'}>
                                <Row gutter={20}>
                                    <Col>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                shape="round"
                                                onClick={() => {
                                                    // @ts-ignore
                                                    window.open(`${Parameters.ApiUrl}/user/${userCompany.primaryUser.id}/csv?X-AUTH-TOKEN=${encodeURIComponent(getAuthToken().value)}`);
                                                }}
                                                size={'large'}
                                                className="__button-inverted primary">
                                                <FileZipOutlined/> {t('users.button.exportUser')}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </Form>
    );
}
