import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`mutation($id : ID!, $workTypeId: ID!){
  workSite_removeRow(id: $id, workTypeId: $workTypeId){
    id
  }
}`;
const useDeleteWorkTypeByIdFromWorkSite = async (id: string, workTypeId: string) => {
    return clientWithErrors(client.mutate({ variables: { id, workTypeId }, mutation: MUTATION }));
};
export default useDeleteWorkTypeByIdFromWorkSite;
