import React, { useEffect, useState } from 'react';


import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { useLocation } from 'react-use';
import { PasswordInput } from 'antd-password-input-strength';
import { REGEX_PASSWORD_VERIFY } from '../../../constants/AppConstants';

interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onResetPassword: (email: string, plainPassword: string, validationCode: string) => void;
    loading: boolean;
}

export default function ResetPasswordForm({
                                              fields,
                                              onChange,
                                              onResetPassword,
                                              loading,
                                          }: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const location = useLocation();
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setEmail(params.get('email') as string);
        setValidationCode(params.get('validation-code') as string);
    }, []);
    const [visibilityC, setVisibilityC] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const [email, setEmail] = useState('');

    function handleSubmit(values: any) {
        if (onResetPassword) {
            const { plainPassword } = values;
            onResetPassword(email, plainPassword, validationCode);
        }
    }

    return (
        <Form
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            initialValues={{
                email,
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMark={false}>
            <Form.Item
                label={t('resetPassword.input.password.label')}
                name="plainPassword"
                rules={[
                    { required: true, message: t('resetPassword.input.password.required') },
                    {
                        pattern: REGEX_PASSWORD_VERIFY,
                        message: t('resetPassword.input.password.wrongFormat'),
                    },
                ]}
            >
                <div className={'__password-input-with-inline-icon-wrapper'}>
                    <PasswordInput onChange={() => {
                        form.validateFields(['confirmPassword']);
                    }}/>
                </div>
            </Form.Item>
            <Form.Item
                label={t('resetPassword.input.confirmPassword.label')}
                name="confirmPassword"
                rules={[
                    { required: true, message: t('resetPassword.input.confirmPassword.required') },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (
                                !value ||
                                getFieldValue('plainPassword') === value
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                t('resetPassword.unmatched'),
                            );
                        },
                    }),
                ]}
            >
                <PasswordInput/>
            </Form.Item>
            <Form.Item>
                <div className={'__forget-password-info'}>
                    {t('resetPassword.infoOnPassword')}
                </div>
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    style={{ width: 'auto' }}
                    shape="round"
                    size={'large'}
                    className="submit"
                >
                    {t('resetPassword.button.changeMyPwd')}
                </Button>
            </Form.Item>
        </Form>
    );
}
