import React from 'react';
import { Button, Layout } from 'antd';
import image from 'shared/images/500.svg';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export default function InternalServerError() {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <Layout className="__pages-blank">
            <img style={{ height: '250px', marginBottom: '20px' }} alt="Accueil" src={image}/>
            <div className="__title">
                {t('error.no500.title')}
            </div>

            <div className="__sub-title">
                {t('error.no500.subTitle')}
            </div>
            <Button
                type="primary"
                htmlType="submit"
                shape="round"
                onClick={() => {
                    history.push('/');
                }}
                size={'middle'}
            >
                {t('error.no500.button')}
            </Button>
        </Layout>
    );
}
