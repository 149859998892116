import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Select, Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, notification } from 'antd';

import moment from 'moment';
import './index.scss';
import { useEffect, useState } from 'react';
import { filteredList, getDefaultPageSize, paginationParams } from 'shared/services/Utility';
import { fetchBills } from 'shared/services/userService';

import PDFDownloadButton from 'shared/components/Ant/Buttons/PDFDownloadButton';

const { Text } = Typography;
type Props = {
    onChange?: Function
}


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const MyBills: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const [bills, setBills] = useState([] as any[]);
    const [filterByYear, setFilterByYear] = useState('');
    const refresh = (refresh = false) => {
        fetchBills(refresh).then((response) => {
            if (response && response.data && response.data.billsByCurrent) {
                setBills(JSON.parse(JSON.stringify(response.data.billsByCurrent)));
            }
        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        });
    };
	// eslint-disable-next-line
    useEffect(refresh, []);

    const columns = [
        {
            title: t('myBills.listTitle.stripeInvoiceId'),
            dataIndex: 'reference',
            sorter: (a: any, b: any) => sortAlpha(a.reference, b.reference),
        },
        {
            title: t('myBills.listTitle.endPeriod'),
            render: (a: any) => (
                <span>{capitalize(moment(a.startPeriod).format(a.type === 'yearly' ? 'YYYY' : 'MMMM YYYY'))}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.startPeriod, b.startPeriod),
        },
        {
            title: t('myBills.listTitle.paymentDate'),
            render: (a: any) => (<span>{moment(a.paymentDate).format('DD/MM/yyyy')}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.paymentDate, b.paymentDate),
        },
        {
            title: t('myBills.listTitle.amount'),
            render: (a: any) => `${a.amount} €`,
            sorter: (a: any, b: any) => sortAlpha(a.amount, b.amount),
        },
        {
            fixed: 'right',
            title: t('myBills.listTitle.actions'),
            render: (a: any) => (
                <div className="__actionButtons">
                    {
                        a.document && <Space size={10}>
                            <Tooltip placement={'top'} title={t('myBills.tooltip.downloadInvoice')}>
                                <div/>
                                <PDFDownloadButton onClick={() => {
                                    window.open(a.document.url);
                                }}/>
                            </Tooltip>
                        </Space>
                    }
                </div>
            ),
            width: '150px',
        },
    ];

    return (
        <ListView
            title={''}
            noP={true}
            pageTitle={t('genDict.page-title', { title: t('pageTitles.myProfile') })}
            cardTitleStyle={{
                padding: 0,
            }}
            searchBar={
                (<>
                    <div className={'__sub-header-with-add-button'}>
                        <h2 className="__form-header-title " style={{
                            width: '100%',
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>

                               <span className={'__giliam-bold'}
                                     style={{ paddingTop: '5px', paddingLeft: '4px', paddingBottom: '4px'}}>
                                    {t('myBills.title')}
                               </span>
                        </h2>
                    </div>
                    <div className="filter-wrapper">
                        <div className="filter-item">
                            <Space style={{ padding: '10px' }} direction={'horizontal'} size={20}>
                                <Select style={{ width: '180px' }} value={filterByYear} onChange={(e) => {
                                    setFilterByYear(e);
                                }}>
                                    <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                                    <Select.Option value={'2021'}>2021</Select.Option>
                                </Select>
                            </Space>
                        </div>
                    </div>
                </>)
            }
        >
            <Table
                locale={{
                    emptyText: (
                        <>
                            <Space style={{ padding: '10px' }}>
                                <Text className={'primary-black'}>{t('myBills.table.emptyMessage')}</Text>
                            </Space>
                        </>
                    ),
                }}
				showSorterTooltip ={false}
                pagination={{
                    showSizeChanger: true,
                    onShowSizeChange: (currentPage, pageSize) => {
                        paginationParams(pageSize);
                    },
                    defaultPageSize: getDefaultPageSize(),
                }}
                // @ts-ignore
                columns={columns}
                dataSource={filteredList(filterByYear, bills)}
            >
            </Table>
        </ListView>
    );
};
export default MyBills;
