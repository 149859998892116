import * as React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import { useEffect, useState } from 'react';
import ClientDetails from './ClientDetails';
import SendEmailClient from './SendEmailClient';
import useSendDocument from '../../../../hooks/work-sites/useSendDocument';
import useAddQuote from '../../../../hooks/work-sites/useAddQuote';

const CreateQuote: React.FunctionComponent<any> = ({
                                                       workSite,
                                                       shouldShowQuoteCreate,
                                                       selectedQuote,
                                                       setSelectedQuote,
                                                       refresh,
                                                   }) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        if (shouldShowQuoteCreate > -1) {
            setActiveStep(shouldShowQuoteCreate);
        }
    }, [shouldShowQuoteCreate]);
    const onSubmitDetails = async (d: any) => {
        const reqObj = {
            ...d,
        };
        setLoading(true);
        try {
            const response = await useAddQuote({ workSiteId: workSite.id, ...reqObj });
            if (response && response.data && response.data.workSite_addQuote) {
                setSelectedQuote({
                    ...response.data.workSite_addQuote,
                    direct: false,
                });
                setActiveStep(1);
            }
        } catch (e) {
            notification.error({
                message: t(e),
            });
        } finally {
            setLoading(false);
        }
    };
    const onDownloadDetails = async (d: any) => {

        const reqObj = {
            ...d,
        };
        setDownloading(true);
        try {
            const response = await useAddQuote({ workSiteId: workSite.id, ...reqObj });
            if (response && response.data && response.data.workSite_addQuote) {
                setActiveStep(-1);
                refresh();
                window.open(response.data.workSite_addQuote.url);
            }
        } catch (e) {
            notification.error({
                message: t(e),
            });
        } finally {
            setDownloading(false);
        }
    };
    const onCancelDetails = () => {
        setActiveStep(-1);
    };


    const onSubmitEmail = async (d: any) => {
        const reqObj = {
            ...d,
        };
        setLoading(true);
        try {
            await useSendDocument(reqObj);
            refresh();
            setActiveStep(-1);
            notification.success({
                message: t('workSite.quotes.mailSentSuccessfully'),
            });
        } catch (e) {
            notification.error({
                message: t(e),
            });
        } finally {
            setLoading(false);
        }
    };
    const onBackEmail = () => {
        setActiveStep(1);
    };
    const onCancelEmail = () => {
        setActiveStep(-1);
        refresh();
    };

    return < >
        {
            activeStep === 0 &&
            <ClientDetails loading={loading} downloading={downloading} workSite={workSite}
                           onSubmit={onSubmitDetails}
                           onDownload={onDownloadDetails}
                           onCancel={onCancelDetails}/>
        }
        {
            activeStep === 1 &&
            <SendEmailClient loading={loading} workSite={workSite}
                             onSubmit={onSubmitEmail}
                             selectedQuote={selectedQuote}
                             onBack={onBackEmail}
                             onCancel={onCancelEmail}/>
        }
    </>;
};
export default CreateQuote;
