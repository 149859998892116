import * as React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { Card, Space, Modal, Divider, Button, Popover, Tooltip, Typography } from 'antd';
import { Link } from 'react-router-dom';

import {
    EditFilled, PhoneFilled, MailFilled,
    InfoCircleFilled,
} from '@ant-design/icons';
import { Route_ClientsView } from '../../../constants/Routes.constants';
import { generateFullName, getPhoneText } from '../../../services/Utility';
import { useState } from 'react';
import CreateModal from '../CreateModal';
import userImage from 'shared/images/position-user.svg';
import userLocation from 'shared/images/user-location.svg';

const { Text } = Typography;
const BasicInfo: React.FunctionComponent<any> = ({ workSite, refresh }) => {
    const { t } = useTranslation();
    const [editWorkSite, setEditWorkSite] = useState(false);
    return <Card  title={
        <div className="__header-text">
            {t('workSite.infoCard.headerText')}
        </div>
    } className={`__basic-info-wrapper __no-padding-card __info-cards`} extra={<a onClick={() => {
        setEditWorkSite(true);
    }}>
        <EditFilled/>
    </a>}>
        <Space size={20} className={'__info-row'}>
            <div className="__icon">
                <img src={userLocation} height={'22px'} alt=""/>
            </div>
            <div className="__text">
                <div>
                    {workSite.address.address}
                </div>
                <div>
                    {workSite.address.additional}
                </div>
                <div>
                    {workSite.address.postalCode} {workSite.address.city}
                </div>
            </div>
        </Space>
        <Modal
            title={t('workSite.editWorkSiteInformation')}
            visible={editWorkSite}
            width={1000}
            maskClosable={false}
            onCancel={() => {
                setEditWorkSite(false);
                refresh();
            }}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}>
            <CreateModal item={workSite} onCancel={() => {
                setEditWorkSite(false);
                refresh();
            }}/>
        </Modal>
        <Space size={20} className={'__info-row'} style={{ alignItems: 'flex-start' }}>
            <div className="__icon">
                <img src={userImage} height={'22px'} alt=""/>
            </div>
            <div className="__text">
                <div className={'__customer-name'}>
                    <Link to={Route_ClientsView(workSite.customer)}>{generateFullName(workSite.customer)}</Link>
                    &nbsp;&nbsp;
                    <Popover content={<div>
                        <Space size={40} direction={'vertical'}>
                            <div>
                                <Space size={22} className={'__info-row'}>
                                    <div className="__icon" style={{paddingLeft: '11px'}}>
                                        <img src={userLocation} height={'22px'} alt=""/>
                                    </div>
                                    <div className="__text">
                                        <div>
                                            {workSite.customer.address.address}
                                        </div>
                                        <div>
                                            {workSite.customer.address.additional}
                                        </div>
                                        <div>
                                            {workSite.customer.address.postalCode} {workSite.customer.address.city}
                                        </div>
                                    </div>
                                </Space>
                                {
                                    workSite.customer.taxNumber && <div>
                                        <Text><strong>{t('genDict.taxNumberShort')}:</strong></Text> <span>
                                        {workSite.customer.taxNumber}</span>
                                    </div>
                                }
                            </div>
                        </Space>
                    </div>} trigger="hover">
                        <InfoCircleFilled/>
                    </Popover>

                </div>
                <div>
                    <a href={`tel:${workSite.customer.phone}`}>
                        <PhoneFilled/> &nbsp;{getPhoneText(workSite.customer.phone)}</a>
                </div>
                <div>
                    <a href={`mailto:${workSite.customer.mail}`}>
                        <MailFilled/> &nbsp;{getPhoneText(workSite.customer.mail)}</a>
                </div>
            </div>
        </Space>
    </Card>;
};
export default BasicInfo;
