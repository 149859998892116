import * as React from 'react';
import { Trans } from 'react-i18next';
import { useState } from 'react';
import { Alert, Card } from 'antd';
import { Customer } from './InfoTab';
import PaymentForm from './PaymentForm';
import { UserOffer } from './PriceContainer';

import './PaymentTab.scss';
import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Parameters from 'shared/parameters';

const stripePromise = loadStripe(Parameters.StripePublicToken);

type Props = {
    next: (client: PaymentInfo) => void;
    customer: Customer;
    loading?: boolean;
    error?: any;
    setError?: any;
    offer: UserOffer | null
}

export type PaymentInfo = {
    firstName: string;
    lastName: string;
    company: string;
    address: string;
    address2: string;
    postalCode: string;
    city: string;
    card: {
        name: string;
        number: string;
        security: string;
    }
}


const PaymentTab: React.FunctionComponent<Props> = ({ setError, error, next, loading, offer, customer }) => {
    const [fields, setFields] = useState([] as any[]);

    function handleSubmit(values: any) {
        const copied = { ...values };
        delete copied.confirmPassword;
        next(copied);
    }


    return (
        <div className="__paymentTab">
            <Card className="__form">
                <div className="__title">
                    <span className={'__line-1'}>
                        <Trans
                            i18nKey={'signup.payment.title'}
                            values={{ value: customer.firstName }}
                        />
                    </span>
                    <br/>
                    <Trans
                        i18nKey={'signup.payment.subtitle'}
                        components={{ b: <span className="bold"/> }}
                    />

                </div>
                <Elements stripe={stripePromise}>
                    <PaymentForm
                        onChange={setFields}
                        fields={fields}
                        loading={loading}
                        onSignUp={handleSubmit}
                        offer={offer}
                        customer={customer}
                    />
                </Elements>
                {
                    error && <Alert
                        message={error}
                        type="error"
                        closable
                        onClose={() => setError('')}
                    />
                }
            </Card>
        </div>
    );
};
export default PaymentTab;
