import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { client, clientWithErrors } from './ApolloClient';

const GET_BILLS = gql`query{
  billsByCurrent{
    id
    reference
    startPeriod
    endPeriod
    isPaid
    paymentDate
    document{
        id
        url
      }
    type
    stripeInvoiceId
    company{
        id
        name
      }
    amount
  }
}`;

const GET_MY_SUBSCRIPTION = gql`query{
  subscription{
    type
    status
    nextPaymentAt
    autoRenewalAt
	expiringAt
	isCancelled
    price	
  }
}`;

const UPDATE_EMAIL = gql`mutation($input:   EditEmailInput!){
  editEmail(input: $input)
}`;

const ADD_DOCUMENT = gql`mutation($billId: ID!, $document: FileUpload!, $reference: String!){
  admin_bill_addDocument(billId: $billId, document: $document, reference: $reference){
    id
    reference
  }
}`;

const DELETE_DOCUMENT = gql`mutation($id: ID!){
  admin_bill_deleteDocument(id: $id){
  id
  }
}`;

const UPDATE_CURRENT_USER = gql`mutation($input:UserInput!){
    user_editCurrent(input: $input){
        id
        firstName
        lastName
      }
}`;

const DELETE_ADMIN_USER = gql`mutation($id:ID!){
    admin_deleteUserData(id:$id)
}`;

const UPDATE_CURRENT_USER_FROM_PROFILE = gql`mutation($input:CompanyInput!){
  company_update(input: $input){
     id
  }
}`;
const UPDATE_CURRENT_COMPANY_FULL = gql`mutation($input:CompanyInput!){
  company_update(input: $input){
     id
  }
}`;
const CANCEL_SUBSCRIPTION = gql`mutation{
  subscription_cancel
}`;

const REACTIVATE_SUBSCRIPTION = gql`mutation{
  subscription_uncancel
}`;

const UPGRADE_SUBSCRIPTION = gql`mutation($subscriptionType: String!){
  subscription_upgradeSubscription(subscriptionType: $subscriptionType){
    type
    status
    nextPaymentAt
    autoRenewalAt
    price
  }
}`;

const UPDATE_PAYMENT_INFO = gql`mutation($stripePaymentMethodId: String!){
   subscription_updatePaymentInfo(stripePaymentMethodId: $stripePaymentMethodId){
        ownerName
        last4Digits
        expirationMonth
        expirationYear
    }
}`;


export const useMySubscription: () => any = () => {
    const { data, loading, error, refetch } = useQuery(GET_MY_SUBSCRIPTION);
    let currentSubscription = null;
    if (!loading && !error) {
        currentSubscription = data.subscription;
    }
    return {
        currentSubscription,
        loading,
        refetch,
        error,
    };
};
export const fetchBills = (refresh = false) => {
    return client.query({ query: GET_BILLS });
};
export const updateEmail = (data: any) => {
    return clientWithErrors(client.mutate({ variables: { input: data }, mutation: UPDATE_EMAIL }));
};
export const addDocument = (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: ADD_DOCUMENT }));
};
export const deleteDocument = (id: any) => {
    return clientWithErrors(client.mutate({ variables: {id}, mutation: DELETE_DOCUMENT }));
};
export const updatePaymentInfo = (stripePaymentMethodId: string) => {
    return clientWithErrors(client.mutate({
        variables: { stripePaymentMethodId: stripePaymentMethodId },
        mutation: UPDATE_PAYMENT_INFO,
    }));
};
export const cancelUserSubscription = () => {
    return clientWithErrors(client.mutate({ variables: {}, mutation: CANCEL_SUBSCRIPTION }));
};
export const reactivateUserSubscription = () => {
    return clientWithErrors(client.mutate({ variables: {}, mutation: REACTIVATE_SUBSCRIPTION }));
};

export const updateCurrentUser = (data: any) => {
    return clientWithErrors(client.mutate({ variables: { input: data }, mutation: UPDATE_CURRENT_USER }));
};

export const deleteUserFromAdmin = (id: any) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_ADMIN_USER }));
};

export const updateCurrentCompanyWithDocument = (data: any) => {
    return clientWithErrors(client.mutate({ variables: { input: data }, mutation: UPDATE_CURRENT_COMPANY_FULL }));
};

export const upgradeSubscription = (subscriptionType: string) => {
    return clientWithErrors(client.mutate({
        variables: { subscriptionType: subscriptionType },
        mutation: UPGRADE_SUBSCRIPTION,
    }));
};

export const updateCurrentCompany = (data: any) => {
    return clientWithErrors(client.mutate({ variables: { input: data }, mutation: UPDATE_CURRENT_USER_FROM_PROFILE }));
};
