import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`mutation($new : Int!, $old: Int!, $id: ID!){
  workType_moveRow(id: $id, new:$new , old: $old){
    id
  }
}`;
const MoveArticleRowInWorkType = async (input: any) => {
    return clientWithErrors(client.mutate({ variables: { ...input }, mutation: MUTATION }));
};
export default MoveArticleRowInWorkType;
