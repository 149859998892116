import * as React from 'react';
import { Card, Col, Row } from 'antd';
import SignUpForm from './SignUpForm';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CGUModal from '../CGUModal';
import PriceContainer, { SubscriptionType, UserOffer } from './PriceContainer';

export type AddressInfo = {
    address: string;
    postalCode: string;
    city: string;
    lastName: string;
    firstName: string;
    company: string;
}
export type Customer = {
    subscriptionType: string;
    firstName: string;
    lastName: string;
    stripePaymentMethodId?: string;
    phone: string;
    email: string;
    companyName: string;
    password: string;
    newsletter: boolean;
    billingAddress: AddressInfo
}
type Props = {
    next: (client: Customer) => void;
    subscriptionType: SubscriptionType;
    defaultValue: Customer;
 }

const InfoTab: React.FunctionComponent<Props> = ({ next, defaultValue, subscriptionType }) => {
    const { t } = useTranslation();
    const [fields, setFields] = useState([] as any[]);
    const [cguModalVisible, setCguModalVisible] = useState<boolean>(false);

    function handleSubmit(values: any) {
        const copied = { ...values };
        delete copied.confirmPassword;
        next(copied);
    }

    return (
        <div className={'__form-wrapper __signup-form'}>
            <Card className="__form">

                <h1 className={'__title'}>{t('signup.title')}</h1>
                <p className={'__sub-title'}>{t('signup.fillAll')}</p>
                <SignUpForm
                    defaultValue={defaultValue}
                    onChange={setFields}
                    subscriptionType={subscriptionType}
                    fields={fields}
                    onSignUp={handleSubmit}
                />
            </Card>

            {cguModalVisible &&
            <CGUModal
                visible={cguModalVisible}
                onClose={() => setCguModalVisible(false)}
                values={{}}
            />
            }
        </div>
    );
};
export default InfoTab;
