import gql from 'graphql-tag';
import { client, clientWithErrors } from '../services/ApolloClient';

const GET_CLIENTS = gql`
    {
  customers{
    id
    type
    mail
    phone
    address{
    address
    additional
    postalCode
    city
  }
    title
    firstName
    lastName
    mobile
    contacts{
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
    name
    legalStatus
    taxNumber
    isDeletable
  }
}  
`;
const DELETE_CLIENT_BY_ID = gql`mutation($id: ID!){ deleteCustomer(id: $id)}`;
const DELETE_CONTACT_BY_ID = gql`mutation($id: ID!){ deleteContact(id: $id)}`;
const GET_CLIENT_BY_ID = gql`
  query Customer($id : ID!) {
  customer(id: $id){
    id
    type
    mail
    phone
    address{
        address
        additional
        postalCode
        city
      }
      quotes{
        id
        title
        type
        url
        createdAt
        workSite{
          id
          reference
          name
           address
            {
            address
            additional
            postalCode
            city
            }
        }
        isEmailSent
      }
      workSites{
        id
        reference
        name
        address
            {
            address
            additional
            postalCode
            city
            }
         sellingPrice
     }
    title
    firstName
    lastName
    mobile
    contacts{
        id
        title
        firstName
        lastName
        email
        phone
        isMain
      }
    name
    legalStatus
    taxNumber
    isDeletable
  }
}
  
`;
const CREATE_NEW_PROFESSIONAL_CUSTOMER = gql`
                        mutation($mail: String!, $phone: String!, $address: AddressInput!, $contacts:[ContactInput]!, $name: String!,$legalStatus: String!,$taxNumber: String){
                        createProfessionalCustomer(input: {
                            mail: $mail, phone: $phone, address: $address, contacts:$contacts, name: $name,legalStatus: $legalStatus,taxNumber: $taxNumber
                        }){
                            id
                            type
                            mail
                            phone
                            address{
                                address
                                additional
                                postalCode
                                city
                            }
                            title
                            firstName
                            lastName
                            mobile
                            contacts{
                                title
                                firstName
                                lastName
                                email
                                phone
                                isMain
                            }
                            name
                            legalStatus
                            taxNumber
                            isDeletable
                        }
                    }
`;

const EDIT_PROFESSIONAL_CUSTOMER = gql`
                        mutation($id: ID!, $mail: String!, $phone: String!, $address: AddressInput!,  $name: String!,$legalStatus: String!,$taxNumber: String){
                        editProfessionalCustomer(id: $id, input: {
                            mail: $mail, phone: $phone, address: $address,   name: $name,legalStatus: $legalStatus,taxNumber: $taxNumber
                        }){
                            id
                            type
                            mail
                            phone
                            address{
                                address
                                additional
                                postalCode
                                city
                            }
                            title
                            firstName
                            lastName
                            mobile
                            contacts{
                                title
                                firstName
                                lastName
                                email
                                phone
                                isMain
                            }
                            name
                            legalStatus
                            taxNumber
                            isDeletable
                        }
                    }
`;

const EDIT_PRIVATE_CUSTOMER = gql`
                        mutation($id: ID!, $title: String!,$mail: String!, $phone: String!,$mobile: String, $address: AddressInput!,  $firstName: String!,$lastName: String!){
                        editPrivateCustomer(id: $id, input: {
                            mail: $mail, phone: $phone, address: $address, firstName: $firstName, lastName: $lastName, mobile: $mobile, title: $title
                        }){
                            id
                            type
                            mail
                            phone
                            address{
                                address
                                additional
                                postalCode
                                city
                            }
                            title
                            firstName
                            lastName
                            mobile
                            contacts{
                                title
                                firstName
                                lastName
                                email
                                phone
                                isMain
                            }
                            name
                            legalStatus
                            taxNumber
                            isDeletable
                        }
                    }
`;
const CREATE_NEW_PRIVATE_CUSTOMER = gql`
                        mutation($title: String!,$mail: String!, $phone: String!,$mobile: String, $address: AddressInput!,  $firstName: String!,$lastName: String!){
                        createPrivateCustomer(input: {
                            mail: $mail, phone: $phone, address: $address, firstName: $firstName, lastName: $lastName, mobile: $mobile, title: $title
                        }){
                            id
                            type
                            mail
                            phone
                            address{
                                address
                                additional
                                postalCode
                                city
                            }
                            title
                            firstName
                            lastName
                            mobile
                            contacts{
                                title
                                firstName
                                lastName
                                email
                                phone
                                isMain
                            }
                            name
                            legalStatus
                            taxNumber
                            isDeletable
                        }
                    }
`;
const CREATE_PROFESSIONAL_CUSTOMER_CONTACT = gql`mutation($id:ID!, $title: String!, $firstName:String!, $lastName: String!, $email: String!, $phone: String,$isMain: Boolean){
  createCustomerContact(input: {title: $title, firstName:$firstName, lastName: $lastName, email: $email, phone: $phone,isMain: $isMain}, id: $id){
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
}`;
const EDIT_PROFESSIONAL_CUSTOMER_CONTACT = gql`mutation($id:ID!, $title: String!, $firstName:String!, $lastName: String!, $email: String!, $phone: String,$isMain: Boolean){
  editContact(input: {title: $title, firstName:$firstName, lastName: $lastName, email: $email, phone: $phone,isMain: $isMain}, id: $id){
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
}`;
export const fetchClients = () => {
    return clientWithErrors(client.query({ query: GET_CLIENTS }));
};

export const fetchClientById = (id: string) => {
    return clientWithErrors(client.query({ variables: { id }, query: GET_CLIENT_BY_ID }));
};

export const deleteClientById = (id: any) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_CLIENT_BY_ID }));
};

export const deleteContactById = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_CONTACT_BY_ID }));

};
export const createNewProfessionalCustomer = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: CREATE_NEW_PROFESSIONAL_CUSTOMER }));
};

export const createNewProfessionalCustomerContact = async (data: any, customerId: any) => {
    return clientWithErrors(client.mutate({
        variables: { ...data, id: customerId },
        mutation: CREATE_PROFESSIONAL_CUSTOMER_CONTACT,
    }));
};

export const updateProfessionalCustomerContact = async (data: any, contactId: any) => {
    return clientWithErrors(client.mutate({
        variables: { ...data, id: contactId },
        mutation: EDIT_PROFESSIONAL_CUSTOMER_CONTACT,
    }));
};
export const editProfessionalCustomer = async (data: any, id: any) => {
    return clientWithErrors(client.mutate({ variables: { ...data, id: id }, mutation: EDIT_PROFESSIONAL_CUSTOMER }));
};

export const createNewPrivateCustomer = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: CREATE_NEW_PRIVATE_CUSTOMER }));
};

export const editPrivateCustomer = async (data: any, id: any) => {
    return clientWithErrors(client.mutate({ variables: { ...data, id: id }, mutation: EDIT_PRIVATE_CUSTOMER }));
};

