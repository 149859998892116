import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Typography, Space } from 'antd';
 import { generateFullName, getDefaultPageSize, paginationParams } from 'shared/services/Utility';
import './index.scss';
// @ts-ignore
import moment from 'moment';
import PDFDownloadButton from '../../../../components/Ant/Buttons/PDFDownloadButton';
import MailButton from '../../../../components/Ant/Buttons/MailButton';

const { Text } = Typography;

type Props = {
    workSite: any,
    showSendEmail: any,
}


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const QuoteList: React.FunctionComponent<Props> = ({ workSite,showSendEmail }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('workSite.quotes.listTitle.reference'),
            render: (a: any) => (
                <span
                      className={'__theme-text __primary-blue __medium'}>{a.title}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.title, b.title),
        },
        {
            title: t('workSite.quotes.listTitle.createdAt'),
            render: (a: any) => (
                <span
                    className={''}>{moment(a.createdAt).format('DD/MM/YYYY')}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.createdAt, b.createdAt),
        },
        {
            title: t('workSite.quotes.listTitle.customer'),
            render: (a: any) => (
                <span
                    className={''}>{generateFullName(a.customer)}</span>),
            sorter: (a: any, b: any) => sortAlpha(generateFullName(a.customer), generateFullName(b.customer)),
        },
        {
            title: t('workSite.quotes.listTitle.customerContact'),
            render: (a: any) => (
                <span
                    className={''}>{a.receiverEmail}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.receiverEmail, b.receiverEmail),
        },		
        {
            fixed: 'right',
            title: t('workSite.quotes.listTitle.action'),
            render: (a: any) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('workSite.quotes.tooltip.download')}>
                            <div/>
                            <PDFDownloadButton onClick={() => {
                                window.open(a.url);
                            }}/>
                        </Tooltip>
                        {!a.isEmailSent &&
                        <Tooltip placement={'top'} title={t('workSite.quotes.tooltip.sendEmail')}>
                            <div/>
                            <MailButton onClick={() => {
                                showSendEmail(a)
                            }}/>
                        </Tooltip>}
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];

    return (
        <Table
            locale={{
                emptyText: (
                    <Space style={{ padding: '10px' }}>
                        <Text className={'primary-black'}>{t('workSite.quotes.table.emptyMessage')}</Text>
                    </Space>
                ),
            }}
			showSorterTooltip ={false}
            pagination={{
                showSizeChanger: true,
                onShowSizeChange: (currentPage, pageSize) => {
                    paginationParams(pageSize);
                },
                defaultPageSize: getDefaultPageSize(),            }}
            // @ts-ignore
            columns={columns}
            dataSource={workSite.quotes}
        >
        </Table>
    );
};
export default QuoteList;
