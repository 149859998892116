import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Customer } from '../../types/customer.type';

type ReturnType = {
    customers: Customer[];
    loading: boolean;
    error: any;
    refetch: any
};
const GET_CLIENTS = gql`
    {
  customers{
    id
    type
    mail
    phone
    address{
    address
    additional
    postalCode
    city
  }
    title
    firstName
    lastName
    mobile
    contacts{
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
    name
    legalStatus
    taxNumber
    isDeletable
  }
}  
`;

const useClients: () => ReturnType = () => {
    const { data, loading, error, refetch } = useQuery(GET_CLIENTS);
    let customers = [];
    if (!loading && !error) {
        customers = data.customers;
    }
    return {
        customers,
        refetch,
        loading,
        error,
    };
};
export default useClients;
