import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`
mutation($workSiteId: ID!, $providerId: ID!, $rows:[WorkSiteOrderArticleInput!]!, $comment: String, $deliveryMode: String, $deliveryDate: Date){
  workSite_addPurchaseOrder(workSiteId: $workSiteId, providerId: $providerId, rows: $rows, comment: $comment, deliveryMode: $deliveryMode, deliveryDate: $deliveryDate){
    id
    title
    type
    mime
    extension
    fileName
    fileSize
    url
    createdAt
    updatedAt
    url
    provider {
        id
        name
        mail
    }
    companyId
    isEmailSent
	receiverEmail
  }
}`;
const useAddPurchaseOrder = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: MUTATION }));
};

export default useAddPurchaseOrder;
