import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Card, Typography, Row, Col, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import EditButton from 'shared/components/Ant/Buttons/EditButton';

const { TabPane } = Tabs;

import './index.scss';
import ClientCreateModal from './ClientCreateModal';
import { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { fetchClientById } from 'shared/hooks/useClients';
import ViewButton from 'shared/components/Ant/Buttons/ViewButton';
import { useEffect } from 'react';
import { generateFullName, useLayoutMedia } from 'shared/services/Utility';
import ProfessionalClientCreateModal from './ProfessionalClientCreateModal';
import ContactsList from './ContactsList';
import { useLocation } from 'react-use';
import API_SERVICE from 'shared/services/api-service';
import { Customer } from '../../types/customer.type';
import WorkSiteList from './WorkSiteList';
import QuotationList from './QuotationList';
import NewSubSidebarLayout from '../../components/layout/NewSubSidebarLayout/NewSubSidebarLayout';
import { isAdmin } from '../../contexts/AuthContext';

const { Paragraph } = Typography;
type Props = {}


const Edit: React.FunctionComponent<Props> = () => {
    const [customer, setCustomer] = useState({} as Customer);
    const [fetchingClient, setFetchingClient] = React.useState(false);
    const { t } = useTranslation();
    const location = useLocation();
    const [tabsPosition, setTabsPosition] = useState(useLayoutMedia(['Desktop', 'TabletLandscape']) ? 'left' : 'top' as any);
    const RouteParams: any = useParams();
    const history = useHistory();
    const [activeTab, setActiveTab] = React.useState('');
    const isViewMode = RouteParams['pageMode'] && RouteParams['pageMode'].toLowerCase() === 'view';
    let aI = 0;
    const informationHeader = (<div className={'__form-header'}>
        <Title level={5} className={'__form-header-title'}>{t('genDict.Information')}</Title>
        {isViewMode ? <EditButton tooltipText={t('genDict.tooltip.editMode')} notFilled={true}
                                  style={{ color: '#0033A0', fontSize: '16px' }}
                                  onClick={() => history.push('/clients/' + customer.id + '/edit')}/> :
            <ViewButton tooltipText={t('genDict.tooltip.viewMode')} style={{ color: '#0033A0', fontSize: '16px' }}
                        onClick={() => history.push(`/clients/${customer.id}/view`)}/>}
    </div>);
    const refresh = () => {
        setFetchingClient(true);
        fetchClientById(RouteParams['clientId']).then((response) => {
            if (response && response.data && response.data.customer) {
                setCustomer(response.data.customer);
                setActiveTab(response.data.customer.type);
            }

        }).catch((e: any) => {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }).finally(() => {
            setFetchingClient(false);
        });
    };
    const dataPro = [
        {
            name: 'genDict.Information',
            hash: '#information',
            count: null,
            component: <>
                {
                    informationHeader
                }
                <ProfessionalClientCreateModal setActiveTab={setActiveTab} client={customer}
                                               onCancel={(reload) => {
                                                   if (reload) {
                                                       refresh();
                                                   }
                                               }} mode={isViewMode ? 'VIEW' : 'EDIT'}/>
            </>,
        },
        {
            name: 'genDict.Contacts',
            count: 0,
            hash: '#contacts',
            component: <ContactsList onChange={refresh} customer={customer}/>,
        },
        {
            name: 'genDict.WorkSites',
            hash: '#WorkSites',
            count: 0,
            component: <WorkSiteList customer={customer}/>,
        },
        {
            name: 'genDict.Quote',
            hash: '#quote',
            count: 0,
            component: <QuotationList customer={customer}/>,
        },
    ];

    const dataPriv = [
        {
            name: 'genDict.Information',
            hash: '#information',
            count: null,
            component: <>
                {
                    informationHeader
                }
                <ClientCreateModal setActiveTab={setActiveTab} client={customer} onCancel={(reload) => {
                    if (reload) {
                        refresh();
                    }
                }}
                                   mode={isViewMode ? 'VIEW' : 'EDIT'}/></>,
        },
        {
            name: 'genDict.WorkSites',
            hash: '#WorkSites',
            count: 0,
            component: <WorkSiteList customer={customer}/>,
        },
        {
            name: 'genDict.Quote',
            hash: '#quote',
            count: 0,
            component: <QuotationList customer={customer}/>,
        },
    ];

    if (location['hash']) {
        if (customer.type === 'pro') {
            aI = dataPro.map((d) => d.hash.toLowerCase()).indexOf(location['hash'].toLowerCase());
        } else {
            aI = dataPriv.map((d) => d.hash.toLowerCase()).indexOf(location['hash'].toLowerCase());
        }
    }

    const getTabNamePriv = (index: any) => {
        if (!customer || !customer.id) {
            return t(dataPriv[index].name);
        }
        if (index === 1) {
            return `${t(dataPriv[index].name)} (${customer.workSites.length})`;
        }
        if (index === 2) {
            return `${t(dataPriv[index].name)} (${customer.quotes.length})`;
        }
        return t(dataPriv[index].name);
    };
    const getTabNamePro = (index: any) => {
        if (!customer || !customer.id) {
            return t(dataPro[index].name);
        }
        if (index === 1) {
            return `${t(dataPro[index].name)} (${customer.contacts.length})`;
        }
        if (index === 2) {
            return `${t(dataPro[index].name)} (${customer.workSites.length})`;
        }
        if (index === 3) {
            return `${t(dataPro[index].name)} (${customer.quotes.length})`;
        }
        return t(dataPro[index].name);
    };
	// eslint-disable-next-line
    useEffect(refresh, []);


    return (
        <NewSubSidebarLayout
            // transparentContent={activeIndex === 1 && activeTab === 'pro'}
            pageTitle={t('genDict.page-title', { title: t(isViewMode ? 'pageTitles.viewClient' : 'pageTitles.editClient') })}
            loading={fetchingClient && !(customer && customer.id)}
            title={
                (
                    <Row align={'top'} wrap={false} gutter={20} style={{ width: '100%' }}>
                        <Col >
                            <Row align={'middle'} gutter={20}>
                                <Col flex="auto" style={{ marginBottom: '5px' }}>
                                    <Title level={2} style={{ maxWidth: '100%' }}>
                                        <div className={'__header-title __header-title-margin'}
                                             style={{ maxWidth: '100%' }}>
                                            <Paragraph ellipsis={{
                                                rows: 2,
                                                expandable:false,
                                                symbol: <span style={{fontSize: '16px'}}>{t('genDict.more')}</span>,
                                            }}>{generateFullName(customer)}</Paragraph>
                                        </div>
                                    </Title>
                                </Col>
                                <Col flex="auto" style={{ marginBottom: '5px' }}>
                                    <div style={{display:'inline-block'}}>
                                        <div style={{ display: 'inline-block' }}>
                                            <div
                                                className={'__theme-tags __default'}>{customer.type === 'pro' ? t('genDict.Professional') : t('genDict.Private')}</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }
        >
            {
                activeTab === 'pro' ?
                    <Tabs tabPosition={tabsPosition} activeKey={aI + ''} onChange={(e: any) => {
                        history.push(dataPro[e].hash);
                    }}>
                        {
                            dataPro.map((item: any, index: any) => {
                                return <TabPane tab={getTabNamePro(index)} key={index}>
                                    <Card className={`${aI !== 0 && 'transparent-content'}`}>
                                        {item.component}
                                    </Card>
                                </TabPane>;
                            })
                        }
                    </Tabs> :
                    <Tabs tabPosition={tabsPosition} activeKey={aI + ''} onChange={(e: any) => {
                        history.push(dataPriv[e].hash);
                    }}>
                        {
                            dataPriv.map((item: any, index: any) => {
                                return <TabPane tab={getTabNamePriv(index)} key={index}>
                                    <Card className={`${aI !== 0 && 'transparent-content'}`}>
                                        {item.component}
                                    </Card>
                                </TabPane>;
                            })
                        }
                    </Tabs>
            }

        </NewSubSidebarLayout>
    );
};
export default Edit;
