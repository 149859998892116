import React from 'react';
import { Button, Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { useTranslation } from 'react-i18next';
import image from 'shared/images/404.svg';


export default function PageNotFound() {
    const { t } = useTranslation();

    const history = useHistory();
    return (
        <Layout className="__pages-blank">
            <img style={{ height: '250px', marginBottom: '20px' }} alt="Accueil" src={image}/>

            <div className="__title">
                {t('error.no404.title')}
            </div>

            <div className="__sub-title">
                {t('error.no404.subTitle')}
            </div>
            <Button
                type="primary"
                htmlType="submit"
                shape="round"
                onClick={() => {
                    history.push('/');
                }}
                size={'middle'}>
                {t('error.no404.button')}
            </Button>
        </Layout>
    );
}
