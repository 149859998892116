import React from 'react';
import ForgetPassword from 'shared/views/auth/forget-password/ForgetPassword';


interface Props {
    location: any;
}

export default function ForgetPasswordWrapper({ location }: Props) {
    return <ForgetPassword location={location}/>
}
