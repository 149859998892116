import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`
mutation($id: ID!, $sellingPrice: String!){
  workType_personnalizeOne(input: {id: $id, sellingPrice: $sellingPrice}){
    id
    reference
    isPersonalized
  }
}`;
const usePersonalizeWorkType = async (id: string, price: string) => {
    return clientWithErrors(client.mutate({ variables: { id, sellingPrice: price }, mutation: MUTATION }));
};

export default usePersonalizeWorkType;
