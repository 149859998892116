import React, { useState } from 'react';


import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { isAdmin } from '../../contexts/AuthContext';
import { REGEX_STRING_VERIFY, REGEX_EMAIL_VERIFY } from 'shared/constants/AppConstants';

interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onLogin: (login: string, password: string) => void;
    loading: boolean;
}

export default function LoginForm({
                                      fields,
                                      onChange,
                                      onLogin,
                                      loading,
                                  }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();
    const [visibility, setVisibility] = useState(false);

    function handleSubmit(values: any) {
        if (onLogin) {
            const { login, password } = values;
            onLogin(login, password);
        }
    }

    return (
        <Form
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMark={false}
        >
            <Form.Item
                label={t('login.username.label')}
                name="login"
                rules={[
                    { required: true, message: t('login.username.required') },
                    {
                        pattern: REGEX_EMAIL_VERIFY,
                        message: t('client.input.emailAddress.wrongFormat'),
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <div className="__password">
                <Form.Item
                    label={t('login.password.label')}
                    name="password"
                    rules={[
                        { required: true, message: t('login.password.required') },
                    ]}
                >
                    <div className={'__login-password-input-wrapper '}>
                        <Input autoComplete={"off"} type={visibility ? 'text' : 'password'}/>
                        {
                            !visibility ? <EyeFilled
                                onClick={() => {
                                    setVisibility(true);
                                }}
                                className={'__visibility-icon'}
                            /> : <EyeInvisibleFilled
                                onClick={() => {
                                    setVisibility(false);
                                }}
                                className={'__visibility-icon'}
                            />
                        }
                    </div>

                </Form.Item>
                <div className={'__password-forgotten'} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    history.push('/forget-password')
                }}>
                    {t('login.password.forgotten')}
                </div>
            </div>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    shape="round"
                    size={'large'}
                    style={{width: 'auto'}}
                    className="submit"
                >
                    {t(isAdmin() ? 'login.connect' : 'login.connect')}
                </Button>
            </Form.Item>
        </Form>
    );
}
