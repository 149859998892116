// @ts-ignore
import React from 'react';
import { Form, Input, Button, Row, Col, Checkbox } from 'antd';

//import ClientTypeService from 'shared/services/ClientTypeService';
import { Trans, useTranslation } from 'react-i18next';
import { Customer } from './InfoTab';
import { SubscriptionType } from './PriceContainer';
import { PasswordInput } from 'antd-password-input-strength';

import { REGEX_STRING_VERIFY, REGEX_PHONE_VERIFY, REGEX_PASSWORD_VERIFY } from '../../../constants/AppConstants';

// @ts-ignore
import { Helmet } from 'react-helmet';
import API_SERVICE from '../../../services/api-service';

interface Props {
    fields: any[];
    defaultValue: Customer;
    subscriptionType: SubscriptionType;
    onChange: (fields: any[]) => void;
    onSignUp: (values: any) => void;
}

export default function SignUpForm({ fields, defaultValue, onChange, onSignUp, subscriptionType }: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t('genDict.page-title', { title: t('pageTitles.Signup') })}</title>
            </Helmet>
            <Form
                layout="vertical"

                size="large"
                initialValues={defaultValue}
                form={form}
                fields={fields}
                onFinish={async (v: any) => {
                    if (!v['companyName']) {
                        v['companyName'] = '';
                    }
                    onSignUp(v);
                }}
                requiredMark={false}
            >
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="email"
                            label={t('signup.input.email.label')}
                            rules={[
                                {
                                    type: 'email',
                                    message: t('signup.input.email.wrongFormat'),
                                },
                                {
                                    validator: (rule, value) => {
                                        return new Promise(async (resolve, reject) => {
                                            if (!value) {
                                                await reject(t('signup.input.email.required'));
                                            }
                                            const response = await API_SERVICE.checkIfEmailExists(value);
                                            if (response && response.data && response.data.isUsed) {
                                                await reject(t('signup.input.email.alreadyUsed'));
                                            } else {
                                                await resolve();
                                            }
                                        });
                                    },
                                },
                            ]}
                        >
                            <Input autoComplete={'dontshow'}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="phone"
                            label={t('signup.input.phone.label')}
                            rules={[
                                { required: true, message: t('signup.input.phone.required') },
                                {
                                    pattern: REGEX_PHONE_VERIFY,
                                    message: t('signup.input.phone.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>

                        <Form.Item
                            name="firstName"
                            label={t('signup.input.firstName.label')}
                            rules={[
                                { required: true, message: t('signup.input.firstName.required') },
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="lastName"
                            label={t('signup.input.lastName.label')}
                            rules={[
                                { required: true, message: t('signup.input.lastName.required') },
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="companyName"
                            label={t('signup.input.company.label')}
                            rules={[
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="password"
                            label={t('signup.input.password.label')}
                            rules={[
                                {
                                    required: true,
                                    message: t('signup.input.password.required'),
                                },
                                {
                                    pattern: REGEX_PASSWORD_VERIFY,
                                    message: t('signup.input.password.wrongFormat'),
                                },
                            ]}
                        >
                            <PasswordInput onChange={() => {
                                form.validateFields(['confirmPassword']);
                            }}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="confirmPassword"
                            label={t('signup.input.passwordConfirm.label')}
                            rules={[
                                {
                                    required: true,
                                    message: t('signup.input.passwordConfirm.required'),
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            t('signup.password.unmatched'),
                                        );
                                    },
                                }),
                            ]}
                        >
                            <PasswordInput/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                    <Col span={24}>
                        <Form.Item
                            name={'newsletter'}
                            valuePropName="checked"
                            rules={[]}
                        >
                            <Checkbox>
                           <span className="__terms-text">
                               <Trans
                                   i18nKey={'signup.input.accept.label'}
                               />
                           </span>
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        style={{ marginTop: 16 }}
                    >
                        {t(subscriptionType === 'trial' ? 'signup.button.create' : 'signup.button.next')}
                    </Button>
                </Form.Item>
                <Row>
                    <Col span={24} className="__14px roboto-regular primary-black ">
                        <Trans
                            i18nKey={'signup.cgu'}
                            components={{
                                cguLink: <a className="__terms-of-use-text" target={'_blank'}
                                            href={'https://nevoo.app/cgu/'}/>,
                                policy: <a className="__terms-of-use-text" target={'_blank'}
                                           href={'https://nevoo.app/confidentialite/'}/>,
                            }}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
}
