import React from 'react';
import { ConfigProvider } from 'antd';
import './i18n';

// Apollo
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'shared/services/ApolloClient';

import frFR from 'antd/es/locale/fr_FR';

// Router
import ProtectedRoute from 'shared/components/ProtectedRoute';
import { BrowserRouter,  Route, Switch } from 'react-router-dom';
import { AuthProvider } from 'shared/contexts/AuthContext';


// Views
import Layout from 'views/Layout';
import SignUp from 'views/auth/SignUp';


import 'antd/dist/antd.css';
import './App.scss';
import 'shared/style/theme/index.scss';
import InternalServerErrorWrapper from './views/pages/500';
import PageNotFoundWrapper from './views/pages/404';
import LoginWrapper from 'views/auth/Login';
import ForgetPasswordWrapper from './views/auth/ForgetPassword';
import ResetPasswordWrapper from './views/auth/ResetPassword';

function App() {
    //TODO Splashscreen on loading?
    //TODO disconnect.
    return (
        <ApolloProvider client={client}>
            <ConfigProvider locale={frFR}>
                <AuthProvider>
                    <BrowserRouter>
                        <Switch>

                            <Route exact path="/login" component={LoginWrapper}/>
                            <Route exact path="/forget-password" component={ForgetPasswordWrapper}/>
                            <Route exact path="/reset-password" component={ResetPasswordWrapper}/>
                            <Route exact path="/404" component={PageNotFoundWrapper}/>
                            <Route exact path="/500" component={InternalServerErrorWrapper}/>
                            <Route exact path="/confirmation-email" component={SignUp} />
                            <Route exact path="/confirmation-email-erreur" component={SignUp} />

                            {/*<Route exact path="/reset-password-request" component={ResetPasswordRequest} />*/}
                            <Route exact path="/sign-up" component={SignUp}/>
                            <ProtectedRoute path="/" component={Layout}/>
                        </Switch>
                    </BrowserRouter>
                </AuthProvider>
            </ConfigProvider>
        </ApolloProvider>

    );
}

export default App;
