// @ts-ignore
import React, { useEffect, useState } from 'react';
import {
    Form,
    Input,
    Button,
    Button as BaseButton,
    Row,
    Col,
    Select,
    Space,
    notification,
    Divider,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dotToObject, objectToDot } from 'shared/services/Utility';
import {
    createNewProfessionalCustomer, editProfessionalCustomer,
} from 'shared/hooks/useClients';
import { PlusOutlined, MinusCircleFilled, CopyOutlined } from '@ant-design/icons';
import {
    ConstantLegalStatuses,
    ConstantTitles,
    REGEX_STRING_VERIFY,
    REGEX_PHONE_VERIFY,
    REGEX_POSTALCODE_VERIFY,
    REGEX_EMAIL_VERIFY,
} from 'shared/constants/AppConstants';
import { Customer } from '../../types/customer.type';
import { Route_ClientsView } from '../../constants/Routes.constants';

interface Props {
    client?: Customer;
    onChange?: (fields: any[]) => void;
    setActiveTab?: (tab: string) => void;
    onCancel?: (refresh?: boolean) => void;
    onCreate?: (data?: any) => void;
    loading?: boolean;
    mode: 'CREATE' | 'EDIT' | 'VIEW';
}

const { Text } = Typography;

export default function ProfessionalClientCreateModal({
                                                          setActiveTab,
                                                          onCancel,
                                                          client,
                                                          onCreate,
                                                          mode,
                                                      }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const fields: any = [];
    const [form] = Form.useForm();
    const optionValues = ConstantTitles;
    const legalStatuses = ConstantLegalStatuses;
    const [savingCustomer, setSavingCustomer] = React.useState(false);
    const [initialValues, setInitialValues] = useState(client ? objectToDot(client) : {});
    useEffect(() => {
        setInitialValues(client ? objectToDot(client) : {});
    }, [client]);
    return (<Form
        className="pro-client-create-modal"
        size="large"
        form={form}
        fields={fields}
        requiredMark={mode !== 'VIEW'}
        initialValues={initialValues}
        onFinish={async (formValues) => {
            const reqObject = dotToObject(formValues);
            reqObject['contacts'] = reqObject['contacts'] ? reqObject['contacts'] : [];
            reqObject['type'] = 'pro';
            setSavingCustomer(true);
            if (reqObject['contacts'][0]) {
                reqObject['contacts'][0].isMain = true;
            }
            if (mode === 'CREATE') {
                createNewProfessionalCustomer(reqObject).then((response) => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('client.notification.clientCreatedSuccess'),
                    });
                    if (onCancel) {
                        onCancel(true);
                    }
                    if (onCreate) {
                        onCreate(response.data);
                    }
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingCustomer(false);
                });
            } else if (client) {


                editProfessionalCustomer(reqObject, client.id).then((response) => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('client.notification.clientUpdatedSuccess'),
                    });
                    if (onCancel) {
                        onCancel(true);
                    }
                    history.push(Route_ClientsView(client));
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingCustomer(false);
                });
            }


        }}
        layout="vertical"
    >
        <div style={{ padding: '20px' }}>
            {
                mode === 'CREATE' &&
                <div className="__tab-switch" style={{ marginBottom: '20px' }}>
                    <BaseButton key="back" type={'default'} onClick={() => {
                        if (setActiveTab) {
                            setActiveTab('priv');
                        }
                    }}>
                        <div className={'roboto-regular'}>
                            {t('genDict.Private')}
                        </div>
                    </BaseButton>
                    <BaseButton key="back" className={'roboto-regular'} type={'primary'} onClick={() => {
                    }}>

                        <div className={'roboto-regular'}>
                            {t('genDict.Professional')}
                        </div>
                    </BaseButton>
                </div>
            }
            <>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.name.label')}
                            name="name"
                            key="name"
                            rules={[
                                { required: true, message: t('client.input.name.required') },
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.name,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.legalStatus.label')}
                            name="legalStatus"
                            rules={[
                                { required: true, message: t('client.input.legalStatus.required') },
                            ]}
                        >
                            <Select
                                disabled={mode === 'VIEW'}
                                placeholder={t('genDict.Select')}
                            >
                                {
                                    legalStatuses.map((option: any) => {
                                        return <Select.Option value={option}>{t(option)}</Select.Option>;
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.emailAddress.label')}
                            name="mail"
                            rules={[
                                { required: true, message: t('client.input.emailAddress.required') },
                                {
                                    pattern: REGEX_EMAIL_VERIFY,
                                    message: t('client.input.emailAddress.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.mail,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.phone.label')}
                            name="phone"
                            rules={[
                                { required: true, message: t('client.input.phone.required') },
                                {
                                    pattern: REGEX_PHONE_VERIFY,
                                    message: t('client.input.phone.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.phone,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.taxNumber.label')}
                            name="taxNumber"
                            rules={[
                                {
                                    pattern: /(FR)?[0-9]{11}/,
                                    message: t('client.input.taxNumber.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.taxNumber,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.address.label')}
                            name="address.address"
                            rules={[
                                { required: true, message: t('client.input.address.required') },
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.address'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.additionalAddress.label')}
                            name="address.additional"
                            rules={[
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}

                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.additional'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.postalCode.label')}
                            name="address.postalCode"
                            rules={[
                                { required: true, message: t('client.input.postalCode.required') },
                                {
                                    pattern: REGEX_POSTALCODE_VERIFY,
                                    message: t('client.input.postalCode.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.postalCode'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('client.input.city.label')}
                            name="address.city"
                            rules={[
                                { required: true, message: t('client.input.city.required') },
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && mode === 'VIEW' &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.city'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                </Row>

                {
                    !client && <Divider/>}
                {
                    !client && <Form.List name="contacts">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <>
                                        <Row gutter={16} className={'__add-contacts-wrapper'}>
                                            <Col span={23}>
                                                <Row gutter={16}>
                                                    <Col className="__theme-small-input-wrapper gutter-row" md={8}
                                                         sm={24} xs={24}>
                                                        <Form.Item
                                                            initialValue={ConstantTitles[0]}
                                                            name={[field.name, 'title']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.civility.required'),
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                placeholder={t('client.input.civility.label') + '*'}
                                                                disabled={mode === 'VIEW'}
                                                            >
                                                                {
                                                                    optionValues.map((option) => {
                                                                        return <Select.Option
                                                                            value={option}>{t(`genDict.${option}`)}</Select.Option>;
                                                                    })
                                                                }

                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row __theme-small-input-wrapper" md={8}
                                                         sm={24} xs={24}>
                                                        <Form.Item
                                                            name={[field.name, 'firstName']}
                                                            key="firstName"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.firstName.required'),
                                                                },
                                                                {
                                                                    pattern: REGEX_STRING_VERIFY,
                                                                    message: t('errorMessage.stringWrongFormat'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.firstName.label') + '*'}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row __theme-small-input-wrapper" md={8}
                                                         sm={24} xs={24}>
                                                        <Form.Item
                                                            name={[field.name, 'lastName']}

                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.lastName.required'),
                                                                },
                                                                {
                                                                    pattern: REGEX_STRING_VERIFY,
                                                                    message: t('errorMessage.stringWrongFormat'),
                                                                },
                                                            ]}>
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.lastName.label') + '*'}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}/>
                                                        </Form.Item>

                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col
                                                        className="gutter-row __theme-small-input-wrapper __hover-copy-input"
                                                        md={8} sm={24} xs={24}>

                                                        <Form.Item
                                                            name={[field.name, 'email']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.emailAddress.required'),
                                                                },
                                                                {
                                                                    pattern: REGEX_EMAIL_VERIFY,
                                                                    message: t('client.input.emailAddress.wrongFormat'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.emailAddress.label') + '*'}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        className="gutter-row __theme-small-input-wrapper __hover-copy-input"
                                                        md={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name={[field.name, 'phone']}
                                                            rules={[
                                                                {
                                                                    pattern: REGEX_PHONE_VERIFY,
                                                                    message: t('client.input.phone.wrongFormat'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.phone.label')}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={1} sm={24} xs={24} style={{ textAlign: 'center' }}>
                                                <MinusCircleFilled className={'icon-primary __minus-icon'}
                                                                   onClick={() => {
                                                                       remove(field.name);
                                                                   }}/>
                                            </Col>
                                        </Row>

                                    </>
                                ))}

                                {
                                    fields.length === 0 && <Form.Item style={{ marginTop: '20px' }}>
                                        <Button
                                            className="__add-contact-btn roboto-regular"
                                            type="dashed"
                                            size="large"
                                            onClick={() => {
                                                add();
                                            }} block icon={<PlusOutlined/>}>
                                            {
                                                t('client.button.addContactToClient')
                                            }
                                        </Button>
                                    </Form.Item>
                                }
                            </>
                        )}
                    </Form.List>
                }

            </>

        </div>
        {
            mode !== 'VIEW' && <Row>
                <Col span={24}>
                    <Space direction={'horizontal'} className={'__form-footer'}>
                        {
                            mode === 'CREATE' && <Form.Item>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        if (onCancel) {
                                            onCancel();
                                        }
                                    }}
                                    disabled={savingCustomer}
                                    shape="round"
                                    size={'large'}
                                    className="submit"
                                >
                                    {t('genDict.Cancel')}
                                </Button>
                            </Form.Item>
                        }
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={savingCustomer}
                                shape="round"
                                size={'large'}
                                className="submit"
                            >
                                {mode === 'CREATE' ? t('client.button.addClient') : t('client.button.saveClient')}
                            </Button>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        }
    </Form>);
}
