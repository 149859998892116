import * as React from 'react';
import { ReactElement } from 'react';
import { Skeleton, Typography, Row, Col } from 'antd';
// @ts-ignore
import { Helmet } from 'react-helmet';

const { Text } = Typography;

import './ListView.scss';

type Props = {
    title?: string;
    subTitle?: any;
    pageTitle?: string;
    paddingBottomRequired?: boolean;
    createButton?: ReactElement;
    subAction?: ReactElement;
    loading?: boolean;
    noLayoutP?: boolean;
    noP?: boolean;
    hideSearchSection?: boolean;
    cardTitleStyle?: any,
    searchBar?: ReactElement;
}

const ListView: React.FunctionComponent<Props> = ({
                                                      noP,
                                                      noLayoutP,
                                                      title,
                                                      pageTitle,
                                                      subTitle,
                                                      paddingBottomRequired,
                                                      hideSearchSection,
                                                      subAction,
                                                      searchBar,
                                                      loading,
                                                      cardTitleStyle,
                                                      createButton,
                                                      children,
                                                  }) => {
    return (
        <div className={`__listView ${paddingBottomRequired && 'padding-bottom-required'} `}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{pageTitle}</title>
            </Helmet>
            <div className={'__inner-list-view'}>
                <div className={`__title-inner-section ${noLayoutP && 'no-p'}`}>
                    {
                        (title || createButton) &&
                        <Row gutter={16} className={'__title-section'}>
                            <Col flex="auto" style={{ paddingLeft: 0 }}>
                                <h1 className="__title">
                                    {!loading ? <Text style={{ width: '100%' }} ellipsis={true}>{title}</Text> :
                                        <Skeleton.Button style={{ marginTop: '20px' }} size={'large'} active={true}/>}

                                </h1>
                            </Col>
                            {createButton && <Col style={{ paddingRight: 0 }}>
                                <div className="__button">
                                    {!loading ? createButton :
                                        <Skeleton.Button style={{ marginTop: '20px' }} size={'large'} active={true}/>}
                                </div>
                            </Col>}
                        </Row>
                    }
                    {
                        subTitle && <div className={'__sub-title-section'}>
                            {
                                subTitle
                            }
                        </div>
                    }
                </div>
                <div
                    className={`__body-inner-section ${(noP || noLayoutP) && 'no-p'} ${(createButton || subTitle || title) && 'has-header'}`}>

                    <div className={'__listView-inner-wrapper'}>
                        {
                            !hideSearchSection && <div className="__card-title __search-items" style={cardTitleStyle}>
                                {!loading ? searchBar :
                                    <Skeleton.Button style={{ width: 300 }} size={'large'} active={true}/>}
                            </div>
                        }
                        {!loading ? children : <Skeleton active/>}
                    </div>
                    {/*<div style={{ height: '90px' }}/>*/}
                </div>
            </div>
        </div>
    );
};
export default ListView;
