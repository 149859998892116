import { Customer } from '../types/customer.type';
import { User, UserCompany } from '../types/user.type';

export const Route_ClientsList = 'clients';
export const Route_ClientsView = (a: Customer) => `/clients/${a.id}/view`;
export const Route_ClientsEdit = (a: Customer) => `/clients/${a.id}/edit`;

export const Route_WorkSitesList = 'chantiers';
export const Route_WorkSitesCreate = 'chantiers/create';
export const Route_WorkSitesView = (a: any) => `/chantiers/${a.id}/view`;
export const Route_WorkSitesEdit = (a: any) => `/chantiers/${a.id}/edit`;


export const Route_WorkTypesView = (a: any) => `/ouvrages/${a.id}/view`;
export const Route_WorkTypesList = `/ouvrages/`;

export const Route_UsersList = 'utilisateurs';
export const Route_UsersView = (a: UserCompany) => `/utilisateurs/${a.id}/view`;
