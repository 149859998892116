import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Typography } from 'antd';
import LoginForm from './LoginForm';

import './Login.scss';
// @ts-ignore
import logo from 'shared/images/logo.svg';
import { useTranslation } from 'react-i18next';

import { AuthConsumer, isAdmin } from 'shared/contexts/AuthContext';
import API_SERVICE from 'shared/services/api-service';
// @ts-ignore
import { Helmet } from 'react-helmet';

const { Text } = Typography;

interface Props {
    location: any;
}

export default function Login({ location }: Props) {
    const { t } = useTranslation();
    const [fields, setFields] = useState([] as any[]);
    const [loginError, setLoginError] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailChanged, setEmailChanged] = useState(false);
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (params['message'] && params['message'] === 'email-changed') {
            setEmailChanged(true);
        }
    }, []);
    const { from } = location.state || { from: { pathname: '/' } };
    return (
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t('genDict.page-title', { title: t('pageTitles.Login') })}</title>
            </Helmet>
            <AuthConsumer>
                {({ isAuth, updateAuthToken }) =>
                    isAuth ? (
                        <Redirect to={from}/>
                    ) : (
                        <div className="login">
                            <div className={`__header ${isAdmin() && '__header-admin'}`}/>
                            <div className="__content">
                                <div className={'form-outer-wrapper'}>
                                    <img src={logo} alt="logo"/>
                                    <h1 className={'__login-title'}>{t(!isAdmin() ? 'login.title' : 'login.titleAdmin')}</h1>
                                    <LoginForm
                                        onChange={setFields}
                                        fields={fields}
                                        onLogin={async (login: string, password: string) => {
                                            setLoading(true);
                                            setLoginError('');
                                            try {
                                                const { data } = await API_SERVICE.login(login, password);
                                                updateAuthToken(data);
                                                localStorage.setItem('show-popup', 'false');
                                            } catch (e) {
                                                setLoginError(t(API_SERVICE.handleErrors(e)));
                                            }
                                            setLoading(false);
                                        }}
                                        loading={loading}
                                    />
                                    {
                                        loginError && <Text type="danger">{loginError}</Text>
                                    }
                                    {
                                        !isAdmin() && !emailChanged && <p className="link-register">
                                            {t('login.noAccount')}
                                            <Link className="__btn-link " to="/sign-up">
                                            <span className={'__register-link'}>
                                            {t('login.register')}
                                        </span>
                                            </Link>
                                        </p>
                                    }
                                    {
                                        !isAdmin() && emailChanged && <p className="link-register">
                                            {t('login.emailChangedMessage')}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </AuthConsumer>
        </>
    );
}
