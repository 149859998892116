import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const GET_PAYMENT_METHODS = gql`query{
  paymentMethod{
    ownerName
    last4Digits
    expirationMonth
    expirationYear
  }
}`;
const usePaymentMethods: () => any = () => {
    const { data, loading, error, refetch } = useQuery(GET_PAYMENT_METHODS);
    let paymentMethod = [];
    if (!loading && !error) {
        paymentMethod = data.paymentMethod;
    }
    return {
        paymentMethod,
        refetch,
        loading,
        error,
    };
};
export default usePaymentMethods;
