import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { UserCompany } from '../../types/user.type';

type ReturnType = {
    admin_userCompany: UserCompany;
    loading: boolean;
    error: any;
    refetch: any
};
const GET_USER_COMPANY = gql`
    query AdminUserCompany($id: ID!){
  admin_userCompany(id: $id){
    id
    createdAt
    name
    users{
    id
    firstName
    lastName
    username
    email
    reference
    phoneNumber
    enabled
    roles
    isSuperAdmin
    emailChangementPending
    
    stopShowPersonalize
  }
     address{
    address
    additional
    postalCode
    city
  }
      billingAddress{
    address
    additional
    postalCode
    city
    firstName
    lastName
    company
  }
    bankName
    accountName
    IBAN
    BIC
    displayBankInformationOnQuote
    hideLogo
    pictureBackgroundUrl
    pictureUrl
    footer
     subscription{
    type
    status
    nextPaymentAt
    autoRenewalAt
    price
  }
  }
} 
`;

const useCompanyUser: (id: any) => ReturnType = (id: any) => {
    const { data, loading, error, refetch } = useQuery(GET_USER_COMPANY, {
        variables: { id },
    });
    let admin_userCompany = [];
    if (!loading && !error && data.admin_userCompany) {
        data.admin_userCompany.primaryUser = data.admin_userCompany.users[0];
        admin_userCompany = data.admin_userCompany;
    }
    return {
        admin_userCompany,
        refetch,
        loading,
        error,
    };
};
export default useCompanyUser;
