import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Typography, Space } from 'antd';
import {
    CheckCircleFilled,
    CloseCircleFilled,
    ExclamationCircleFilled,
} from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import { filteredList, generateFullName, getDefaultPageSize, paginationParams } from 'shared/services/Utility';
import { Route_UsersView } from '../../constants/Routes.constants';
import './index.scss';
import { UserCompany } from '../../types/user.type';
// @ts-ignore
import moment from 'moment';

const { Text } = Typography;

type Props = {
    searchKey: string,
    refresh?: any,
    admin_userCompanies: UserCompany[]
}


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const List: React.FunctionComponent<Props> = ({ admin_userCompanies, searchKey, refresh }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const columns = [
        {
            title: t('users.listTitle.reference'),
            render: (a: UserCompany) => (
                <Link to={Route_UsersView(a)}
                      className={'__theme-text __medium'}>{a.primaryUser.reference}</Link>),
            sorter: (a: UserCompany, b: UserCompany) => sortAlpha(a.primaryUser.reference, b.primaryUser.reference),
        },
        {
            title: t('users.listTitle.name'),
            render: (a: UserCompany) => (
                <Link to={Route_UsersView(a)}
                      className={'__theme-text __medium'}>{generateFullName(a.primaryUser)}</Link>),
            sorter: (a: UserCompany, b: UserCompany) => sortAlpha(generateFullName(a.primaryUser), generateFullName(b.primaryUser)),
        },
        {
            title: t('users.listTitle.registrationDate'),
            render: (a: UserCompany) => (
                <span>{moment(a.createdAt).format('DD/MM/yyyy')}</span>),
            sorter: (a: UserCompany, b: UserCompany) => sortAlpha(a.createdAt, b.createdAt),
        },
        {

            title: t('users.listTitle.email'),
            sorter: (a: UserCompany, b: UserCompany) => sortAlpha(a.primaryUser.email, b.primaryUser.email),
            render: (a: UserCompany) => (<a href={`mailto:${a.primaryUser.email}`}>{a.primaryUser.email}</a>),
        },
        {

            title: t('users.listTitle.phone'),
            sorter: (a: UserCompany, b: UserCompany) => sortAlpha(a.primaryUser.phoneNumber, b.primaryUser.phoneNumber),
            render: (a: UserCompany) => (<a href={`tel:${a.primaryUser.phoneNumber}`}>{a.primaryUser.phoneNumber}</a>),
        },
        {

            title: t('users.listTitle.offerType'),
            render: (a: UserCompany) => (
                <span>{a.subscription ? t(`mySubscription.type.${a.subscription.type}`) : 'NA'}</span>),
            sorter: (a: UserCompany, b: UserCompany) => sortAlpha(a.subscription ? a.subscription.type : '', b.subscription ? b.subscription.type : ''),
        },
        {

            title: <div className={'text-left'}>{t('users.listTitle.subscriptionStatus')}</div>,
            render: (a: UserCompany) => (
                <div className={'text-left'}>  {
                    !!a.subscription ?
                        (a.subscription.status === 'active' ? <CheckCircleFilled
                            className={`__theme-icon  success `}/> : <ExclamationCircleFilled
                            className={`__theme-icon danger`}/>)
                        : <CloseCircleFilled
                            className={`__theme-icon default`}/>
                }</div>),
            sorter: (a: UserCompany, b: UserCompany) => sortAlpha(a.subscription ? a.subscription.status : '', b.subscription ? b.subscription.status : ''),

        },
        {
            fixed: 'right',
            title: t('users.listTitle.actions'),
            render: (a: UserCompany) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('users.tooltip.viewUser')}>
                            <div/>
                            <EditButton onClick={() => history.push(Route_UsersView(a))}/>
                        </Tooltip>
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];

    return (
        <Table
            locale={{
                emptyText: (
                    searchKey ? <>
                        <Space style={{ padding: '10px' }}>
                            <Text className={'primary-black'}>{t('users.table.noSearchResultFound')}</Text>
                        </Space>
                    </> : <>
                        <Space style={{ padding: '10px' }}>
                            <Text className={'primary-black'}>{t('users.table.emptyMessage')}</Text>
                        </Space>
                    </>
                ),
            }}
			showSorterTooltip ={false}
            pagination={{
                showSizeChanger: true,
                onShowSizeChange: (currentPage, pageSize) => {
                    paginationParams(pageSize);
                },
                defaultPageSize: getDefaultPageSize(),            }}
            // @ts-ignore
            columns={columns}
            dataSource={filteredList(searchKey, admin_userCompanies, {})}
        >
        </Table>
    );
};
export default List;
