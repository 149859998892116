// Apollo

import { ApolloClient, DefaultOptions } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { getAuthToken } from 'shared/contexts/AuthContext';
import { HttpLink } from 'apollo-link-http';
import BaseUrlConstants from '../constants/BaseUrlConstants';
import { ApolloLink, from } from 'apollo-link';

let AUTH_TOKEN: string | null = null;

const cache: any = new InMemoryCache();

const httpLink = new HttpLink({
    uri: BaseUrlConstants.BASE_URL + 'graphql/',
});

// @ts-ignore
const uploadLink = new createUploadLink({
    uri: BaseUrlConstants.BASE_URL + 'graphql/',
});

let subscription: any;

const authLink = new ApolloLink((operation, forward) => {
    let authToken: any = localStorage.getItem('auth-token');
    if (authToken) {
        authToken = JSON.parse(authToken);
        authToken.updatedAt = new Date();
        localStorage.setItem('auth-token', JSON.stringify(authToken));
    }
    operation.setContext({
        headers: {
            'X-Auth-Token': getAuthToken() ? getAuthToken().value : null,
        },
    });

    return forward(operation);
});

const loggOutAfterware = onError(({ graphQLErrors }) => {
    if (!graphQLErrors) {
        return;
    }
    const error = graphQLErrors.filter((pError: any) => pError.unauthenticated)[0];

    if (error) {
        //AuthContext.logout();
    }
});
const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'all',
    },
};
const client = new ApolloClient({
    defaultOptions,
    link: from([loggOutAfterware, authLink, uploadLink, httpLink]),
    cache,
});

function updateAuthToken(authToken: any) {
    AUTH_TOKEN = authToken;
}

//AuthContext.addListener(updateAuthToken);

const clientWithErrors = async (req: any) => {
    try {
        const response = await req;
        if (response && response.errors && response.errors.length) {
            throw showApolloErrors(response.errors);
        }
        return response;
    } catch (e) {
        throw e;
    }
};
const showApolloErrors = (e: any[]) => {
    let message = 'Something went wrong !!';
    let errors: string[] = [];
    e.forEach((error: any) => {
        if (error.state && error.state.formErrors) {
            error.state.formErrors.forEach((error1: any) => {
                if (error1.message) {
                    errors.push(error1.message);
                }
            });
        } else if (error.message) {
            errors.push(error.message);
        }
    });
    return errors.length ? errors.join(', ') : message;
};
export { client, clientWithErrors, updateAuthToken };
