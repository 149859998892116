import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Space, Select, Button } from 'antd';

import { useTranslation } from 'react-i18next';
import ListSearchBar from 'shared/components/Ant/ListSearchBar/ListSearchBar';
import UsersTable from './UsersTable';
import useCompanyUsers from '../../hooks/users/useCompanyUsers';
import Parameters from 'shared/parameters';
import { getAuthToken } from '../../contexts/AuthContext';
import './index.scss';

type Props = {}
const List: React.FunctionComponent<Props> = () => {
    const { admin_userCompanies, refetch: refresh, loading: fetchingUsers } = useCompanyUsers();
    const [fiterParType, setFiterParType] = React.useState('');
    const [fiterParStatus, setFiterParStatus] = React.useState('');
    const [searchKey, setSearchKey] = React.useState('');
    const { t } = useTranslation();
    const getListItems = () => {
        let result = [...admin_userCompanies];
        if (fiterParType) {
            result = result.filter((a: any) => {
                return a.subscription && a.subscription.type === fiterParType;
            });
        }
        if (fiterParStatus) {
            result = result.filter((a: any) => {
                return (fiterParStatus === 'active' && a.subscription && a.subscription.status === 'active') || (fiterParStatus === 'expired' && !a.subscription);
            });
        }
        return result;
    };

    function downloadCsv() {
        window.open(`${Parameters.ApiUrl}/users.csv?X-AUTH-TOKEN=${encodeURIComponent(getAuthToken().value)}`);
    }

    return (
        <ListView
            pageTitle={t('genDict.page-title', { title: t('pageTitles.listUsers') })}
            title={t('genDict.Users')}
            loading={fetchingUsers}
            paddingBottomRequired={true}
            searchBar={
                (

                    <Space direction={'horizontal'} className={'filter-wrapper'}
                           style={{ justifyContent: 'space-between', display: 'flex' }} size={20}>
                        <div className="filter-item">
                            <ListSearchBar onChange={(e: string) => {
                                setSearchKey(e);
                            }} text={t('genDict.Search')}/>
                            <Button
                                type="primary"
                                shape="round"
                                style={{marginLeft: 8}}
                                onClick={downloadCsv}
                                >
                                {t('genDict.exportCsv')}
                            </Button>
                        </div>
                        <div className="filter-item">
                            <Space direction={'horizontal'} className={'filter-wrapper'}>
                                <div className="filter-item">

                                    <Select style={{ width: '240px' }} value={fiterParType || undefined}
                                            onChange={(e: any) => {
                                                setFiterParType(e);
                                            }} placeholder={t('users.table.search.fiterParOffer')}>
                                        <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                                        <Select.Option value={'monthly'}>{
                                            t('mySubscription.type.monthly')
                                        }
                                        </Select.Option>
                                        <Select.Option value={'yearly'}>{
                                            t('mySubscription.type.yearly')
                                        }
                                        </Select.Option>
                                        <Select.Option value={'trial'}>{
                                            t('mySubscription.type.trial')
                                        }
                                        </Select.Option>
                                    </Select>
                                </div>
                                <div className="filter-item">

                                    <Select style={{ width: '240px' }} value={fiterParStatus || undefined}
                                            onChange={(e: any) => {
                                                setFiterParStatus(e);
                                            }} placeholder={t('users.table.search.filterParStatus')}>
                                        <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                                        <Select.Option value={'active'}>{
                                            t('mySubscription.status.active')
                                        }
                                        </Select.Option>
                                        <Select.Option value={'expired'}>{
                                            t('mySubscription.status.expired')
                                        }
                                        </Select.Option>
                                    </Select>
                                </div>
                            </Space>
                        </div>
                    </Space>)
            }
        >
            <UsersTable refresh={() => {
                refresh();
            }} searchKey={searchKey} admin_userCompanies={getListItems()}/>
        </ListView>
    );
};
export default List;
