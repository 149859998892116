import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Typography, Image } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import '../LoaderOverlay.scss';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'antd';
import { hideTutorialPopup } from '../../services/articleService';
import slide1 from 'shared/images/Slide1.svg';
import slide2 from 'shared/images/Slide2.svg';
import slide3 from 'shared/images/Slide3.svg';
import slide4 from 'shared/images/Slide4.svg';
import { useMedia } from 'react-use';

const { Text } = Typography;

export default function TutorialPopupComponent({ onFinish }: any) {
  const { t } = useTranslation();
  const [sliderRef, setSliderRef] = useState(null as any);
  const [showPopup, setShowPopup] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const history = useHistory();
  const handleHide = () => {
    localStorage.setItem('hidePopupTutorial', 'true');
    setShowPopup(false);
    onFinish();
  };
  const onChange = (...a: any) => {
    console.log(a);
  };

  return (
    <div className={`__page-custom-timeout ${showPopup && 'active'}`}>
      <Modal
        visible={showPopup}
        width={800}
        maskClosable={false}
        title={t('tutorial.tutorialHeader')}
        onCancel={() => {
          handleHide();
        }}
        destroyOnClose={true}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <div className="__connection-timeout-popup-body-wrapper">

          <Carousel ref={node => (setSliderRef(node))} afterChange={onChange} dots={false}>
            <div className={'tutorial-slide-wrapper'}>
              <Row gutter={20} justify={'center'} align={'middle'}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <img style={{ height: '250px', margin: 'auto' }} alt="Accueil" src={slide1}/>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div className={'title'}>
                    {t('tutorial.slide1Header')}
                  </div>
                  <div className={'sub-title'}>
                    {t('tutorial.slide1Text')}
                  </div>
                </Col>
              </Row>
            </div>
            <div className={'tutorial-slide-wrapper'}>
              <Row gutter={20} justify={'center'} align={'middle'}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <img style={{ height: '250px', margin: 'auto' }} alt="Accueil" src={slide2}/>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div className={'title'}>
                    {t('tutorial.slide2Header')}
                  </div>
                  <div className={'sub-title'}>
                    {t('tutorial.slide2Text')}
                  </div>
                </Col>
              </Row>
            </div>
            <div className={'tutorial-slide-wrapper'}>
              <Row gutter={20} justify={'center'} align={'middle'}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <img style={{ height: '250px', margin: 'auto' }} alt="Accueil" src={slide3}/>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div className={'title'}>
                    {t('tutorial.slide3Header')}
                  </div>
                  <div className={'sub-title'}>
                    {t('tutorial.slide3Text')}
                  </div>
                </Col>
              </Row>
            </div>
            <div className={'tutorial-slide-wrapper'}>
              <Row gutter={20} justify={'center'} align={'middle'}>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <img style={{ height: '250px', margin: 'auto' }} alt="Accueil" src={slide4}/>
                </Col>
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div className={'title'}>
                    {t('tutorial.slide4Header')}
                  </div>
                  <div className={'sub-title'}>
                    {t('tutorial.slide4Text')}
                  </div>
                </Col>
              </Row>
            </div>

          </Carousel>
        </div>
        <Row gutter={20} align={'middle'} wrap={useMedia('(max-width: 576px)')} className={'__form-footer-parent-row popup-footer'}>
          <Col flex={'auto'} className={!useMedia('(max-width: 576px)') ? 'align-left' : ''}>
              <Button
                type="default"
                onClick={async () => {
                  hideTutorialPopup();
                  handleHide();
                }}
                disabled={false}
                shape="round"
                size={'large'}
                className="flat-primary-button"
              >
                {t('tutorial.button.stopShowing')}
              </Button>
          </Col>
          <Col flex={useMedia('(max-width: 1024px)') ? '1' :'0'} className={'__form-footer-row'}>
            <Row gutter={20} wrap={false}>
              <Col className={!useMedia('(max-width: 576px)') ? 'align-right' : ''}>
                <Button
                  type="default"
                  onClick={async () => {
                    setCurrentSlide(currentSlide - 1);
                    sliderRef.prev();
                  }}
                  disabled={currentSlide === 0}
                  shape="round"
                  size={'large'}
                  className="submit"
                >
                  {t('tutorial.button.back')}
                </Button>
              </Col>
              <Col className={!useMedia('(max-width: 576px)') ? 'align-right' : ''}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={false}
                  onClick={() => {
                    if (currentSlide === 3) {
                      handleHide();
                    } else {
                      setCurrentSlide(currentSlide + 1);
                      sliderRef.next();
                    }
                  }}
                  shape="round"
                  size={'large'}
                  className="submit">
                  {currentSlide === 3 ? t('tutorial.button.close') : t('tutorial.button.next')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}
