import * as React from 'react';
import { deleteClientById } from 'shared/hooks/useClients';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Modal, notification, Typography, Space } from 'antd';

import { Link, useHistory } from 'react-router-dom';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import {
    filteredList,
    generateFullName,
    getDefaultPageSize,
    getPhoneText,
    paginationParams,
} from 'shared/services/Utility';
import { Customer } from '../../types/customer.type';
import {
    Route_ClientsEdit,
    Route_ClientsList,
    Route_ClientsView, Route_WorkSitesCreate, Route_WorkSitesEdit,
    Route_WorkSitesView,
} from '../../constants/Routes.constants';
import './index.scss';
// @ts-ignore
import moment from 'moment';
import useDeleteWorkSiteById from '../../hooks/work-sites/useDeleteWorkSiteById';

const { Text } = Typography;

type Props = {
    searchKey: string,
    refresh: any,
    items: any[]
}

const { confirm } = Modal;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const List: React.FunctionComponent<Props> = ({ items, searchKey, refresh }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [deletingWorkSite, setDeletingWorkSite] = useState(false);

    const columns = [
        {
            title: t('workSite.listTitle.reference'),
            render: (a: any) => (
                <Link to={Route_WorkSitesView(a)} className={'__theme-text __medium'}>{a.reference}</Link>),
            sorter: (a: any, b: any) => sortAlpha(b.reference, b.reference),
        },
        {
            title: t('workSite.listTitle.name'),
            render: (a: any) => (
                <Link to={Route_WorkSitesView(a)} className={'__theme-text __medium'}>{generateFullName(a)}</Link>),
            sorter: (a: any, b: any) => sortAlpha(generateFullName(a), generateFullName(b)),
        }, {
            title: t('workSite.listTitle.date'),
            render: (a: any) => (<span>{moment(a.created).format('DD/MM/yyyy')}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.created, b.created),
        },
        {
            title: t('workSite.listTitle.customer'),
            render: (a: any) => (
                <Link to={Route_ClientsView(a.customer)} className={'__theme-text __medium'}>{generateFullName(a.customer)}</Link>),
            sorter: (a: any, b: any) => sortAlpha(generateFullName(a.customer), generateFullName(b.customer)),
        },
        {
            title: t('workSite.listTitle.phone'),
            render: (a: any) => (<a href={`tel:${a.customer.phone}`}>{getPhoneText(a.customer.phone)}</a>),
        },
        {
            title: t('workSite.listTitle.pinCode'),
            dataIndex: 'address',
            render: (address: any) => (<span>{address.postalCode}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.address.postalCode, b.address.postalCode),
        },

        {
            title: t('workSite.listTitle.city'),
            dataIndex: 'address',
            render: (address: any) => (<span>{address.city}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.address.city, b.address.city),
        },

        {
            fixed: 'right',
            title: t('workSite.listTitle.actions'),
            render: (a: any) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('workSite.tooltip.editWorkSite')}>
                            <div/>
                            <EditButton onClick={() => history.push(Route_WorkSitesView(a))}/>
                        </Tooltip>

                        <Tooltip placement={'top'} title={t('workSite.tooltip.deleteWorkSite')}>
                            <div/>
                            <DeleteButton onClick={() =>
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('workSite.confirmation.msg'),
                                    okText: t('workSite.button.deleteWorkSite'),
                                    cancelText: t('genDict.Cancel'),
                                    content: t('workSite.confirmation.deleteWorkSite'),// TODO
                                    okButtonProps: { loading: deletingWorkSite, danger: true },
                                    onOk: async () => {
                                        setDeletingWorkSite(true);
                                        useDeleteWorkSiteById(a.id).then((success) => {
                                            notification.success({
                                                message: t('genDict.success'),
                                                description: t('workSite.notification.workSiteDeletedSuccess'),
                                            });
                                            refresh();
                                        }).catch(e => {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }).finally(() => {
                                            setDeletingWorkSite(false);
                                        });
                                    },
                                })}/>
                        </Tooltip>
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];

    return (
        <Table
            locale={{
                emptyText: (
                    searchKey ? <>
                        <Space style={{ padding: '10px' }}>
                            <Text className={'primary-black'}>{t('workSite.table.noSearchResultFound')}</Text>
                        </Space>
                    </> : <>
                        <Space style={{ padding: '10px' }}>
                            <Text className={'primary-black'}>{t('workSite.table.emptyMessage')}</Text>
                            <div className={'flat-button primary'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(Route_WorkSitesCreate);
                            }}>
                                {t('workSite.button.addWorkSiteMenu')}
                            </div>
                        </Space>
                    </>
                ),
            }}
			showSorterTooltip ={false}
            pagination={{
                // Set this value in pagiation to remove pagination
                showSizeChanger: true,
                onShowSizeChange: (currentPage, pageSize) => {
                    paginationParams(pageSize);
                },
                defaultPageSize: getDefaultPageSize(),            }}
            // @ts-ignore
            columns={columns}
            dataSource={filteredList(searchKey, items)}
        >
        </Table>
    );
};
export default List;
