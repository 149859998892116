import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import fr from './trans/fr.json';

import moment from 'moment';
import 'moment/locale/fr';

const lang = 'fr';

moment.locale(lang);

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    fr: {
        translation1: {},
        translation: fr
    },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lang,
        debug: true,

        //keySeparator: true, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
