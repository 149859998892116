// noinspection RequiredAttributes

import React, { useEffect, useState } from 'react';


import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Space,
    Skeleton, Card, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';
import useCurrentUser from 'shared/hooks/useCurrentUser';
// @ts-ignore
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
// @ts-ignore
import draftToHtml from 'draftjs-to-html';

// @ts-ignore
import { Helmet } from 'react-helmet';
import { REGEX_STRING_VERIFY } from 'shared/constants/AppConstants';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import updateEmailTemplateQuote from 'shared/hooks/users/useUpdateEmailTemplateQuote';
import updateEmailTemplatePurchaseOrder from 'shared/hooks/users/useUpdateEmailTemplatePurchaseOrder';

export default function TemplatesForm() {
    const { t } = useTranslation();
    const [fields] = useState([] as any[]);
    const [form] = Form.useForm();
    const { currentUser } = useCurrentUser();
    const [savingForm, setSavingForm] = React.useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorStateQuote, setEditorStateQuote] = useState(EditorState.createEmpty());

    useEffect(() => {
        const cU = currentUser ? JSON.parse(JSON.stringify(currentUser)) : null;
        if (cU && cU.company && cU.company.quoteTemplate) {
            setEditorStateQuote(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(cU.company.quoteTemplate.template || '<p></p>'),
                ),
            ));
            form.setFieldsValue({ quote: { object: cU.company.quoteTemplate.object } });
        }
        if (cU && cU.company && cU.company.purchaseOrderTemplate) {
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(cU.company.purchaseOrderTemplate.template || '<p></p>'),
                ),
            ));
            form.setFieldsValue({ purchaseOrder: { object: cU.company.purchaseOrderTemplate.object } });
        }
    }, [currentUser, form]);
    const informationHeader = (<div className={'__form-header'}>
        <Title level={5} className={'__form-header-title'}>{t('emailTemplate.title')}</Title>
    </div>);
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };
    const onEditorStateChangeQuote = (editorState: any) => {
        setEditorStateQuote(editorState);
    };
    if (!currentUser) {
        return <Card style={{ padding: '30px' }}>
            <Skeleton.Button active={true} size={'large'}/>
            <Skeleton avatar paragraph={{ rows: 4 }}/>
        </Card>;
    }
    return <>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{t('genDict.page-title', { title: t('pageTitles.setting') })}</title>
        </Helmet>
        {
            informationHeader
        }
        <Form
            className="__my-document-form"
            size="large"
            form={form}
            fields={fields}
            requiredMark={true}
            initialValues={{
                purchaseOrder: {
                    object: '',
                },
                quote: {
                    object: '',
                },
            }}
            onFinish={async (formValues) => {
                const { purchaseOrder, quote } = formValues;

                const rawContentState = convertToRaw(editorState.getCurrentContent());

                const pTemplate = draftToHtml(
                    rawContentState,
                );
                const rawContentStateQuote = convertToRaw(editorStateQuote.getCurrentContent());

                const qTemplate = draftToHtml(
                    rawContentStateQuote,
                );
                purchaseOrder['template'] = pTemplate;
                quote['template'] = qTemplate;
                setSavingForm(true);

                updateEmailTemplateQuote(quote).then(() => {
                    updateEmailTemplatePurchaseOrder(purchaseOrder).then(() => {
                        notification.success({
                            message: t('genDict.success'),
                            description: t('emailTemplate.form.notification.UpdatedSuccess'),
                        });
                    }).catch(e => {
                        notification.error({
                            message: t(e),
                        });
                    });
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingForm(false);
                });
            }}
            layout="vertical">
            <div style={{ padding: '20px' }}>
                <>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="__sub-title">
                                {t('emailTemplate.form.quote.title')}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={24} sm={24} xs={24}>
                            <Form.Item
                                label={t('emailTemplate.form.quote.input.subject.label')}
                                name={['quote', 'object']}
                                key="object"
                                rules={[
                                    { required: true, message: t('emailTemplate.form.quote.input.subject.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input autoComplete={'off'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label style={{fontWeight: 500}} className={'ant-form-item-label'}>
                                {t('emailTemplate.form.quote.input.message.label')}
                            </label>
                            <Editor  editorState={editorStateQuote} onEditorStateChange={onEditorStateChangeQuote}/>
                        </Col>
                    </Row>

                    <br/>
                    <br/>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="__sub-title">
                                {t('emailTemplate.form.purchaseOrder.title')}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={24} sm={24} xs={24}>
                            <Form.Item
                                label={t('emailTemplate.form.purchaseOrder.input.subject.label')}
                                name={['purchaseOrder', 'object']}
                                key="object"
                                rules={[
                                    {
                                        required: true,
                                        message: t('emailTemplate.form.purchaseOrder.input.subject.required'),
                                    },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input autoComplete={'off'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <label style={{fontWeight: 500}} className={'ant-form-item-label'}>
                                {t('emailTemplate.form.purchaseOrder.input.message.label')}
                            </label>
                            <Editor editorState={editorState} onEditorStateChange={onEditorStateChange}/>
                        </Col>
                    </Row>
                </>

            </div>
            <Col span={24}>
                <Space direction={'horizontal'} className={'__form-footer-parent'}>
                    <div/>
                    <Space size={20} direction={'horizontal'} className={'__form-footer'}>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={savingForm}
                                shape="round"
                                size={'large'}
                                className="submit"
                            >
                                {t('emailTemplate.form.button.save')}
                            </Button>
                        </Form.Item>
                    </Space>
                </Space>
            </Col>
        </Form>
    </>;
}
