import * as React from 'react';
import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Layout, Menu, Modal, Row, Col, Button, Drawer, Dropdown, Divider } from 'antd';
import {
  LogoutOutlined,
  MenuUnfoldOutlined,
  LeftOutlined,
  RightOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import logoIcon from 'shared/images/logoIcon.png';
import personalizePopupImage from 'shared/images/personalizePopup-image.svg';

import '../Layout.scss';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { NavLink, useHistory } from 'react-router-dom';
import { isAdmin, logout } from 'shared/contexts/AuthContext';
import userIcon from 'shared/icons/single-03.svg';
import { hideArticlePersonalizePopup } from '../../../services/articleService';
import TutorialPopupComponent from '../TutorialPopupComponent';

interface MenuItem {
  route: string;
  icon: ReactElement;
  iconAction?: string;
  hideForAdmin?: boolean;
  hideForClient?: boolean;
  iconActive: ReactElement;
  label: string;
  warning?: ReactElement;
  daysBeforeQBExpiration?: number;
}

interface LayoutProps {
  topMenuItems?: MenuItem[];
  bottomMenuItems?: MenuItem[];
  selectedMenu?: string | null;
  onMenuClick?: (route: string) => void;
  children: any;
  hasHeader?: boolean;
  logout?: any;
  backLink?: any;
  hasLink?: boolean;
  linkText?: string;
  linkUrl?: string;
}


export default function MobileLayout({
                                       topMenuItems,
                                       bottomMenuItems,
                                       children,
                                     }: LayoutProps) {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [hidePopupPersonalize, setHidePopupPersonalize] = useState(true);
  const [hideTutorialPopup, setHideTutorialPopup] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (currentUser && !currentUser.stopShowTutorial && currentUser.company && currentUser.company?.subscription && !isAdmin() && !localStorage.getItem('hidePopupTutorial')) {
      setHideTutorialPopup(false);
    } else if (currentUser && !currentUser.stopShowPersonalize && currentUser.hasArticlesToPersonalize && currentUser.company?.subscription && !isAdmin()) {
      if (!localStorage.getItem('hidePopupPersonalize')) {
        setHidePopupPersonalize(false);
      }
    }
  }, [currentUser]);

  function handleLogout() {
    logout();
  }

  const menu = (
    <Menu className="__layout-menu">
      {
        <Menu.Item>
          <div className="__menu-item">
            <NavLink className={'link'} to={'/settings'} activeClassName="active-link">
                           <span className="inner-element">
                                {t('appbar.settings')}
                             <div className="__icon">
                                    <SettingOutlined/>
                             </div>
                           </span>
            </NavLink>

          </div>
        </Menu.Item>

      }
      {
        !isAdmin() && <Menu.Item>
          <div className="__menu-item">
            <NavLink className={'link'} activeClassName="active-link" to={'/account-settings#my-profile'}>
                           <span className="inner-element">
                            {t('appbar.account')}
                             <div className="__icon">
                                        <UserOutlined/>
                                </div>
                           </span>
            </NavLink>

          </div>
        </Menu.Item>
      }
      <Menu.Item>
        <div className="__menu-item" onClick={() => handleLogout()}>
          <a rel="noopener noreferrer">
            {t('appbar.logout')}
          </a>
          <div className="__icon">
            <LogoutOutlined/>
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout style={{ minHeight: '100vh' }} className="mobile-layout">
      <div className="__header">
        <div className="__logo">
          <img
            className={`__icon`}
            alt="Accueil"
            src={logoIcon}/>
          <div className={`__menu-toggle ${isAdmin() && 'admin'}`} onClick={() => setCollapsed(false)}>
            {
              collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>
            }
            &nbsp;
            &nbsp;
            {
              t('MENU')
            }
          </div>
        </div>
        <div className="__right-menu">
          {currentUser ?
            <Dropdown overlay={menu}>
              <div className="__user-card">
                <div className="__user-icon">
                  <img src={userIcon}/>
                </div>
              </div>
            </Dropdown> : <></>
          }
        </div>
      </div>
      {
        !hideTutorialPopup &&  <TutorialPopupComponent onFinish={()=>{
          if (currentUser && !currentUser.stopShowPersonalize && currentUser.hasArticlesToPersonalize && currentUser.company?.subscription && !isAdmin()) {
            if (!localStorage.getItem('hidePopupPersonalize')) {
              setHidePopupPersonalize(false);
            }
          }
        }}/>
      }
      <Modal
        title={t('personalizePrice.title')}
        visible={!hidePopupPersonalize}
        width={800}
        maskClosable={false}
        onCancel={() => {
          localStorage.setItem('hidePopupPersonalize', 'true');
          setHidePopupPersonalize(true);
        }}
        destroyOnClose={true}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <div className="__personalize-popup-body-wrapper">
          <div className="__image">
            <img src={personalizePopupImage} alt=""/>
          </div>
          <div className="__text-section">
            {
              t('personalizePrice.welcomeParagraph')
            }
          </div>
        </div>
        <Row>
          <Col span={24}>
            <Row gutter={20} align={'middle'} className={'__form-footer-parent-row __from-sm'}>
              <Col>
                <div className={'flat-button primary'} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  localStorage.setItem('hidePopupPersonalize', 'true');
                  setHidePopupPersonalize(true);
                  hideArticlePersonalizePopup();
                }}>
                  {t('personalizePrice.button.noLongerDisplay')}
                </div>
              </Col>
              <Col className={'__form-footer-row __from-sm'}>
                <Row gutter={20}>
                  <Col>
                    <Button
                      type="default"
                      onClick={async () => {
                        localStorage.setItem('hidePopupPersonalize', 'true');
                        setHidePopupPersonalize(true);
                      }}
                      disabled={false}
                      shape="round"
                      size={'large'}
                      className="submit"
                    >
                      {t('personalizePrice.button.ignore')}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={false}
                      onClick={() => {
                        localStorage.setItem('hidePopupPersonalize', 'true');
                        setHidePopupPersonalize(true);
                        history.push('/customize-price');
                      }}
                      shape="round"
                      size={'large'}
                      className="submit">
                      {t('personalizePrice.button.personalizePrice')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
      <div className="__content">
        <Layout.Content>
          <Suspense
            fallback={<LoaderOverlay size="large" loading={true}/>}
          >
            {children}
          </Suspense>
        </Layout.Content>
      </div>
      <Drawer
        placement="left"
        closable={false}
        className={`__sidebar-drawer ${isAdmin() && 'admin'}`}
        onClose={() => setCollapsed(true)}
        visible={!collapsed}
      >
        <div className="_app-side-bar">
          <div className="__menu-wrapper">
            {
              topMenuItems && <div className="__upper-menu _app-menu">
                {
                  topMenuItems.map((menuItem) => {
                    if (isAdmin() && menuItem.hideForAdmin) {
                      return <></>;
                    }
                    if (!isAdmin() && menuItem.hideForClient) {
                      return <></>;
                    }
                    return <>
                      <NavLink to={menuItem.route} onClick={() => setCollapsed(true)}
                               className="__menu-item">
                        <div className="__menu-item-icon">
                          {menuItem.icon}
                        </div>
                        <div className="__menu-item-icon-active">
                          {menuItem.iconActive}
                        </div>
                        <div className="__menu-item-label">
                          {menuItem.label}
                        </div>
                        {/*<div className="__menu-item-action">*/}
                        {/*    <NavLink to={menuItem.iconAction || menuItem.route}>*/}
                        {/*        <PlusOutlined/>*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </NavLink>
                    </>;

                  })
                }
              </div>
            }
            <Divider style={{ margin: '10px 0', backgroundColor: '#FFFFFF24' }}/>
            {
              bottomMenuItems && <div className="__lower-menu _app-menu">
                {
                  bottomMenuItems.map((menuItem) => {
                    if (isAdmin() && menuItem.hideForAdmin) {
                      return <></>;
                    }
                    if (!isAdmin() && menuItem.hideForClient) {
                      return <></>;
                    }
                    return <>
                      <NavLink to={menuItem.route} onClick={() => setCollapsed(true)}
                               className="__menu-item">
                        <div className="__menu-item-icon">
                          {menuItem.icon}
                        </div>
                        <div className="__menu-item-icon-active">
                          {menuItem.iconActive}
                        </div>
                        <div className="__menu-item-label">
                          {menuItem.label}
                        </div>
                        {/*<div className="__menu-item-action">*/}
                        {/*    <NavLink to={menuItem.iconAction || menuItem.route}>*/}
                        {/*        <PlusOutlined/>*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}
                      </NavLink>
                    </>;

                  })
                }
              </div>
            }
          </div>
          <div className="__toggle-wrapper __font-bold">
            <div className="__toggle-opened" onClick={() => setCollapsed(true)}>
              <LeftOutlined/>
              {t('sideMenu.collapseMenu')}
            </div>
            <div className="__toggle-closed" onClick={() => setCollapsed(false)}>
              <RightOutlined/>
            </div>
          </div>
        </div>
      </Drawer>
    </Layout>
  );
}
