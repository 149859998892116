import * as React from 'react';
// @ts-ignore
import {useTranslation} from 'react-i18next';
import {Card} from 'antd';

import {
    PlusOutlined,
} from '@ant-design/icons';
import ListView from '../../../../components/layout/ListView';
import PurchaseOrderList from './PurchaseOrderList';

const PurchaseOrders: React.FunctionComponent<any> = ({workSite, callCreatePO, setSelectedPO}) => {
    const {t} = useTranslation();

    return <Card title={<div className="__header-title ">
        {
            t('workSite.purchaseOrders.headerText')
        } &nbsp;
        <div className="__button-add-icon " onClick={() => {
            callCreatePO()
        }}>
            <PlusOutlined/>
        </div>
    </div>} className={'__purchase-order-wrapper'}>

        <div className={'__table-pagination-padded'}>
            <ListView
                title={''}
                noP={true}
                pageTitle={t('genDict.page-title', {title: t('pageTitles.listPurchaseOrders')})}
                cardTitleStyle={{
                    padding: 0,
                }}>

                <PurchaseOrderList workSite={workSite} showSendEmail={(e: any) => {
                    setSelectedPO({...e, direct: true, providerId: e.provider.id});
                }}/>
            </ListView>
        </div>
    </Card>;
};
export default PurchaseOrders;
