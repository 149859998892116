import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
    Table,
    Modal,
    Select,
    Tag,
    Switch,
    Button as BaseButton,
    Typography,
    Row,
    Col,
    Button,
    Space, Tooltip, notification,
} from 'antd';
import {
    CheckOutlined,
    CheckCircleFilled, InfoCircleFilled,
} from '@ant-design/icons';

import './index.scss';
import { useEffect, useState } from 'react';
import ListView from '../../../../components/layout/ListView';
import {
    ConstantCeilingPlasterboardFixing, ConstantCeilingSlabsFixing,
    ConstantCenterDistance, ConstantCenterDistanceIfCeiling,
    ConstantPartitionType,
    ConstantCeilingHanger,
    ConstantWorkFamiliesMapping, ConstantWorkTypeConstantsAndKeysMappings,
    ConstantWorkTypeFamily,
    DIGITS_TO_SHOW_AFTER_DOT,
} from '../../../../constants/AppConstants';
import { isAdmin } from '../../../../contexts/AuthContext';
import { getDefaultPageSize, paginationParams } from '../../../../services/Utility';
import { fetchWorkTypes } from '../../../../services/workService';

const { Text } = Typography;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

type Props = {
    onSubmit?: any,
    workSite?: any,
    onCancel?: any,
}

const WorkTypeSelection: React.FunctionComponent<Props> = ({ workSite, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    const [items, setItems] = useState([] as any[]);


    const [loadingList, setLoadingList] = React.useState(false);
    const [workTypes, setWorkTypes] = useState([] as any[]);
    const [showMoreFilters, setShowMoreFilters] = React.useState(false);
    const [filterByFamily, setFilterByFamily] = useState('');
    const [filterCreatedByMe, setFilterCreatedByMe] = useState(false);
    const [filtersInputs, setFiltersInputs] = React.useState({} as any);
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(0);
    const [sort, setSort] = useState(null as any);


    const refresh = () => {
        if (!filterCreatedByMe && !filterByFamily) {
            return;
        }
        let params: any = {
            ignoreWorkTypes: workSite.worktypes.map((s: any) => s.reference),
            limit: getDefaultPageSize(),
            offset: getDefaultPageSize() * (page - 1 || 0),
        };
        if (filterByFamily) {
            params['family'] = filterByFamily;
        }
        if (filtersInputs) {
            params = { ...params, ...filtersInputs };
        }
        if (sort) {
            params = { ...params, sort };
        }

        if (filterCreatedByMe && !isAdmin()) {
            params = { ...params, myWorkTypes: filterCreatedByMe };
        }

        setLoadingList(true);

        fetchWorkTypes(params).then((response) => {
            if (response && response.data && response.data.workTypes.workTypes) {
                setItems(response.data.workTypes.workTypes);
                setTotal(response.data.workTypes.total);
            }
        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        }).finally(() => {
            setLoadingList(false);
        });
    };
    // eslint-disable-next-line
    useEffect(() => {
        if (page === 1) {
            refresh();
        } else {
            setPage(1);
        }
    }, [filterByFamily, filtersInputs, filterCreatedByMe]);
    useEffect(refresh, [page, sort]);

    useEffect(() => {
        setWorkTypes([]);
    }, [workSite]);

    const renderFilters = () => {
        if (!showMoreFilters || !filterByFamily) {
            return '';
        }

        const filters: any[] = [];

        if (filterByFamily === 'framePartition' || filterByFamily === 'alveolarPartition'
            || filterByFamily === 'brickPartition') {

            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['partitionType']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'partitionType': e });
                }}
                placeholder={t(`workType.search.partitionType`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantPartitionType[filterByFamily].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }
        if (filterByFamily === 'againstPartition') {

            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['counterPartitionType']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'counterPartitionType': e });
                }}
                placeholder={t(`workType.search.counterPartitionType`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantPartitionType[filterByFamily].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }

        if (ConstantWorkFamiliesMapping.find((e: any) => filterByFamily === e.name)) {
            ConstantWorkFamiliesMapping.find((e: any) => filterByFamily === e.name)!.fields!.forEach((field: any) => {
                filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                    showSearch

                    style={{ width: '100%', marginTop: '10px' }} value={filtersInputs[field]}
                    onChange={(e) => {
                        setFiltersInputs({ ...filtersInputs, [field]: e });
                    }}
                    placeholder={t(`workType.search.${field}`)} allowClear={true}>
                    <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                    {
                        // @ts-ignore
                        ConstantWorkTypeConstantsAndKeysMappings[field] && ConstantWorkTypeConstantsAndKeysMappings[field].map((option: any) => {
                            return <Select.Option value={option}>{option}</Select.Option>;
                        })
                    }
                </Select>
                </Col>);
            });

        }
        if (filterByFamily === 'framePartition') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['centerDistance']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'centerDistance': e });
                }}
                placeholder={t(`workType.search.centerDistance`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCenterDistance.map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        } else if (filterByFamily === 'ceilingPlasterboard') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['centerDistance']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'centerDistance': e });
                }}
                placeholder={t(`workType.search.centerDistance`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCenterDistanceIfCeiling.map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }

        if (filterByFamily === 'ceilingPlasterboard' || filterByFamily === 'ceilingSlabs') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['ceilingHanger']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'ceilingHanger': e, 'ceilingFixing': null });
                }}
                placeholder={t(`workType.search.ceilingHanger`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCeilingHanger.map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }

        if (filterByFamily === 'ceilingPlasterboard' && filtersInputs['ceilingHanger'] && filtersInputs['ceilingHanger'] !== t('genDict.Other')) {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['ceilingFixing']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'ceilingFixing': e });
                }}
                placeholder={t(`workType.search.ceilingFixing`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCeilingPlasterboardFixing[filtersInputs['ceilingHanger']] && ConstantCeilingPlasterboardFixing[filtersInputs['ceilingHanger']].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        } else if (filterByFamily === 'ceilingSlabs' && filtersInputs['ceilingHanger'] && filtersInputs['ceilingHanger'] !== t('genDict.Other')) {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['ceilingFixing']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'ceilingFixing': e });
                }}
                placeholder={t(`workType.search.ceilingFixing`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCeilingSlabsFixing[filtersInputs['ceilingHanger']] && ConstantCeilingSlabsFixing[filtersInputs['ceilingHanger']].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }
        return filters;

    };

    const columns = [
        {
            title: '',

            render: (a: any) => (
                a.selected ? <CheckCircleFilled className={'checked-icon'}/> : (
                    <CheckCircleFilled className={'checked-icon'} style={{ opacity: 0 }}/>)
            ),
        },
        {
            title: t('workSite.workTypes.workType.listTitle.reference'),
            key: 'reference',

            render: (a: any) => (
                <a className={'__theme-text __medium'}>{a.reference}</a>),
            sorter: (a: any, b: any) => sortAlpha(a.reference, b.reference),
        },
        {
            title: t('workSite.workTypes.workType.listTitle.name'),
            key: 'name',

            render: (a: any) => (
                <a className={'__theme-text'}>{a.name}</a>),
            sorter: (a: any, b: any) => sortAlpha(a.name, b.name),
        },
        {
            title: t('workSite.workTypes.workType.listTitle.price'),
            key: 'sellingPrice',

            render: (a: any) => (a.isPersonalized ? <span>{a.sellingPrice}</span> : <Space size={7}>
                        <span className={'text-disabled'}>
					{
                        a.sellingPrice ? parseFloat(a.sellingPrice).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
                    }
							</span>
                <span className={'text-disabled'}>
                        <Tooltip placement={'top'} title={t('workType.tooltip.workTypePersonalize')}>
									<InfoCircleFilled className={'icon-disabled'}/>
								</Tooltip>
							</span>
            </Space>),
            sorter: (a: any, b: any) => sortAlpha(a.sellingPrice, b.sellingPrice),
        },
        {
            title: <div className={'text-center'}>{t('workSite.workTypes.workType.listTitle.creator')}</div>,
            render: (a: any) => (
                <div className={'text-center'}>
                    {
                        a.isAdminCreated ? <></> :
                            <CheckOutlined className={'round primary table-icons'}/>
                    }
                </div>),
        },
    ];

    return (
        <Modal
            title={t('workSite.workTypes.workType.title')}
            visible={true}
            width={1000}
            maskClosable={false}
            afterClose={
                onCancel
            }
            style={{ top: 10 }}
            onCancel={onCancel}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}>
            <data className={'highlighted-select __work-type-selection'} style={{ padding: '0' }}>
                {workTypes.length ?
                    <Row style={{ paddingLeft: '20px', marginTop: '10px' }}>
                        <Col span={24}>
                            {
                                workTypes.map((a: any) => {
                                    return <Tag
                                        style={{ marginBottom: '10px' }}
                                        className="edit-tag"
                                        closable={false}
                                    >
                                        {a.name}
                                    </Tag>;
                                })
                            }
                        </Col>
                    </Row> : <></>
                }
                <ListView
                    title={''}
                    noLayoutP={true}
                    searchBar={
                        (<Space className={'__first-item-flex-1 filter-wrapper last-align-self-auto'}
                                style={{ justifyContent: 'space-between', width: '100%' }}>
                            <div className="filter-item">
                                <div style={{ flex: 1 }}>
                                    <div className="margin-in-small" style={{ display: 'inline-block' }}>

                                        <Select onClear={() => setItems([])} style={{ width: '220px' }}
                                                value={filterByFamily || undefined}
                                                onChange={(e) => {
                                                    setFilterByFamily(e);
                                                    setFiltersInputs({});
                                                }}
                                                placeholder={t('workType.search.filterByFamily')} allowClear={true}>
                                            {
                                                ConstantWorkTypeFamily.map((option: any) => {
                                                    return <Select.Option
                                                        value={option}>{t(`workType.family.${option}`)}</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </div>

                                    &nbsp;
                                    &nbsp;
									<BaseButton
										type={filterByFamily && showMoreFilters ? 'primary' : 'default'}
										disabled={!filterByFamily}
										onClick={() => {
											// setDuplicateItem(false);
											setShowMoreFilters(!showMoreFilters);
										}}
										shape="round"
										size={'middle'}
										className={filterByFamily && showMoreFilters ? '__button-inverted primary submit' : 'submit'}
									>
										{showMoreFilters ? t('workType.button.hideFilters') : t('workType.button.addFilters')}
									</BaseButton>									
                                    &nbsp;
                                    &nbsp;									

                                    {
                                        !isAdmin() && <>
                                            <Switch defaultChecked={false} checked={filterCreatedByMe}
                                                    onChange={setFilterCreatedByMe}/>&nbsp;&nbsp;<span
                                            className={'__theme-text __disabled'}>{t('workType.search.myWorks')}</span>

                                        </>
                                    }
                                    <br/>
                                    <Row gutter={16}>
                                        {
                                            renderFilters()
                                        }
                                    </Row>
                                </div>
                            </div>
                        </Space>)
                    }
                >
                    <Table
                        className={'__table-pagination-padded'}

                        rowClassName={(record, index) => (record.selected || workTypes.find((a: any) => a.id === record.id)) ? 'ant-table-row-selected' : ''}
                        locale={{
                            emptyText: (
                                !filterByFamily && !filterCreatedByMe ? <>
                                    <Space style={{ padding: '10px' }}>
                                        <Text
                                            className={'primary-black'}>{t('workType.table.selectFamilyToFilter')}</Text>
                                    </Space>
                                </> : <>
                                    <Space style={{ padding: '10px' }}>
                                        <Text
                                            className={'primary-black'}>{t('workType.table.noSearchResultFound')}</Text>
                                    </Space>
                                </>
                            ),
                        }}
                        onChange={
                            (pagination, filters, sorter: any, extra) => {
                                if (sorter) {
                                    if (sorter.column) {
                                        setSort({
                                            field: sorter.columnKey,
                                            order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
                                        });
                                    } else {
                                        setSort(null);
                                    }
                                } else {
                                    setSort(null);
                                }
                            }
                        }
                        // @ts-ignore
                        onRow={(r, index: number) => ({
                            onClick: () => {
                                let foundItemIndex = -1;
                                items.forEach((aa: any, i: number) => {
                                    if (aa.id === r.id) {
                                        foundItemIndex = i;
                                    }
                                });

                                items[foundItemIndex].selected = !items[foundItemIndex].selected;
                                setItems(JSON.parse(JSON.stringify(items)));
                                foundItemIndex = -1;
                                workTypes.forEach((aa: any, i: number) => {
                                    if (aa.id === r.id) {
                                        foundItemIndex = i;
                                    }
                                });

                                if (foundItemIndex > -1) {
                                    workTypes.splice(foundItemIndex, 1);
                                } else {
                                    workTypes.push(r);
                                }
                                setWorkTypes(JSON.parse(JSON.stringify(workTypes)));
                            },
                        })}
						showSorterTooltip ={false}
                        pagination={{
                            showSizeChanger: true,
                            current: page,
                            onShowSizeChange: (currentPage, pageSize) => {
                                paginationParams(pageSize);
                                refresh();
                            },
                            onChange: (e: any) => setPage(e),
                            total: (filterByFamily || filterCreatedByMe) ? total : 0,
                            defaultPageSize: getDefaultPageSize(),
                        }}
                        // @ts-ignore
                        columns={columns}
                        loading={loadingList}
                        dataSource={!filterByFamily && !filterCreatedByMe ? [] : items.map((e) => {
                            return { ...e, key: e.id };
                        })}
                    >
                    </Table>
                </ListView>
            </data>
            <Row>
                <Col span={24}>
                    <Space direction={'horizontal'} className={'__form-footer-parent'}>
                        <div/>

                        <Space direction={'horizontal'} size={20} className={'__form-footer'}>
                            <Button
                                type="default"
                                style={{ marginBottom: '8px' }}
                                onClick={async () => {
                                    onSubmit(workTypes);
                                }}
                                shape="round"
                                size={'large'}
                                className="submit">
                                {t('workSite.workTypes.workType.button.toClose')}
                            </Button>
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Modal>
    );
};
export default WorkTypeSelection;
