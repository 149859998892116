import * as React from 'react';
import { Button, Card, notification, Space } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { Customer } from './InfoTab';
import { useEffect, useState } from 'react';
import API_SERVICE from 'shared/services/api-service';

export type UserOffer = {
    type: SubscriptionType;
    amount: number;
    savingAmount: number;
    durationDays: number;
};

export type SubscriptionType = 'trial' | 'monthly' | 'yearly';

type Props = {
    offer: UserOffer | null;
    initialSubscriptionType: SubscriptionType;
    onOfferChange: (offer: UserOffer) => void;
};

const PriceContainer: React.FunctionComponent<Props> = ({
    offer,
    onOfferChange,
    initialSubscriptionType,
}) => {
    const { t } = useTranslation();
    const [availableOffers, setAvailableOffers] = useState<UserOffer[]>([]);
    useEffect(() => {
        API_SERVICE.subscriptionPrices()
            .then((subscriptions) => {
                const newAvailableOffers: UserOffer[] = subscriptions?.data
                    ? subscriptions.data
                    : [];
                setAvailableOffers(newAvailableOffers);
            })
            .catch((e) => {
                notification.error(t(API_SERVICE.handleErrors(e)));
            });
    }, []);

    useEffect(() => {
        const newOffer = availableOffers.find((a) => {
            return a.type === initialSubscriptionType;
        });
        if (newOffer) {
            onOfferChange(newOffer);
        }
    }, [initialSubscriptionType, availableOffers]);

    const monthlyOffer = availableOffers.find((o) => o.type === 'monthly');
    const yearlyOffer = availableOffers.find((o) => o.type === 'yearly');

    const subscriptionType = offer ? offer.type : initialSubscriptionType;

    return (
        <Space size={20} direction={'vertical'} style={{ width: '100%' }}>
            {
                <>
                    <Card className="__price-container">
                        <h3>
                            {t(`signup.subscription.${subscriptionType}.title`)}
                        </h3>
                        <div>
                            {offer && (
                                <>
                                    {offer.type === 'trial' && (
                                        <>
                                            <Space
                                                style={{
                                                    justifyContent:
                                                        'space-between',
                                                    display: 'flex',
                                                }}
                                            >
                                                <div>
                                                    <span className={'__price'}>
                                                        <Trans
                                                            i18nKey={`signup.subscription.trial.duration`}
                                                            values={{
                                                                duration:
                                                                    offer.durationDays,
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Space>
                                        </>
                                    )}

                                    {offer.type === 'monthly' && (
                                        <>
                                            <Space
                                                style={{
                                                    justifyContent:
                                                        'space-between',
                                                    display: 'flex',
                                                }}
                                            >
                                                <div>
                                                    <span className={'__price'}>
                                                        <Trans
                                                            i18nKey={`signup.subscription.monthly.price`}
                                                            components={{
                                                                span: (
                                                                    <span className="__price" />
                                                                ),
                                                            }}
                                                            values={{
                                                                cost:
                                                                    offer.amount,
                                                            }}
                                                        />
                                                    </span>
                                                    <span
                                                        className={'__text'}
                                                        style={{ flex: 1 }}
                                                    >
                                                        <Trans
                                                            i18nKey={`signup.subscription.monthly.text`}
                                                            components={{
                                                                span: (
                                                                    <span className="__text" />
                                                                ),
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Space>
                                        </>
                                    )}

                                    {offer.type === 'yearly' && (
                                        <>
                                            <Space
                                                style={{
                                                    justifyContent:
                                                        'space-between',
                                                    display: 'flex',
                                                }}
                                            >
                                                <div>
                                                    <span className={'__price'}>
                                                        <Trans
                                                            i18nKey={`signup.subscription.yearly.price`}
                                                            components={{
                                                                span: (
                                                                    <span className="__price" />
                                                                ),
                                                            }}
                                                            values={{
                                                                cost:
                                                                    offer.amount,
                                                            }}
                                                        />
                                                    </span>
                                                    <span
                                                        className={'__text'}
                                                        style={{ flex: 1 }}
                                                    >
                                                        <Trans
                                                            i18nKey={`signup.subscription.yearly.text`}
                                                            components={{
                                                                span: (
                                                                    <span className="__text" />
                                                                ),
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                                {monthlyOffer && (
                                                    <div
                                                        style={{
                                                            height: 24,
                                                            marginTop: -7
                                                        }}
                                                    >
                                                        <Button
                                                            type="link"
                                                            className={
                                                                'text-underline'
                                                            }
                                                            style={{
                                                                color: 'white',
                                                            }}
                                                            onClick={() =>
                                                                onOfferChange(
                                                                    monthlyOffer,
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                `signup.subscription.yearly.actionText`,
                                                            )}
                                                        </Button>
                                                    </div>
                                                )}
                                            </Space>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </Card>
                    {subscriptionType === 'monthly' && yearlyOffer && (
                        <Card className="__year">
                            <div className={'__year-wrapper'}>
                                <span className={'__year'}>
                                    <Trans
                                        i18nKey={`signup.subscription.monthly.economy`}
                                        values={{
                                            total: yearlyOffer.savingAmount,
                                        }}
                                    />
                                </span>
                                <span className={'__text'}>
                                    <Button
                                        type="link"
                                        onClick={() =>
                                            onOfferChange(yearlyOffer)
                                        }
                                    >
                                        {t(
                                            `signup.subscription.monthly.actionText`,
                                        )}
                                    </Button>
                                </span>
                            </div>
                        </Card>
                    )}
                </>
            }
        </Space>
    );
};
export default PriceContainer;
