import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    Modal,
    Select,
    Typography,
    Form,
    Row,
    Col,
    Button,
    Input,
    Space,
} from 'antd';


import { generateFullName, replaceVariables } from 'shared/services/Utility';
import './index.scss';
// @ts-ignore
import useSuppliers from '../../../../hooks/suppliers/useSuppliers';
import { useState } from 'react';

// @ts-ignore
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// @ts-ignore
import draftToHtml from 'draftjs-to-html';
import { useEffect } from 'react';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { REGEX_STRING_VERIFY } from '../../../../constants/AppConstants';

const { Text } = Typography;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

type Props = {
    onSubmit?: any,
    workSite?: any,
    selectedPurchaseOrder?: any,
    loading?: any,
    onBack?: any,
    onCancel?: any,
}

const SendEmail: React.FunctionComponent<Props> = ({
                                                       loading,
                                                       workSite,
                                                       selectedPurchaseOrder,
                                                       onBack,
                                                       onSubmit,
                                                       onCancel,
                                                   }) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [defaultContact, setDefaultContact] = useState(selectedPurchaseOrder.defaultContact);
    const [disableCheck, setDisableCheck] = useState(false);
    const [editorError, setEditorError] = useState('');
    const [showDefaultContact, setShowDefaultContact] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const { currentUser } = useCurrentUser();

    useEffect(() => {
        const cU = currentUser ? JSON.parse(JSON.stringify(currentUser)) : null;
        if (cU && cU.company && cU.company.purchaseOrderTemplate) {
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(replaceVariables(cU.company.purchaseOrderTemplate.template, {
                        title: selectedPurchaseOrder.title,
                        name: workSite.name,
                        company: currentUser,
                    })),
                ),
            ));
            form.setFieldsValue({
                subject: replaceVariables(cU.company.purchaseOrderTemplate.object, {
                    title: selectedPurchaseOrder.title,
                    name: workSite.name,
                    company: currentUser,
                }),
            });
            const rawContentState = convertToRaw(editorState.getCurrentContent());
            const message = draftToHtml(
                rawContentState,
            );
            form.setFieldsValue({ message });
        }
    }, [currentUser]);
    const { items: suppliers } = useSuppliers();

    useEffect(() => {
        if (suppliers && suppliers.length) {
            const c = suppliers.find((a: any) => {
                return a.id === selectedPurchaseOrder.providerId;
            });
            if (c.mail) {
                setShowDefaultContact(true);
            } else {
                setDefaultContact(false);
            }
            if (suppliers.find((a: any) => a.id === selectedPurchaseOrder.providerId) && !suppliers.find((a: any) => a.id === selectedPurchaseOrder.providerId).contacts.length) {
                form.setFieldsValue({ defaultContact: true });
                setDefaultContact(true);
                setDisableCheck(true);
            }
        } else {

        }
    }, [suppliers]);
    const fields: any[] = [];
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
        setEditorError('');
    };


    return (<Modal
            title={t('workSite.purchaseOrders.sendEmail.title')}
            visible={true}
            width={1000}
            maskClosable={false}
            afterClose={
                onCancel
            }

            onCancel={onCancel}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}>
            <div>
                <Form
                    size="large"
                    fields={fields}
                    form={form}
                    onFinish={(values) => {
                        setEditorError('');
                        const rawContentState = convertToRaw(editorState.getCurrentContent());
                        const message = draftToHtml(
                            rawContentState,
                        );
                        if (!message || (message.indexOf('<p></p>') > -1 && message.length === 8)) {
                            setEditorError(t('workSite.purchaseOrders.sendEmail.input.message.required'));
                            return;
                        }
                        const c = suppliers.find((a: any) => {
                            return a.id === selectedPurchaseOrder.providerId;
                        });
                        const receiverEmail = defaultContact ? c.mail : values.contactId;

                        onSubmit({
                            receiverEmail,
                            workSiteId: workSite.id,
                            documentId: selectedPurchaseOrder.id,
                            subject: values.subject,
                            message,
                        });
                    }}
                    onFinishFailed={() => {
                        setEditorError('');
                        const rawContentState = convertToRaw(editorState.getCurrentContent());
                        const message = draftToHtml(
                            rawContentState,
                        );
                        if (!message || (message.indexOf('<p></p>') > -1 && message.length === 8)) {
                            setEditorError(t('workSite.purchaseOrders.sendEmail.input.message.required'));
                            return;
                        }
                    }}
                    initialValues={
                        {
                            providerId: selectedPurchaseOrder.providerId,
                            defaultContact: defaultContact,
                            contactId: selectedPurchaseOrder.contactId,
                        }
                    }
                    layout="vertical"
                    requiredMark={true}>
                    {selectedPurchaseOrder.direct && <Row gutter={16} style={{ padding: '10px 20px 0px 20px' }}>
                        <Col md={showDefaultContact ? 12 : 24} sm={24} xs={24}>
                            <Form.Item
                                label={t('workSite.purchaseOrders.sendEmail.input.provider.label')}
                                name={'providerId'}
                                rules={[
                                    {
                                        required: true,
                                        message: t('workSite.purchaseOrders.sendEmail.input.provider.required'),
                                    },
                                ]}
                            >
                                <Select disabled={true} placeholder={t('genDict.Select')}>
                                    {
                                        suppliers.map((a: any) => {
                                            return <Select.Option
                                                value={a.id}>{generateFullName(a)} {a.mail ? `- ${a.mail}` : ''}</Select.Option>;

                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {
                            showDefaultContact && <Col md={12} sm={24} xs={24} style={{ paddingTop: '34px' }}>
                                <Form.Item
                                    valuePropName="checked"
                                    name={'defaultContact'}>
                                    <Checkbox
                                        disabled={disableCheck}
                                        onChange={(e) => {
                                            setDefaultContact(e.target.checked);
                                        }}>
                                        <span
                                            className={'__theme-checkbox'}>{t('workSite.purchaseOrders.addSupplier.input.principalContact.label')}</span>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        }
                    </Row>}
                    {
                        suppliers && suppliers.length && selectedPurchaseOrder && !defaultContact ?
                            <Row gutter={16} style={{ padding: '0px 20px 0px 20px' }}>
                                <Col md={24} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('workSite.purchaseOrders.sendEmail.input.contact.label')}
                                        name={'contactId'}
                                        rules={[
                                            {
                                                required: !defaultContact,
                                                message: t('workSite.purchaseOrders.sendEmail.input.contact.required'),
                                            },
                                        ]}
                                    >
                                        <Select disabled={!selectedPurchaseOrder.direct}
                                                placeholder={t('genDict.Select')}>
                                            {
                                                suppliers.find((a: any) => a.id === selectedPurchaseOrder.providerId).contacts.map((a: any) => {
                                                    return <Select.Option
                                                        value={a.email}>{`${generateFullName(a)} - ${a.isMain ? (t('genDict.contactPrinciple') + ' - ') : ''} ${a.email}`}</Select.Option>;

                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            : ''
                    }
                    <Row gutter={16} style={{ padding: '0px 20px 0px 20px' }}>
                        <Col lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                label={t('workSite.purchaseOrders.sendEmail.input.subject.label')}
                                name={'subject'}
                                rules={[
                                    {
                                        required: true,
                                        message: t('workSite.purchaseOrders.sendEmail.input.subject.required'),
                                    },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ padding: '0 20px' }}>
                        <Col sm={24}>
                            <div className="ant-form-item __custom-form-label">
                                <div className="ant-form-item-required __lable-font-500">
                                    <label className={'ant-form-item-required'}><span
                                        className={'__required-mark'}>*</span>{t('workSite.purchaseOrders.sendEmail.input.message.label')}
                                    </label>
                                </div>
                            </div>
                            <Editor editorState={editorState} onEditorStateChange={onEditorStateChange}/>
                            <span className={'__form-error'}>
                            {
                                editorError
                            }
                           </span>

                        </Col>

                    </Row>

                    <Row gutter={16} style={{ padding: '15px 20px 0 20px' }}>
                        <Col sm={24}>
                            <span className={'__document-name'}>
                                {t('genDict.documentTitle')}:&nbsp;
                            </span>
                            <span className={'__document-name'}>
                                {selectedPurchaseOrder.title}.pdf
                            </span>
                        </Col>

                    </Row>
                    <Form.Item>
                        <Space direction={'horizontal'} className={'__form-footer-parent'}
                               style={{ marginTop: '15px' }}>
                            <div/>
                            <Space direction={'horizontal'} size={20} className={'__form-footer'}
                                   style={{ padding: '20px' }}>
                                <Button
                                    type="default"
                                    disabled={loading}
                                    onClick={async () => {
                                        onCancel();
                                    }}
                                    shape="round"
                                    size={'large'}
                                    className="submit">
                                    {t('genDict.Cancel')}
                                </Button>

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    disabled={!suppliers || !suppliers.length}
                                    style={{ width: 'auto' }}
                                    shape="round"
                                    size={'large'}
                                    className="submit"
                                >
                                    {t('workSite.purchaseOrders.sendEmail.button.next')}
                                </Button>
                            </Space>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
export default SendEmail;
