import * as React from 'react';
import { Button as BaseButton, Select, Space, Modal } from 'antd';

import useWorkSites from '../../hooks/work-sites/useWorkSites';
import ListSearchBar from '../../components/Ant/ListSearchBar/ListSearchBar';
import ListView from '../../components/layout/ListView';
import WorkSitesTable from './WorkSitesTable';
import { useHistory } from 'react-router';
import { useLocation } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useLayoutMedia } from 'shared/services/Utility';

const List: React.FunctionComponent<{}> = () => {
    const { items: workSites, refetch: refresh, loading } = useWorkSites();
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    const [searchKey, setSearchKey] = useState('');
	const isDesktop = useLayoutMedia(['Desktop']);
    return <ListView
        pageTitle={t('genDict.page-title', { title: t('pageTitles.listWorkSites') })}
        title={t('genDict.WorkSites')}
        loading={loading}
        paddingBottomRequired={true}
        searchBar={
            (<Space direction={'horizontal'} className={'__stretch-item-on-mobile'} size={20}>
                <ListSearchBar onChange={(e: string) => {
                    setSearchKey(e);
                }} text={t('genDict.Search')}/>
            </Space>)
        }
        createButton={(

            <BaseButton type="primary"
                        shape="round"
                        size={'large'} onClick={() => {
                history.push('/chantiers/create');
            }}>
				{isDesktop ? t('workSite.button.addWorkSiteMenu') : t('genDict.add')}
            </BaseButton>
        )}
    >
        <WorkSitesTable refresh={() => {
            refresh();
        }} searchKey={searchKey} items={workSites}/>
    </ListView>;
};
export default List;
