import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Space, Modal, Upload, Row, Col, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { Image } from 'antd';

import './index.scss';
import { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { useEffect } from 'react';
import SubSideViewLayout from 'shared/components/layout/SubSidebarLayout/SubViewLayout';
import ArticleCreateModal from './ArticleCreateModal';
import { deleteImage, fetchArticleById } from 'shared/services/articleService';
import {
    ExclamationCircleOutlined,
    EyeOutlined,
    PlusOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { ConstantImageData } from 'shared/constants/AppConstants';
import { isAdmin } from 'shared/contexts/AuthContext';

const { Paragraph } = Typography;

const { confirm } = Modal;

type Props = {
    t?: Function,
}

function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


const Edit: React.FunctionComponent<Props> = () => {
    const [article, setArticle] = useState({} as any);
    const [imageUrl, setImageUrl] = useState('');
    const [deletingItem, setDeletingItem] = useState(false);
    const [fetchingArticle, setFetchingArticle] = React.useState(false);
    const { t } = useTranslation();
    const RouteParams: any = useParams();
    const [showImagePreview, setShowImagePreview] = React.useState(false);
    const [showImage, setShowImage] = React.useState(false);
    const [showImageUploader, setShowImageUploader] = React.useState(true);
    const isViewMode = RouteParams['pageMode'] && RouteParams['pageMode'].toLowerCase() === 'view';
    const [allowDisabled, setAllowDisabled] = useState(false);

    const refresh = () => {
        setFetchingArticle(true);
        setShowImage(false);
        fetchArticleById(RouteParams['articleId']).then((response) => {
            if (response && response.data && response.data.article) {
                setAllowDisabled(!isAdmin() && response.data.article.isAdminCreated);
                setArticle({
                    ...response.data.article, category: response.data.article.category.id,
                });
            }

        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        }).finally(() => {
            setFetchingArticle(false);
            setTimeout(() => {
                setShowImage(true);
            }, 10);
        });
    };
    const uploadButton = (
        <div>
            <PlusOutlined/>
            <div style={{ marginTop: 8 }}>{t('article.input.image.uploadText')}</div>
        </div>
    );
	// eslint-disable-next-line
    useEffect(refresh, []);
    return (
        <SubSideViewLayout
            loading={fetchingArticle && !(article && article.id)}
            pageTitle={t('genDict.page-title', { title: t(isViewMode ? 'pageTitles.viewArticle' : 'pageTitles.editArticle') })}
            title={
                (
                    <Row align={'middle'} style={{ margin: 0, width: '100%' }} gutter={20}>
                        <Col style={{ paddingLeft: 0, marginBottom: '5px' }}>
                            <Title level={2} style={{ maxWidth: '100%' }}>
                                <div className={'__header-title __header-title-margin'}
                                     style={{ maxWidth: '100%' }}>
                                    <Paragraph style={{ width: '100%' }} ellipsis={{
                                        rows: 2,
                                        expandable: article && article.name && article.name.length > 10,
                                        symbol: <span style={{fontSize: '16px'}}>{t('genDict.more')}</span>,
                                    }}>{article.name}</Paragraph>
                                </div>
                            </Title>
                        </Col>
                        <Col style={{ marginBottom: '5px' , paddingLeft: 0}}>
                            <div style={{ display: 'inline-block' }}>
                                <div
                                    className={'__theme-tags __default'}>{article.reference}</div>
                            </div>
                        </Col>
                        {
                            article.isAdminCreated && !isAdmin() &&
                            <Col flex="auto" style={{ marginBottom: '5px', paddingLeft: '0' }}>
                                <div style={{ display: 'inline-block' }}>
                                    <div
                                        className={'__theme-tags __default'}>{t('genDict.Model')}</div>
                                </div>
                            </Col>}
                    </Row>
                )
            }
            leftSidebar={
                <div className={'__image-thumb-wrapper'}>
                    {
                        article.pictureUrl && showImage && <Image
                            width={'100%'}
                            preview={
                                {
                                    visible: showImagePreview,
                                    onVisibleChange: (value: boolean) => {
                                        setShowImagePreview(value);
                                    },
                                }
                            }
                            src={(showImagePreview ? (article.pictureUrl + '&format=full') : article.pictureUrl) || ConstantImageData}
                            fallback={ConstantImageData}
                        />
                    }
                    {
                        !article.pictureUrl && isViewMode && showImage && !allowDisabled && <Image
                            width={'100%'}
                            preview={
                                {
                                    visible: false,
                                }
                            }
                            src={ConstantImageData}
                            fallback={ConstantImageData}
                        />
                    }
                    {
                        article.pictureUrl && (isAdmin() || !article.isAdminCreated) &&
                        <div className="__action-overlay">
                            <div className="__actions">
                                <Space>
                                    <EyeOutlined onClick={() => {
                                        setShowImagePreview(true);
                                    }
                                    }/>
                                    <DeleteOutlined onClick={() =>
                                        confirm({
                                            icon: <ExclamationCircleOutlined/>,
                                            title: t('article.confirmation.msg'),
                                            okText: t('article.button.deleteArticleImage'),
                                            cancelText: t('genDict.Cancel'),
                                            content: t('article.confirmation.deleteArticleImage'),// TODO
                                            okButtonProps: { loading: deletingItem, danger: true },
                                            onOk: async () => {
                                                setDeletingItem(true);
                                                deleteImage(article.id).then(() => {
                                                    notification.success({
                                                        message: t('genDict.success'),
                                                        description: t('article.notification.articleDeletedImageSuccess'),
                                                    });
                                                    refresh();
                                                }).catch((e: any) => {
                                                    notification.error({
                                                        message: t(e),
                                                    });
                                                }).finally(() => {
                                                    setDeletingItem(false);
                                                });
                                            },
                                        })}/>
                                </Space>
                            </div>
                        </div>
                    }
                    {
                        article.pictureUrl && !(isAdmin() || !article.isAdminCreated) &&
                        <div className="__action-overlay">
                            <div className="__actions">
                                <Space>
                                    <EyeOutlined onClick={() => {
                                        setShowImagePreview(true);
                                    }
                                    }/>
                                </Space>
                            </div>
                        </div>
                    }

                    {
                        !article.pictureUrl && isViewMode && !allowDisabled &&
                        <div className="__action-overlay edit-uploader">
                            {showImageUploader && <Upload
                                name="avatar"
                                accept="image/x-png,image/jpg,image/jpeg"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                beforeUpload={(file) => {
                                    if (file && file.type.toLowerCase().indexOf('image') === -1) {
                                        notification.error({
                                            message: t('article.input.image.invalidImage'),
                                        });
                                        setShowImageUploader(false);
                                        setTimeout(() => {
                                            setShowImageUploader(true);
                                        });
                                        return false;
                                    }
                                    setArticle({ ...article, picture: file });
                                    return false;
                                }}
                                onChange={(e) => {
                                    if (e.file) {
                                        setArticle({ ...article, picture: e.file });
                                        getBase64(e.file, (imageUrl: any) =>
                                            setImageUrl(imageUrl),
                                        );
                                    }

                                }}
                            >
                                {imageUrl ?
                                    <img src={imageUrl} alt="avatar" style={{ width: '100%' }}/> : uploadButton}
                            </Upload>}
                        </div>
                    }
                </div>
            }
        >
            <ArticleCreateModal item={article} onCancel={(reload) => {
                if (reload) {
                    refresh();
                }
            }} mode={'EDIT'}/>
        </SubSideViewLayout>
    );
};
export default Edit;
