import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    Modal,
    Select,
    DatePicker,
    Form,
    Row,
    notification,
    Col,
    Button,
    Input,
} from 'antd';
// @ts-ignore
import moment from 'moment';

const { TextArea } = Input;

import { generateFullName } from 'shared/services/Utility';
import './index.scss';
// @ts-ignore
import useSuppliers from '../../../../hooks/suppliers/useSuppliers';
import {  useState } from 'react';
import SupplierCreateModal from '../../../../../client/src/views/suppliers/SupplierCreateModal';


type Props = {
    onSubmit?: any,
    workSite?: any,
    downloading?: any,
    loading?: any,
    onDownload?: any,
    onBack?: any,
    onCancel?: any,
}

const SupplierSelection: React.FunctionComponent<Props> = ({ downloading, loading, onDownload, onBack, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();

    const { items: suppliers, refetch: refreshSuppliers, loading: loadingSuppliers } = useSuppliers();
    const fields: any[] = [];

    const [isSupplierSelected, setIsSupplierSelected] = useState(null as any);
    const [createModal, setCreateModal] = useState(false);
    const [defaultContact, setDefaultContact] = useState(false);
    const [disableContact, setDisableContact] = useState(false);

    return (
        createModal ? <Modal
            title={t('workSite.purchaseOrders.addSupplier.createSupplier.title')}
            visible={true}
            width={1000}
            maskClosable={false}
            afterClose={
                onCancel
            }
            onCancel={onCancel}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}>
            <div>
                <SupplierCreateModal onCancel={(reload) => {
                    if (reload && reload.data && reload.data.createProvider) {
                        form.setFieldsValue({ providerId: reload.data.createProvider.id });
                        if (reload.data.createProvider.contacts.length === 0) {
                            setDefaultContact(true);
                            setDisableContact(true);
                            form.setFieldsValue({ defaultContact: true });
                        }
                        setIsSupplierSelected(reload.data.createProvider.id);
                        refreshSuppliers();
                        setCreateModal(false);
                    } else {
                        setCreateModal(false);
                    }
                }} mode={'CREATE'}/>
            </div>
        </Modal> : <Modal
            title={t('workSite.purchaseOrders.addSupplier.title')}
            visible={true}
            width={1000}
            maskClosable={false}
            afterClose={
                onCancel
            }
            onCancel={onCancel}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}>
            <div>
                <Form
                    size="large"
                    form={form}
                    fields={fields}
                    onFinish={(values) => {
                        if (defaultContact) {
                            const { providerId } = form.getFieldsValue(['providerId']);
                            if (providerId) {
                                const su = suppliers.find((s: any) => s.id === providerId);
                                if (!su) {
                                    notification.error({
                                        message: t('workSite.purchaseOrders.addSupplier.noSupplierFound'),
                                    });
                                    return;
                                } else if (!su.mail) {
                                    notification.error({
                                        message: t('workSite.purchaseOrders.addSupplier.noEmail'),
                                    });
                                    return;
                                }
                            } else {
                                notification.error({
                                    message: t('workSite.purchaseOrders.addSupplier.noSupplierFound'),
                                });
                                return;
                            }
                        }
                        onSubmit(values);
                    }}
                    layout="vertical"
                    requiredMark={true}>
                    <Row gutter={16} style={{ padding: '10px 20px 20px 20px' }}>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('workSite.purchaseOrders.addSupplier.input.deliveryMode.label')}
                                name={'deliveryMode'}
                                rules={[]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('workSite.purchaseOrders.addSupplier.input.deliveryDate.label')}
                                name={'deliveryDate'}
                                rules={[]}
                            >
                                <DatePicker format={'MM/DD/YYYY'} style={{ width: '100%' }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ padding: '0 20px' }}>
                        <Col sm={24} xs={24}>
                            <Form.Item
                                name={'comment'}
                                label={t('workSite.purchaseOrders.addSupplier.input.comment.label')}
                                rules={[]}
                            >
                                <TextArea style={{height: '400px'}} rows={4}/>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row gutter={16} style={{ padding: '0 20px' }}>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                name={'providerId'}
                                label={t('workSite.purchaseOrders.addSupplier.input.provider.label')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('workSite.purchaseOrders.addSupplier.input.provider.required'),
                                    },
                                ]}
                            >
                                <Select onChange={(e: any) => {
                                    setIsSupplierSelected(!!e ? e : null);
                                    if (e) {
                                        setDefaultContact(false);
                                        form.setFieldsValue({ contactId: null });
                                        form.setFieldsValue({ defaultContact: false });
                                        const su = suppliers.find((s: any) => s.id === e);
                                        if (su && su.contacts && su.contacts.length === 0) {
                                            setDefaultContact(true);
                                            setDisableContact(true);
                                            form.setFieldsValue({ defaultContact: true });
                                        } else {
                                            setDisableContact(false);
                                        }
                                    } else {
                                        setDefaultContact(false);
                                        setDisableContact(false);
                                    }
                                }} placeholder={t('genDict.Select')}
                                        allowClear={true}
                                >
                                    <Select.Option
                                        value={''}>{t('genDict.SelectNone')}</Select.Option>
                                    {
                                        suppliers.map((a: any) => {
                                            return <Select.Option
                                                value={a.id}>{generateFullName(a)}</Select.Option>;

                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row primary-black" sm={12} xs={24} style={{
                            display: 'flex',
                            paddingTop: '18px',
                            flexDirection: 'column',
                            maxHeight: '90px',
                            justifyContent: 'center',
                        }}>
                            {
                                isSupplierSelected ?
                                    <Form.Item
                                        valuePropName="checked"
                                        style={{ paddingTop: '14px' }}
                                        name={'defaultContact'}>
                                        <Checkbox
                                            disabled={disableContact}
                                            checked={defaultContact}
                                            value={defaultContact}
                                            onChange={(e) => {
                                                setDefaultContact(e.target.checked);
                                            }}>
                                            <span
                                                className={'__theme-checkbox'}>{t('workSite.purchaseOrders.addSupplier.input.principalContact.label')}</span>
                                        </Checkbox>
                                    </Form.Item> : <div className={'flat-button primary'} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setCreateModal(true);
                                    }}>
                                        {t('workSite.purchaseOrders.addSupplier.button.addSupplier')}
                                    </div>
                            }
                        </Col>

                    </Row>
                    {
                        suppliers && !loadingSuppliers && suppliers.length && !defaultContact && isSupplierSelected && suppliers.find((a: any) => a.id === isSupplierSelected) ?
                            <Row gutter={16} style={{ padding: '0px 20px 0px 20px' }}>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('workSite.purchaseOrders.addSupplier.input.contactProvider.label')}
                                        name={'contactId'}
                                        rules={[
                                            {
                                                required: !defaultContact,
                                                message: t('workSite.purchaseOrders.addSupplier.input.contactProvider.required'),
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t('genDict.Select')}>
                                            {
                                                suppliers.find((a: any) => a.id === isSupplierSelected).contacts.map((a: any) => {
                                                    return <Select.Option
                                                        value={a.email}>{generateFullName(a)} - {a.email}</Select.Option>;

                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            : ''
                    }
                    <Form.Item>
                        <Row gutter={20} align={'middle'} className={'__form-footer-parent-row __from-sm'}>
                            <Col>
                                <Button
                                    type="default"
                                    disabled={downloading || loading}
                                    onClick={async () => {
                                        onBack();
                                    }}
                                    shape="round"
                                    size={'large'}
                                    className="submit">
                                    {t('workSite.purchaseOrders.addSupplier.button.back')}
                                </Button>
                            </Col>
                            <Col className={'__form-footer-row __from-sm'}>
                                <Row gutter={20}>
                                    <Col>
                                        <Button
                                            type="default"
                                            disabled={downloading || loading}
                                            onClick={async () => {
                                                onCancel();
                                            }}
                                            shape="round"
                                            size={'large'}
                                            className="submit">
                                            {t('workSite.purchaseOrders.addSupplier.button.cancel')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            loading={downloading}
                                            className={'__button-inverted primary'}
                                            disabled={loading}
                                            onClick={async () => {
                                                if (defaultContact) {
                                                    const { providerId } = form.getFieldsValue(['providerId']);
                                                    if (providerId) {
                                                        const su = suppliers.find((s: any) => s.id === providerId);
                                                        if (!su) {
                                                            notification.error({
                                                                message: t('workSite.purchaseOrders.addSupplier.noSupplierFound'),
                                                            });
                                                            return;
                                                        } else if (!su.mail) {
                                                            notification.error({
                                                                message: t('workSite.purchaseOrders.addSupplier.noEmail'),
                                                            });
                                                            return;
                                                        }
                                                    } else {
                                                        notification.error({
                                                            message: t('workSite.purchaseOrders.addSupplier.noSupplierFound'),
                                                        });
                                                        return;
                                                    }
                                                }
                                                form.validateFields(['providerId']).then((d) => {
                                                    const values = form.getFieldsValue();

                                                    onDownload(values);
                                                }).catch((e) => {
                                                });
                                            }}
                                            shape="round"
                                            size={'large'}>
                                            {t('workSite.purchaseOrders.addSupplier.button.download')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={loading}
                                            disabled={downloading || loading}
                                            style={{ width: 'auto' }}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {t('workSite.purchaseOrders.addSupplier.button.next')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};
export default SupplierSelection;
