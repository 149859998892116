import React, { useEffect, useState } from 'react';
import {  Button, Card, Layout } from 'antd';
import { useHistory } from 'react-router-dom';

import logo from 'shared/images/logo.svg';
import logoWhite from 'shared/images/WhiteLogo.svg';

import './Login.scss';
import background from './images/splashScreen.jpeg';
import { Trans, useTranslation } from 'react-i18next';
import Header from './SignUp/Header';

import './SignUp.scss';
import InfoTab from './SignUp/InfoTab';
import { Customer } from './SignUp/InfoTab';
import PaymentTab, { PaymentInfo } from './SignUp/PaymentTab';
import PriceContainer, {
    UserOffer,
    SubscriptionType,
} from './SignUp/PriceContainer';
import API_SERVICE from 'shared/services/api-service';
import { useLocation } from 'react-use';

const { Content, Footer } = Layout;

const defaultUser = {
    subscriptionType: '',
    password: '',
    lastName: '',
    firstName: '',
    phone: '',
    email: '',
    companyName: '',
    billingAddress: {
        address: '',
        postalCode: '',
        city: '',
        lastName: '',
        firstName: '',
        company: '',
    },
    newsletter: true,
};

export default function SignUp() {
    const { t } = useTranslation();
    const [tab, setTab] = useState(1);
    const [values, setValues] = useState<Customer>(defaultUser);
    const [
        initialSubscriptionType,
        setInitialSubscriptionType,
    ] = useState<SubscriptionType>('monthly');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const history = useHistory();
    const [offer, setOffer] = useState<UserOffer | null>(null);

    const location = useLocation();

    useEffect(() => {
        if (location.pathname) {
            if (
                location.pathname
                    .toLowerCase()
                    .indexOf('/confirmation-email-erreur') > -1
            ) {
                setTab(6);
            } else if (
                location.pathname.toLowerCase().indexOf('/confirmation-email') >
                -1
            ) {
                setTab(5);
            }
        }
        const params = new URLSearchParams(location.search);

        const offerParam = params.get('offer');

        if (
            offerParam &&
            ['monthly', 'yearly', 'trial'].indexOf(offerParam) > -1
        ) {
            setInitialSubscriptionType(offerParam as SubscriptionType);
        }
    }, []);

    const subscriptionType = offer ? offer.type : initialSubscriptionType;
    return (
        <Layout className="sign-up">
            <Header tab={tab} subscriptionType={subscriptionType}/>
            <Content className="__content-wrapper">
                <div className="__content">
                    <div
                        className="__splash"
                        style={{
                            backgroundImage: `url(${background})`,
                        }}
                    />
                    <div className="__main-section">
                        {tab === 0 && <></>}
                        {tab === 1 && (
                            <InfoTab
                                defaultValue={defaultUser}
                                subscriptionType={subscriptionType}
                                next={async (customer: Customer) => {
                                    setValues({
                                        ...values,
                                        ...customer,
                                        billingAddress: {
                                            ...values.billingAddress,
                                            firstName: customer.firstName,
                                            lastName: customer.lastName,
                                            company: customer.companyName,
                                        },
                                    });
                                    if (subscriptionType === 'trial') {
                                        setLoading(true);
                                        setError('');
                                        try {
                                            await API_SERVICE.registerUser(
                                                {
                                                    ...values,
                                                    ...customer,
                                                    subscriptionType: 'trial',
                                                },
                                            );
                                            setTab(4);
                                        } catch (e) {
                                            setError(
                                                t(API_SERVICE.handleErrors(e)),
                                            );
                                        }
                                        setLoading(false);
                                    } else {
                                        setTab(2);
                                    }
                                }}
                            />
                        )}
                        {tab === 2 && (
                            <PaymentTab
                                setError={setError}
                                error={error}
                                next={async (paymentInfo: PaymentInfo) => {
                                    setValues({
                                        ...values,
                                        ...paymentInfo,
                                        subscriptionType,
                                    });
                                    setLoading(true);
                                    setError('');
                                    try {
                                        await API_SERVICE.registerUser(
                                            {
                                                ...values,
                                                ...paymentInfo,
                                                subscriptionType,
                                            },
                                        );
                                        setTab(3);
                                    } catch (e) {
                                        setError(
                                            t(API_SERVICE.handleErrors(e)),
                                        );
                                    }
                                    setLoading(false);
                                }}
                                loading={loading}
                                offer={offer}
                                customer={values}
                            />
                        )}
                        {tab === 3 && (
                            <Card className={'__after-confirmation-1'}>
                                <div className="__title">
                                    {t('signup.afterConfirmation1.title')}
                                </div>
                                <div className="__sub-title">
                                    <Trans
                                        i18nKey={
                                            'signup.afterConfirmation1.subtitle'
                                        }
                                        components={{
                                            b: <span className="bold"/>,
                                        }}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    shape="round"
                                    onClick={() => {
                                        history.push('/login');
                                    }}
                                    size={'large'}
                                >
                                    {t('signup.afterConfirmation1.action')}
                                </Button>
                            </Card>
                        )}
                        {tab === 4 && (
                            <Card className={'__after-confirmation-1'}>
                                <div className="__title">
                                    {t('signup.afterConfirmation2.title')}
                                </div>
                                <div className="__sub-title">
                                    <Trans
                                        i18nKey={
                                            'signup.afterConfirmation2.subtitle'
                                        }
                                        components={{
                                            b: <span className="bold"/>,
                                        }}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        history.push('/login');
                                    }}
                                    shape="round"
                                    size={'large'}
                                >
                                    {t('signup.afterConfirmation2.action')}
                                </Button>
                            </Card>
                        )}
                        {tab === 5 && (
                            <Card className={'__after-confirmation-1'}>
                                <div className="__title">
                                    {t('signup.afterConfirmation3.title')}
                                </div>
                                <div className="__sub-title">
                                    <Trans
                                        i18nKey={
                                            'signup.afterConfirmation3.subtitle'
                                        }
                                        components={{
                                            b: <span className="bold"/>,
                                        }}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        history.push('/login');
                                    }}
                                    shape="round"
                                    size={'large'}
                                >
                                    {t('signup.afterConfirmation3.action')}
                                </Button>
                            </Card>
                        )}
                        {tab === 6 && (
                            <Card className={'__after-confirmation-1'}>
                                <div className="__title">
                                    {t('signup.afterConfirmation4.title')}
                                </div>
                                <div className="__sub-title">
                                    <Trans
                                        i18nKey={
                                            'signup.afterConfirmation4.subtitle'
                                        }
                                        components={{
                                            b: <span className="bold"/>,
                                        }}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        history.push('/login');
                                    }}
                                    shape="round"
                                    size={'large'}
                                    ghost
                                >
                                    {t('signup.afterConfirmation4.action')}
                                </Button>
                            </Card>
                        )}

                    </div>
                    {(tab === 1 || tab === 2) && (
                        <div className={'__sub-section'}>
                            <PriceContainer
                                offer={offer}
                                initialSubscriptionType={
                                    initialSubscriptionType
                                }
                                onOfferChange={(o: UserOffer) => {
                                    setOffer(o);
                                }}
                            />
                        </div>
                    )}
                </div>
            </Content>
            <Footer className="__footer">
                <div className="__footer-wrapper">
                    <img src={logoWhite} alt={'logo'} height={22}/>
                    <div className={'__text'}>
                        <a target={'_blank'} href={'https://nevoo.app/legal/'}> {t('genDict.footerCopyright')}</a>
                    </div>
                </div>
            </Footer>
        </Layout>
    );
}
