import gql from 'graphql-tag';
import { client, clientWithErrors } from '../../services/ApolloClient';

const MUTATION = gql`mutation($input: WorkSiteInput!, $id: ID!){
  workSite_edit(input: $input, id: $id){
     id
  }
} 
`;

const useWorkSiteUpdate = async (data: any, id: any) => {
    return clientWithErrors(client.mutate({ variables: { input: data, id }, mutation: MUTATION }));
};
export default useWorkSiteUpdate;
