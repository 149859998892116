import * as React from 'react';
// @ts-ignore
import debounce from 'lodash.debounce';
import { SearchOutlined } from '@ant-design/icons';

import { Input } from 'antd';


import './ListSearchBar.scss';

type Props = {
    text: string,
    roundBorders?: boolean,
    onChange: Function,
    debounce?: boolean
}

const ListSearchBar: React.FunctionComponent<Props> = ({ text,  onChange }) => {
    const [searchKey, setSearchKey] = React.useState('');
    return (
        <div className={'__search-box-wrapper'}>
            <Input autoComplete={"off"} placeholder={text} value={searchKey} className={'__rounded-input'} onChange={(e) => {
                const { value: nextValue } = e.target;
                setSearchKey(nextValue);
                const debouncedSave = debounce(() => onChange(nextValue), 500);
                debouncedSave();
            }} style={{ width: 250 }}
            />
            <SearchOutlined
                className={'__search-icon'}
            />
        </div>
    );
};
export default ListSearchBar;
