// noinspection RequiredAttributes

import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Button as BaseButton, Col, Form, Input, Row, Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Modal, notification } from 'antd';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import { ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { getDefaultPageSize, paginationParams, useLayoutMedia } from 'shared/services/Utility';
import './index.scss';
import { useEffect, useState } from 'react';
import { dotToObject, filteredList } from 'shared/services/Utility';
import { REGEX_STRING_VERIFY } from 'shared/constants/AppConstants';

import {
    createNewCategory,
    deleteArticleCategoryById,
    fetchCategories,
    updateCategory,
} from 'shared/services/articleService';
import { Article } from '../articles/Article.model';
import ListSearchBar from 'shared/components/Ant/ListSearchBar/ListSearchBar';
import { isAdmin } from 'shared/contexts/AuthContext';

const { Text } = Typography;
type Props = {
    onChange?: Function
}

const { confirm } = Modal;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const ArticleCategories: React.FunctionComponent<Props> = (p) => {
    const { t } = useTranslation();
    const [searchKey, setSearchKey] = React.useState('');
    const [categories, setCategories] = useState([] as any[]);
    const [fields, setFields] = useState([] as any[]);
    const [deletingCategory, setDeletingCategory] = useState(false);
    const [saving, setSaving] = useState(false);
    const isDesktop = useLayoutMedia(['Desktop']);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const refresh = (refresh = false) => {
        fetchCategories(refresh).then((response) => {
            if (response && response.data && response.data.articleCategories) {
                setCategories(JSON.parse(JSON.stringify(response.data.articleCategories)));
            }
        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        });
    };
	// eslint-disable-next-line
    useEffect(refresh, []);

    const [initialValues, setInitialValues] = useState({} as any);
    const handleCancel = () => {
        setCreateModalVisible(false);
        setFields([]);
        setInitialValues(null);
    };
    const columns = [
        {
            title: <span style={{ paddingLeft: '4px' }}>{t('article.category.listTitle.name')}</span>,
            render: (a: Article) => (
                <div style={{ paddingLeft: '4px' }}>
                    {
                        a.name
                    }
                </div>),
            sorter: (a: any, b: any) => sortAlpha(a.name, b.name),
        },
        {
            title: t('article.category.listTitle.description'),
            dataIndex: 'description',
        },
        {
            title: t('article.category.listTitle.isAdminCreated'),
            render: (a: Article) => (
                <div>
                    {
                        ((isAdmin() && a.isAdminCreated) || (!isAdmin() && !a.isAdminCreated)) ?
                            <CheckOutlined className={'round primary table-icons'}/> : <></>
                    }
                </div>),
        },
        {
            title: t('article.category.listTitle.actions'),
            fixed: 'right',
            render: (a: any) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('article.category.tooltip.editCategory')}>
                            <div/>
                            <EditButton disabled={!isAdmin() && a.isAdminCreated} onClick={() => {
                                setInitialValues(JSON.parse(JSON.stringify(a)));
                                setCreateModalVisible(true);
                            }}/>
                        </Tooltip>

                        <Tooltip placement={'top'}
                                 title={!isAdmin() && a.isAdminCreated ? t('article.category.tooltip.deleteCategory') : ''}>
                            <div/>
                            <DeleteButton disabled={(!isAdmin() && a.isAdminCreated) || (a.isDeletable === false)} onClick={() =>
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('article.category.confirmation.msg'),
                                    okText: t('article.category.button.deleteCategory'),
                                    cancelText: t('genDict.Cancel'),
                                    content: t('article.category.confirmation.deleteCategory'),// TODO
                                    okButtonProps: { loading: deletingCategory, danger: true },
                                    onOk: async () => {
                                        setDeletingCategory(true);
                                        deleteArticleCategoryById(a.id).then(() => {
                                            notification.success({
                                                message: t('genDict.success'),
                                                description: t('article.category.notification.categoryDeletedSuccess'),
                                            });
                                            refresh(true);
                                            if (p.onChange) {
                                                p.onChange();
                                            }
                                        }).catch(e => {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }).finally(() => {
                                            setDeletingCategory(false);
                                        });
                                    },
                                })}/>
                        </Tooltip>
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];

    return (
        <ListView
            title={''}
            noP={true}
            pageTitle={t('genDict.page-title', { title: t('pageTitles.listArticleCategories') })}
            cardTitleStyle={{
                padding: 0,
            }}
            searchBar={
                (<>
                    <div className={'__sub-header-with-add-button'}>
                        <h2 className="__form-header-title" style={{
                            width: '100%',
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>

                               <span
                                   className={'__giliam-bold'}
                                   style={{ marginTop: '0px' }}>
                                    {t('article.category.menuText')}
                               </span>
                            <span className="__button">
                            <BaseButton type="primary"
                                        shape="round"
                                        size={'middle'} onClick={() => {
                                setCreateModalVisible(true);
                            }}>
							{isDesktop ? t('article.category.button.addCategoryMenu') : t('genDict.add')}
							</BaseButton>
                        </span>
                        </h2>
                    </div>
                    <Space style={{ padding: '10px 16px' }} direction={'horizontal'} size={20}>
                        <ListSearchBar onChange={(e: string) => {
                            setSearchKey(e);
                        }} text={t('genDict.Search')}/>
                    </Space>
                </>)
            }
        >
            <Modal
                title={t('article.category.button.addCategoryMenu')}
                visible={createModalVisible}
                width={800}
                maskClosable={false}
                onCancel={handleCancel}
                afterClose={handleCancel}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <Form
                    size="large"
                    //form={form}
                    fields={fields}
                    initialValues={initialValues}
                    onFinish={async (formValues) => {
                        const reqObject = dotToObject(formValues);
                        setSaving(true);
                        if (!initialValues || !initialValues.id) {
                            createNewCategory(reqObject).then((response) => {
                                notification.success({
                                    message: t('genDict.success'),
                                    description: t('article.category.notification.categoryCreatedSuccess'),
                                });
                                refresh(true);
                                setCreateModalVisible(false);
                            }).catch(e => {
                                notification.error({
                                    message: t(e),
                                });
                            }).finally(() => {
                                setSaving(false);
                                if (p.onChange) {
                                    p.onChange();
                                }
                                handleCancel();

                            });
                        } else if (initialValues && initialValues.id) {
                            updateCategory({ ...reqObject, id: initialValues.id }).then(() => {
                                notification.success({
                                    message: t('genDict.success'),
                                    description: t('article.category.notification.categoryUpdatedSuccess'),
                                });
                                refresh(true);
                                setCreateModalVisible(false);
                            }).catch(e => {
                                notification.error({
                                    message: t(e),
                                });
                            }).finally(() => {
                                setSaving(false);
                            });

                        }


                    }}
                    layout="vertical"
                >
                    <Row gutter={16} style={{ padding: '20px' }}>
                        <Col className="gutter-row" md={8} sm={24} xs={24}>
                            <Form.Item
                                label={t('article.category.input.name.label')}
                                name={'name'}
                                key="name"
                                rules={[
                                    { required: true, message: t('article.category.input.name.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={16} sm={24} xs={24}>
                            <Form.Item
                                label={t('article.category.input.description.label')}
                                name={'description'}
                                key="description"
                                rules={[
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Space direction={'horizontal'} className={'__form-footer'}>
                                <Form.Item>
                                    <BaseButton
                                        type="default"
                                        onClick={() => {
                                            setCreateModalVisible(false);
                                            setInitialValues({});
                                        }}
                                        disabled={saving}
                                        shape="round"
                                        size={'large'}
                                        className="submit"
                                    >
                                        {t('genDict.Cancel')}
                                    </BaseButton>
                                </Form.Item>
                                <Form.Item>
                                    <BaseButton
                                        type="primary"
                                        htmlType="submit"
                                        loading={saving}
                                        shape="round"
                                        size={'large'}
                                        className="submit"
                                    >
                                        {initialValues && initialValues.id ? t('article.category.button.saveCategory') : t('article.category.button.addCategory')}
                                    </BaseButton>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <div className="__table-pagination-padded">
                <Table
                    locale={{
                        emptyText: (
                            searchKey ? <>
                                    <Space style={{ padding: '10px' }}>
                                        <Text
                                            className={'primary-black'}>{t('article.category.table.noSearchResultFound')}</Text>
                                    </Space>
                                </>
                                : <>
                                    <Space style={{ padding: '10px' }}>
                                        <Text className={'primary-black'}>{t('article.category.table.emptyMessage')}</Text>
                                        <div className={'flat-button primary'} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setCreateModalVisible(true);
                                        }}>
                                            {t('article.category.button.addCategory')}
                                        </div>
                                    </Space>
                                </>
                        ),
                    }}
					showSorterTooltip ={false}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (currentPage, pageSize) => {
                            paginationParams(pageSize);
                        },
                        defaultPageSize: getDefaultPageSize(),                    }}
                    //@ts-ignore
                    columns={columns}
                    dataSource={filteredList(searchKey, categories, {})}
                >
                </Table>
            </div>
        </ListView>
    );
};
export default ArticleCategories;
