// @ts-ignore
import React, { useEffect, useState } from 'react';

import { Form, Input, Button, Button as BaseButton, Row, Col, Select, Space, notification, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dotToObject, objectToDot } from 'shared/services/Utility';
import { CopyOutlined } from '@ant-design/icons';
import {
    createNewPrivateCustomer,
    editPrivateCustomer,
} from 'shared/hooks/useClients';
import {
    ConstantTitles,
    REGEX_STRING_VERIFY,
    REGEX_PHONE_VERIFY,
    REGEX_POSTALCODE_VERIFY,
    REGEX_EMAIL_VERIFY,
} from 'shared/constants/AppConstants';
import { Customer } from '../../types/customer.type';

interface Props {
    client?: Customer;
    onChange?: (fields: any[]) => void;
    onCancel?: (refresh?: boolean) => void;
    onCreate?: (data?: any) => void;
    setActiveTab?: (tab: string) => void;
    loading?: boolean;
    mode: 'CREATE' | 'EDIT' | 'VIEW';
}

const { Text } = Typography;

export default function ClientCreateModal({
                                              onCancel,
                                              onCreate,
                                              client,
                                              setActiveTab,
                                              mode,
                                          }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const fields: any[] = [];
    const [form] = Form.useForm();
    const optionValues = ConstantTitles;
    const [savingCustomer, setSavingCustomer] = React.useState(false);
    const [initialValues, setInitialValues] = useState(client ? objectToDot(client) : {});
    useEffect(() => {
        setInitialValues(client ? objectToDot(client) : {});
    }, [client]);
    return (
        <Form
            className="client-create-modal"
            size="large"
            form={form}
            fields={fields}
            requiredMark={mode !== 'VIEW'}
            onValuesChange={(changedValue, formValues) => {
                setInitialValues({ ...formValues, id: initialValues.id });
            }}
            initialValues={initialValues}
            onFinish={async (formValues) => {
                const reqObject = dotToObject(formValues);
                reqObject['contacts'] = reqObject['contacts'] ? reqObject['contacts'] : [];
                reqObject['type'] = 'priv';
                setSavingCustomer(true);
                if (mode === 'CREATE') {
                    createNewPrivateCustomer(reqObject).then((response) => {
                        notification.success({
                            message: t('genDict.success'),
                            description: t('client.notification.clientCreatedSuccess'),
                        });
                        if (onCancel) {
                            onCancel(true);
                        }
                        if (onCreate) {
                            onCreate(response.data);
                        }
                    }).catch(e => {
                        notification.error({
                            message: t(e),
                        });
                    }).finally(() => {
                        setSavingCustomer(false);
                    });
                } else if (client) {
                    editPrivateCustomer(reqObject, client.id).then((response) => {
                        notification.success({
                            message: t('genDict.success'),
                            description: t('client.notification.clientUpdatedSuccess'),
                        });
                        if (onCancel) {
                            onCancel(true);
                        }
                        history.push('/clients/' + client.id + '/view');
                    }).catch(e => {
                        notification.error({
                            message: t(e),
                        });
                    }).finally(() => {
                        setSavingCustomer(false);
                    });
                }


            }}
            layout="vertical">
            <div style={{ padding: '20px' }}>
                {
                    mode === 'CREATE' && <div style={{ marginBottom: '20px' }}>
                        <BaseButton key="back" type={'primary'} onClick={() => {
                        }}>
                            <div className={'roboto-regular'} style={{ fontWeight: 'normal' }}>
                                {t('genDict.Private')}
                            </div>
                        </BaseButton>
                        <BaseButton key="back" type={'default'} onClick={() => {
                            if (setActiveTab) {
                                setActiveTab('pro');
                            }
                        }}>
                            <div className={'roboto-regular'} style={{ fontWeight: 'normal' }}>
                                {t('genDict.Professional')}
                            </div>
                        </BaseButton>
                    </div>
                }
                <>
                    <Row gutter={16}>
                        <Col className="gutter-row primary-black" md={6} sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.civility.label')}
                                name="title"
                                initialValue={optionValues[0]}
                                rules={[
                                    { required: true, message: t('client.input.civility.required') },
                                ]}
                            >
                                <Select
                                    disabled={mode === 'VIEW'}
                                >
                                    {
                                        optionValues.map((option) => {
                                            return <Select.Option
                                                value={option}>{t(`genDict.${option}`)}</Select.Option>;
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row __hover-copy-input" md={9} sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.firstName.label')}
                                name="firstName"
                                key="firstName"
                                rules={[
                                    { required: true, message: t('client.input.firstName.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues.firstName,
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input" md={9} sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.lastName.label')}
                                name="lastName"
                                rules={[
                                    { required: true, message: t('client.input.lastName.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues.lastName,
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>


                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.emailAddress.label')}
                                name="mail"
                                rules={[
                                    { required: true, message: t('client.input.emailAddress.required') },
                                    {
                                        pattern: REGEX_EMAIL_VERIFY,
                                        message: t('client.input.emailAddress.wrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />

                            </Form.Item>

                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues.mail,
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.phone.label')}
                                name="phone"
                                rules={[
                                    { required: true, message: t('client.input.phone.required') },
                                    {
                                        pattern: REGEX_PHONE_VERIFY,
                                        message: t('client.input.phone.wrongFormat'),
                                    },

                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>
                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues.phone,
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.mobilePhone.label')}
                                name="mobile"
                                rules={[
                                    {
                                        pattern: REGEX_PHONE_VERIFY,
                                        message: t('client.input.mobilePhone.wrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>
                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues.mobile,
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.address.label')}
                                name="address.address"
                                rules={[
                                    { required: true, message: t('client.input.address.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}/>
                            </Form.Item>
                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['address.address'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }

                        </Col>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.additionalAddress.label')}
                                name="address.additional"
                                rules={[
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>
                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['address.additional'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.postalCode.label')}
                                name="address.postalCode"
                                rules={[
                                    { required: true, message: t('client.input.postalCode.required') },
                                    {
                                        pattern: REGEX_POSTALCODE_VERIFY,
                                        message: t('client.input.postalCode.wrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['address.postalCode'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                            <Form.Item
                                label={t('client.input.city.label')}
                                name="address.city"
                                rules={[
                                    { required: true, message: t('client.input.city.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>

                            {
                                initialValues && initialValues.id && (mode === 'VIEW') &&
                                <Text className={'__copy-to-clipboard-button'}
                                      copyable={{
                                          text: initialValues['address.city'],
                                          icon: <CopyOutlined/>,
                                          tooltips: [t('genDict.copy'), t('genDict.copied')],
                                      }}/>
                            }
                        </Col>
                    </Row>
                </>
            </div>
            {
                mode !== 'VIEW' && <Row>
                    <Col span={24}>
                        <Space size={20} direction={'horizontal'} className={'__form-footer'}>
                            {
                                mode === 'CREATE' && <Form.Item>
                                    <Button
                                        type="default"
                                        onClick={() => {
                                            if (onCancel) {
                                                onCancel();
                                            }
                                        }}
                                        disabled={savingCustomer}
                                        shape="round"
                                        size={'large'}
                                        className="submit">
                                        {t('genDict.Cancel')}
                                    </Button>
                                </Form.Item>
                            }
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={savingCustomer}
                                    shape="round"
                                    size={'large'}
                                    className="submit">
                                    {mode === 'CREATE' ? t('client.button.addClient') : t('client.button.saveClient')}
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            }
        </Form>
    );
}
