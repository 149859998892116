// noinspection RequiredAttributes

import React, { useState } from 'react';


import {
    Form,
    Input,
    Button,
    Button as BaseButton,
    Row,
    Col,
    Space,
    notification,

    Typography, Modal, Skeleton, Card, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { dotToObject, objectToDot } from 'shared/services/Utility';
import { InfoCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import { updateCurrentCompany, updateCurrentUser, updateEmail } from 'shared/services/userService';
import {
    REGEX_STRING_VERIFY,
    REGEX_PHONE_VERIFY,
    REGEX_POSTALCODE_VERIFY,
    REGEX_EMAIL_VERIFY,
} from 'shared/constants/AppConstants';

const { Text } = Typography;
const { confirm } = Modal;

interface Props {
    item?: any;
    onChange?: (fields: any[]) => void;
    setActiveTab?: (tab: string) => void;
    onCancel?: (refresh?: boolean) => void;
    loading?: boolean;
    mode: 'CREATE' | 'EDIT' | 'VIEW';
}

export default function MyProfileForm({
                                          onCancel,
                                          mode,
                                      }: Props) {
    const { t } = useTranslation();
    const [fields]: any[] = useState([] as any[]);
    const [form] = Form.useForm();
    const { currentUser, refetch: refetchUser } = useCurrentUser();
    const [changeEmail, setChangeEmail] = React.useState(false);
    const [updatingEmail, setUpdatingEmail] = React.useState(false);
    const [deletingProfile] = useState(false);
    const [savingItem, setSavingItem] = useState(false);
    const informationHeader = (<div className={'__form-header'}>
        <Title level={5} className={'__form-header-title'}>{t('genDict.myProfile')}</Title>
    </div>);

    if (!currentUser) {
        return <Card style={{ padding: '30px' }}>
            <Skeleton.Button active={true} size={'large'}/>
            <Skeleton avatar paragraph={{ rows: 4 }}/>
        </Card>;
    }
    const initialValues = {
        ...currentUser,
    };
    return <>
        {
            informationHeader
        }
        <Form
            className="__my-profile-form"
            size="large"
            form={form}
            fields={fields}
            requiredMark={mode !== 'VIEW'}
            initialValues={objectToDot(initialValues)}
            onFinish={async (formValues) => {
                const data = dotToObject(formValues);
                setSavingItem(true);
                const d = {
                    phoneNumber: data.phoneNumber,
                    firstName: data.firstName,
                    lastName: data.lastName,
                };
                const bA = {
                    billingAddress: {
                        firstName: data.company.billingAddress.firstName,
                        lastName: data.company.billingAddress.lastName,
                        address: data.company.billingAddress.address,
                        additional: data.company.billingAddress.additional,
                        postalCode: data.company.billingAddress.postalCode,
                        city: data.company.billingAddress.city,
                        company: data.company.billingAddress.company,
                    },
                };
                updateCurrentUser(d).then((response) => {
                    updateCurrentCompany(bA).then((response) => {
                        notification.success({
                            message: t('genDict.success'),
                            description: t('myProfile.form.notification.myProfileUpdatedSuccess'),
                        });
                        if (onCancel) {
                            onCancel(true);
                        }
                        refetchUser();
                    }).catch(e => {
                        notification.error({
                            message: t(e),
                        });
                    }).finally(() => {
                        setSavingItem(false);
                    });
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                });
            }}
            layout="vertical">
            {
                changeEmail && <Modal
                    title={t('myProfile.changeEmailPopup.title')}
                    visible={changeEmail}
                    maskClosable={false}
                    className={'__no-p-body'}
                    afterClose={() => {
                        setChangeEmail(false);
                    }}
                    width={560}
                    onCancel={() => {
                        setChangeEmail(false);
                    }}
                    destroyOnClose={true}
                    footer={null}
                >
                    <Form
                        size="large"
                        fields={fields}
                        initialValues={{ email: currentUser ? currentUser.email : '' }}
                        onFinish={async (formValues) => {
                            setUpdatingEmail(true);
                            updateEmail({ oldEmail: currentUser.email, newEmail: formValues.email }).then((success) => {
                                notification.success({
                                    message: t('genDict.success'),
                                    description: t('myProfile.form.notification.emailUpdateSuccess'),
                                });
                                refetchUser();
                                setChangeEmail(false);
                            }).catch(e => {
                                notification.error({
                                    message: t(e),
                                });
                            }).finally(() => {
                                setUpdatingEmail(false);
                            });
                        }}
                        layout="vertical"
                    >
                        <Row gutter={16}>
                            <Col span={24}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={24} style={{ padding: '20px 30px 20px 30px' }}>
                                        <div className="__popup-sb-title">
                                            {
                                                t('myProfile.changeEmailPopup.subTitle')
                                            }
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={24} style={{ padding: '0 30px 30px 30px' }}>
                                        <Form.Item
                                            label={t('myProfile.changeEmailPopup.input.emailAddress.label')}
                                            name={'email'}
                                            key="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('myProfile.changeEmailPopup.input.emailAddress.required'),
                                                },
                                                {
                                                    pattern: REGEX_EMAIL_VERIFY,
                                                    message: t('myProfile.changeEmailPopup.input.emailAddress.wrongFormat'),
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Space direction={'horizontal'} className={'__form-footer'}>
                                    <Form.Item>
                                        <BaseButton
                                            type="default"
                                            onClick={() => {
                                                setChangeEmail(false);
                                            }}
                                            disabled={updatingEmail}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {t('genDict.Cancel')}
                                        </BaseButton>
                                    </Form.Item>
                                    <Form.Item>
                                        <BaseButton
                                            type="primary"
                                            htmlType="submit"
                                            loading={updatingEmail}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {t('myProfile.changeEmailPopup.button.save')}
                                        </BaseButton>
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            }
            <div style={{ padding: '20px' }}>
                <>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="__title">
                                {t('myProfile.form.title')}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('myProfile.form.input.emailAddress.label')}
                                name="email"
                                key="email"
                                rules={[
                                    { required: true, message: t('myProfile.form.input.emailAddress.required') },
                                    {
                                        pattern: REGEX_EMAIL_VERIFY,
                                        message: t('myProfile.form.input.emailAddress.wrongFormat'),
                                    },
                                ]}
                            >
                                <Input autoComplete={'off'} disabled={true}
                                />
                            </Form.Item>
                            {
                                currentUser.newEmail && <>
                                    <Text type="warning">{currentUser.newEmail}
                                        &nbsp;
                                        &nbsp;
                                        <Tooltip title={t('myProfile.form.unverifiedEmail')}>
                                            <InfoCircleFilled/>
                                        </Tooltip>
                                    </Text>

                                </>
                            }
                        </Col>
                        <Col className="gutter-row __hover-copy-input __column-vertical-bottom"
                             style={{ paddingBottom: '10px' }} md={12} sm={24} xs={24}>
                            <Button
                                type="primary"
                                loading={savingItem}
                                shape="round"
                                onClick={() => setChangeEmail(true)}
                                size={'large'}
                                className="submit"
                            >
                                {t('myProfile.form.button.modify')}
                            </Button>
                            {
                                currentUser.newEmail && <>
                                    <Text type="warning">
                                        &nbsp;
                                        &nbsp;
                                    </Text>

                                </>
                            }
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="__sub-title">
                                {t('myProfile.form.subTitle')}
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                            <Form.Item
                                name={'firstName'}
                                key="firstName"
                                label={t('myProfile.form.input.firstName.label')}
                                rules={[
                                    { required: true, message: t('myProfile.form.input.firstName.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete={'off'}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                            <Form.Item
                                name={'lastName'}
                                key="lastName"
                                label={t('myProfile.form.input.lastName.label')}
                                rules={[
                                    { required: true, message: t('myProfile.form.input.lastName.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete={'off'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('myProfile.form.input.phone.label')}
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: t('myProfile.form.input.phone.required'),
                                    },
                                    {
                                        pattern: REGEX_PHONE_VERIFY,
                                        message: t('myProfile.form.input.phone.wrongFormat'),
                                    },
                                ]}
                            >
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <div className="__sub-title">
                                {t('myProfile.form.subTitle2')}
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                            <Form.Item
                                name={'company.billingAddress.firstName'}
                                key="company.billingAddress.firstName"
                                label={t('myProfile.form.input.firstName.label')}
                                rules={[
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input
                                    autoComplete={'off'}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                            <Form.Item
                                name={'company.billingAddress.lastName'}
                                key="company.billingAddress.lastName"
                                label={t('myProfile.form.input.lastName.label')}
                                rules={[
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}

                            >
                                <Input
                                    autoComplete={'off'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                            <Form.Item
                                name={'company.billingAddress.company'}
                                key="company.billingAddress.company"
                                label={t('myProfile.form.input.companyName.label')}
                                rules={[
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}

                            >
                                <Input
                                    autoComplete={'off'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                name="company.billingAddress.address"
                                label={t('myProfile.form.input.address.label')}
                                rules={[
                                    { required: true, message: t('myProfile.form.input.address.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                name="company.billingAddress.additional"
                                key="company.billingAddress.additional"
                                label={t('myProfile.form.input.additionalAddress.label')}
                                rules={[
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                name="company.billingAddress.postalCode"
                                label={t('myProfile.form.input.postalCode.label')}
                                rules={[
                                    { required: true, message: t('myProfile.form.input.postalCode.required') },
                                    {
                                        pattern: REGEX_POSTALCODE_VERIFY,
                                        message: t('myProfile.form.input.postalCode.wrongFormat'),
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                name="company.billingAddress.city"
                                key="company.billingAddress.city"
                                label={t('myProfile.form.input.city.label')}
                                rules={[
                                    { required: true, message: t('myProfile.form.input.city.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                    </Row>
                </>

            </div>
            <Col span={24}>
                <Row gutter={20} align={'middle'} className={'__form-footer-parent-row __single-layer'}>
                    <Col style={{ height: '46px' }}>
                        {
                            <div className={'flat-button primary'} style={{ lineHeight: '46px' }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('myProfile.form.confirmation.information'),
                                    okText: t('myProfile.form.button.deleteAccount'),
                                    cancelText: t('genDict.Ok'),
                                    content: t('myProfile.form.confirmation.deleteAccount'),// TODO
                                    okButtonProps: {
                                        loading: deletingProfile, danger: true, style: {
                                            display: 'none',
                                        },
                                    },
                                    onOk: async () => {

                                    },
                                });
                            }
                            }>
                                {t('myProfile.form.button.deleteAccount')}
                            </div>
                        }
                    </Col>
                    <Col>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={savingItem}
                                shape="round"
                                size={'large'}
                                className="submit"
                            >
                                {t('myProfile.form.button.save')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Col>
        </Form>
    </>;
}
