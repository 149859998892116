import * as React from 'react';
import { Button as BaseButton, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

type Props = {
    onClick: () => void;
    tooltipText?: any;
    style?: any;
    className?: any;
    loading?: boolean;
    notFilled?: boolean;
    disabled?: boolean;
}
const CloseButton: React.FunctionComponent<Props> = ({ className, disabled, tooltipText, notFilled, style, onClick, loading }) => {
    return (
        <Tooltip placement="top" title={tooltipText}>
            {

                notFilled ? <CloseOutlined
                        className={'_hover-primary-icon'}
                        style={style || {}}
                        onClick={onClick}
                    /> :
                    <BaseButton
                        className={className}
                        type={'primary'}
                        style={style || {}}
                        shape="circle"
                        onClick={onClick}
                        icon={<CloseOutlined/>}
                    />
            }
        </Tooltip>
    );
};
export default CloseButton;
