import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { WorkSiteType } from '../../types/workSite.type';
import { client, clientWithErrors } from '../../services/ApolloClient';

const MUTATION = gql`  mutation($id: ID!, $rowId: ID!, $input: PriceAndQuantityUpdatableInput!){
workSite_editRow(id: $id, rowId: $rowId, input: $input){
    id
    reference

}
}`;


const useUpdateWorkSiteRow = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: MUTATION }));

};
export default useUpdateWorkSiteRow;
