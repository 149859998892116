import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
    item: any;
    loading: boolean;
    error: any;
    refetch: any
};
const QUERY = gql`
     query WorkSite($id: ID!){
      workSite(id: $id){
        id
        reference
        name
         customer{
          id
          firstName
          lastName
          type
          name
          mail
          phone
          taxNumber
          address{
            address
            additional
            postalCode
            city
          }
        }
        address{
          address
          additional
          postalCode
          city
        }
        taxRate
         worktypes{
          id
          price
          quantity
          sellingPrice
          rows{
            id
            type
            quantity
            name
            reference
            order
            price
          }
          reference
          name
        }
       fees{
          delivery
          supply
          evacuation
          cleaning
          engineRenting
          movement
          parking
          accountingProportion
          other
        }   
        effortCost
        articleCost
        feesCost
        sellingPrice
        margin
        ratio
        quotes{
          id
          title
          type
          mime
          extension
          isEmailSent
          customer{
            id
            mail
            title
            type
            firstName
            lastName
            name
             contacts{
                id
                title
                firstName
                lastName
                email
                phone
                isMain
              }
          }
          fileName
          fileSize
          url
          createdAt
          updatedAt
          companyId
		  receiverEmail
        }
         purchaseOrders{
          id
          title
          isEmailSent
          url
          createdAt
          provider{
            name
            id
          }
          links{
            entityType
            entityId
          }
		  receiverEmail
        }
      }
    }  
`;

const useWorkSiteById: (id: any) => ReturnType = (id: any) => {
    const { data, loading, error, refetch } = useQuery(QUERY, { variables: { id } });
    let item: any = null;
    if (!loading && !error) {
        item = data.workSite;
    }
    return {
        item,
        refetch,
        loading,
        error,
    };
};
export default useWorkSiteById;
