import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { notification, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import ForgetPasswordForm from './ForgetPasswordForm';

import '../Login.scss';
import logo from 'shared/images/logo.svg';
import { Trans, useTranslation } from 'react-i18next';

import { AuthConsumer } from 'shared/contexts/AuthContext';
// @ts-ignore
import { Helmet } from 'react-helmet';
import API_SERVICE from 'shared/services/api-service';

const { Text } = Typography;

interface Props {
    location: any;
}

export default function ForgetPassword({ location }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [fields, setFields] = useState([] as any[]);
    const [forgetPasswordError, setForgetPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const { from } = location.state || { from: { pathname: '/' } };

    return (
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t('genDict.page-title', { title: t('pageTitles.ForgetPassword') })}</title>
            </Helmet>
            <AuthConsumer>
                {({ isAuth, updateAuthToken }) =>
                    isAuth ? (
                        <Redirect to={from}/>
                    ) : (
                        <div className="login">
                            <div className="__header"/>
                            <div className="__content">
                                <div className={'form-outer-wrapper'}>
                                    <img src={logo} alt="logo"/>

                                    {emailSent ? <>
                                        <h1 className={'__login-title __forget-password'}>{t('forgetPassword.afterEmailTitle')}</h1>
                                        <div className={'__forget-password-sub-title'}>
                                            {t('forgetPassword.afterEmailSubTitle1')}
                                        </div>

                                        <div className={'__forget-password-sub-title'}>
                                            <Trans i18nKey={'forgetPassword.afterEmailSubTitle2'}
                                                components={{
                                                    supportMail: <a className="__forget-password-sub-title contact-link" href={'mailto:support@nevoo.app'}></a>,
                                                }}
                                            />
                                        </div>
										
										
                                        <div className={'flat-button primary'} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            history.push('/login');
                                        }
                                        }>
                                            {t('forgetPassword.goToLogin')}
                                        </div>
                                    </> : <>

                                        <h1 className={'__login-title __forget-password'}>{t('forgetPassword.title')}</h1>
                                        <div className={'__forget-password-sub-title'}>
                                            {t('forgetPassword.subTitle')}
                                        </div>
                                        <ForgetPasswordForm
                                            onChange={setFields}
                                            fields={fields}
                                            onForgetPassword={async (email: string) => {
                                                setLoading(true);
                                                setForgetPasswordError('');
                                                try {
                                                    const { data } = await API_SERVICE.forgetPassword(email);
                                                    setEmailSent(true);
                                                    notification.success({
                                                        message: t('forgetPassword.notification.successMessage'),
                                                    });
                                                } catch (e) {
                                                    setForgetPasswordError(t(API_SERVICE.handleErrors(e)));
                                                }
                                                setLoading(false);
                                            }}
                                            loading={loading}
                                        />
                                    </>
                                    }
                                    {
                                        forgetPasswordError && <Text type="danger">{forgetPasswordError}</Text>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </AuthConsumer>
        </>
    );
}
