import React, { useState } from 'react';
import { Redirect, Link, useHistory } from 'react-router-dom';
import { notification, Button, Typography } from 'antd';
import Parameters from 'shared/parameters';

import ResetPasswordForm from './ResetPasswordForm';

import '../Login.scss';
import logo from 'shared/images/logo.svg';
import { useTranslation } from 'react-i18next';

import { AuthConsumer } from 'shared/contexts/AuthContext';
// @ts-ignore
import { Helmet } from 'react-helmet';
import API_SERVICE from 'shared/services/api-service';

const { Text } = Typography;

interface Props {
    location: any;
}

export default function ResetPassword({ location }: Props) {
    const { t } = useTranslation();
    const [fields, setFields] = useState([] as any[]);
    const [forgetPasswordError, setForgetPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetDone, setResetDoone] = useState(false);
    const history = useHistory();

    const { from } = location.state || { from: { pathname: '/' } };

    return (
        <>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>{t('genDict.page-title', { title: t('pageTitles.ResetPassword') })}</title>
            </Helmet>
            <AuthConsumer>
                {({ isAuth, updateAuthToken }) =>
                    isAuth ? (
                        <Redirect to={from}/>
                    ) : (
                        <div className="login">
                            <div className="__header"/>
                            <div className="__content">
                                <div className={'form-outer-wrapper'}>
                                    <img src={logo} alt="logo"/>
                                    {
                                        resetDone ? <>
                                                <h1
                                                    className={'__login-title __forget-password'}>{t('resetPassword.success.title')}</h1>
                                                <div className={'__forget-password-sub-title'}>
                                                    {t('resetPassword.success.subTitle')}
                                                </div>
                                                <Button
                                                    type="primary"
                                                    shape="round"
                                                    onClick={() => {
                                                        history.push('/login');
                                                    }}
                                                    size={'large'}
                                                >
                                                    {t('resetPassword.success.button.loginPage')}
                                                </Button>
                                            </>
                                            : <><h1
                                                className={'__login-title __forget-password'}>{t('resetPassword.title')}</h1>
                                                <div className={'__forget-password-sub-title'}>
                                                    {t('resetPassword.subTitle')}
                                                </div>

                                                <ResetPasswordForm
                                                    onChange={setFields}
                                                    fields={fields}
                                                    onResetPassword={async (email: string, plainPassword: string, validationCode: string) => {
                                                        setLoading(true);
                                                        setForgetPasswordError('');
                                                        try {
                                                            const { data } = await API_SERVICE.resetPassword(email, plainPassword, validationCode);
                                                            notification.success({
                                                                message: t('resetPassword.notification.successMessage'),
                                                            });
                                                            setResetDoone(true);
                                                        } catch (e) {
                                                            setForgetPasswordError(t(API_SERVICE.handleErrors(e)));
                                                        }
                                                        setLoading(false);
                                                    }}
                                                    loading={loading}
                                                /></>
                                    }

                                    {
                                        forgetPasswordError && <Text type="danger">{forgetPasswordError}</Text>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </AuthConsumer>
        </>
    );
}
