import * as React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import {
    Card,
    Modal,
    notification,
    Form,
    Row,
    Col,
    Space,
    Button as BaseButton,
    Input,
    InputNumber,
    Typography,
    Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';

import {
    EditFilled, PhoneFilled, MailFilled,
    InfoCircleFilled,
} from '@ant-design/icons';
import { Route_ClientsView } from '../../../constants/Routes.constants';
import { dotToObject, generateFullName, getPhoneText, useLayoutMedia } from '../../../services/Utility';
import { useState } from 'react';
import { createNewCategory, updateCategory } from '../../../services/articleService';
import ListView from '../../../components/layout/ListView';
import useWorkSiteFees from '../../../hooks/work-sites/useWorkSiteFees';
import { DIGITS_TO_SHOW_AFTER_DOT,
         MAX_INPUT_NUMBER, 
		 MIN_INPUT_NUMBER, 
         STEP_INCREASE } from '../../../constants/AppConstants';

const { Text } = Typography;

const Suppliers: React.FunctionComponent<any> = ({ workSite, refresh }) => {
    const { t } = useTranslation();
    const fields: any[] = [];
    const [showForm, setShowForm] = useState(false);
    const [saving, setSaving] = useState(false);
    const enableEditButton = !parseFloat(workSite.fees.accountingProportion) && !parseFloat(workSite.fees.cleaning) && !parseFloat(workSite.fees.delivery) && !parseFloat(workSite.fees.engineRenting) && !parseFloat(workSite.fees.evacuation) && !parseFloat(workSite.fees.movement) && !parseFloat(workSite.fees.other) &&
        !parseFloat(workSite.fees.parking) && !parseFloat(workSite.fees.supply);

    const _renderFeeComponent = () => {
        const result: any[] = [];
        Object.keys(workSite.fees).forEach((a: any) => {
            // @ts-ignore
            if (parseFloat(workSite.fees[a]) > 0) {
                result.push(<Col xl={12}  lg={24}  md={24} sm={24} xs={24} className={'item-row'}>
                    <div className="key">
                        {t(`workSite.fees.${a}`)}
                    </div>
                    <div className="value" style={{wordWrap: 'normal'}}>
                        {parseFloat(workSite.fees[a]).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    </div>
                </Col>);
            }
        });
        return result;
    };
    return <Card title={
        <div className="__header-text">
            {t('workSite.fees.headerText')}
        </div>
    } className={`__suppliers-wrapper __info-cards`} extra={<a onClick={() => {
        setShowForm(true);
    }
    }>
        <EditFilled/>
    </a>}>
        <Modal
            title={t('workSite.fees.headerText')}
            visible={showForm}
            maskClosable={false}
            width={800}
            onCancel={() => setShowForm(false)}
            afterClose={() => setShowForm(false)}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}
        >
            <Form
                size="large"
                //form={form}
                fields={fields}
                onFieldsChange={(changedFields, allFields) => {
                }}
                initialValues={workSite.fees}
                onFinish={async (formValues) => {
                    setSaving(true);
                    useWorkSiteFees({ input: formValues, id: workSite.id }).then((response) => {
                        notification.success({
                            message: t('genDict.success'),
                            description: t('workSite.fees.notification.feesUpdatedSuccess'),
                        });
                        refresh();
                        setShowForm(false);
                    }).catch(e => {
                        notification.error({
                            message: t(e),
                        });
                    }).finally(() => {
                        setSaving(false);
                    });

                }}
                layout="vertical"
            >
                <Row gutter={16} style={{ padding: '20px' }}>
                    <Col span={24}>
                        <Row gutter={16}>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.delivery.label')}
                                    name={'delivery'}
                                    key="delivery"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.delivery.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.delivery.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.supply.label')}
                                    name={'supply'}
                                    key="supply"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.supply.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.supply.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.evacuation.label')}
                                    name={'evacuation'}
                                    key="evacuation"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.evacuation.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.evacuation.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.cleaning.label')}
                                    name={'cleaning'}
                                    key="cleaning"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.cleaning.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.cleaning.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.engineRenting.label')}
                                    name={'engineRenting'}
                                    key="engineRenting"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.engineRenting.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.engineRenting.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.movement.label')}
                                    name={'movement'}
                                    key="movement"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.movement.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.movement.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.parking.label')}
                                    name={'parking'}
                                    key="parking"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.parking.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.parking.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.accountingProportion.label')}
                                    name={'accountingProportion'}
                                    key="accountingProportion"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.accountingProportion.required'),},
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.accountingProportion.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" sm={12} xs={24} md={12}>
                                <Form.Item
                                    label={t('workSite.fees.input.other.label')}
                                    name={'other'}
                                    key="other"
                                    rules={[
                                        { required: true, message: t('workSite.fees.input.other.required') },
                                        {
                                            pattern: /^[0-9]*?.[0-9]*$/,
                                            message: t('workSite.fees.input.other.wrongFormat'),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        step={STEP_INCREASE}
                                        min={MIN_INPUT_NUMBER}
										max={MAX_INPUT_NUMBER}
										precision={DIGITS_TO_SHOW_AFTER_DOT}
										formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Space direction={'horizontal'} className={'__form-footer'}>
                            <Form.Item>
                                <BaseButton
                                    type="default"
                                    onClick={() => {
                                        setShowForm(false);
                                    }}
                                    disabled={saving}
                                    shape="round"
                                    size={'large'}
                                    className="submit"
                                >
                                    {t('genDict.Cancel')}
                                </BaseButton>
                            </Form.Item>
                            <Form.Item>
                                <BaseButton
                                    type="primary"
                                    htmlType="submit"
                                    loading={saving}
                                    shape="round"
                                    size={'large'}
                                    className="submit"
                                >
                                    {t('workSite.fees.button.saveCategory')}
                                </BaseButton>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </Modal>
        <div className={`__fee-structure-wrapper  ${useLayoutMedia(['TabletPortrait', 'Mobile']) && '__static'}`}>
            {
                enableEditButton ? <div className={'edit-section'}>
                        <div className={'__inline-section'} style={{ padding: '10px' }}>
                            <div className="__inline-item">
                                <Text className={'primary-black'}>{t('workSite.fees.emptyMessage')}</Text>
                            </div>
                            &nbsp;&nbsp;
                            <div className="__inline-item">
                                <div className={'flat-button primary'} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowForm(true);
                                }}>
                                    {t('workSite.fees.button.addFeeMenu')}
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className={'__fees'}>
                        <Row gutter={16} className="__fees-row">
                            {
                                _renderFeeComponent()
                            }
                        </Row>
                    </div>
            }
        </div>
    </Card>;
};
export default Suppliers;
