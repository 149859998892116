import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import {
    createNewProfessionalCustomer, createNewProfessionalCustomerContact,
    deleteClientById, deleteContactById,
    editProfessionalCustomer, fetchClientById,
    fetchClients, updateProfessionalCustomerContact,
} from 'shared/hooks/useClients';
import { Button, Button as BaseButton, Col, Form, Input, Row, Select, Space, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Modal, notification } from 'antd';
import { Link, useHistory, withRouter } from 'react-router-dom';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import './index.scss';
import { useEffect, useState } from 'react';
import {
    dotToObject,
    generateFullName,
    getDefaultPageSize,
    getPhoneText,
    paginationParams,
} from 'shared/services/Utility';
import { ConstantTitles } from 'shared/constants/AppConstants';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { Customer } from '../../types/customer.type';
import { Route_WorkSitesView } from '../../constants/Routes.constants';

const { Text } = Typography;
type Props = {
    customer: Customer,
    onChange?: Function
}

const { confirm } = Modal;

function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const WorkSiteList: React.FunctionComponent<Props> = (p) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('client.workSite.listTitle.number'),
            render: (a: any) => (<Link to={Route_WorkSitesView(a)}
                className={'__theme-text __primary-blue'}>{a.reference}</Link>),
            sorter: (a: any, b: any) => sortAlpha(a.reference, b.reference),
        },
        {
            title: t('client.workSite.listTitle.name'),
            render: (a: any) => (<Link to={Route_WorkSitesView(a)}
                className={'__theme-text __primary-blue'}>{a.name}</Link>),
            sorter: (a: any, b: any) => sortAlpha(a.name, b.name),
        },
        {
            title: t('client.workSite.listTitle.date'),
            render: (a: any) => (<span>{a.date}</span>),
        },
        {
            title: t('client.workSite.listTitle.pinCode'),
            render: (a: any) => (<span>{a.address.postalCode}</span>),
        },
        {
            title: t('client.workSite.listTitle.city'),
            render: (a: any) => (<span>{a.address.city}</span>),
        },
    ];

    return (
        <ListView
            title={''}
			pageTitle={t('genDict.page-title', { title: t('pageTitles.viewClient') })}
            searchBar={
                (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h2 className={'__form-header-title'} style={{
                        width: '100%',
                        display: 'flex',
                        marginBottom: 0,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <div className={'__form-header-title'}>
                            {t('client.workSite.title')}</div>
                    </h2>
                </div>)
            }
            noP={true}>
            <div className="__table-pagination-padded">
                <Table
                    locale={{
                        emptyText: (
                            <>
                                <Space style={{ padding: '10px' }}>
                                    <Text className={'primary-black'}>{t('client.workSite.emptyTableMessage')}</Text>
                                </Space>
                            </>
                        ),
                    }}
					showSorterTooltip ={false}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (currentPage, pageSize) => {
                            paginationParams(pageSize);
                        },
                        defaultPageSize: getDefaultPageSize(),                    }}
                    // @ts-ignore
                    columns={columns}
                    dataSource={p.customer.workSites}>
                </Table>
            </div>
        </ListView>
    );
};
export default WorkSiteList;
