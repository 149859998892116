import * as React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { Card, Space, Tooltip } from 'antd';
import { DIGITS_TO_SHOW_AFTER_DOT } from '../../../constants/AppConstants';
// @ts-ignore
import DonutChart from 'react-d3-donut';
import { useLayoutMedia } from '../../../services/Utility';

const CHARTComponent = (props: any) => {
    const shouldReduceWidth = !useLayoutMedia(['Desktop']);

    const datad = [{
        count: parseFloat(props.data.supplies).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
        color: '#0033A0',
        name: props.t('workSite.infoGraphCard.pieChart.supplies'),
    }, {
        count: parseFloat(props.data.workForce).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
        color: '#DC3545',
        name: props.t('workSite.infoGraphCard.pieChart.workForce'),
    }, {
        count: parseFloat(props.data.margin).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
        color: '#0FD3A0',
        name: props.t('workSite.infoGraphCard.pieChart.margin'),
    }, {
        count: parseFloat(props.data.fees).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
        color: '#585D58',
        name: props.t('workSite.infoGraphCard.pieChart.fees'),
    }];
    return <DonutChart
        innerRadius={10}
        outerRadius={45}
        transition={true}
        svgClass="example6"
        pieClass="pie6"
        displayTooltip={true}
        strokeWidth={3}
        data={datad}/>;
};

const CHART = React.memo(CHARTComponent);
const InfoGraph: React.FunctionComponent<any> = ({ workSite }) => {
    const { t } = useTranslation();
    const refreshCount = () => {
        const ITEM = workSite;
        const c = {
            quantity: 0,
            supplies: 0,
            fees: 0,
            workForce: 0,
            costOfWork: 0, sellingPrice: 0, margin: 0, cofficient: 0,
        };
        if (ITEM && ITEM.worktypes) {
            ITEM.worktypes.forEach((w: any) => {
				let varSupplies = 0.0;
                let varWorkForce = 0.0;
                let varCostOfWork = 0.0;
                w.rows.forEach((a: any) => {
                    if (a.type === 'article') {
                        varSupplies += (a.price * a.quantity);
                    }
                    if (a.type === 'effort') {
                        varWorkForce += (a.price * a.quantity);
                    }
                    varCostOfWork += (a.price * a.quantity);
                });
                c.sellingPrice += (parseFloat(w.price) * parseFloat(w.quantity));
				c.costOfWork += (parseFloat(varCostOfWork + '') * parseFloat(w.quantity));
				c.supplies += (parseFloat(String(varSupplies)) * parseFloat(w.quantity));
				c.workForce += (parseFloat(String(varWorkForce)) * parseFloat(w.quantity));
            });
        }
        Object.keys(workSite.fees).forEach((a: any) => {
            if (parseFloat(workSite.fees[a]) > 0) {
                c.fees += parseFloat(workSite.fees[a]);
            }
        });
		c.costOfWork += c.fees;
        c.margin = c.sellingPrice - c.costOfWork;
        c.cofficient = c.sellingPrice / c.costOfWork;


        return c;
    };
    const calculations: any = refreshCount();
    return <Card title={
        <div className="__header-text">
            {t('workSite.infoGraphCard.headerText')}
        </div>
    } className={`__info-graph-wrapper __info-cards`}>
        <div className="__info-card graph">
            <div className="__body">
                <div className="__item-row">
                    <div className="__items">
                        <div className="__color primary">
                        </div>
                        <div className="__key">
                            {t('workSite.infoGraphCard.pieChart.supplies')}
                        </div>
                        <div className="__value">
                            {calculations.supplies ? parseFloat(calculations.supplies.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                        </div>
                    </div>
                    <div className="__items">
                        <div className="__color danger">
                        </div>
                        <div className="__key">
                            {t('workSite.infoGraphCard.pieChart.workForce')}
                        </div>
                        <div className="__value">
                            {calculations.workForce ? parseFloat(calculations.workForce.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}

                        </div>
                    </div>
                    <div className="__items">
                        <div className="__color default">
                        </div>
                        <div className="__key">
                            {t('workSite.infoGraphCard.pieChart.fees')}
                        </div>
                        <div className="__value">
                            {calculations.fees ? parseFloat(calculations.fees.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}

                        </div>
                    </div>
                    <div className="__items">
                        <div className="__color">
                        </div>
                        <div className="__key">
                            {t('workSite.infoGraphCard.pieChart.siteCost')}
                        </div>
                        <div className="__value">
                            {calculations.costOfWork ? parseFloat(calculations.costOfWork + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                        </div>
                    </div>
                    <div className="__items">
                        <div className="__color ">
                        </div>
                        <div className="__key">
                            <strong> {t('workSite.infoGraphCard.pieChart.sellingPrice')}</strong>
                        </div>
                        <div className="__value">
                            <strong>{calculations.sellingPrice ? parseFloat(calculations.sellingPrice.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}</strong>
                        </div>
                    </div>
                    <div className="__items">
                        <div className="__color secondary">
                        </div>
                        <div className="__key">
                            {t('workSite.infoGraphCard.pieChart.margin')}
                        </div>
                        <div className="__value">
                            {calculations.margin ? parseFloat(calculations.margin.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                        </div>
                    </div>
                    <div className="__items">
                        <div className="__color ">
                        </div>
                        <div className="__key">
                            {t('workSite.infoGraphCard.pieChart.coefficient')}
                        </div>
                        <div className="__value">
                            {calculations.cofficient ? parseFloat(calculations.cofficient.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                        </div>
                    </div>
                </div>
                <div className="__graph">
                    <CHART data={calculations} t={t}/>
                </div>
            </div>
        </div>
    </Card>;
};
export default InfoGraph;
