import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Space, Tooltip, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { Link } from 'react-router-dom';


import './index.scss';
import { Route_WorkSitesView } from 'shared/constants/Routes.constants';
import moment from 'moment';
import PDFDownloadButton from 'shared/components/Ant/Buttons/PDFDownloadButton';
import { getDefaultPageSize, paginationParams } from 'shared/services/Utility';

const { Text } = Typography;
type Props = {
    provider: any,
    onChange?: Function
}


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const PurchaseOrderList: React.FunctionComponent<Props> = (p) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('supplier.purchaseOrder.listTitle.number'),
            render: (a: any) => (<span
                className={'__theme-text __primary-blue'}>{a.title}</span>),
        },
        {
            title: t('supplier.purchaseOrder.listTitle.createdAt'),
            render: (a: any) => (<span>{moment(a.createdAt).format('DD/MM/YYYY')}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.createdAt, b.createdAt),
        },
        {
            title: t('supplier.purchaseOrder.listTitle.chainterNumber'),
            render: (a: any) => (<Link to={a.workSite ? Route_WorkSitesView(a.workSite) : '#'}
                                       className={'__theme-text __primary-blue'}>{a.workSite ? a.workSite.reference : 'NA'}</Link>),
        }, {
            fixed: 'right',
            title: t('supplier.purchaseOrder.listTitle.action'),
            render: (a: any) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('supplier.purchaseOrder.tooltip.download')}>
                            <div/>
                            <PDFDownloadButton onClick={() => {
                                window.open(a.url);
                            }}/>
                        </Tooltip>

                    </Space>
                </div>
            ),
            width: '100px',
        },
    ];

    return (
        <ListView
            title={''}
            searchBar={
                (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h2 className={'__form-header-title'} style={{
                        width: '100%',
                        display: 'flex',
                        marginBottom: 0,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <div className={'__form-header-title'}>
                            {t('supplier.purchaseOrder.title')}</div>
                    </h2>
                </div>)
            }
            noP={true}>
            <div className="__table-pagination-padded">
                <Table
                    locale={{
                        emptyText: (
                            <>
                                <Space style={{ padding: '10px' }}>
                                    <Text
                                        className={'primary-black'}>{t('supplier.purchaseOrder.emptyTableMessage')}</Text>
                                </Space>
                            </>
                        ),
                    }}
					showSorterTooltip ={false}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (currentPage, pageSize) => {
                            paginationParams(pageSize);
                        },
                        defaultPageSize: getDefaultPageSize(),                    }}
                    // @ts-ignore
                    columns={columns}
                    dataSource={p.provider.purchaseOrders}>
                </Table>
            </div>
        </ListView>
    );
};
export default PurchaseOrderList;
