import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { UserCompany } from '../../types/user.type';

type ReturnType = {
    admin_userCompanies: UserCompany[];
    loading: boolean;
    error: any;
    refetch: any
};
const GET_USER_COMPANIES = gql`
    {
  admin_userCompanies{
    id
    createdAt
    name
    users{
      id
      firstName
      lastName
      username
      email
      reference
      phoneNumber
      enabled
      roles
      isSuperAdmin
      emailChangementPending
      
      stopShowPersonalize
    }
    address{
      address
      additional
      postalCode
      city
    }
    billingAddress{
      address
      additional
      postalCode
      city
      firstName
      lastName
      company
    }
    bankName
    accountName
    IBAN
    BIC
    displayBankInformationOnQuote
    hideLogo
    pictureBackgroundUrl
    pictureUrl
    footer
    subscription{
      type
      status
      nextPaymentAt
      autoRenewalAt
      price
    }
  }
} 
`;

const useCompanyUsers: () => ReturnType = () => {
    const { data, loading, error, refetch } = useQuery(GET_USER_COMPANIES);
    let admin_userCompanies = [];
    if (!loading && !error) {
        admin_userCompanies = data.admin_userCompanies.map((aC: any) => {
            return { ...aC, primaryUser: aC.users[0] };
        }).filter((a: any) => !!a.primaryUser);
    }
    return {
        admin_userCompanies,
        refetch,
        loading,
        error,
    };
};
export default useCompanyUsers;
