import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Table, Row, Col, Skeleton, Button, InputNumber, Modal, notification, Tooltip, Typography, Space } from 'antd';
import {
    FilePdfOutlined,
    ExclamationCircleOutlined,
    MenuOutlined,
} from '@ant-design/icons';

import EditButton from 'shared/components/Ant/Buttons/EditButton';
import './index.scss';
// @ts-ignore
import { useState } from 'react';
import useDebounce from '../../../../hooks/useDebounce';
import { useEffect } from 'react';
import { updateWorkSiteWorkTypeRow } from '../../../../services/workService';
import useUpdateWorkSiteRow from '../../../../hooks/work-sites/useUpdateWorkSiteRow';
import DeleteButton from '../../../../components/Ant/Buttons/DeleteButton';
import useDeleteWorkTypeByIdFromWorkSite from '../../../../hooks/work-sites/useDeleteWorkTypeFromWorkSite';
import ChartButton from '../../../../components/Ant/Buttons/ChartButton';
import {
    DIGITS_TO_SHOW_AFTER_DOT,
    THREE_DIGITS_TO_SHOW_AFTER_DOT,
    MAX_INPUT_NUMBER,
    MIN_INPUT_NUMBER,
    STEP_INCREASE, DEBOUNCE_IN_MILISECONDS,
} from '../../../../constants/AppConstants';

// @ts-ignore
import DonutChart from 'react-d3-donut';
import CloseButton from '../../../../components/Ant/Buttons/CloseButton';
// @ts-ignore
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import useMoveWorkTypeRowInWorkSite from '../../../../hooks/work-sites/useMoveWorkTypeRowInWorkSite';
import InputWithDebounce from './InputWithDebounce';

const { confirm } = Modal;

const SortableItem = sortableElement((props: any) => <tr {...props} />);
const SortableContainer = sortableContainer((props: any) => <tbody {...props} />);

const { Text } = Typography;
const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }}/>);

type Props = {
    workSite: any,
    callAddWorkType: any,
    setUpdating: any,
    callPurchaseOrder: any,
    callQuote: any,
    refresh: any,
}


const CHARTComponent = (props: any) => {
    const datad = [{
        count: parseFloat(props.data.supplies).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
        color: '#0033A0',
        name: props.t('workType.pieChart.supplies'),
    }, {
        count: parseFloat(props.data.workForce).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
        color: '#DC3545',
        name: props.t('workType.pieChart.workForce'),
    }, {
        count: parseFloat(props.data.margin).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
        color: '#0FD3A0',
        name: props.t('workType.pieChart.margin'),
    }];
    return <DonutChart
        innerRadius={20}
        outerRadius={65}
        transition={true}
        svgClass="example7"
        pieClass="pie7"
        displayTooltip={true}
        strokeWidth={3}
        data={datad}/>;
};

const CHART = React.memo(CHARTComponent);

const WorkTypesList: React.FunctionComponent<Props> = ({
                                                           callQuote,
                                                           callPurchaseOrder,
                                                           workSite,
                                                           callAddWorkType,
                                                           refresh,
                                                           setUpdating,
                                                       }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false as any);
    const [workSiteP, setWorkSiteP] = useState({
        ...workSite, worktypes: workSite.worktypes.map((a: any) => {
            return { ...a, index: parseInt(a.id) };
        }),
    });
    const [changed, setChanged] = useState(true);
    const [activeIndex, setActiveIndes] = useState(-1);
    const [movingItem, setMovingItem] = useState(false);
    const [selectedWorkType, setSelectedWorkType] = useState(null as any);
    const [deletingItem, setDeletingItem] = useState(false);
    const [changedRowIndex, setChangedRowIndex] = useState(null as any);
    const [changedSubRowIndex, setChangedSubRowIndex] = useState(null as any);
    const debouncedRows = useDebounce(changedRowIndex, DEBOUNCE_IN_MILISECONDS);
    const debouncedSubRows = useDebounce(changedSubRowIndex, DEBOUNCE_IN_MILISECONDS);
    useEffect(
        () => {
            if (changedRowIndex && (!isNaN(parseFloat(changedRowIndex.quantity))) && (!isNaN(parseFloat(changedRowIndex.price)))) {
                setUpdating(true);
                setLoading(true);
                setChanged(true);
                setChangedRowIndex(null);
                useUpdateWorkSiteRow({
                    id: workSiteP.id,
                    rowId: changedRowIndex.id,
                    input: { quantity: changedRowIndex.quantity, price: changedRowIndex.price },
                }).then(() => {
                    refresh();
                }).catch((e: any) => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setUpdating(false);
                    setLoading(false);
                });
            }
        },
        [debouncedRows],
    );
    useEffect(
        () => {

            if (changedSubRowIndex && (!isNaN(parseFloat(changedSubRowIndex.quantity))) && (!isNaN(parseFloat(changedSubRowIndex.price)))) {
                setUpdating(true);
                setChangedSubRowIndex(null);
                setChanged(true);

                setLoading(true);

                updateWorkSiteWorkTypeRow({
                    id: workSite.id,
                    rowId: changedSubRowIndex.id,
                    input: { price: changedSubRowIndex.price },
                }).then(() => {
                    refresh();
                }).catch((e: any) => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setUpdating(false);
                    setLoading(false);
                });
            }
        },
        [debouncedSubRows],
    );
    useEffect(
        () => {
            setWorkSiteP({
                ...workSite, worktypes: workSite.worktypes.map((a: any) => {
                    return { ...a, index: parseInt(a.id) };
                }),
            });
        },
        [workSite],
    );
    // @ts-ignore
    const columnsArticles = [
        {
            title: t('workSite.workTypes.listTitle.reference'),
            render: (a: any) => (
                <div>
                    <span
                        className={'__theme-text light'}>{a.reference}</span>
                </div>),
        }, {
            title: t('workSite.workTypes.listTitle.insideName'),
            render: (a: any) => (
                <div>
                    <span className={'__theme-text light'}
                    >{a.name}</span>
                </div>),
        },
        {
            title: <div className={'text-right'}>{t('workSite.workTypes.listTitle.quantity')}</div>,
            render: (a: any) => (
                <div className={'text-right'}>
                    <span className={'__theme-text light'}>{a.quantity}</span>
                </div>),
        },
        {
            title: <div className={'text-right'}>{t('workSite.workTypes.listTitle.insidePrice')}</div>,
            render: (a: any, values: any, index: number) => (
                <div className={'text-right'}>
                    <InputWithDebounce loading={loading} fromEffort={a.type} value={a.price} onChange={(e: any) => {
                        const newItem = JSON.parse(JSON.stringify(workSiteP));
                        if (!isNaN(e)) {
                            setLoading(true);
                            newItem.worktypes[activeIndex].rows[index].price = e;
                            setWorkSiteP(newItem);
                            setChangedSubRowIndex(JSON.parse(JSON.stringify({
                                ...newItem.worktypes[activeIndex].rows[index],
                                workTypeId: newItem.worktypes[activeIndex].id,
                            })));
                        }
                    }}/>
                </div>),
        },
        {
            title: <div className={'text-right'}>{t('workSite.workTypes.listTitle.total')}</div>,
            render: (a: any) => (
                <div className={'text-right'}>
                    <span
                        className={'__theme-text light'}>{(parseFloat(a.quantity) * parseFloat(a.price)) ? parseFloat((parseFloat(a.quantity) * parseFloat(a.price)).toString()).toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}</span>
                </div>),
        },
    ];

    let timeoutUpdate: any = null;
    const columns = [
        {
            title: <div className={'text-center'}>{t('genDict.sort')}</div>,
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <div className={'text-center'}><DragHandle/></div>,
        },
        {
            title: t('workSite.workTypes.listTitle.name'),
            render: (a: any, v: any, i: number) => {
                return {
                    children: (
                        <div>
                            <a
                                onClick={() => {
                                    setActiveIndes(i === activeIndex ? -1 : i);
                                }}
                                className={'__theme-text __medium'}>{a.name}</a>
                            {
                                activeIndex === i && <div className="__sub-article-list-section">
                                    <Table
                                        columns={columnsArticles}
                                        dataSource={workSiteP.worktypes[activeIndex].rows}
                                        pagination={false}
                                        bordered
                                        summary={pageData => {

                                            let sum = 0;
                                            pageData.forEach(({ quantity, price }) => {
                                                sum += parseFloat(quantity) * parseFloat(price);
                                            });

                                            return (
                                                <>
                                                    <Table.Summary.Row style={{ backgroundColor: '#F5F5F5' }}
                                                                       className={'__td-background-light'}>
                                                        <Table.Summary.Cell
                                                            className={'__theme-text __medium light text-right'}
                                                            colSpan={4}
                                                            index={0}>{t('workSite.workTypes.listTitle.costPrice')}
                                                        </Table.Summary.Cell>
                                                        <Table.Summary.Cell index={0} className={'text-right'}>
                                                            <Text
                                                                className={'__theme-text light text-right'}>{sum ? parseFloat(sum.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}</Text>
                                                        </Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                </>
                                            );
                                        }}
                                    />
                                </div>
                            }
                        </div>),
                };
            },
            /*sorter: (a: any, b: any) => sortAlpha(a.name, b.name),*/
        },
        {
            title: <div className={'text-right'}
                        style={{ paddingRight: '2px' }}> {t('workSite.workTypes.listTitle.quantity')}</div>,
            className: '__vertical-initial',
            render: (a: any, values: any, index: number) => {
                return {

                    children: (
                        <div className={'__vertical-initial text-right'}>
                            <InputWithDebounce loading={loading} fromEffort={a.type} value={a.quantity} onChange={(e: any) => {
                                setLoading(true);
                                const newItem = JSON.parse(JSON.stringify(workSiteP));
                                newItem.worktypes[index].quantity = e;
                                setWorkSiteP(JSON.parse(JSON.stringify(newItem)));
                                setChangedRowIndex(JSON.parse(JSON.stringify(newItem.worktypes[index])));
                            }}/>
                        </div>),
                };
            },
        },
        {
            className: ' __vertical-initial',
            title: <div className={'text-right'}
                        style={{ paddingRight: '2px' }}>{t('workSite.workTypes.listTitle.price')}</div>,
            render: (a: any, values: any, index: number) => {
                return {

                    children: (
                        <div className={'text-right'}>
                            <InputWithDebounce loading={loading} fromEffort={a.type} value={a.price} onChange={(e: any) => {
                                setLoading(true);
                                const newItem = JSON.parse(JSON.stringify(workSiteP));
                                newItem.worktypes[index].price = e;
                                setWorkSiteP(newItem);
                                setChangedRowIndex(JSON.parse(JSON.stringify(newItem.worktypes[index])));
                            }}/>
                        </div>),
                };
            },
        },
        {
            className: '__vertical-initial',

            title: <div className={'text-right'}
                        style={{ paddingRight: '2px' }}> {t('workSite.workTypes.listTitle.total')}</div>,
            render: (a: any) => {
                return {

                    children: (
                        <div>
                            <div
                                className={'text-right'}>{parseFloat((parseFloat(a.price) * parseFloat(a.quantity)) + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>
                        </div>),
                };
            },
        },
        {
            className: '__vertical-initial',

            fixed: 'right',
            title: t('workSite.workTypes.listTitle.action'),
            render: (a: any, v: any, i: number) => {
                return {
                    props: {},
                    children: (
                        <div>
                            {a.type === 'total' ? <></> :
                                <div className="__actionButtons" style={{ width: '120px' }}>
                                    <Space size={10}>
                                        {
                                            activeIndex === i ?
                                                <CloseButton tooltipText={t('workSite.workTypes.tooltip.closeRow')}
                                                             className={'__button-active'} onClick={() => {
                                                    setActiveIndes(-1);
                                                }}/> :
                                                <EditButton tooltipText={t('workSite.workTypes.tooltip.editRow')}
                                                            onClick={() => {
                                                                setActiveIndes(i);
                                                            }}/>
                                        }
                                        <Tooltip placement={'top'} title={t('workSite.workTypes.tooltip.showChart')}>
                                            <div/>
                                            <ChartButton
                                                onClick={() => {
                                                    setSelectedWorkType(a);
                                                    refreshCount(a);
                                                }}/>
                                        </Tooltip>
                                        <Tooltip placement={'top'} title={t('workSite.workTypes.tooltip.deleteRow')}>
                                            <div/>
                                            <DeleteButton
                                                onClick={() => {
                                                    confirm({
                                                        icon: <ExclamationCircleOutlined/>,
                                                        title: t('workSite.workTypes.confirmation.msg'),
                                                        okText: t('workSite.workTypes.button.delete'),
                                                        cancelText: t('genDict.Cancel'),
                                                        content: t('workSite.workTypes.confirmation.delete'),// TODO
                                                        okButtonProps: { loading: deletingItem, danger: true },
                                                        onOk: async () => {
                                                            setDeletingItem(true);
                                                            useDeleteWorkTypeByIdFromWorkSite(workSiteP.id, a.id).then(() => {
                                                                notification.success({
                                                                    message: t('genDict.success'),
                                                                    description: t('workSite.workTypes.notification.deletedSuccess'),
                                                                });
                                                                refresh();
                                                            }).catch(e => {
                                                                notification.error({
                                                                    message: t(e),
                                                                });
                                                            }).finally(() => {
                                                                setDeletingItem(false);
                                                            });
                                                        },
                                                    });
                                                }}/>
                                        </Tooltip>


                                    </Space>
                                </div>}
                        </div>
                    ),
                };
            },
            width: '120px',
        },
    ];

    const [calculations, setCalculations] = useState({
        supplies: 0,
        workForce: 0,
        costOfWork: 0, sellingPrice: 0, margin: 0, cofficient: 0,
    });
    const refreshCount = (a = null) => {
        const ITEM = a || selectedWorkType;
        if (!ITEM) {
            return;
        }
        const c = {
            supplies: 0,
            workForce: 0,
            costOfWork: 0, sellingPrice: parseFloat(ITEM.price) * ITEM.quantity, margin: 0, cofficient: 0,
        };
        if (ITEM && ITEM.rows) {
            ITEM.rows.forEach((a: any) => {
                if (a.type === 'article') {
                    c.supplies += (a.price * a.quantity) * ITEM.quantity;
                }
                if (a.type === 'effort') {
                    c.workForce += (a.price * a.quantity) * ITEM.quantity;
                }
                c.costOfWork += (a.price * a.quantity) * ITEM.quantity;
            });
        }
        c.margin = c.sellingPrice - c.costOfWork;
        c.cofficient = c.sellingPrice / c.costOfWork;
        setCalculations(c);
    };
    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        // @ts-ignore
        setMovingItem(true);
        useMoveWorkTypeRowInWorkSite({ new: newIndex, old: oldIndex, id: workSiteP.id }).then(() => {
            refresh();
        }).catch(e => {
            notification.error({
                message: t(e),
            });
        }).finally(() => {
            setMovingItem(false);
        });

    };

    const DraggableContainer = (props: any) => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        const dataSource = workSiteP.worktypes;
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex((x: any) => x.index === restProps['data-row-key']);
        return movingItem ? <div>
            <Skeleton.Button active={true} size={'large'}/>
        </div> : <SortableItem className={className.indexOf('background-white') > -1 ? 'background-white' : ''}
                               index={index} {...restProps} />;
    };

    // @ts-ignore
    return (
        <>
            <Modal
                title={t('workSite.workTypes.chartTitle')}
                visible={!!selectedWorkType}
                width={500}
                maskClosable={false}
                afterClose={() => {
                    setSelectedWorkType(null);
                }}
                onCancel={() => {
                    setSelectedWorkType(null);
                }}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <div className="__g-body">
                    <div className="__item-row">
                        <div className="__items">
                            <div className="__color primary">
                            </div>
                            <div className="__key">
                                {t('workType.pieChart.supplies')}
                            </div>
                            <div className="__value">
                                {calculations.supplies ? parseFloat(calculations.supplies.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                            </div>
                        </div>
                        <div className="__items">
                            <div className="__color danger">
                            </div>
                            <div className="__key">
                                {t('workType.pieChart.workForce')}
                            </div>
                            <div className="__value">
                                {calculations.workForce ? parseFloat(calculations.workForce.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}

                            </div>
                        </div>
                        <div className="__items">
                            <div className="__color">
                            </div>
                            <div className="__key">
                                {t('workType.pieChart.costOfWork')}
                            </div>
                            <div className="__value">
                                {calculations.costOfWork ? parseFloat(calculations.costOfWork + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                            </div>
                        </div>
                        <div className="__items">
                            <div className="__color ">
                            </div>
                            <div className="__key">
                                <strong> {t('workType.pieChart.sellingPrice')}</strong>
                            </div>
                            <div className="__value">
                                <strong>{calculations.sellingPrice ? parseFloat(calculations.sellingPrice.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}</strong>
                            </div>
                        </div>
                        <div className="__items">
                            <div className="__color secondary">
                            </div>
                            <div className="__key">
                                {t('workType.pieChart.margin')}
                            </div>
                            <div className="__value">
                                {calculations.margin ? parseFloat(calculations.margin.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                            </div>
                        </div>
                        <div className="__items">
                            <div className="__color ">
                            </div>
                            <div className="__key">
                                {t('workType.pieChart.coefficient')}
                            </div>
                            <div className="__value">
                                {calculations.cofficient ? parseFloat(calculations.cofficient.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                            </div>
                        </div>
                    </div>
                    <div className="__graph">
                        <CHART data={calculations} t={t}/>
                    </div>
                </div>
            </Modal>
            <Table
                className={`__table-pagination-padded`}
                locale={{
                    emptyText: (
                        <Space style={{ padding: '10px' }}>
                            <Text className={'primary-black'}>
                                {/*@ts-ignore*/}
                                {t('workSite.workTypes.emptyTableMessage')}
                            </Text>
                            <div className={'flat-button primary'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                callAddWorkType();
                            }}>
                                {t('workSite.addWorkType')}
                            </div>
                        </Space>
                    ),
                }}
                rowClassName={(record: any, index: any) => {
                    return index === activeIndex ? 'background-white' : '';
                }}
                summary={pageData => {

                    let sum = 0;
                    pageData.forEach(({ quantity, price }) => {
                        sum += parseFloat(quantity) * parseFloat(price);
                    });

                    return (
                        <>
                            <Table.Summary.Row className={'top-border'}>
                                <Table.Summary.Cell className={'__theme-text __bold __disabled_11px text-right'}
                                                    colSpan={4}
                                                    index={0}>
                                    {/*@ts-ignore*/}
                                    {t('workSite.workTypes.listTitle.price')}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={0} className={'text-right'}>
                                    <Text
                                        className={'__theme-text light text-right'}>{parseFloat(sum + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
                bordered
				showSorterTooltip ={false}
                pagination={{
                    // Set this value in pagiation to remove pagination
                    hideOnSinglePage: true,
                    //showSizeChanger: true,
                    pageSize: 10000,
                }}
                rowKey="index"
                // @ts-ignore
                columns={columns}
                dataSource={workSiteP.worktypes}
                components={{
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },

                }}
            >
            </Table>
            <Row>
                <Col span={24}>
                    <Row gutter={20} align={'middle'} className={'__form-footer-parent-row'}>
                        <Col flex={'auto'}>
                            <Button
                                type="primary"
                                onClick={async () => {
                                    callAddWorkType();
                                }}
                                disabled={false}
                                shape="round"
                                size={'middle'}
                                className="submit"
                            >
                                {t('workSite.workTypes.button.addWorkType')}
                            </Button>
                        </Col>
                        <Col className={'__form-footer-row'}>
                            <Row gutter={20}>
                                <Col>
                                    <Button
                                        type="primary"
                                        onClick={async () => {
                                            callPurchaseOrder();
                                        }}
                                        disabled={false}
                                        shape="round"
                                        size={'middle'}
                                        className="__button-inverted primary"
                                    >
                                        <FilePdfOutlined
                                        /> {t('workSite.workTypes.button.purchaseOrder')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="default"
                                        loading={false}
                                        onClick={() => {
                                            callQuote();
                                        }}
                                        shape="round"
                                        size={'middle'}
                                        className="submit">
                                        <FilePdfOutlined
                                        /> {t('workSite.workTypes.button.quote')}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};


export default WorkTypesList;
