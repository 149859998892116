import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Select, Space, Typography, Upload } from 'antd';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Form, Modal, Row, Input, Col, notification } from 'antd';

// @ts-ignore
import moment from 'moment';
import './index.scss';
import { useState } from 'react';
import { filteredList, getDefaultPageSize, paginationParams } from 'shared/services/Utility';
import { Button as BaseButton } from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import PDFDownloadButton from 'shared/components/Ant/Buttons/PDFDownloadButton';
import { UserCompany } from '../../types/user.type';
import useCompanyUserBills from '../../hooks/users/useCompanyUserBills';
import AddFilledButton from '../../components/Ant/Buttons/AddFilledButton';
import { createNewCategory, updateCategory } from '../../services/articleService';
import { addDocument, deleteDocument, deleteUserFromAdmin } from '../../services/userService';
import DeleteButton from '../../components/Ant/Buttons/DeleteButton';
import { deleteClientById } from '../../hooks/useClients';
import { REGEX_STRING_VERIFY } from '../../constants/AppConstants';
import ListSearchBar from '../../components/Ant/ListSearchBar/ListSearchBar';
import Parameters from '../../parameters';
import { getAuthToken } from '../../contexts/AuthContext';

const { Text } = Typography;
type Props = {
    userCompany: UserCompany
}

const { confirm } = Modal;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const UserBills: React.FunctionComponent<Props> = (p) => {
    const { t } = useTranslation();
    const [searchKey, setSearchKey] = React.useState('');
    const [form] = Form.useForm();
    const [fields, setFields] = useState([] as any[]);
    const [filterByYear, setFilterByYear] = useState('');
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [initialValues, setInitialValues] = useState({ billId: '', document: null, reference: '' } as any);
    const { admin_billsByCompany: bills, refetch: refreshBills } = useCompanyUserBills(p.userCompany.id);
    const [showImageUploader, setShowImageUploader] = useState(true);

    const columns = [
        {
            title: <span style={{ paddingLeft: '8px' }}>
                {t('userBills.listTitle.stripeInvoiceId')}
            </span>,
            render: (a: any) => (<span style={{ paddingLeft: '8px' }}>{a.reference}</span>),

            sorter: (a: any, b: any) => sortAlpha(a.reference, b.reference),
        },
        {
            title: t('userBills.listTitle.endPeriod'),
            render: (a: any) => (<span>{moment(a.endPeriod).format('MMMM yyyy')}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.endPeriod, b.endPeriod),
        },
        {
            title: t('userBills.listTitle.paymentDate'),
            render: (a: any) => (<span>{moment(a.paymentDate).format('DD/MM/yyyy')}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.paymentDate, b.paymentDate),
        },
        {
            title: t('userBills.listTitle.amount'),
            dataIndex: 'amount',
            sorter: (a: any, b: any) => sortAlpha(a.amount, b.amount),
        },
        {
            fixed: 'right',
            title: t('userBills.listTitle.actions'),
            render: (a: any) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        {
                            a.document && <>
                                <Tooltip placement={'top'} title={t('userBills.tooltip.downloadInvoice')}>
                                    <div/>
                                    <PDFDownloadButton onClick={() => {
                                        window.open(a.document.url);
                                    }}/>
                                </Tooltip>
                            </>
                        }
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];


    return (
        <ListView
            title={''}
            noP={true}
            pageTitle={t('genDict.page-title', { title: t('pageTitles.viewUser') })}
            cardTitleStyle={{
                padding: 0,
            }}
            searchBar={
                (<>
                    <div className={'__sub-header-with-add-button'} style={{ padding: '10px 20px' }}>
                        <h2 className="__form-header-title" style={{
                            width: '100%',
                            display: 'flex',
                            marginTop: 0,
                            marginBottom: 0,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>

                               <span style={{ marginTop: '5px' }}>
                                    {t('userBills.title')}
                               </span>
                        </h2>
                    </div>
                    <Space direction={'horizontal'} className={'filter-wrapper'}
                           style={{ justifyContent: 'space-between', display: 'flex', padding: '10px 20px' }} size={20}>
                        <div className="filter-item">
                            <Select style={{ width: '180px' }} value={filterByYear} onChange={(e) => {
                                setFilterByYear(e);
                            }}>
                                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                                <Select.Option value={'2021'}>2021</Select.Option>
                            </Select>
                        </div>
                    </Space>
                </>)
            }
        >
            <Table
                locale={{
                    emptyText: (
                        searchKey ? <>
                                <Space style={{ padding: '10px' }}>
                                    <Text
                                        className={'primary-black'}>{t('userBills.table.noSearchResultFound')}</Text>
                                </Space>
                            </>
                            : <>
                                <Space style={{ padding: '10px' }}>
                                    <Text className={'primary-black'}>{t('userBills.table.emptyMessage')}</Text>
                                </Space>
                            </>
                    ),
                }}
				showSorterTooltip ={false}
                pagination={{
                    showSizeChanger: true,
                    onShowSizeChange: (currentPage, pageSize) => {
                        paginationParams(pageSize);
                    },
                    defaultPageSize: getDefaultPageSize(),
                }}
                // @ts-ignore
                columns={columns}
                dataSource={filteredList(filterByYear, bills)}
            >
            </Table>
        </ListView>
    );
};
export default UserBills;
