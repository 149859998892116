// noinspection RequiredAttributes

import React, { useEffect, useState } from 'react';


import {
    Form,
    Row,
    Col,
    Skeleton, Card, notification, Modal, Button, Input, Select, Alert,
} from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import Title from 'antd/es/typography/Title';
import {
    cancelUserSubscription, reactivateUserSubscription,
    updateCurrentCompany,
    updatePaymentInfo, upgradeSubscription,
    useMySubscription,
} from 'shared/services/userService';
import { ExclamationCircleOutlined, CreditCardFilled } from '@ant-design/icons';
import API_SERVICE from 'shared/services/api-service';
import usePaymentMethods from 'shared/hooks/users/usePaymentMethods';
import { pad } from 'shared/services/Utility';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import PaymentForm from 'shared/views/auth/SignUp/PaymentForm';

import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import Parameters from 'shared/parameters';
import moment from 'moment';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { REGEX_STRING_VERIFY } from 'shared/constants/AppConstants';

const stripePromise = loadStripe(Parameters.StripePublicToken);

const { confirm } = Modal;

interface Props {
    item?: any;
    onChange?: (fields: any[]) => void;
    setActiveTab?: (tab: string) => void;
    onCancel?: (refresh?: boolean) => void;
    loading?: boolean;
    mode: 'CREATE' | 'EDIT' | 'VIEW';
}

export default function MySubscription({
                                           onCancel,
                                       }: Props) {
    const { t } = useTranslation();
    const [cancelingSubscription, setCancelingSubscription] = useState(false);
    const [upgradingSubscription, setUpgradingSubscription] = useState(false);
    const [yearlySubscription, setYearlySubscription] = useState(null as any);
    const [allSubscriptions, setAllSubscriptions] = useState([] as any);
    const [processing, setProcessing] = useState(false);
    const [offer, setOffer] = useState(null as any);
    const [cardComplete, setCardComplete] = useState(false);
    const [error, setError] = useState('' as any);
    const [cardNumberError, setCardNumberError] = useState<string | null>(null);
    const [cardCvcError, setCardCvcError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const [fields, setFields] = useState([] as any[]);
    const elements = useElements();
    const [enablePaymentMethodForm, setEnablePaymentMethodForm] = useState(false);
    const { currentSubscription, refetch: refresh } = useMySubscription();
    const { paymentMethod, refetch: refreshPaymentMethod } = usePaymentMethods();
    const { currentUser, refetch: refetchUser } = useCurrentUser();
    const [paymentMethodForm] = Form.useForm();


    const getMonth = (a: any) => {
        if (!a) {
            return 'NA';
        }
        return pad(a, 2);
    };
    const getYear = (a: any) => {
        if (!a) {
            return 'NA';
        }
        return a % 100;
    };
    useEffect(() => {
        API_SERVICE.subscriptionPrices().then((subscriptions) => {
            if (subscriptions.data) {
                subscriptions.data = subscriptions.data.map((s: any) => {
                    return { ...s, subscriptionType: s.type, price: s.amount };
                });
                setYearlySubscription(subscriptions.data.find((a: any) => a.type === 'yearly'));
                setAllSubscriptions(subscriptions.data.filter((a: any) => a.type !== 'trial'));
                setOffer(subscriptions.data.find((a: any) => a.type === 'monthly'));
            }
        }).catch((e) => {
            notification.error(t(API_SERVICE.handleErrors(e)));

        });
    }, [t]);
    const informationHeader = (<div className={'__form-header'}>
        <Title level={5} className={'__form-header-title'}>{t('genDict.mySubscriptions')}</Title>
    </div>);


    function handleSubmit(values: any) {
        const copied = { ...values };
        delete copied.confirmPassword;
        setLoading(true);
        updateCurrentCompany({ billingAddress: copied.billingAddress }).then(async (response) => {
            notification.success({
                message: t('genDict.success'),
                description: t('myProfile.form.notification.myProfileUpdatedSuccess'),
            });
            if (onCancel) {
                onCancel(true);
            }
            refetchUser();
            await updatePaymentInfo(copied.stripePaymentMethodId);
            refreshPaymentMethod();
            await upgradeSubscription(offer.type);
            refresh();
        }).catch(e => {
            notification.error({
                message: t(e),
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    if (!currentUser || !yearlySubscription || !offer) {
        return <Card style={{ padding: '30px' }}>
            <Skeleton.Button active={true} size={'large'}/>
            <Skeleton avatar paragraph={{ rows: 4 }}/>
        </Card>;
    }
    return <div className={'my-subscription-card-wrapper'}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{t('genDict.page-title', { title: t('pageTitles.myProfile') })}</title>
        </Helmet>
        {
            informationHeader
        }


        <Card style={{ padding: '20px', borderRadius: 0 }} bordered={false}>
            {
                currentSubscription && currentSubscription.status === 'unpaid' &&
                <div style={{ paddingBottom: '20px' }}>
                    <Alert message={t('mySubscription.paymentFailed')}
                           description={t('mySubscription.paymentFailedDescription')} type={'error'}/>
                </div>
            }
            {
                currentSubscription && currentSubscription.type === 'trial' && offer ? <>
                        <div className="__paymentTab __subscription-section __offer-section">
                            <Row gutter={10}>
                                <Col span={24}>
                                    <div className="__title-main-offer">
                                        {
                                            t('mySubscription.offer.title')
                                        }
                                    </div>
                                    <div className="__title-sub-sub-main-offer">
                                        {
                                            t('mySubscription.offer.subSubTitle')
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col md={12} sm={24} xs={24}>
                                    <div style={{ marginBottom: '2px' }}>
                                        <label className={'__label'}>{t('mySubscription.selectOffer')}</label>
                                    </div>
                                    <Select style={{ width: '100%', marginBottom: '30px' }} value={offer.type || undefined}
                                            onChange={(e: any) => {
                                                setOffer(allSubscriptions.find((a: any) => {
                                                    return a.type === e;
                                                }));
                                            }} placeholder={t('myBills.table.search.filterByYear')}>
                                        {
                                            allSubscriptions.map((sub: any) => {
                                                return <Select.Option value={sub.type}>{
                                                    <Trans
                                                        i18nKey={`mySubscription.${sub.type}.titleWithPrice`}
                                                        components={{ span: <span className="__price"/> }}
                                                        values={{
                                                            cost: sub.price,
                                                        }}
                                                    />}
                                                </Select.Option>;
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>

                            <Elements stripe={stripePromise}>
                                <PaymentForm
                                    onChange={setFields}
                                    fields={fields}
                                    loading={loading}
                                    onSignUp={handleSubmit}
                                    offer={offer}
                                    customer={{ ...currentUser, billingAddress: currentUser.company.billingAddress }}
                                />
                            </Elements>
                        </div>
                    </> :

                    (!currentSubscription ? <>
                        <div className="__paymentTab __subscription-section __offer-section">
                            <Row gutter={10}>
                                <Col span={24}>
                                    <div className="__title-main-offer">
                                        {
                                            t('mySubscription.expiredOffer.title')
                                        }
                                    </div>
                                    <div className="__title-sub-main-offer">
                                        {
                                            t('mySubscription.expiredOffer.subTitle')
                                        }
                                    </div>
                                    <div className="__title-sub-sub-main-offer">
                                        {
                                            t('mySubscription.expiredOffer.subSubTitle')
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col md={12} sm={24} xs={24}>
                                    <div style={{ marginBottom: '2px' }}>
                                        <label
                                            className={'__label --required-mark'}>{t('mySubscription.selectOffer')}</label>
                                    </div>
                                    <Select style={{ width: '100%', marginBottom: '30px' }}
                                            value={offer.type || undefined}
                                            onChange={(e: any) => {
                                                setOffer(allSubscriptions.find((a: any) => {
                                                    return a.type === e;
                                                }));
                                            }} placeholder={t('myBills.table.search.filterByYear')}>
                                        {
                                            allSubscriptions.map((sub: any) => {
                                                return <Select.Option value={sub.type}>{
                                                    <Trans
                                                        i18nKey={`mySubscription.${sub.type}.titleWithPrice`}
                                                        components={{ span: <span className="__price"/> }}
                                                        values={{
                                                            cost: sub.price,
                                                        }}
                                                    />}
                                                </Select.Option>;
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>

                            <Elements stripe={stripePromise}>
                                <PaymentForm
                                    onChange={setFields}
                                    fields={fields}
                                    loading={loading}
                                    onSignUp={handleSubmit}
                                    offer={offer}
                                    customer={{ ...currentUser, billingAddress: currentUser.company.billingAddress }}
                                />
                            </Elements>
                        </div>
                    </> : <>
                        <Row gutter={20}>
                            <Col md={12} sm={24} xs={24}>
                                <Card title={t(`mySubscription.${currentSubscription.type}.title`)}
                                      className={'__my-subscription-card'}>
                                    <div className="__subscription-details">
                                        <Trans
                                            i18nKey={`subscription.${currentSubscription.type}.price`}
                                            components={{ span: <span className="__price"/> }}
                                            values={{
                                                cost: currentSubscription.price,
                                            }}
                                        /> <Trans
                                        i18nKey={`subscription.${currentSubscription.type}.text`}
                                        components={{ span: <span className="__price"/> }}
                                        values={{
                                            cost: currentSubscription.price,
                                        }}
                                    />
                                        {currentSubscription.nextPaymentAt &&
                                        <div className="__subscription-details">
                                            <Trans
                                                i18nKey={`subscription.${currentSubscription.type}.expirationText`}
                                                values={{
                                                    date: moment(currentSubscription.nextPaymentAt).format('D MMM YYYY'),
                                                }}
                                            />
                                        </div>
                                        }

                                        <div className="__subscription-details">
                                            {currentSubscription.autoRenewalAt ?
                                                <Trans
                                                    i18nKey={`subscription.${currentSubscription.type}.autoRenewAt`}
                                                    values={{
                                                        date: moment(currentSubscription.autoRenewalAt).format('D MMM YYYY'),
                                                    }}
                                                /> :
                                                <Trans i18nKey={`subscription.${currentSubscription.type}.noRenewal`}
                                                       values={{
                                                           date: moment(currentSubscription.expiringAt).format('D MMM YYYY'),
                                                       }}
                                                />}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                {
                                    currentSubscription.type === 'monthly' &&
                                    <div className="__my-subscription-upgrate-action">
                                        <Button
                                            type="primary"
                                            loading={upgradingSubscription}
                                            shape="round"
                                            size={'large'}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                confirm({
                                                    icon: <ExclamationCircleOutlined/>,
                                                    title: t('mySubscription.confirmation.msg'),
                                                    content: t('mySubscription.confirmation.upgradeSubscription'),
                                                    onOk: async () => {
                                                        setUpgradingSubscription(true);
                                                        upgradeSubscription('yearly').then((success) => {
                                                            notification.success({
                                                                message: t('genDict.success'),
                                                                description: t('mySubscription.notification.subscriptionUpgraded'),
                                                            });

                                                            refresh();
                                                        }).catch(e => {
                                                            notification.error({
                                                                message: t(e),
                                                            });
                                                        }).finally(() => {
                                                            setUpgradingSubscription(false);
                                                        });
                                                    },
                                                });
                                            }}
                                        >
                                            {t('mySubscription.button.upgradeSubscription')}
                                        </Button>
                                        <div className={'__my-subscription-upgrate-text'}>
                                            <Trans
                                                i18nKey={`mySubscription.${currentSubscription.type}.upgradeText`}
                                                values={{
                                                    price: yearlySubscription.savingAmount,
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12} sm={24} xs={24}>
                                {currentSubscription.autoRenewalAt ?
                                    <div className={'flat-button primary cancel-my-offer-button'}
                                         onClick={(e) => {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             confirm({
                                                 icon: <ExclamationCircleOutlined/>,
                                                 title: t('mySubscription.confirmation.msg'),
                                                 okText: t('mySubscription.button.cancelSubscription'),
                                                 cancelText: t('genDict.Cancel'),
                                                 content: t('mySubscription.confirmation.cancelSubscription'),// TODO
                                                 okButtonProps: {
                                                     loading: cancelingSubscription,
                                                     danger: true,
                                                 },
                                                 onOk: async () => {
                                                     setCancelingSubscription(true);
                                                     cancelUserSubscription().then((success) => {
                                                         notification.success({
                                                             message: t('genDict.success'),
                                                             description: t('mySubscription.notification.subscriptionCancelled'),
                                                         });

                                                         refresh();
                                                     }).catch(e => {
                                                         notification.error({
                                                             message: t(e),
                                                         });
                                                     }).finally(() => {
                                                         setCancelingSubscription(false);
                                                     });
                                                 },
                                             });
                                         }
                                         }>
                                        {t('mySubscription.cancelMyOffer')}
                                    </div> : <div className={'flat-button primary cancel-my-offer-button'}
                                                  onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      confirm({
                                                          icon: <ExclamationCircleOutlined/>,
                                                          title: t('mySubscription.confirmation.reactivateMsg'),
                                                          okText: t('mySubscription.button.reactivateSubscription'),
                                                          cancelText: t('genDict.Cancel'),
                                                          content: t('mySubscription.confirmation.reactivateSubscription'),// TODO
                                                          okButtonProps: {
                                                              loading: cancelingSubscription,
                                                              danger: false,
                                                          },
                                                          onOk: async () => {
                                                              setCancelingSubscription(true);
                                                              reactivateUserSubscription().then((success) => {
                                                                  notification.success({
                                                                      message: t('genDict.success'),
                                                                      description: t('mySubscription.notification.subscriptionReactivated'),
                                                                  });

                                                                  refresh();
                                                              }).catch(e => {
                                                                  notification.error({
                                                                      message: t(e),
                                                                  });
                                                              }).finally(() => {
                                                                  setCancelingSubscription(false);
                                                              });
                                                          },
                                                      });
                                                  }
                                                  }>
                                        {t('mySubscription.reActivateSubscription')}
                                    </div>}
                            </Col>
                            <Col md={12} sm={24} xs={24}>

                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col md={12} sm={24} xs={24}>
                                <div className="__sub-title">
                                    {t('mySubscription.meansOfPayments')}
                                </div>
                                {
                                    paymentMethod && !enablePaymentMethodForm &&
                                    <Card title={t(`mySubscription.paymentMethod.title`)}
                                          className={'__my-payment-method-card'}
                                          extra={<a href="/" onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              setEnablePaymentMethodForm(true);
                                          }}>{t('mySubscription.paymentMethod.changePaymentMethod')}</a>}>
                                        <div className="__payment-method-details">
                                            <div className="__subscription-details">
                                                {
                                                    paymentMethod.ownerName
                                                }
                                            </div>
                                            <div className="__subscription-details">
                                                **** &nbsp;{
                                                paymentMethod.last4Digits
                                            }
                                            </div>
                                            <div className="__subscription-details">
                                                {
                                                    getMonth(paymentMethod.expirationMonth)
                                                }/{
                                                getYear(paymentMethod.expirationYear)
                                            }
                                            </div>
                                        </div>
                                        <div className="__payment-method-icon">
                                            <CreditCardFilled/>
                                        </div>
                                    </Card>
                                }{
                                enablePaymentMethodForm && <Form
                                    layout="vertical"
                                    hideRequiredMark
                                    size="large"
                                    form={paymentMethodForm}
                                    onFinish={async (event) => {
                                        if (!stripe || !elements) {
                                            // Stripe.js has not loaded yet. Make sure to disable
                                            // form submission until Stripe.js has loaded.
                                            return;
                                        }

                                        if (error && elements) {
                                            // @ts-ignore
                                            elements.getElement('card').focus();
                                            return;
                                        }

                                        if (cardComplete) {
                                            setProcessing(true);
                                        }

                                        try {
                                            const payload = await stripe.createPaymentMethod({
                                                type: 'card',
                                                // @ts-ignore
                                                card: elements.getElement(CardNumberElement),
                                                billing_details: {
                                                    name: event['name'],
                                                },
                                            });

                                            if (payload.error) {
                                                setProcessing(false);
                                                // @ts-ignore
                                                setError(payload.error);
                                            } else {
                                                // @ts-ignore
                                                await updatePaymentInfo(payload.paymentMethod.id);
                                                refreshPaymentMethod();
                                                setEnablePaymentMethodForm(false);
                                                refresh();
                                                setProcessing(false);
                                                notification.success({
                                                    message: t('genDict.success'),
                                                    description: t('signup.card.notification.cardUpdatedSuccess'),
                                                });
                                            }
                                        } catch (e) {
                                            // @ts-ignore
                                            setError('Something went wrong!!');
                                            setProcessing(false);
                                            notification.error({
                                                message: t((e as any).toString()),
                                            });
                                        }
                                    }}
                                >
                                    <fieldset className="__cardForm"
                                              style={{ marginTop: '30px', maxWidth: 'auto !important' }}>
                                        <Row gutter={10}>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="name"
                                                    label={t('payment.input.name.label')}
                                                    rules={[
                                                        { required: true, message: t('payment.input.name.required') },
                                                        {
                                                            pattern: REGEX_STRING_VERIFY,
                                                            message: t('errorMessage.stringWrongFormat'),
                                                        },
                                                    ]}
                                                >
                                                    <Input/>
                                                </Form.Item>
                                                <Form.Item
                                                    label={t('payment.input.details.label')}
                                                    name="cardNumber"
                                                    rules={[
                                                        {
                                                            validator: () => cardNumberError ? Promise.reject(new Error(cardNumberError)) : Promise.resolve(),
                                                        }]}
                                                >
                                                    <CardField
                                                        onChange={(e: any) => {
                                                            setCardNumberError(e.error?.message);
                                                            setCardComplete(e.complete);
                                                            paymentMethodForm.validateFields(['cardNumber']).finally();
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={t('payment.input.securityInfo.label')}
                                                    name="cardCvc"
                                                    rules={[{
                                                        validator: () => cardCvcError ? Promise.reject(new Error(cardCvcError)) : Promise.resolve(),
                                                    }]}
                                                >
                                                    <CardCvcField
                                                        onChange={(e: any) => {
                                                            setCardCvcError(e.error?.message);
                                                            setCardComplete(e.complete);
                                                            paymentMethodForm.validateFields(['cardCvc']).finally();
                                                        }}
                                                    />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                    </fieldset>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            loading={loading || processing}
                                            htmlType="submit"
                                            shape="round"
                                            style={{ marginTop: 16 }}
                                        >
                                            {t('payment.validate')}
                                        </Button>
                                    </Form.Item>
                                </Form>
                            }
                            </Col>
                        </Row>
                    </>)

            }
        </Card>
    </div>;
}

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#BFBFBF',
            color: '#212121',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '17px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#fce883',
            },
            '::placeholder': {
                color: '#BFBFBF',
            },
        },
        invalid: {
            iconColor: '#DC3545',
            color: '#DC3545',
        },
    },
};

const CardField = ({ onChange }: any) => {
    return <div className="__payment-FormRow">
        {/*@ts-ignore*/}
        <CardNumberElement options={CARD_OPTIONS} onChange={onChange}/>
        {/*@ts-ignore*/}
        <CardExpiryElement options={CARD_OPTIONS} onChange={onChange} className="__card-expiry"/>
    </div>;
};

const CardCvcField = ({ onChange }: any) => (
    <div className="__payment-FormRow">
        {/*@ts-ignore*/}
        <CardCvcElement options={CARD_OPTIONS} onChange={onChange}/>
    </div>
);
;
