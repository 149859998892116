import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Card } from 'antd';
import * as _ from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import ListView from '../../../../components/layout/ListView';
import WorkTypesList from './WorkTypesList';
import { useState } from 'react';
import WorkTypeSelection from './WorkTypeSelection';
import ArticlesToPersonalize from './ArticlesToPersonalize';
import useAddWorkTypeToWorkSite from '../../../../hooks/work-sites/useAddWorkTypeToWorkSite';
import WorkTypesToPersonalize from './WorksToPersonalize';

const WorkSiteWorkTypes: React.FunctionComponent<any> = ({
    callPOCreate,
    callQuoteCreate,
    workSite,
    setUpdating,
    refresh,
}) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(-1);
    const [workTypeArticlesToPersonalize, setWorkTypeArticlesToPersonalize] =
        useState([] as any[]);
    const [workTypeNames, setWorkTypeNames] = useState([] as any[]);
    const [workTypeToPersonalize, setWorkTypeToPersonalize] = useState(
        [] as any[],
    );

    const [workTypesToAdd, setWorkTypesToAdd] = useState([]);

    const addWorkTypes = async (workTypes: { id: number }[]) => {
        try {
            await Promise.all(
                workTypes.map((a: any) => {
                    return useAddWorkTypeToWorkSite(workSite.id, a.id);
                }),
            );
            setActiveStep(-1);
            if (refresh) {
                refresh();
            }
        } catch (e) {
            notification.error({
                message: t(e),
            });
        }
    };

    const handleWorkTypeSelectSubmit = (workTypes: any[]) => {
        let d: any = [];
        setWorkTypesToAdd(workTypes);
        workTypes.forEach((f: any) => {
            d = [
                ...d,
                ...f.rows
                    .filter((a: any) => {
                        return (
                            (a.article && !a.article.isPersonalized) ||
                            (a.effort && a.isAdminCreated && !a.isPersonalized)
                        );
                    })
                    .map((aa: any) => {
                        return {
                            ...aa,
                            workTypeName: f.name,
                            i: aa.article || aa.effort,
                            newReference: aa.effort
                                ? `[${f.reference}] ${aa.effort.reference}`
                                : aa.article.reference,
                            newName: aa.effort
                                ? `[${f.name}] ${aa.effort.name}`
                                : aa.article.name,
                            k: aa.effort
                                ? 'effort-' + aa.effort.reference + '-' + f.reference
                                : 'article-' + aa.article.reference,
                        };
                    }),
            ];
        });

        const wTToEdit = workTypes.filter((a: any) => !a.isPersonalized);
        setWorkTypeToPersonalize(wTToEdit);

        setWorkTypeNames(d.map((a: any) => a.workTypeName));
        if (d.length) {
            setWorkTypeArticlesToPersonalize(_.uniqBy(d, (n: any) => n.k));
            if (d.find((d: any) => !!d.article)) {
                setActiveStep(1);
            } else {
                setActiveStep(2);
            }
            return;
        }

        if (wTToEdit.length) {
            setActiveStep(3);
            return;
        }

        addWorkTypes(workTypes);
    };

    const handleArticlePersonalizeSubmit = (articles: any[]) => {
        if (articles.filter((a: any) => !!a.effort).length) {
            setActiveStep(2);
        } else {
            setActiveStep(3);
        }
    };

    const handleEffortPersonalizeSubmit = () => {
        setActiveStep(3);
    };

    const handleWorkTypesPersonalizeSubmit = (workTypes: any[]) => {
        let d: any = [];
        workTypes.forEach((f: any) => {
            d = [
                ...d,
                ...f.rows
                    .filter((a: any) => {
                        return (
                            (a.article && !a.article.isPersonalized) ||
                            (a.effort && a.isAdminCreated && !a.isPersonalized)
                        );
                    })
                    .map((aa: any) => {
                        return {
                            ...aa,
                            workTypeName: f.name,
                            i: aa.article || aa.effort,
                            newReference: aa.effort
                                ? `[${f.reference}] ${aa.effort.reference}`
                                : aa.article.reference,
                            newName: aa.effort
                                ? `[${f.name}] ${aa.effort.name}`
                                : aa.article.name,
                            k: aa.effort
                                ? aa.effort.reference + '-' + f.reference
                                : aa.article.reference,
                        };
                    }),
            ];
        });
        setWorkTypeArticlesToPersonalize(_.uniqBy(d, (n: any) => n.k));
        setWorkTypeNames(d.map((a: any) => a.workTypeName));

        addWorkTypes(workTypesToAdd);
    };

    return (
        <>
            <Card
                title={
                    <div className="__header-title">
                        {t('workSite.workTypes.headerText')} &nbsp;
                        <div
                            className="__button-add-icon"
                            onClick={() => setActiveStep(0)}
                        >
                            <PlusOutlined />
                        </div>
                    </div>
                }
                className="__work-types-wrapper"
            >
                {activeStep === 0 && (
                    <WorkTypeSelection
                        workSite={workSite}
                        onSubmit={handleWorkTypeSelectSubmit}
                        onCancel={() => setActiveStep(-1)}
                    />
                )}
                {activeStep === 1 && (
                    <ArticlesToPersonalize
                        workTypeNames={workTypeNames}
                        workSite={workSite}
                        articles={workTypeArticlesToPersonalize.filter(
                            (a: any) => !!a.article,
                        )}
                        onSubmit={handleArticlePersonalizeSubmit}
                        efforts={workTypeArticlesToPersonalize.filter(
                            (a: any) => !!a.effort,
                        )}
                        onCancel={() => setActiveStep(-1)}
                    />
                )}
                {activeStep === 2 && (
                    <ArticlesToPersonalize
                        workTypeNames={workTypeNames}
                        fromEffort={true}
                        workSite={workSite}
                        articles={workTypeArticlesToPersonalize.filter(
                            (a: any) => !!a.effort,
                        )}
                        onSubmit={handleEffortPersonalizeSubmit}
                        onCancel={() => setActiveStep(-1)}
                    />
                )}
                {activeStep === 3 && (
                    <WorkTypesToPersonalize
                        workSite={workSite}
                        workTypes={workTypeToPersonalize}
                        onSubmit={handleWorkTypesPersonalizeSubmit}
                        onCancel={() => setActiveStep(-1)}
                    />
                )}

                <ListView
                    title=""
                    noP={true}
                    pageTitle={t('genDict.page-title', {
                        title: t('pageTitles.viewWorkSites'),
                    })}
                    cardTitleStyle={{
                        padding: 0,
                    }}
                >
                    <WorkTypesList
                        callQuote={callQuoteCreate}
                        callPurchaseOrder={callPOCreate}
                        callAddWorkType={() => setActiveStep(0)}
                        refresh={refresh}
                        setUpdating={setUpdating}
                        workSite={workSite}
                    />
                </ListView>
            </Card>
        </>
    );
};
export default WorkSiteWorkTypes;
