import * as React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import WorkSiteCreateModal from 'shared/views/work-sites/CreateModal';
import { useParams } from 'react-router';
import useWorkSiteById from '../../hooks/work-sites/useWorkSiteById';

const Create: React.FunctionComponent<{}> = () => {
    const { t } = useTranslation();

    const RouteParams: any = useParams();
    const id = RouteParams['id'];
    const isEditMode = RouteParams['pageMode'] && RouteParams['pageMode'].toLowerCase() === 'edit';
    const { item: workSite, loading } = useWorkSiteById(id);
    return <div className={`__listView __add-work-type not-from-popup`}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{t('pageTitles.createWorkSites')}</title>
        </Helmet>
        <div className={'__title-wrapper'}>
            <h1 className="__title">
                {t('workSite.addWorkSite')}
            </h1>
        </div>
        <div className={'__listView-inner-wrapper'} style={{ background: 'white' }}>
            <WorkSiteCreateModal isEditMode={isEditMode} item={workSite} loading={id && loading}/>
        </div>
    </div>;
};
export default Create;
