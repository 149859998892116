// noinspection RequiredAttributes

import React, { useEffect, useState } from 'react';


import { Form, Input, Button, Row, Col, Select, notification, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { dotToObject, objectToDot } from 'shared/services/Utility';
import {
	ConstantWorkTypeFamily,
	ConstantPartitionType,
	ConstantTypeOfPlate,
	ConstantInsulationType,
	ConstantPlankLength,
	ConstantCenterDistance,
	ConstantCenterDistanceIfCeiling,
	ConstantInsulation,
	ConstantMiddleSupport,
	ConstantEpaisseur,
	ConstantCeilingHanger,
	ConstantCeilingSlabsFixing,
	ConstantCeilingPlasterboardFixing,
    REGEX_STRING_VERIFY,
} from 'shared/constants/AppConstants';

import { createNewWorkType, fetchWorkTypeById, updateWorkType } from 'shared/services/workService';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { isAdmin } from 'shared/contexts/AuthContext';


interface Props {
    fromPopup?: boolean,
    workType?: any,
    onClose?: any
}


const WorkTypeCreateModal: React.FunctionComponent<Props> = ({ onClose, fromPopup }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [fields]= useState([] as any[]);
    const [form] = Form.useForm();
    const RouteParams: any = useParams();
    const [item, setItem] = useState({} as any);

    const [savingItem, setSavingItem] = React.useState(false);
    const [, setFormValues] = React.useState(null as any);
    const initialValues: any = item ? objectToDot(item) : {};
    const [fetchingItem, setFetchingItem] = React.useState(false);
    const refresh = () => {
        if (!RouteParams['workTypeId']) {
            return;
        }
        setFetchingItem(true);
        fetchWorkTypeById(RouteParams['workTypeId']).then((response) => {
            if (response && response.data && response.data.workType) {
                form.setFieldsValue(response.data.workType);
                setTimeout(()=>{
                    form.setFieldsValue({ ceilingHanger: response.data.workType['ceilingHanger'] });
                    setItem(response.data.workType);
                }, 100)
            }

        }).catch((e) => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        }).finally(() => {
            setFetchingItem(false);
        });
    };
	// eslint-disable-next-line
    useEffect(refresh, []);

    const {family, ceilingHanger} = form.getFieldsValue();
    return (

        <div className={`__listView __add-work-type ${!fromPopup && 'not-from-popup'}`}>
            {
                !fromPopup && <>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{t('genDict.page-title', { title: item && item.id ? t('pageTitles.createWorkTypes') : t('pageTitles.editWorkTypes') })}</title>
                    </Helmet>
                    <div className={'__title-wrapper'}>
                        {
                            <h1 className="__title">
                                {!fetchingItem ? (item && item.id ? t(isAdmin() ? 'workType.editWorkTypeAdmin' : 'workType.editWorkType') : t(isAdmin() ? 'workType.addWorkTypeAdmin' : 'workType.addWorkType')) :
                                    <Skeleton.Button style={{ marginTop: '20px' }} size={'large'} active={true}/>}
                            </h1>
                        }
                    </div>
                </>
            }
            <div className={'__listView-inner-wrapper'} style={{ background: 'white' }}>
                {!fetchingItem ? <Form
                    size="large"
                    form={form}
                    fields={fields}
                    onFieldsChange={(changedValues, formValues) => {
                        setFormValues(formValues);
                    }}
                    requiredMark={true}
                    initialValues={initialValues}
                    onFinish={async (formValues) => {
                        const reqObject = dotToObject(formValues);
                        setSavingItem(true);
                        if (!item || !item.id) {
                            createNewWorkType(reqObject).then((response) => {
                                notification.success({
                                    message: t('genDict.success'),
                                    description: t('workType.notification.workTypeCreatedSuccess'),
                                });
                                if (response && response.data && response.data.workType_create) {
                                    history.push(`/ouvrages/${response.data.workType_create.id}/view`);
                                }
                            }).catch(e => {
                                notification.error({
                                    message: t(e),
                                });
                            }).finally(() => {
                                setSavingItem(false);
                            });
                        } else if (item) {
                            updateWorkType({ ...reqObject, id: item.id }).then(() => {
                                notification.success({
                                    message: t('genDict.success'),
                                    description: t('workType.notification.workTypeUpdatedSuccess'),
                                });
                                if (fromPopup && onClose) {
                                    onClose();
                                } else {
                                    history.push(`/ouvrages/${item.id}/view`);
                                }
                            }).catch(e => {
                                notification.error({
                                    message: t(e),
                                });
                            }).finally(() => {
                                setSavingItem(false);
                                if (onClose) {
                                    onClose();
                                }
                            });
                        }

                    }}
                    layout="vertical">
                    <div style={{ padding: '20px' }}>
                        <>
                            <Row gutter={16}>

                                <Col className="gutter-row" md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('workType.input.name.label')}
                                        name="name"
                                        key="name"
                                        rules={[
                                            { required: true, message: t('workType.input.name.required') },
                                            {
                                                pattern: REGEX_STRING_VERIFY,
                                                message: t('errorMessage.stringWrongFormat'),
                                            },
                                        ]}>
                                        <Input
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" md={12} sm={24} xs={24}>
                                    <Form.Item
                                        label={t('workType.input.family.label')}
                                        name="family"
                                        rules={[
                                            { required: true, message: t('workType.input.family.required') },
                                        ]}
                                    >
                                        <Select										
                                            onChange={() => {
                                                form.setFieldsValue({
                                                    ceilingFixing: undefined,
                                                    middleSupport: undefined,
                                                    insulationType: undefined,
                                                    insulation: undefined,
                                                    centerDistance: undefined,
                                                    plankLength: undefined,
                                                    plankType: undefined,
                                                    partitionType: undefined,
													counterPartitionType: undefined,
													thickness: undefined,
                                                    ceilingHanger: undefined,													
                                                });
                                            }}
                                            placeholder={t('genDict.Select')}>
                                            <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                            {
                                                ConstantWorkTypeFamily.map((option: any) => {
                                                    return <Select.Option
                                                        value={option}>{t(`workType.family.${option}`)}</Select.Option>;
                                                })
                                            }

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {
								 (family === 'framePartition' || family === 'alveolarPartition'
									|| family === 'brickPartition') &&
									
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.partitionType.label')}
                                            name="partitionType"
                                            rules={[
                                                {
                                                    required: (family === 'framePartition' || family === 'alveolarPartition'
																|| family === 'brickPartition'),
                                                    message: t('workType.input.partitionType.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
													// @ts-ignore
                                                    ConstantPartitionType[family].map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
								 (family === 'againstPartition') &&
									
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.counterPartitionType.label')}
                                            name="counterPartitionType"
                                            rules={[
                                                {
                                                    required: (family === 'againstPartition'),
                                                    message: t('workType.input.counterPartitionType.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
													// @ts-ignore
                                                    ConstantPartitionType[family].map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }								
                                {
                                    ((family === 'alveolarPartition' || family === 'brickPartition')
                                        && family) &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.thickness.label')}
                                            name="thickness"
                                            rules={[
                                                {
                                                    required: ((family === 'alveolarPartition' || family === 'brickPartition')
                                                        && family),
                                                    message: t('workType.input.thickness.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
                                                    ConstantEpaisseur.map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (family === 'ceilingPlasterboard' || family === 'framePartition' || family === 'againstPartition'
									   || family === 'insulationType' || family === 'form') &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.plankType.label')}
                                            name="plankType"
                                            rules={[
                                                {
                                                    required: (family === 'ceilingPlasterboard' || family === 'framePartition' || family === 'againstPartition'
																|| family === 'insulationTypen' || family === 'form'),
                                                    message: t('workType.input.plankType.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
                                                    ConstantTypeOfPlate.map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    ((family === 'framePartition' || family === 'alveolarPartition' || family === 'againstPartition'
                                         || family === 'insulationType' || family === 'ceilingPlasterboard' || family === 'form')
                                        && family) &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.plankLength.label')}
                                            name="plankLength"
                                            rules={[
                                                {
                                                    required: ((family === 'framePartition' || family === 'alveolarPartition' || family === 'againstPartition'
																|| family === 'insulationType' || family === 'ceilingPlasterboard' || family === 'form')
																	&& family),
                                                    message: t('workType.input.plankLength.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
                                                    ConstantPlankLength.map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
								
                                {
                                    (family === 'insulationType') &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.insulationType.label')}
                                            name="insulationType"
                                            rules={[
                                                {
                                                    required: (family === 'insulationType'),
                                                    message: t('workType.input.insulationType.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
                                                    ConstantInsulationType.map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
								
                                {
                                    (family === 'ceilingPlasterboard') &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.centerDistance.label')}
                                            name="centerDistance"
                                            rules={[
                                                {
                                                    required: (family === 'ceilingPlasterboard'),
                                                    message: t('workType.input.centerDistance.required'),
                                                },
                                            ]}
                                        >
                                            <Select
												showSearch
												allowClear={true}
													placeholder={t('genDict.Select')}>
													<Select.Option value={''}>{t('genDict.Select')}</Select.Option>
													{
														ConstantCenterDistanceIfCeiling.map((option) => {
															return <Select.Option
																value={option}>{t(option)}</Select.Option>;
														})
													}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (family === 'framePartition') &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.centerDistance.label')}
                                            name="centerDistance"
                                            rules={[
                                                {
                                                    required: (family === 'framePartition'),
                                                    message: t('workType.input.centerDistance.required'),
                                                },
                                            ]}
                                        >
                                            <Select
												showSearch
												allowClear={true}
													placeholder={t('genDict.Select')}>
													<Select.Option value={''}>{t('genDict.Select')}</Select.Option>
													{
														ConstantCenterDistance.map((option) => {
															return <Select.Option
																value={option}>{t(option)}</Select.Option>;
														})
													}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }								
                                {
                                    ((family === 'framePartition' || family === 'insulationType'
                                        || family === 'againstPartition' || family === 'ceilingPlasterboard'
                                        ) && family) &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.insulation.label')}
                                            name="insulation"
                                            rules={[
                                                {
                                                    required: ((family === 'framePartition' || family === 'insulationType' 
													     || family === 'againstPartition' || family === 'ceilingPlasterboard') && family),
                                                    message: t('workType.input.insulation.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
                                                    ConstantInsulation.map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (family === 'againstPartition') &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.middleSupport.label')}
                                            name="middleSupport"
                                            rules={[
                                                {
                                                    required: (family === 'againstPartition'),
                                                    message: t('workType.input.middleSupport.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}>
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
                                                    ConstantMiddleSupport.map((option) => {
                                                        return <Select.Option
                                                            value={option}>{t(option)}</Select.Option>;
                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (family === 'ceilingPlasterboard'
                                        || family === 'ceilingSlabs') &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.ceilingHanger.label')}
                                            name="ceilingHanger"
                                            rules={[
                                                {
                                                    required: (family === 'ceilingPlasterboard'
                                                        || family === 'ceilingSlabs'),
                                                    message: t('workType.input.ceilingHanger.required'),
                                                },
                                            ]}
                                        >
                                            <Select
											showSearch
											allowClear={true}
                                                placeholder={t('genDict.Select')}
                                                onChange={() => {
                                                    form.setFieldsValue({ ceilingFixing: undefined });
                                                }}
                                            >
                                                <Select.Option value={''}>{t('genDict.Select')}</Select.Option>
                                                {
                                                    ConstantCeilingHanger.map((option) => {
                                                        return <Select.Option
                                                            //value={option}>{t(option)}</Select.Option>;
                                                            value={option}>{option}</Select.Option>;

                                                    })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    (family === 'ceilingSlabs') && (!!ceilingHanger && (ceilingHanger !== t('genDict.Other'))) &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.ceilingFixing.label')}
                                            name="ceilingFixing"
                                            rules={[
                                                {
                                                    required: (family === 'ceilingSlabs') && (ceilingHanger && ceilingHanger !== t('genDict.Other')),
                                                    message: t('workType.input.ceilingFixing.required'),
                                                },
                                            ]}
                                        >	
										<Select
											showSearch
											allowClear={true}
												placeholder={t('genDict.Select')}>
												<Select.Option value={''}>{t('genDict.Select')}</Select.Option>
												{
													// @ts-ignore
                                                    ConstantCeilingSlabsFixing[ceilingHanger].map((option) => {
														return <Select.Option
															value={option} key={option}>{t(option)}</Select.Option>;
													})
												}
										</Select>										
                                        </Form.Item>
                                    </Col>
                                }								
                                {
                                    (family === 'ceilingPlasterboard') && (!!ceilingHanger && (ceilingHanger !== t('genDict.Other'))) &&
                                    <Col className="gutter-row" md={12} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('workType.input.ceilingFixing.label')}
                                            name="ceilingFixing"
                                            rules={[
                                                {
                                                    required: (family === 'ceilingPlasterboard') && (ceilingHanger && ceilingHanger !== t('genDict.Other')),
                                                    message: t('workType.input.ceilingFixing.required'),
                                                },
                                            ]}
                                        >	
										<Select
										showSearch
										allowClear={true}
											placeholder={t('genDict.Select')}>
											<Select.Option value={''}>{t('genDict.Select')}</Select.Option>
											{
												// @ts-ignore
                                                    ConstantCeilingPlasterboardFixing[ceilingHanger].map((option) => {
														return <Select.Option
															value={option} key={option}>{t(option)}</Select.Option>;
												})
											}
										</Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                        </>
                    </div>
                    {
                        <Row>
                            <Col md={24} sm={24} xs={24}>
                                <Row gutter={20} align={'middle'} className={'__form-footer-parent-row'}>
                                    <Col flex={'auto'}/>
                                    <Col className={'__form-footer-row'}>
                                        <Row gutter={20}>
                                            <Col>
                                                <Form.Item>
                                                    <Button
                                                        type="default"
                                                        onClick={() => {
                                                            if (fromPopup && onClose) {
                                                                onClose();
                                                            } else {
                                                                history.goBack();
                                                            }
                                                        }}
                                                        disabled={savingItem}
                                                        shape="round"
                                                        size={'large'}
                                                        className="submit">
                                                        {t('genDict.Cancel')}
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <Button
                                                        type="primary"
                                                        htmlType="submit"
                                                        loading={savingItem}
                                                        shape="round"
                                                        size={'large'}
                                                        className="submit">
                                                        {item && item.id ? t('workType.button.saveWorkType') : t('workType.button.addWorkType')}
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    }
                </Form> : <Skeleton active/>}
            </div>
        </div>
    );
};
export default WorkTypeCreateModal;
