import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { WorkSiteType } from '../../types/workSite.type';

type ReturnType = {
    items: any[];
    loading: boolean;
    error: any;
    refetch: any
};
const QUERY = gql`
   query WorkSiteOrderArticles($id: ID!) {
  workSiteOrderArticles(id: $id){
    name
    length
    quantity
    unit
  }
}  
`;

const useWorkSiteOrderArticles: (id: any) => ReturnType = (id: any) => {
    const { data, loading, error, refetch } = useQuery(QUERY, { variables: { id } });
    let items = [];
    if (!loading && !error) {
        items = data.workSiteOrderArticles;
    }
    return {
        items,
        refetch,
        loading,
        error,
    };
};
export default useWorkSiteOrderArticles;
