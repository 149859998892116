import * as React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import { useEffect, useState } from 'react';
import ArticleSelection from './ArticleSelection';
import SupplierSelection from './SupplierSelection';
import useAddPurchaseOrder from '../../../../hooks/work-sites/useAddPurchaseOrder';
import SendEmail from './SendEmail';
import useSendDocument from '../../../../hooks/work-sites/useSendDocument';

const CreatePurchaseOrder: React.FunctionComponent<any> = ({
                                                               workSite,
                                                               shouldShowPOCreate,
                                                               selectedPO,
                                                               setSelectedPO,
                                                               refresh,
                                                           }) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(-1);
    const [selectedArticles, setSelectedArticles] = useState([] as any[]);
    const [loading, setLoading] = useState(false);
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        if (shouldShowPOCreate > -1) {
            if (shouldShowPOCreate === 0) {
                setSelectedArticles([]);
            }
            setActiveStep(shouldShowPOCreate);
        }
    }, [shouldShowPOCreate]);


    const onSubmitArticles = (articles: any[]) => {
        setSelectedArticles(articles.map((a: any) => {
            return { length: a.length, name: a.name, quantity: a.quantity, unit: a.unit };
        }));
        setActiveStep(1);
    };
    const onCancelArticles = () => {
        setActiveStep(-1);
    };

    const onSubmitSupplier = async (d: any) => {
        const reqObj = {
            ...d,
            deliveryDate: d.deliveryDate ? d.deliveryDate.format('MM/DD/YYYY') : '',
        };
        setLoading(true);
        try {
            const response = await useAddPurchaseOrder({ workSiteId: workSite.id, rows: selectedArticles, ...reqObj });
            if (response && response.data && response.data.workSite_addPurchaseOrder) {
                setSelectedPO({
                    ...response.data.workSite_addPurchaseOrder,
                    providerId: response.data.workSite_addPurchaseOrder.provider.id,
                    defaultContact: d.defaultContact,
                    contactId: d.contactId,
                    direct: false,
                });
                setActiveStep(2);
            }
        } catch (e) {
            notification.error({
                message: t(e),
            });
        } finally {
            setLoading(false);
        }
    };
    const onDownloadSupplier = async (d: any) => {
        const reqObj = {
            ...d,
            deliveryDate: d.deliveryDate ? d.deliveryDate.format('MM/DD/YYYY') : '',
        };
        setDownloading(true);
        try {
            const response = await useAddPurchaseOrder({ workSiteId: workSite.id, rows: selectedArticles, ...reqObj });
            if (response && response.data && response.data.workSite_addPurchaseOrder) {
                setActiveStep(-1);
                refresh();
                window.open(response.data.workSite_addPurchaseOrder.url);
            }
        } catch (e) {
            notification.error({
                message: t(e),
            });
        } finally {
            setDownloading(false);
        }
    };
    const onCancelSupplier = () => {
        setActiveStep(-1);
    };
    const onBackSupplier = () => {
        setActiveStep(0);
    };

    const onSubmitEmail = async (d: any) => {
        const reqObj = {
            ...d,
        };
        setLoading(true);
        try {
            const response = await useSendDocument(reqObj);
            refresh();
            setActiveStep(-1);
            notification.success({
                message: t('workSite.purchaseOrders.mailSentSuccessfully'),
            });
        } catch (e) {
            notification.error({
                message: t(e),
            });
        } finally {
            setLoading(false);
        }
    };
    const onBackEmail = () => {
        setActiveStep(1);
    };
    const onCancelEmail = () => {
        setActiveStep(-1);
        refresh();
    };


    return <> {
        activeStep === 0 &&
        <ArticleSelection workSite={workSite} onSubmit={onSubmitArticles}
                          onCancel={onCancelArticles}/>
    }
        {
            activeStep === 1 &&
            <SupplierSelection loading={loading} downloading={downloading} workSite={workSite}
                               onSubmit={onSubmitSupplier}
                               onDownload={onDownloadSupplier} onBack={onBackSupplier}
                               onCancel={onCancelSupplier}/>
        }
        {
            activeStep === 2 &&
            <SendEmail loading={loading} workSite={workSite}
                       onSubmit={onSubmitEmail}
                       selectedPurchaseOrder={selectedPO}
                       onBack={onBackEmail}
                       onCancel={onCancelEmail}/>
        }
    </>;
};
export default CreatePurchaseOrder;
