import * as React from 'react';
import { Button as BaseButton } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

type Props = {
    onClick: () => void;
    loading?: boolean;
    disabled?: boolean;
}

const DeleteButton: React.FunctionComponent<Props> = ({ disabled, onClick, loading }) => {
    return (
        <BaseButton
            type="primary"
            danger={!disabled}
            loading={loading}
            disabled={disabled}
            shape="circle"
            onClick={onClick}
            icon={<DeleteFilled/>}
        />
    );
};
export default DeleteButton;
