import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { WorkSiteType } from '../../types/workSite.type';
import { client, clientWithErrors } from '../../services/ApolloClient';

const MUTATION = gql`mutation($input: WorkSiteInput!){
  workSite_create(input: $input){
    id
  }
} 
`;

const useWorkSiteCreate = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: { input: data }, mutation: MUTATION }));
};
export default useWorkSiteCreate;
