declare const API_URL: string;
declare const IS_ADMIN: boolean;
declare const STRIPE_PUBLIC_TOKEN: string;
declare const TIMEOUT_DURATION_IN_SECONDS: number;
declare const CHAT_BOT_LISENCE: number;
declare const TIMEOUT_DURATION_CHECK_IN_SECONDS: number;
declare const SUPPORT_MAIL: string;
declare const FAQ_LINK: string;
declare const TIMEOUT_DURATION_NOTIFICATION_IN_SECONDS: number;
export default {
    ApiUrl: API_URL,
    IsAdmin: IS_ADMIN,
    SUPPORT_MAIL: SUPPORT_MAIL,
    FAQ_LINK: FAQ_LINK,
    TimeoutDurationInSeconds: TIMEOUT_DURATION_IN_SECONDS,
    TimeoutDurationNotificationInSeconds: TIMEOUT_DURATION_NOTIFICATION_IN_SECONDS,
    TimeoutDurationCheckInSeconds: TIMEOUT_DURATION_CHECK_IN_SECONDS,
    StripePublicToken: STRIPE_PUBLIC_TOKEN,
    ChatBotLisence: CHAT_BOT_LISENCE,
};
