import { useMemo } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { client, clientWithErrors } from './ApolloClient';

type CategoriesReturnType = {
    articleCategories: any;
    loadingCategories: boolean;
    error: any;
};
const GET_ARTICLES = gql`{
    articles{
        id
        reference
        name
        priority
        isDeletable
        isPersonalized
        isUsed
        price
        isAdminCreated
        type
        pictureUrl
        isHeightSensitive
        variations
        {
            id
            reference
            name
            isDeletable
            isUsed
            isPersonalized
            price
            isAdminCreated
            type
            variations{
                id
                reference
                name
                isPersonalized
                isDeletable
                isUsed
                price
                isAdminCreated
                type
            }
        }
        unit
        category{
            id
            name
            isDeletable
        }
    }
}`;
const DELETE_ARTICLE_BY_ID = gql`mutation($id: ID!){ article_delete(id: $id)}`;
const UPDATE_ARTICLES_ANY = gql`mutation($articleIds: [ID!]!, $price: String, $category: ID){  article_bulkEdit(articleIds: $articleIds, price: $price, category: $category){
    article{
        id
        reference
        name
    }
}}`;
const DELETE_ARTICLE_IMAGE_BY_ID = gql`mutation($id: ID!){
    article_deletePicture(id: $id){
        id
        reference
        name
        isDeletable
        isPersonalized
    }
}`;
const DELETE_ROW_FROM_WORK_TYPE_BY_ID = gql`mutation($id : ID!, $rowId: ID!){
    workType_removeRow(id: $id, rowId: $rowId){
        id
        rows{
            id
            type
            order
            quantity
            unitPrice
            price
        }
        name
        family
        sellingPrice
        cost
        partitionType
		counterPartitionType
        plankType
        plankLength
        centerDistance
        insulation
        isAdminCreated
    }
}`;
const ADD_ARTICLES_TO_WORK_TYPE = gql`mutation($articleId: ID!, $id: ID!){
    workType_addArticle(articleId: $articleId, id: $id){
        id
        rows{
            id
            type
            order
            quantity
            unitPrice
            price
        }
        name
        family
        sellingPrice
        cost
        partitionType
		counterPartitionType
        plankType
        plankLength
        centerDistance
        insulation
        isAdminCreated
    }
}`;

const DELETE_ARTICLE_CATEGORY_BY_ID = gql`mutation($id: ID!){ articleCategory_delete(id: $id)}`;
const GET_CATEGORIES = gql`query{articleCategories{ id name isAdminCreated isDeletable description }}`;
const CREATE_ARTICLE = gql`mutation($name: String!, $price: String!,$picture: FileUpload, $isHeightSensitive: Boolean!,$priority: Boolean, $variations: [ArticleVariationInput]!, $unit:String!, $category:String!){
    article_create(input: { name: $name, price: $price,  isHeightSensitive: $isHeightSensitive,picture: $picture,priority: $priority, variations: $variations, unit: $unit, category: $category}){
        name
        price
        id
        isAdminCreated
        pictureUrl
        isHeightSensitive
        variations{
            name
            price
        }
        unit
        category{
            id
        }
    }
}`;
const DUPLICATE_ARTICLE = gql`mutation($name: String!, $pictureFromArticle: ID!,$price: String!,$picture: FileUpload, $isHeightSensitive: Boolean!,$priority: Boolean, $variations: [ArticleVariationInput]!, $unit:String!, $category:String!){
    article_create(input: { name: $name,pictureFromArticle: $pictureFromArticle, price: $price,  isHeightSensitive: $isHeightSensitive,picture: $picture,priority: $priority, variations: $variations, unit: $unit, category: $category}){
        name
        price
        id
        isAdminCreated
        pictureUrl
        isHeightSensitive
        variations{
            name
            price
        }
        unit
        category{
            id
        }
    }
}`;
const EDIT_ARTICLE = gql`mutation($name: String!,$price: String!,$picture: FileUpload,$priority: Boolean, $isHeightSensitive: Boolean!, $variations: [ArticleVariationInput]!, $unit:String!, $category:String!, $id: ID!){
    article_edit(id: $id, input: { name: $name, price: $price,  isHeightSensitive: $isHeightSensitive,picture: $picture,priority: $priority, variations: $variations, unit: $unit, category: $category}){
        name
        price
        isAdminCreated
        pictureUrl
        isHeightSensitive
        variations{
            name
            price
        }
        unit
        category{
            id
        }
    }
}`;
const CREATE_ARTICLE_CATEGORY = gql` mutation($name: String!, $description: String!) {articleCategory_create(input: {name : $name, description : $description}){
    name
    id
    description
}}`;
const EDIT_ARTICLE_CATEGORY = gql` mutation($name: String!,$description: String!, $id: ID!) {articleCategory_edit(id: $id, input: {name : $name, description : $description}){
    id
    name
}}`;

const GET_ARTICLE_BY_ID = gql`query Article($id : ID!){
    article(id: $id){
        id
        reference
        name
        isDeletable
        isUsed
        price
        isAdminCreated
        priority
        type
        pictureUrl
        isHeightSensitive
        variations
        {
            id
            reference
            name
            isDeletable
            isUsed
            price
            isAdminCreated
            type
            variations{
                id
                reference
                name
                isDeletable
                isUsed
                price
                isAdminCreated
                type
            }
        }
        unit
        category{
            id
            name
            isDeletable
        }
    }
}`;
const ARTICLES_TO_CUSTOMIZE = gql`{
    articlesToPersonalize{
        id
        reference
        isPersonalized
        name
        isDeletable
        isUsed
        price
        isAdminCreated
        type
        pictureUrl
        priority
        adminPrice
        isHeightSensitive
        variations{
            id
            reference
            name
            isDeletable
            isUsed
            price
            isAdminCreated
            type
            pictureUrl
            priority
            adminPrice

        }
        unit
        category{
            id
            name
            description
            isDeletable
        }
    }
}`;
const EFFORTS_TO_CUSTOMIZE = gql` {efforts{
    id
    name
    isPersonalized
    adminPrice
    price
}
}`;


const EFFORTS_PERSONALIZE = gql`mutation($efforts: [EffortPersonalizationInput]!){efforts_personnalize(input: {efforts: $efforts}){
    id
    price
}}`;
const ARTICLES_BULK_EDIT = gql`mutation($input: [ArticleBulkEdit!]!){
    article_bulkEdit2(input: $input){
        id
        reference
        name
        isDeletable
        isPersonalized
        isUsed
        price
        isAdminCreated
        type
        pictureUrl
        priority
        adminPrice
        isHeightSensitive
        unit
    }
}`;
const ARTICLES_PERSONALIZE = gql`mutation($articles: [ArticlePersonalizationInput]!){articles_personnalize(input: {articles: $articles}){
    id
    price
}}`;
const HIDE_ARTICLES_PERSONALIZE_POPUP = gql`mutation{
    stopShowingPersonnalizePopup{
        id
        firstName
        lastName
        username
        email
        phoneNumber
        enabled
        roles
        isSuperAdmin
        emailChangementPending
        stopShowPersonalize
    }
}`;
const HIDE_TUTORIAL_POPUP = gql`mutation{
    stopShowingTutorialPopup{
        id
    }
}`;
const GET_BILLS = gql`query{
    billsByCurrent{
        id
        reference
        startPeriod
        endPeriod
        isPaid
        paymentDate
        type
        stripeInvoiceId
        company{
            id
            name
        }
        amount
    }
}`;
export const fetchArticles = () => {
    return client.query({ query: GET_ARTICLES });
};
export const useArticlesToCustomize = () => {
    const { data, loading, error } = useQuery(ARTICLES_TO_CUSTOMIZE);
    let articlesToCustomize: any = [];
    if (!loading && !error) {
        articlesToCustomize = data.articlesToPersonalize;
    }
    return {
        articlesToCustomize,
        loadingArticles: loading,
        error,
    };
};
// .filter((a: any) => !a.isPersonalized)
// .filter((a: any) => !notPersonalized || !a.isPersonalized)
export const useEfforts = () => {
    const { data, loading, error } = useQuery(EFFORTS_TO_CUSTOMIZE);
    let efforts: any = [];
    if (!loading && !error) {
        efforts = data.efforts;
    }
    return {
        efforts,
        loadingEfforts: loading,
        error,
    };
};

export const fetchArticleById = (id: any) => {
    return client.query({ query: GET_ARTICLE_BY_ID, variables: { id } });
};

export const fetchCategories = (refresh = false) => {
    return client.query({ query: GET_CATEGORIES });
};
export const useCategories: () => CategoriesReturnType = () => {
    const { data, loading, error } = useQuery(GET_CATEGORIES);
    let articleCategories: any = [];
    if (!loading && !error) {
        articleCategories = data.articleCategories;
    }
    return {
        articleCategories,
        loadingCategories: loading,
        error,
    };
};

export const useArticles: (flat?: any) => any = (flat = false) => {
    const { data, loading, error, refetch } = useQuery(GET_ARTICLES);

    const result = useMemo(() => {

        let articles: any = [];
        if (!loading && !error && data) {
            articles = data.articles;
        }

        let result: any[] = [];
        if (flat) {
            for (let articlesKey in articles) {
                if (!articles[articlesKey].variations || !articles[articlesKey].variations.length) {
                    result.push(articles[articlesKey]);
                } else {
                    articles[articlesKey].variations.forEach((variation: any) => {
                        if (!variation.variations || !variation.variations.length) {
                            result.push({
                                ...variation,
                                isHeightSensitive: articles[articlesKey].isHeightSensitive,
                                categoryId: articles[articlesKey].category.id,
                                unit: articles[articlesKey].unit,
                                pictureUrl: articles[articlesKey].pictureUrl,
                                category: articles[articlesKey].category,
                            });
                        } else {
                            result = [...result, ...variation.variations.map((v: any) => {
                                return {
                                    ...v,
                                    isHeightSensitive: articles[articlesKey].isHeightSensitive,
                                    pictureUrl: articles[articlesKey].pictureUrl,
                                    categoryId: articles[articlesKey].category.id,
                                    unit: articles[articlesKey].unit,
                                    category: articles[articlesKey].category,
                                };
                            })];
                        }
                    });
                }
            }
        } else {
            result = articles;
        }
        return result;
    }, [flat, data]);


    return {
        articles: result,
        loadingArticles: loading,
        refetch,
        error,
    };
};

export const deleteArticleById = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_ARTICLE_BY_ID }));
};
export const updateArticlesBulk = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: UPDATE_ARTICLES_ANY }));
};
export const updateArticlesBulk2 = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: { input: data }, mutation: ARTICLES_BULK_EDIT }));
};
export const deleteImage = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_ARTICLE_IMAGE_BY_ID }));
};
export const deleteArticleByIdFromWork = async (id: string, rowId: string) => {
    return clientWithErrors(client.mutate({ variables: { id, rowId }, mutation: DELETE_ROW_FROM_WORK_TYPE_BY_ID }));
};
export const addArticleToWorkType = async (articleId: string, id: string) => {
    return clientWithErrors(client.mutate({ variables: { id, articleId }, mutation: ADD_ARTICLES_TO_WORK_TYPE }));
};
export const effortsPersonalize = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: EFFORTS_PERSONALIZE }));
};
export const articlesPersonalize = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: ARTICLES_PERSONALIZE }));
};

export const hideArticlePersonalizePopup = async () => {
    return clientWithErrors(client.mutate({ variables: {}, mutation: HIDE_ARTICLES_PERSONALIZE_POPUP }));
};

export const hideTutorialPopup = async () => {
    return clientWithErrors(client.mutate({ variables: {}, mutation: HIDE_TUTORIAL_POPUP }));
};

export const deleteArticleCategoryById = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_ARTICLE_CATEGORY_BY_ID }));
};

export const createNewArticle = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: CREATE_ARTICLE }));
};
export const duplicateArticle = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: DUPLICATE_ARTICLE }));
};
export const editArticle = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: EDIT_ARTICLE }));
};
export const createNewCategory = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: CREATE_ARTICLE_CATEGORY }));
};

export const updateCategory = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: EDIT_ARTICLE_CATEGORY }));
};

export const uploadImage = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_ARTICLE_BY_ID }));
};
