// noinspection RequiredAttributes

import React, { useEffect, useState } from 'react';


import {
    Form,
    Input,
    Button,
    Button as BaseButton,
    Row,
    Col,
    Select,
    Space,
    notification,
    Divider,
    Typography, Switch, Checkbox, Upload,
    Tooltip, Modal,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dotToObject } from 'shared/services/Utility';
import { isAdmin } from 'shared/contexts/AuthContext';
import { QuestionOutlined, DeleteOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
    ConstantUnits, REGEX_DIGITS_3_DECIMAL_PLACES,
    REGEX_STRING_VERIFY,
} from 'shared/constants/AppConstants';

import {
    articlesPersonalize,
    createNewArticle,
    createNewCategory,
    deleteArticleById, duplicateArticle,
    editArticle,
    useCategories,
} from 'shared/services/articleService';

const { Text } = Typography;
const { confirm } = Modal;

interface Props {
    item?: any;
    onChange?: (fields: any[]) => void;
    setActiveTab?: (tab: string) => void;
    onCancel?: (refresh?: boolean) => void;
    loading?: boolean;
    mode: 'CREATE' | 'EDIT' | 'VIEW' | 'DUPLICATE';
}

export default function ArticleCreateModal({
                                               onCancel,
                                               item,
                                               mode,
                                           }: Props) {
    const { t } = useTranslation();
    const [duplicatingItem] = useState(false);
    const [duplicateItem, setDuplicateItem] = useState(false);
    const [showImageUploader, setShowImageUploader] = React.useState(true);
    const { articleCategories, loadingCategories } = useCategories();
    const history = useHistory();
    const fields: any = [];
    const [deletingItem, setDeletingItem] = useState(false);
    const [form] = Form.useForm();
    const units = ConstantUnits;
    const [enableCategoryCreate, setEnableCategoryCreate] = useState(false);
    const [image, setImage] = React.useState(null as any);
    const [imageUrl, setImageUrl] = React.useState(null as any);
    const [savingItem, setSavingItem] = React.useState(false);
    const initialValues = (item ? item : {}) as any;
    const [allowDisabled, setAllowDisabled] = useState(false);
    const [showVariationsSection, setShowVariationsSection] = useState(item && item.variations && item.variations.length);
    useEffect(() => {
        setAllowDisabled(!isAdmin() && item && item.isAdminCreated);
        form.setFieldsValue({ picture: item ? item.picture : '' });
        //@ts-ignore
    }, [item, form]);

    useEffect(() => {
        if (showVariationsSection)
            form.validateFields(['price']).then();
        //@ts-ignore
    }, [showVariationsSection, form]);
    return (<Form
        className="article-create-modal"
        size="large"
        form={form}
        fields={fields}
        requiredMark={mode !== 'VIEW'}
        initialValues={initialValues}


        onFinish={async (formValues) => {
            const reqObject = dotToObject(formValues);
            reqObject['variations'] = reqObject['variations'] ? reqObject['variations'] : [];
            if (reqObject['isHeightSensitive'] === '') {
                reqObject['isHeightSensitive'] = false;
            }

            if (isAdmin()) {
                reqObject['priority'] = !!reqObject['priority'];
            }
            if (reqObject['variations'].length) {
                reqObject['price'] = '';
            }
            reqObject['variations'].forEach((variation: any) => {
                const variationKeys = Object.keys(variation);
                variationKeys.forEach((k) => {
                    if (k !== 'name' && k !== 'price' && k !== 'variations') {
                        delete variation[k];
                    }
                });
                if (variation['variations'] && variation['variations'].length) {
                    variation['price'] = '';
                    variation['variations'].forEach((subVariation: any) => {
                        const subVariationKeys = Object.keys(subVariation);
                        subVariationKeys.forEach((k) => {
                            if (k !== 'name' && k !== 'price') {
                                delete subVariation[k];
                            }
                        });
                    });
                }
            });
            if (image) {
                reqObject['picture'] = image;
            }
            // @ts-ignore
            if (item && item.picture) {
                // @ts-ignore
                reqObject['picture'] = item.picture;
            }
            setSavingItem(true);
            if (enableCategoryCreate) {
                try {
                    const cat = await createNewCategory({
                        name: reqObject['categoryName'],
                        description: reqObject['categoryDescription'],
                    });
                    if (cat && cat.data && cat.data.articleCategory_create) {
                        reqObject['category'] = cat.data.articleCategory_create.id;
                    } else {
                        notification.error({
                            message: t('Error while creating new category'),
                        });
                        return;
                    }
                } catch (ee) {
                    notification.error({
                        message: t((ee as any)),
                    });
                    return;
                }
            }
            if (mode === 'CREATE') {
                createNewArticle(reqObject).then(() => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('article.notification.articleCreatedSuccess'),
                    });
                    if (onCancel) {
                        onCancel(true);
                    }
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingItem(false);
                });
            } else if (mode === 'DUPLICATE') {
                duplicateArticle({ pictureFromArticle: item.id, ...reqObject }).then((response) => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('article.notification.articleCreatedSuccess'),
                    });
                    if (response && response.data && response.data.article_create) {
                        history.push('/articles/' + response.data.article_create.id + '/view');
                        window.location.reload();
                    }
                    if (onCancel) {
                        onCancel(true);
                    }
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingItem(false);
                });
            } else if (item) {
                if (allowDisabled) {
                    const articlesToPersonalize = [];
                    if (!reqObject.variations || !reqObject.variations.length) {
                        articlesToPersonalize.push({ id: item.id, price: reqObject.price });
                    } else {
                        reqObject.variations.forEach((v: any, i: any) => {
                            if (v.variations && v.variations.length) {
                                v.variations.forEach((vInner: any, iInner: any) => {
                                    articlesToPersonalize.push({
                                        id: item.variations[i].variations[iInner].id,
                                        price: vInner.price,
                                    });
                                });
                            } else {
                                articlesToPersonalize.push({ id: item.variations[i].id, price: v.price });
                            }
                        });
                    }
                    try {

                        await articlesPersonalize({
                            articles: articlesToPersonalize,
                        });
                        notification.success({
                            message: t('genDict.success'),
                            description: t('article.notification.articleUpdatedSuccess'),
                        });
                    } catch (e) {
                        notification.error({
                            message: t(e as any),
                        });
                    } finally {
                        setSavingItem(false);
                    }
                } else {
                    editArticle({ ...reqObject, id: item.id }).then(() => {
                        notification.success({
                            message: t('genDict.success'),
                            description: t('article.notification.articleUpdatedSuccess'),
                        });
                        if (onCancel) {
                            onCancel(true);
                        }
                        history.push('/articles/' + item.id + '/view');
                    }).catch(e => {
                        notification.error({
                            message: t(e),
                        });
                    }).finally(() => {
                        setSavingItem(false);
                    });
                }
            }

        }}
        layout="vertical">
        <div style={{ padding: '10px 20px' }}>
            <>
                <Row gutter={16}>
                    <Col className="gutter-row" lg={8} md={24} sm={24} xs={24}>
                        <Form.Item
                            label={t('article.input.name.label')}
                            name="name"
                            key="name"
                            rules={[
                                { required: true, message: t('article.input.name.required') },
                                {
                                    pattern: REGEX_STRING_VERIFY,
                                    message: t('errorMessage.stringWrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW' || allowDisabled}/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" lg={8} md={24} sm={24} xs={24}>
                        <Form.Item
                            label={t('article.input.unit.label')}
                            name="unit"
                            rules={[
                                { required: true, message: t('article.input.unit.required') },
                            ]}
                        >
                            <Select
                                disabled={mode === 'VIEW' || allowDisabled}
                                placeholder={t('genDict.Select')}
                            >
                                {
                                    units.map((option: any) => {
                                        return <Select.Option value={option}>{option}</Select.Option>;
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" lg={8} md={24} sm={24} xs={24}>
                        <Form.Item
                            label={t('article.input.price.label')}
                            name="price"
                            key="price"
                            rules={[

                                {
                                    required: !showVariationsSection,
                                    message: t('article.input.price.required'),
                                },
                                // max 10 digits with max 4 decimal values
                                {
                                    pattern: !showVariationsSection ? REGEX_DIGITS_3_DECIMAL_PLACES : /.*/,
                                    message: t('article.input.price.validPattern'),
                                },
                            ]}
                        >
                            <Input
                                autoComplete={'off'}
                                disabled={mode === 'VIEW' || showVariationsSection}
                                suffix="€ HT"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {
                    mode === 'CREATE' && <Row gutter={16} style={{ marginTop: '7px', marginBottom: '7px' }}>
                        <Col span={24}>
                            <Form.Item
                                label={t('article.input.image.label')}
                            >
                                {
                                    imageUrl ?
                                        <div className={'__image-overlay-form'}>
                                            <img src={imageUrl} alt="avatar" style={{ width: '100px' }}/>
                                            <div className="__overlay">
                                                <DeleteOutlined onClick={() => {
                                                    setImageUrl(null);
                                                }}/>
                                            </div>
                                        </div> : showImageUploader && <Upload
                                        accept="image/x-png,image/jpg,image/jpeg"
                                        listType="picture"
                                        beforeUpload={(file) => {
                                            if (file && file.type.toLowerCase().indexOf('image') === -1) {
                                                notification.error({
                                                    message: t('article.input.image.invalidImage'),
                                                });
                                                setShowImageUploader(false);
                                                setTimeout(() => {
                                                    setShowImageUploader(true);
                                                });
                                                return false;
                                            }
                                            const reader = new FileReader();
                                            // eslint-disable-next-line
                                            const url = reader.readAsDataURL(file);
                                            reader.onloadend = function() {
                                                setImageUrl(reader.result);
                                            };
                                            setImage(file);
                                            return false;
                                        }}
                                        // @ts-ignore
                                        maxCount={1}
                                    >
                                        <Button
                                            className={'__button-grey __theme-text __medium roboto-medium'}
                                            style={{ fontWeight: 300 }}
                                            icon={
                                                <UploadOutlined/>}>{t('article.input.image.uploadText')}</Button>&nbsp;&nbsp;
                                        <Text className={'roboto-regular text-12 color-8c8c8c'}
                                              type="secondary">{t('article.input.image.uploadInfo')}</Text>
                                    </Upload>
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                }

                {!enableCategoryCreate && <Row gutter={16}>
                    {
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={t('article.input.category.label')}
                                name="category"
                                rules={[
                                    { required: !enableCategoryCreate, message: t('article.input.category.required') },
                                ]}
                            >
                                <Select
                                    showSearch

                                    filterOption={(inputValue, option) =>
                                        (option?.children as string | undefined)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    placeholder={t('genDict.SelectArticleCategory')}
                                    disabled={mode === 'VIEW' || loadingCategories || enableCategoryCreate || allowDisabled}
                                >
                                    {
                                        articleCategories.map((option: any) => {
                                            return <Select.Option value={option.id}>{option.name}</Select.Option>;
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                    }

                    <Col md={12} sm={24} xs={24}>
                        {
                            mode !== 'VIEW' && !allowDisabled && <div className={'margin-41-top-from-sm'}>
                                {
                                    !enableCategoryCreate &&
                                    <div className={'flat-button primary p-16'} style={{ fontSize: '16px !important' }}
                                         onClick={(e) => {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             setEnableCategoryCreate(true);
                                         }
                                         }>
                                        {t('article.input.category.addCategory')}
                                    </div>
                                }
                            </div>
                        }
                    </Col>
                </Row>
                }
                {
                    enableCategoryCreate && <Row gutter={16}>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                label={t('article.input.category.newCategotyName')}
                                name={'categoryName'}
                                rules={[
                                    { required: enableCategoryCreate, message: t('article.input.category.required') },
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <Form.Item
                                label={t('article.input.category.newCategotyDescription')}
                                name={'categoryDescription'}
                                rules={[
                                    {
                                        pattern: REGEX_STRING_VERIFY,
                                        message: t('errorMessage.stringWrongFormat'),
                                    },
                                ]}>
                                <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} sm={24} xs={24}>
                            <div className={'margin-41-top-from-sm'}>
                                {
                                    <div className={'flat-button danger'} style={{ fontSize: '16px' }} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEnableCategoryCreate(false);
                                    }
                                    }>
                                        {t('article.input.category.removeCategory')}
                                    </div>
                                }
                            </div>
                        </Col>
                    </Row>
                }
                <Row gutter={16}>
                    <Col span={24}>
                        <Space direction={'horizontal'}>
                            <Form.Item
                                valuePropName="checked"
                                name={'isHeightSensitive'}>
                                <Checkbox disabled={allowDisabled}>
            <span
                className={'text-16 color-262626 __font-regular'}> {t('article.input.heightSensitive.label')}</span>
                                    <Tooltip title={t('article.input.heightSensitive.tooltip')}>
                                        <QuestionOutlined className={'__help-tooltip table-icons round primary'}
                                                          style={{ verticalAlign: 'text-bottom' }}/>
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>&nbsp;&nbsp;
                            {
                                isAdmin() && <Form.Item
                                    valuePropName="checked"
                                    name={'priority'}>
                                    <Checkbox>
            <span
                className={'text-16 color-262626 __font-regular'}> {t('article.input.isPrioritize.label')}</span>
                                        <Tooltip title={t('article.input.isPrioritize.tooltip')}>
                                            <QuestionOutlined className={'__help-tooltip table-icons round primary'}
                                                              style={{ verticalAlign: 'text-bottom' }}/>
                                        </Tooltip>
                                    </Checkbox>
                                </Form.Item>
                            }
                        </Space>

                    </Col>
                </Row>
                <Divider style={{ marginTop: '4px' }}/>
                {<Form.List name="variations">
                    {(fields, { add, remove }) => {

                        return <>
                            <>
                                <div style={{ marginBottom: '20px' }}>
                                    <Switch disabled={allowDisabled}
                                            checked={showVariationsSection}
                                            onChange={(e) => {
                                                if (e) {
                                                    add({ checked: true, name: '', variations: [] });
                                                }
                                                setShowVariationsSection(e);

                                            }}/> &nbsp;
                                    <span
                                        className={'text-16 color-262626 __font-regular'}> {t('article.input.variation.articleWithVariations')}</span>
                                    <Tooltip title={t('article.input.variation.tooltip')}>
                                        <QuestionOutlined
                                            className={'__help-tooltip table-icons round primary'}
                                            style={{ verticalAlign: 'text-bottom' }}/>
                                    </Tooltip>
                                </div>
                                {fields.map((field: any) => {
                                    if (!showVariationsSection) {
                                        remove(field.name);
                                    }
                                    return <>
                                        <Row gutter={16} className={'p-2'}>
                                            <Col lg={1} md={2} sm={3} xs={3} className={'padding-42-top-from-sm'}>
                                                <Checkbox
                                                    disabled={allowDisabled}
                                                    checked={true}
                                                    onChange={() => {
                                                        remove(field.name);
                                                        if (fields.length === 1) {
                                                            setShowVariationsSection(false);
                                                            form.setFieldsValue({ variations: [] });
                                                        }
                                                    }}/>
                                            </Col>

                                            <Col lg={11} md={22} sm={21} xs={21}>
                                                <Form.Item
                                                    label={t('article.input.name.label')}
                                                    name={[field.name, 'name']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('article.input.name.required'),
                                                        },
                                                        {
                                                            pattern: REGEX_STRING_VERIFY,
                                                            message: t('errorMessage.stringWrongFormat'),
                                                        },
                                                    ]}>
                                                    <Input autoComplete={'off'}
                                                           disabled={mode === 'VIEW' || allowDisabled}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={0} md={2} sm={3} xs={3} className={'padding-42-top-from-sm'}>
                                            </Col>
                                            <Col lg={6} md={22} sm={21} xs={21}>
                                                <Form.Item
                                                    label={t('article.input.price.label')}
                                                    name={[field.name, 'price']}
                                                    rules={[
                                                        {
                                                            required: !(form.getFieldValue(['variations', field.name, 'variations']) && form.getFieldValue(['variations', field.name, 'variations']).length),
                                                            message: t('article.input.price.required'),
                                                        },
                                                        {
                                                            pattern: !(form.getFieldValue(['variations', field.name, 'variations']) && form.getFieldValue(['variations', field.name, 'variations']).length) ? REGEX_DIGITS_3_DECIMAL_PLACES : /.*/,
                                                            message: t('article.input.price.validPattern'),
                                                        },
                                                    ]}>
                                                    <Input
                                                        disabled={mode === 'VIEW' || (form.getFieldValue(['variations', field.name, 'variations']) && form.getFieldValue(['variations', field.name, 'variations']).length)}
                                                        suffix="€ HT"
                                                    />

                                                </Form.Item>
                                            </Col>
                                            <Col lg={0} md={2} sm={3} xs={3}>
                                            </Col>
                                            <Form.List name={[field.name, 'variations']}>

                                                {(variations, { add: addInner, remove: removeInner }) => {

                                                    return (
                                                        <>
                                                            {!allowDisabled && !(form.getFieldValue(['variations', field.name, 'variations']) && form.getFieldValue(['variations', field.name, 'variations']).length) ?
                                                                <Col lg={6} md={21} sm={21} xs={21}
                                                                     className={'padding-41-top-from-sm'}>

                                                                    <div>
                                                                        <Checkbox onChange={() => {
                                                                            addInner();
                                                                        }}
                                                                        ><span
                                                                            className={'text-16 text-primary text-medium'}>{t('article.input.variation.underVariation')}</span></Checkbox>
                                                                    </div>

                                                                </Col> : <></>}
                                                            <Col span={24}>
                                                                {variations.map((fieldInner: any, index2: any) => {
                                                                    return <Row gutter={16} className={'p-2'}
                                                                                style={{ marginBottom: '0' }}>
                                                                        <Col md={1} sm={0} xs={0}
                                                                             style={{ paddingTop: '34px' }}>
                                                                        </Col>
                                                                        <Col md={23} sm={24} xs={24}>
                                                                            <Row gutter={16} align={'middle'} style={{
                                                                                padding: '15px',
                                                                                marginLeft: '0',
                                                                                backgroundColor: '#F5F5F5',
                                                                            }}>
                                                                                <Col lg={1} md={2} sm={3} xs={3}
                                                                                     style={{ paddingTop: '10px' }}>
                                                                                    <Checkbox checked={true}
                                                                                              disabled={allowDisabled}
                                                                                              onChange={() => {
                                                                                                  removeInner(fieldInner.name);
                                                                                              }}/>
                                                                                </Col>
                                                                                <Col lg={9} md={22} sm={21} xs={21}>
                                                                                    <Form.Item
                                                                                        name={[fieldInner.name, 'name']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: t('article.input.variation.name.required'),
                                                                                            },
                                                                                            {
                                                                                                pattern: REGEX_STRING_VERIFY,
                                                                                                message: t('errorMessage.stringWrongFormat'),
                                                                                            },
                                                                                        ]}>
                                                                                        <Input
                                                                                            placeholder={t('article.input.variation.name.label') + '*'}
                                                                                            className={'__input-small with-input'}
                                                                                            size={'small'}
                                                                                            autoComplete={'off'}
                                                                                            disabled={allowDisabled || mode === 'VIEW'}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col lg={0} md={2} sm={3} xs={3}/>
                                                                                <Col lg={6} md={22} sm={21} xs={21}>
                                                                                    <Form.Item
                                                                                        name={[fieldInner.name, 'price']}
                                                                                        rules={[
                                                                                            {
                                                                                                required: true,
                                                                                                message: t('article.input.price.required'),
                                                                                            },
                                                                                            {
                                                                                                pattern: REGEX_DIGITS_3_DECIMAL_PLACES,
                                                                                                message: t('article.input.price.validPattern'),
                                                                                            },

                                                                                        ]}>
                                                                                        <Input
                                                                                            placeholder={t('article.input.variation.price.label') + '*'}
                                                                                            className={'__input-small'}
                                                                                            size={'small'}
                                                                                            autoComplete={'off'}
                                                                                            disabled={mode === 'VIEW'}
                                                                                            suffix="€ HT"
                                                                                        />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col lg={0} md={22} sm={3} xs={3}/>
                                                                                <Col lg={8} md={22} sm={21} xs={21}>
                                                                                    {
                                                                                        index2 === (variations.length - 1) && !allowDisabled &&
                                                                                        <div
                                                                                            className={'flat-button primary p-14'}
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                addInner();
                                                                                            }
                                                                                            }>
                                                                                            {t('article.input.variation.addUnderVariation')}
                                                                                        </div>
                                                                                    }
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>;
                                                                })}
                                                            </Col>
                                                        </>
                                                    );
                                                }}
                                            </Form.List>
                                        </Row>

                                    </>;
                                })}
                            </>
                            {
                                showVariationsSection && !allowDisabled ?
                                    <div className={'flat-button primary'} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        add({ checked: true, name: '', variations: [] });
                                    }
                                    }>
                                        {t('article.input.variation.addAnotherVariation')}
                                    </div>
                                    : <></>
                            }
                        </>;
                    }}
                </Form.List>
                }
            </>

        </div>
        {
            duplicateItem && <Modal
                title={t('article.duplicateArticle')}
                visible={duplicateItem}
                maskClosable={false}
                width={1000}
                afterClose={() => {
                    setDuplicateItem(false);
                }}
                onCancel={() => {
                    setDuplicateItem(false);
                }}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <ArticleCreateModal
                    // @ts-ignore
                    item={{ ...item, name: item.name + ' - ' + t('genDict.copy_noun') }}
                    onCancel={() => {
                        setDuplicateItem(false);
                    }} mode={'DUPLICATE'}/>
            </Modal>
        }
        {
            mode !== 'VIEW' && <Row>
                <Col span={24}>
                    <Row gutter={20} className={'__form-footer-parent-row'}>
                        <Col flex={'auto'} style={{ paddingTop: '10px' }}>
                            {
                                !(mode === 'CREATE' || mode === 'DUPLICATE') && item.isDeletable &&
                                <div className={'flat-button primary'} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    confirm({
                                        icon: <ExclamationCircleOutlined/>,
                                        title: t('article.confirmation.msg'),
                                        okText: t('article.button.deleteArticle'),
                                        cancelText: t('genDict.Cancel'),
                                        content: t('article.confirmation.deleteArticle'),// TODO
                                        okButtonProps: { loading: deletingItem, danger: true },
                                        onOk: async () => {
                                            setDeletingItem(true);
                                            deleteArticleById(initialValues.id).then(() => {
                                                notification.success({
                                                    message: t('genDict.success'),
                                                    description: t('article.notification.articleDeletedSuccess'),
                                                });
                                                history.push('/articles');
                                            }).catch(e => {
                                                notification.error({
                                                    message: t(e),
                                                });
                                            }).finally(() => {
                                                setDeletingItem(false);
                                            });
                                        },
                                    });
                                }
                                }>
                                    {t('article.button.deleteArticle')}
                                </div>
                            }
                        </Col>
                        <Col className={'__form-footer-row'}>
                            <Row gutter={20}>
                                <Col>
                                    {
                                        (mode === 'CREATE' || mode === 'DUPLICATE') ? <Form.Item>
                                            <Button
                                                type="default"
                                                onClick={async () => {
                                                    if (onCancel) {
                                                        onCancel();
                                                    }

                                                }}
                                                disabled={savingItem}
                                                shape="round"
                                                size={'large'}
                                                className="submit"
                                            >
                                                {t('genDict.Cancel')}
                                            </Button>
                                        </Form.Item> : (mode === 'EDIT' ? <Form.Item>
                                            <BaseButton type="primary"
                                                        shape="round"
                                                        className={'__button-inverted primary'}
                                                        size={'large'} onClick={() => {
                                                if (duplicatingItem) {
                                                    return;
                                                }
                                                setDuplicateItem(true);
                                            }}>
                                                {t('article.button.duplicateArticle')}
                                            </BaseButton>
                                        </Form.Item> : <></>)
                                    }

                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={savingItem}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {(mode === 'CREATE' || mode === 'DUPLICATE') ? t('article.button.addArticle') : t('article.button.saveArticle')}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        }
    </Form>);
}
