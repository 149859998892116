import React, { useState } from 'react';


import { Form, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EyeFilled, EyeInvisibleFilled } from '@ant-design/icons';
import { REGEX_EMAIL_VERIFY } from 'shared/constants/AppConstants';

interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onForgetPassword: (email: string) => void;
    loading: boolean;
}

export default function ForgetPasswordForm({
                                               fields,
                                               onChange,
                                               onForgetPassword,
                                               loading,
                                           }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [form] = Form.useForm();
    const [visibility, setVisibility] = useState(false);

    function handleSubmit(values: any) {
        if (onForgetPassword) {
            const { email } = values;
            onForgetPassword(email);
        }
    }

    return (
        <Form
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
            layout="vertical"
            requiredMark={false}>
            <Form.Item
                label={t('forgetPassword.input.emailAddress.label')}
                name="email"
                rules={[
                    { required: true, message: t('forgetPassword.input.emailAddress.required') },
                    {
                        pattern: REGEX_EMAIL_VERIFY,
                        message: t('forgetPassword.input.emailAddress.wrongFormat'),
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={() => true}>
                {(form) => 
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{width: 'auto'}}
                        shape="round"
                        size={'large'}
                        className="submit"
                        disabled={!form.isFieldsTouched() || form.getFieldError('email')?.length > 0}
                    >
                        {t('forgetPassword.button.changeMyPwd')}
                    </Button>
                }
            </Form.Item>
        </Form>
    );
}
