import * as React from 'react';
import { Button as BaseButton, Tooltip } from 'antd';
import { EyeFilled } from '@ant-design/icons';

type Props = {
    onClick: () => void;
    style?: any;
    tooltipText?: string;
}

const ViewButton: React.FunctionComponent<Props> = ({ tooltipText, style, onClick }) => {
    return (
        <Tooltip placement="top" title={tooltipText}>
            <EyeFilled
                className={'_hover-primary-icon'}
                style={style || {}}
                onClick={onClick}
            />
        </Tooltip>

    );
};
export default ViewButton;
