import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`
mutation($workSiteId: ID!, $documentId: ID!, $receiverEmail: String!, $subject: String!, $message: String!){
  workSite_sendDocument(workSiteId: $workSiteId, documentId: $documentId, receiverEmail: $receiverEmail, subject: $subject, message: $message)
}`;
const useSendDocument = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: MUTATION }));
};

export default useSendDocument;
