import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

type ReturnType = {
  refetch: any;
  currentUser: any;
  loading: boolean;
  error: any;
};

const GET_CURRENT_USER = gql`{
  currentUser{
    id
    firstName
    lastName
    username
    email
    reference
    phoneNumber
    enabled
    roles
    isSuperAdmin
    company{
      id
      footer
      displayBankInformationOnQuote
      name
      address{
        address
        additional
        postalCode
        city
      }
      billingAddress{
        address
        additional
        postalCode
        city
        firstName
        lastName
        company
      }
      bankName
      accountName
      IBAN
      BIC
      displayBankInformationOnQuote
      hideLogo
      pictureBackgroundUrl
      pictureUrl
      footer
      subscription{
        type
        status
        nextPaymentAt
        autoRenewalAt
        price
      }
      purchaseOrderTemplate{
        object
        template
      }
      quoteTemplate{
        object
        template
      }
    }
    emailChangementPending
    newEmail
    address{
      address
      additional
      postalCode
      city
    }
    stopShowPersonalize
    stopShowTutorial
    hasArticlesToPersonalize
  }
}
`;

const useCurrentUser: () => ReturnType = () => {
  const { data, loading, error, refetch } = useQuery(GET_CURRENT_USER);
  let currentUser = null;
  if (!loading && !error) {
    currentUser = data.currentUser;
  }
  return {
    currentUser,
    loading,
    refetch,
    error,
  };
};
export default useCurrentUser;
