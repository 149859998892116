import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Table, Modal, Tooltip, Typography, Row, Col, Button, Input, InputNumber, Space, Alert } from 'antd';

import {
    DIGITS_TO_SHOW_AFTER_DOT,
	THREE_DIGITS_TO_SHOW_AFTER_DOT,
    MAX_INPUT_NUMBER,
    MIN_INPUT_NUMBER,
    STEP_INCREASE,
} from '../../../../constants/AppConstants';

import './index.scss';
// @ts-ignore
import moment from 'moment';
import useWorkSiteOrderArticles from '../../../../hooks/work-sites/useWorkSiteOrderArticles';
import { useEffect, useState } from 'react';
import { getDefaultPageSize, paginationParams } from '../../../../services/Utility';

const { Text } = Typography;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

type Props = {
    onSubmit?: any,
    workSite?: any,
    onCancel?: any,
}

const ArticleSelection: React.FunctionComponent<Props> = ({ workSite, onSubmit, onCancel }) => {
    const { t } = useTranslation();

    const { items } = useWorkSiteOrderArticles(workSite.id);
    const [showAlert, setShowAlert] = useState(false);
    const [articles, setArticles] = useState([] as any[]);
    useEffect(() => {
        setArticles(items);
    }, [items]);
    const columns = [
        {
            title: <span
                style={{ paddingLeft: '10px' }}>{t('workSite.purchaseOrders.addArticle.listTitle.name')}</span>,
            render: (a: any) => (
                <span style={{ paddingLeft: '10px' }}>{a.name}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.name, b.name),
        },
        {
            title: t('workSite.purchaseOrders.addArticle.listTitle.length'),
            render: (a: any) => (
                <span>{a.length}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.length, b.length),
        },

        {
            width: '200px',
            className: 'right-padded-table-items',
            title: <span
                style={{ paddingRight: '10px' }}>{t('workSite.purchaseOrders.addArticle.listTitle.quantity')}</span>,
            render: (a: any) => (
                <div
                    style={{ minWidth: '100px', marginRight: '10px' }}
                >{<InputNumber
                    value={a.quantity}
                    step={STEP_INCREASE}
                    min={MIN_INPUT_NUMBER}
                    max={MAX_INPUT_NUMBER}
                    precision={THREE_DIGITS_TO_SHOW_AFTER_DOT}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    className={'__input-small text-right'} size={'small'}
                    onChange={(value) => {
                        a.quantity = parseFloat(parseFloat((value || 0.0) + '').toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT));
                        setArticles(JSON.parse(JSON.stringify(articles)));
                    }}
                />}</div>
            ),
        },
        {
            title: t('workSite.purchaseOrders.addArticle.listTitle.unit'),
            render: (a: any) => (
                <span>{a.unit}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.unit, b.unit),
        },
    ];

    return (
        <Modal
            title={t('workSite.purchaseOrders.addArticle.title')}
            visible={true}
            width={1000}
            maskClosable={false}
            afterClose={
                onCancel
            }
            onCancel={onCancel}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}>
            <div className={'__table-pagination-padded'}>

                <Table
                    className={'__purchaseOrders-table-articleSelection'}
                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                    locale={{
                        emptyText: (
                            <Space style={{ padding: '10px' }}>
                                <Text
                                    className={'primary-black'}>{t('workSite.purchaseOrders.table.emptyMessage')}</Text>
                            </Space>
                        ),
                    }}
					showSorterTooltip ={false}
                    pagination={{
                        showSizeChanger: true,
                        onShowSizeChange: (currentPage, pageSize) => {
                            paginationParams(pageSize);
                        },
                        defaultPageSize: getDefaultPageSize(),
                    }}
                    // @ts-ignore
                    columns={columns}
                    dataSource={articles}
                >
                </Table>
            </div>
            <Row>
                <Col span={24}>
                    {
                        showAlert && <Alert
                            message={t('workSite.purchaseOrders.addArticle.atleatOneArticleWithQuantityRequired')}
                            type="error"
                            closable
                            onClose={() => setShowAlert(false)}
                        />
                    }
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Space direction={'horizontal'} className={'__form-footer-parent'}>
                        <div/>

                        <Space direction={'horizontal'} size={20} className={'__form-footer'}>
                            <Button
                                type="default"
                                onClick={async () => {
                                    onCancel();
                                }}
                                shape="round"
                                size={'large'}
                                className="submit">
                                {t('workSite.purchaseOrders.addArticle.button.cancel')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={async () => {
                                    setShowAlert(false);
                                    const hasQuantity = articles.filter((a) => parseFloat(a.quantity));
                                    if (!hasQuantity.length) {
                                        setShowAlert(true);
                                        return;
                                    }
                                    onSubmit(hasQuantity);
                                }}
                                shape="round"
                                size={'large'}
                                className="submit">
                                {t('workSite.purchaseOrders.addArticle.button.next')}
                            </Button>
                        </Space>
                    </Space>
                </Col>
            </Row>
        </Modal>
    );
};
export default ArticleSelection;
