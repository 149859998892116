import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import {
    Button,
    Col,
    Image,
    InputNumber,
    Row,
    Space,
    Typography,
} from 'antd';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { InfoCircleFilled } from '@ant-design/icons';


import './index.scss';
import { useEffect, useState } from 'react';
import { articlesPersonalize, useArticlesToCustomize } from 'shared/services/articleService';
import {
    ConstantImageData,
    THREE_DIGITS_TO_SHOW_AFTER_DOT,
    MAX_INPUT_NUMBER,
    MIN_INPUT_NUMBER,
    STEP_INCREASE,
} from 'shared/constants/AppConstants';

const { Text } = Typography;

type Props = {
    onChange?: Function
}

const CustomizePrice: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { articlesToCustomize } = useArticlesToCustomize();
    const [articles, setArticles] = useState([] as any[]);
    const [saving, setSaving] = useState(false);
    const refresh = () => {
        if (articlesToCustomize.length) {
            const a = [...articlesToCustomize.filter((a: any) => !a.isPersonalized).map((e: any) => {
                return { ...e, contentType: 'articles' };
            })];
            setArticles(a);
        }
    };
    useEffect(refresh, [articlesToCustomize]);
    const columns = [
        {
            title: t('customizeArticles.listTitle.image'),
            render: (a: any) => (
                <Image
                    width={'40px'}
                    height={'40px'}
                    src={a.pictureUrl || ConstantImageData}
                    fallback={ConstantImageData}
                />),
        },
        {
            title: t('customizeArticles.listTitle.name'),
            dataIndex: 'name',
        },
        {
            title: t('customizeArticles.listTitle.category'),
            render: (a: any) => (<span>{a.category ? a.category.name : ''}</span>),
        },
        {
            title: t('customizeArticles.listTitle.unit'),
            render: (a: any) => (<span>{a.unit || ''}</span>),
        },
        {
            title: t('customizeArticles.listTitle.price'),
            render: (a: any, data: any, index: number) => (
                <Space align={'center'} className={'__theme-small-input-wrapper'}>
                    <div>
                        <InputNumber
                            value={a.price}
                            step={STEP_INCREASE}
                            min={MIN_INPUT_NUMBER}
                            max={MAX_INPUT_NUMBER}
                            precision={THREE_DIGITS_TO_SHOW_AFTER_DOT}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            className={'text-right __input-height-35'}
                            style={{ width: '120px', height: '35px !important' }}
                            onChange={(e) => {
                                articles[index].price = e;
                                setArticles(JSON.parse(JSON.stringify(articles)));
                            }
                            }
                        />
                    </div>
                    <div style={{marginTop: '-3px'}}>
                        {a.contentType === 'efforts' && <>
                            <Tooltip title={t('customizeArticles.notification.exclamation')}>
                                <InfoCircleFilled
                                    className={'icon-disabled icon-exclamation'} size={50}/>
                            </Tooltip>
                        </>}
                    </div>
                </Space>
            ),
        },
    ];
    return (
        <>
            <ListView
                pageTitle={t('genDict.page-title', { title: t('pageTitles.customizePrice') })}
                title={t('customizeArticles.title')}
                searchBar={
                    (<div
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p className="" style={{
                            width: '100%',
                            display: 'flex',
                            marginBottom: 0,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                            <div className={'__customize-prize-sub-title'}>
                                {t('customizeArticles.customizePriceSubDescription')}</div>
                            <span className="__button">
                        </span>
                        </p>
                    </div>)
                }
            >
                <div style={{ backgroundColor: 'white' }}>

                    <Table
                        locale={{
                            emptyText: (
                                <Space style={{ padding: '10px' }}>
                                    <Text className={'primary-black'}>
                                        {t('customizeArticles.emptyTableMessage')}
                                    </Text>
                                </Space>
                            ),
                        }}
						showSorterTooltip ={false}
                        pagination={{
                            //showSizeChanger: true,
                            //defaultPageSize: 20,
                            // Set this value in pagiation to remove pagination
                            hideOnSinglePage: true,
                            pageSize: 20000,
                        }}
                        columns={columns}
                        dataSource={articles}
                    >
                    </Table>
                    <Row>
                        <Col span={24}>
                            <Space direction={'horizontal'} className={'__form-footer-parent'}>
                                <div/>
                                <Space direction={'horizontal'} size={20} className={'__form-footer'}
                                       style={{ paddingBottom: '20px' }}>
                                    <Button
                                        type="default"
                                        onClick={async () => {
                                            history.goBack();
                                        }}
                                        disabled={saving}
                                        shape="round"
                                        size={'large'}
                                        className="submit"
                                    >
                                        {t('genDict.Cancel')}
                                    </Button>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={saving}
                                        shape="round"
                                        size={'large'}
                                        onClick={async () => {
                                            setSaving(true);
                                            try {
                                                // await effortsPersonalize({
                                                //     efforts: articles.filter((e: any) => {
                                                //         return e.contentType === 'efforts';
                                                //     }).map((e: any) => {
                                                //         return { id: e.id, price: e.price };
                                                //     }),
                                                // });
                                                await articlesPersonalize({
                                                    articles: articles.filter((e: any) => {
                                                        return e.contentType !== 'efforts';
                                                    }).map((e: any) => {
                                                        return { id: e.id, price: e.price };
                                                    }),
                                                });
                                                notification.success({
                                                    message: t('genDict.success'),
                                                    description: t('customizeArticles.notification.updateSuccess'),
                                                });
                                            } catch (e) {
                                                notification.error({
                                                    message: t('customizeArticles.notification.errorWhileSaving'),
                                                });
                                            } finally {
                                                setSaving(false);
                                            }
                                        }}
                                        className="submit"
                                    >
                                        {t('customizeArticles.button.save')}
                                    </Button>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </div>
            </ListView>
        </>
    );
};
export default CustomizePrice;
