import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Space } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './LoaderOverlay.scss';
import { useTranslation } from 'react-i18next';
import {
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { logout } from 'shared/contexts/AuthContext';
import Parameters from 'shared/parameters';

// @ts-ignore
const TimeoutDurationInSeconds = Parameters.TimeoutDurationInSeconds;
const TimeoutDurationNotificationInSeconds = Parameters.TimeoutDurationNotificationInSeconds;
const TimeoutDurationCheckInSeconds = Parameters.TimeoutDurationCheckInSeconds;

export default function ConnectionTimeoutComponent() {
    const { t } = useTranslation();

    const [showPopup, setShowPopup] = useState(false);
    const history = useHistory();
    const handleCancel = () => {
        setShowPopup(false);
        logout();
    };
    const [subscription, setSubscription] = useState(null as any);
    const startTimeout = () => {
        if (subscription) {
            clearTimeout(subscription);
        }
        setSubscription(setTimeout(() => {
            let authToken: any = localStorage.getItem('auth-token');
            let showPopupLocal: any = localStorage.getItem('show-popup');
            if (authToken) {
                authToken = JSON.parse(authToken);
                const date = new Date(authToken.updatedAt || authToken.createdAt);
                if (showPopupLocal !== 'true' && TimeoutDurationNotificationInSeconds < ((new Date().getTime() - date.getTime()) / 1000)) {
                    setShowPopup(true);
                    localStorage.setItem('show-popup', 'true');
                }
                if (TimeoutDurationInSeconds < ((new Date().getTime() - date.getTime()) / 1000)) {
                    setShowPopup(false);
                    logout();
                }
            }
            startTimeout();
        }, TimeoutDurationCheckInSeconds * 1000));
    };
    useEffect(
        () => {
            localStorage.setItem('show-popup', 'false');
            startTimeout();
            return () => {
                if (subscription)
                    clearTimeout(subscription);
            };
        },
        [],
    );
    return (
        <div className={`__page-custom-timeout ${showPopup && 'active'}`}>
            <Modal
                visible={showPopup}
                width={588}
                maskClosable={false}
                onCancel={() => {
                    handleCancel();
                }}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <div className="__connection-timeout-popup-body-wrapper">

                    <Space size={20} align={'start'}>
                        <div className="__icon">
                            <ExclamationCircleOutlined/>
                        </div>
                        <div className="__text-section">
                            <div className="__title">
                                {
                                    t('connectionTimeout.title')
                                }
                            </div>
                            <div className="__sub-title">
                                {
                                    t('connectionTimeout.subTitle')
                                }
                            </div>
                        </div>
                    </Space>
                </div>
                <Row>
                    <Col span={24}>
                        <Row gutter={20} align={'middle'} className={'__form-footer-parent-row'}>
                            <Col flex={'auto'}/>
                            <Col className={'__form-footer-row'}>
                                <Row gutter={20}>
                                    <Col>
                                        <Button
                                            type="default"
                                            onClick={async () => {
                                                handleCancel();
                                            }}
                                            disabled={false}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {t('connectionTimeout.button.logout')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={false}
                                            onClick={() => {
                                                let authToken: any = localStorage.getItem('auth-token');
                                                if (authToken) {
                                                    authToken = JSON.parse(authToken);
                                                    authToken.updatedAt = new Date();
                                                    localStorage.setItem('auth-token', JSON.stringify(authToken));
                                                    setShowPopup(false);
                                                    localStorage.setItem('show-popup', 'false');
                                                    startTimeout();
                                                }
                                            }}
                                            shape="round"
                                            size={'large'}
                                            className="submit">
                                            {t('connectionTimeout.button.continue')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}
