const GlobalContextReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'UPDATE_BACK_LINK':
            return {
                ...state,
                backLink: action.payload,
            };
        default:
            return state;
    }
};

export default GlobalContextReducer;
