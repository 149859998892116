import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
  Table,
  notification,
  Modal,
  Button as BaseButton,
  Tooltip,
  Image,
  Typography,
  InputNumber,
  Row,
  Col,
  Button,
  Input,
  Space,
} from 'antd';
import {
  ExclamationCircleOutlined,

} from '@ant-design/icons';

import {
  THREE_DIGITS_TO_SHOW_AFTER_DOT,
  DIGITS_TO_SHOW_AFTER_DOT,
  MAX_INPUT_NUMBER,
  MIN_INPUT_NUMBER,
  STEP_INCREASE,
} from '../../../../constants/AppConstants';

import { Link } from 'react-router-dom';
import './index.scss';
import { useEffect, useState } from 'react';
import { Route_WorkTypesView } from '../../../../constants/Routes.constants';
import useWorkTypes from '../../../../hooks/work-types/useWorkTypes';
import ListView from '../../../../components/layout/ListView';
import { personalizeArticlePrice, personalizeEffortPrice } from '../../../../services/workService';
import { ConstantImageData } from '../../../../constants/AppConstants';
import { getDefaultPageSize, paginationParams } from '../../../../services/Utility';
import * as _ from 'lodash';

const { Text } = Typography;


function sortAlpha(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

type Props = {
  onSubmit?: any,
  workSite?: any,
  fromEffort?: boolean,
  articles: any[],
  workTypeNames?: any[],
  efforts?: any[],
  onCancel?: any,
}

const ArticlesToPersonalize: React.FunctionComponent<Props> = ({ workTypeNames, fromEffort, efforts, articles, onSubmit, onCancel
}) =>
{
  const { t } = useTranslation();

  const [addingArticlesToWork, setAddingArticlesToWork] = useState(false);
  const [articlesToP, setArticlesToP] = useState(JSON.parse(JSON.stringify(articles)));
  const [workTypes, setWorkTypes] = useState([] as any);
  useEffect(() => {
    let ar: any = [];
    articles.forEach((a: any) => {
      if (ar.indexOf(a.workTypeName) === -1) {
        ar.push(a.workTypeName);
      }
    });
    setWorkTypes(ar);
  }, [articles]);
  const columns = [
    {
      title: <span
        style={{ paddingLeft: '10px' }}>{t('workSite.workTypes.workType.articleToPersonalize.listTitle.reference')}</span>,
      render: (a: any) => (
        <Link style={{ paddingLeft: '10px' }} to={Route_WorkTypesView(a)}
              className={'__theme-text __medium'}>{a.newReference}</Link>),
      sorter: (a: any, b: any) => sortAlpha(a.newReference, b.newReference),
    },
    {
      title: t('workSite.workTypes.workType.articleToPersonalize.listTitle.image'),
      render: (a: any) => (<Image
        width={'40px'}
        height={'40px'}
        src={a.i.pictureUrl || ConstantImageData}
        fallback={ConstantImageData}
      />),
    },
    {
      title: t('workSite.workTypes.workType.articleToPersonalize.listTitle.name'),
      render: (a: any) => (
        <Link to={Route_WorkTypesView(a)} className={'__theme-text __medium'}>{a.newName}</Link>),
      sorter: (a: any, b: any) => sortAlpha(a.newName, b.newName),
    },
    {
      title: <div className={'text-right'}>
        {t('workSite.workTypes.workType.articleToPersonalize.listTitle.unit')}
      </div>,
      render: (a: any) => (
        <div className={'text-right'}>
          {a.i.unit}
        </div>),
    },
    {
      title: t('workSite.workTypes.workType.articleToPersonalize.listTitle.price'),
      render: (a: any, values: any, index: number) => {
        return <InputNumber
          value={a.unitPrice}
          step={STEP_INCREASE}
          min={MIN_INPUT_NUMBER}
          max={MAX_INPUT_NUMBER}
          precision={fromEffort ? DIGITS_TO_SHOW_AFTER_DOT : THREE_DIGITS_TO_SHOW_AFTER_DOT}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          className={'text-right __input-height-35'}
          style={{ width: '120px', height: '35px !important' }}
          onChange={(value) => {
            articlesToP[index].unitPrice = value;
            setArticlesToP(JSON.parse(JSON.stringify(articlesToP)));
          }}
        />;
      },
    },
  ];
  if (fromEffort) {
    columns.splice(1, 1);
  }
  return (
    <Modal
      title={t('workSite.workTypes.workType.articleToPersonalize.title')}
      visible={true}
      width={fromEffort ? 1000 : 700}
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      destroyOnClose={true}
      bodyStyle={{ padding: 0 }}
      footer={null}
    >
      <div className={'__work-type-article-selection-list'}>
        <ListView
          title={''}
          noLayoutP={true}
          hideSearchSection={true}
          subTitle={
            <div className={'__sub-title'}>
              <Space align={'start'} size={20}>
                <ExclamationCircleOutlined className={'__icon-themed __icon-yellow'}/>
                <span
                  className="__theme-text __black-shade">
                                    {t('workSite.workTypes.workType.articleToPersonalize.' +(fromEffort ? 'subTitleEffort' : 'subTitle'), { quantity: articles.length })}
                  {
                    _.uniq(workTypeNames).map((w: any) => {
                      return <div className={'text-italics'} key={w}> {w}</div>;
                    })
                  }
                                </span>
              </Space>
            </div>
          }>
          <Table
            showSorterTooltip={false}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: true,
              onShowSizeChange: (currentPage, pageSize) => {
                paginationParams(pageSize);
              },
              defaultPageSize: getDefaultPageSize(),
            }}
            locale={{
              emptyText: (
                <>
                  <Space style={{ padding: '10px' }}>
                    <Text
                      className={'primary-black'}>{t('workSite.workTypes.personaliseTableEmptyMessage')}</Text>
                  </Space>
                </>
              ),
            }}

            columns={columns}
            dataSource={articlesToP}
          >articles
          </Table>
        </ListView>
        <Row>
          <Col span={24}>
            <Space direction={'horizontal'} className={'__form-footer-parent'}>
              <div/>
              <Space direction={'horizontal'} className={'__form-footer'}>
                <Button
                  type="default"
                  style={{ marginBottom: '8px' }}
                  onClick={() => {
                    onCancel();
                  }}
                  shape="round"
                  size={'large'}
                  className="submit">
                  {t('genDict.Cancel')}
                </Button>
                <BaseButton
                  type="primary"
                  loading={addingArticlesToWork}
                  onClick={async () => {
                    setAddingArticlesToWork(true);

                    try {
                      await Promise.all(
                        articlesToP.map((article) => {
                          if (article.article)
                            return personalizeArticlePrice(article.article.id, article.unitPrice);
                          return personalizeEffortPrice(article.id, article.unitPrice);

                        }),
                      );

                    } catch (e) {
                      notification.error({
                        message: t(e),
                      });
                    }
                    setAddingArticlesToWork(false);
                    onSubmit(efforts);
                  }}
                  shape="round"
                  size={'large'}
                  className="submit"
                >
                  {t('workSite.workTypes.button.register')}
                </BaseButton>
              </Space>
            </Space>
          </Col>
        </Row>
      </div>
    </Modal>

  );
}
;
export default ArticlesToPersonalize;
