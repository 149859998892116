import gql from 'graphql-tag';
import { client, clientWithErrors } from '../services/ApolloClient';

type ReturnType = {
    customers: any;
    loading: boolean;
    error: any;
};
type CustomerByIdReturnType = {
    customer: any,
    loading: boolean,
    error: any,
}
const GET_SUPPLIERS = gql`
    {
  providers{
    id
    mail
    phone
    address{
    address
    additional
    postalCode
    city
  }
      contacts{
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
    name
    taxNumber
    isDeletable
  }
  }
`;

const GET_SUPPLIER_BY_ID = gql`
  query Provider($id : ID!) {
  provider(id: $id){
    id
    mail
    phone
     address{
    address
    additional
    postalCode
    city
  }
    purchaseOrders{
    id
    title
    createdAt
    url
    companyId
    workSite{
      id
      reference
      name
      address{
        postalCode
        city
      }
    }
  }
     contacts{
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
    name
    taxNumber
    isDeletable
   }
  
} 
`;
const DELETE_CONTACT_BY_ID = gql`mutation($id: ID!){ deleteContact(id: $id)}`;

const CREATE_SUPPLIER_CONTACT = gql`mutation($id:ID!, $title: String!, $firstName:String!, $lastName: String!, $email: String!, $phone: String,$isMain: Boolean){
  createProviderContact(input: {title: $title, firstName:$firstName, lastName: $lastName, email: $email, phone: $phone,isMain: $isMain}, id: $id){
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
}`;
const EDIT_SUPPLIER_CONTACT = gql`mutation($id:ID!, $title: String!, $firstName:String!, $lastName: String!, $email: String!, $phone: String,$isMain: Boolean){
  editContact(input: {title: $title, firstName:$firstName, lastName: $lastName, email: $email, phone: $phone,isMain: $isMain}, id: $id){
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
}`;
const DELETE_SUPPLIER_BY_ID = gql`mutation($id: ID!){ deleteProvider(id: $id)}`;
const CREATE_NEW_SUPPLIER = gql`mutation(
$mail: String, $phone: String, $address: AddressInput!, $contacts: [ContactInput]!, $name: String!,$taxNumber: String){
  createProvider(input: {
  mail: $mail, phone: $phone, address: $address, contacts: $contacts, name: $name,taxNumber: $taxNumber
  }){
    id
    mail
    phone
     address{
    address
    additional
    postalCode
    city
  }
    contacts{
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
    name
    taxNumber
    isDeletable
  }
}`;
const EDIT_SUPPLIER = gql`mutation($id: ID!, 
$mail: String, $phone: String, $address: AddressInput!, $name: String!,$taxNumber: String){
  editProvider(id: $id, input: {
  mail: $mail, phone: $phone, address: $address,name: $name,taxNumber: $taxNumber
  }){
    id
    mail
    phone
     address{
    address
    additional
    postalCode
    city
  }
    contacts{
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
    name
    taxNumber
    isDeletable
  }
}`;
export const fetchSuppliers = () => {
    return clientWithErrors(client.query({ query: GET_SUPPLIERS }));
};

export const fetchSupplierById = (id: string) => {
    return clientWithErrors(client.query({ variables: { id }, query: GET_SUPPLIER_BY_ID }));
};


export const deleteSupplierById = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_SUPPLIER_BY_ID }));
};

export const deleteContactById = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_CONTACT_BY_ID }));
};
export const createNewSupplier = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: { ...data }, mutation: CREATE_NEW_SUPPLIER }));
};

export const createNewSupplierContact = async (data: any, customerId: any) => {
    return clientWithErrors(client.mutate({
        variables: { ...data, id: customerId },
        mutation: CREATE_SUPPLIER_CONTACT,
    }));
};

export const updateSupplierContact = async (data: any, contactId: any) => {
    return clientWithErrors(client.mutate({ variables: { ...data, id: contactId }, mutation: EDIT_SUPPLIER_CONTACT }));
};
export const editSupplier = async (data: any, id: any) => {
    return clientWithErrors(client.mutate({ variables: { ...data, id: id }, mutation: EDIT_SUPPLIER }));
};
