import * as React from "react";

import { Route, Redirect } from "react-router-dom";

import { AuthConsumer } from "shared/contexts/AuthContext";

export default function ProtectedRoute({ component: Component, ...rest }: any) {
    return (
        <Route
            {...rest}
            render={(props) => (
                <AuthConsumer>
                    {({ isAuth }) =>
                        isAuth ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: rest.location },
                                }}
                            />
                        )
                    }
                </AuthConsumer>
            )}
        />
    );
}
