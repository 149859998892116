// noinspection RequiredAttributes

import React, { useEffect, useState } from 'react';


import {
    Form,
    Input,
    Button,
    Row,
    Col,
    notification, Skeleton, Card, Checkbox, Upload, Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { dotToObject, objectToDot } from 'shared/services/Utility';
import { FilePdfOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import { updateCurrentCompanyWithDocument } from 'shared/services/userService';

// @ts-ignore
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// @ts-ignore
import draftToHtml from 'draftjs-to-html';

// @ts-ignore
import { Helmet } from 'react-helmet';
import { REGEX_STRING_VERIFY, REGEX_POSTALCODE_VERIFY } from 'shared/constants/AppConstants';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';
import { AuthConsumer, getUrlFormatedToken } from 'shared/contexts/AuthContext';


const { Text } = Typography;

interface Props {
    item?: any;
    onChange?: (fields: any[]) => void;
    setActiveTab?: (tab: string) => void;
    onCancel?: (refresh?: boolean) => void;
    loading?: boolean;
    mode: 'CREATE' | 'EDIT' | 'VIEW';
}

export default function CompanyProfile({
                                           onCancel,
                                           mode,
                                       }: Props) {
    const { t } = useTranslation();
    const [fields] = useState([] as any[]);
    const [form] = Form.useForm();
    const { currentUser, refetch: refetchUser } = useCurrentUser();

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [backgroundPicture, setBackgroundPicture] = React.useState(null as any);
    const [picture, setPicture] = React.useState(null as any);
    const [showImageUploader, setShowImageUploader] = React.useState(true);
    const [showImageUploaderBack, setShowImageUploaderBack] = React.useState(true);
    const [backgroundPictureUrl, setBackgroundPictureUrl] = React.useState(null as any);
    const [pictureUrl, setPictureUrl] = React.useState(null as any);
    const [savingItem, setSavingItem] = useState(false);
    const [displayBankInformationOnQuote, setDisplayBankInformationOnQuote] = useState(false as any);

    const informationHeader = (<div className={'__form-header'}>
        <Title level={5} className={'__form-header-title'}>{t('genDict.documents')}</Title>
    </div>);
    const onEditorStateChange = (editorState: any) => {
        setEditorState(editorState);
    };


    useEffect(() => {
        const cU = currentUser ? JSON.parse(JSON.stringify(currentUser)) : null;
        if (cU && cU.company) {
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(cU.company.footer || '<p></p>'),
                ),
            ));
            if (cU.company.pictureUrl) {
                setPictureUrl(cU.company.pictureUrl);
            }
            if (cU.company.pictureBackgroundUrl) {
                setBackgroundPictureUrl(cU.company.pictureBackgroundUrl);
            }
        }
        if (currentUser && currentUser.company) {
            setDisplayBankInformationOnQuote(!!currentUser.company.displayBankInformationOnQuote);
        }
    }, [currentUser]);
    if (!currentUser) {
        return <Card style={{ padding: '30px' }}>
            <Skeleton.Button active={true} size={'large'}/>
            <Skeleton avatar paragraph={{ rows: 4 }}/>
        </Card>;
    }
    const initialValues = {
        ...currentUser.company,
    };
    return <>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{t('genDict.page-title', { title: t('pageTitles.setting') })}</title>
        </Helmet>
        {
            informationHeader
        }
        <AuthConsumer>
            {({ updateUser }) =>
                <Form
                    className="__my-profile-form"
                    size="large"
                    form={form}
                    fields={fields}
                    requiredMark={mode !== 'VIEW'}
                    initialValues={objectToDot(initialValues)}
                    onFinish={async (formValues) => {
                        const data = dotToObject(formValues);


                        const rawContentState = convertToRaw(editorState.getCurrentContent());

                        const markup = draftToHtml(
                            rawContentState,
                        );
                        setSavingItem(true);
                        const d = {
                            name: data.name,
                            address: {
                                address: data.address.address,
                                additional: data.address.additional,
                                postalCode: data.address.postalCode,
                                city: data.address.city,
                            },
                            bankName: data.bankName,
                            BIC: data.BIC,
                            IBAN: data.IBAN,
                            accountName: data.accountName,
                            hideLogo: data.hideLogo,
                            displayBankInformationOnQuote: data.displayBankInformationOnQuote,
                            footer: markup,
                            pictureBackground: backgroundPicture,
                            picture: picture,
                        };
                        updateCurrentCompanyWithDocument(d).then(() => {
                            notification.success({
                                message: t('genDict.success'),
                                description: t('myCompanyProfile.form.notification.myProfileUpdatedSuccess'),
                            });
                            if (onCancel) {
                                onCancel(true);
                            }
                            refetchUser();
                            updateUser();
                        }).catch(e => {
                            notification.error({
                                message: t(e),
                            });
                        }).finally(() => {
                            setSavingItem(false);
                        });
                    }}
                    layout="vertical">
                    <div style={{ padding: '20px' }}>
                        <>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="__sub-text">
                                        {t('myCompanyProfile.form.subText')}
                                    </div>
                                    <div className="__title">
                                        {t('myCompanyProfile.form.title')}
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={'name'}
                                        key="name"
                                        label={t('myCompanyProfile.form.input.name.label')}
                                        rules={[
                                            { required: true, message: t('myCompanyProfile.form.input.name.required') },
                                            {
                                                pattern: REGEX_STRING_VERIFY,
                                                message: t('errorMessage.stringWrongFormat'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            autoComplete={'off'}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name="address.address"
                                        label={t('myCompanyProfile.form.input.address.label')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('myCompanyProfile.form.input.address.required'),
                                            },
                                            {
                                                pattern: REGEX_STRING_VERIFY,
                                                message: t('errorMessage.stringWrongFormat'),
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name="address.additional"
                                        key="additional"
                                        label={t('myCompanyProfile.form.input.additionalAddress.label')}
                                        rules={[
                                            {
                                                pattern: REGEX_STRING_VERIFY,
                                                message: t('errorMessage.stringWrongFormat'),
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name="address.postalCode"
                                        label={t('myCompanyProfile.form.input.postalCode.label')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t('myCompanyProfile.form.input.postalCode.required'),
                                            },
                                            {
                                                pattern: REGEX_POSTALCODE_VERIFY,
                                                message: t('myCompanyProfile.form.input.postalCode.wrongFormat'),
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name="address.city"
                                        key="city"
                                        label={t('myCompanyProfile.form.input.city.label')}
                                        rules={[
                                            { required: true, message: t('myCompanyProfile.form.input.city.required') },
                                            {
                                                pattern: REGEX_STRING_VERIFY,
                                                message: t('errorMessage.stringWrongFormat'),
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="__sub-title">
                                        {t('myCompanyProfile.form.subTitle')}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="__sub-title-text">
                                        {t('myCompanyProfile.form.subTitleSubText')}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col className="gutter-row" md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={'bankName'}
                                        key="bankName"
                                        label={t('myCompanyProfile.form.input.bankName.label')}
                                        rules={[
											{ required: displayBankInformationOnQuote, message: t('myCompanyProfile.form.input.bankName.required') },
                                            {
                                                pattern: REGEX_STRING_VERIFY,
                                                message: t('errorMessage.stringWrongFormat'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            autoComplete={'off'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={'accountName'}
                                        key="accountName"
                                        label={t('myCompanyProfile.form.input.accountName.label')}
                                        rules={[
											{ required: displayBankInformationOnQuote, message: t('myCompanyProfile.form.input.accountName.required') },
                                            {
                                                pattern: REGEX_STRING_VERIFY,
                                                message: t('errorMessage.stringWrongFormat'),
                                            },
                                        ]}
                                    >
                                        <Input
                                            autoComplete={'off'}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row gutter={16}>
                                <Col className="gutter-row" md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={'IBAN'}
                                        key="IBAN"
                                        label={t('myCompanyProfile.form.input.IBAN.label')}
                                        rules={[
											{ required: displayBankInformationOnQuote, message: t('myCompanyProfile.form.input.IBAN.required') },
                                            {
                                                pattern: /^FR\d{12}[0-9A-Z]{11}\d{2}$/,
                                                message: t('myCompanyProfile.form.input.IBAN.wrongFormat'),
                                            },
                                        ]}>

                                        <Input
                                            autoComplete={'off'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" md={12} sm={24} xs={24}>
                                    <Form.Item
                                        name={'BIC'}
                                        key="BIC"
                                        label={t('myCompanyProfile.form.input.BIC.label')}
                                        rules={[
											{ required: displayBankInformationOnQuote, message: t('myCompanyProfile.form.input.BIC.required') },
                                            {
                                                pattern: /^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})?$/i,
                                                message: t('myCompanyProfile.form.input.BIC.wrongFormat'),
                                            },
                                        ]}>
                                        <Input
                                            autoComplete={'off'}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={16}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        valuePropName="checked"
                                        name={'displayBankInformationOnQuote'}>
                                        <Checkbox onChange={(e) => setDisplayBankInformationOnQuote(e.target.checked)}>
                                            <span
                                                className={'__checkbox-text'}>
                                                {t('myCompanyProfile.form.input.displayBankInformationOnQuote.required')}
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="__sub-title">
                                        {t('myCompanyProfile.form.subTitle2')}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                    >
                                        {pictureUrl ?
                                            <div className={'__image-overlay-form'}>
                                                <img src={pictureUrl} alt="avatar" style={{ width: '100px' }}/>
                                                <div className="__overlay">
                                                    <DeleteOutlined onClick={() => {
                                                        setPictureUrl(null);
                                                    }}/>
                                                </div>
                                            </div> : (showImageUploader && <Upload
                                                accept="image/x-png,image/jpg,image/jpeg"
                                                listType="picture"
                                                beforeUpload={(file) => {
                                                    if (file && file.type.toLowerCase().indexOf('image') === -1) {
                                                        notification.error({
                                                            message: t('myCompanyProfile.form.image.invalidImage'),
                                                        });
                                                        setShowImageUploader(false);
                                                        setTimeout(() => {
                                                            setShowImageUploader(true);
                                                        });
                                                        return false;
                                                    }
                                                    const reader = new FileReader();
                                                    // eslint-disable-next-line
                                                    const url = reader.readAsDataURL(file);
                                                    reader.onloadend = function() {
                                                        setPictureUrl(reader.result);
                                                    };
                                                    setPicture(file);
                                                    return false;
                                                }}
                                                // @ts-ignore
                                                maxCount={1}
                                            >
                                                <Button
                                                    className={'__button-grey __theme-text __medium roboto-medium'}
                                                    style={{ fontWeight: 300 }}
                                                    icon={
                                                        <UploadOutlined/>}>{t('myCompanyProfile.form.input.picture.uploadText')}</Button>
                                                &nbsp;&nbsp;
                                                <Text className={'roboto-regular text-12 color-8c8c8c'}
                                                      type="secondary">{t('myCompanyProfile.form.input.picture.uploadInfo')}</Text>
                                            </Upload>)}

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        valuePropName="checked"
                                        name={'hideLogo'}>
                                        <Checkbox>
                                <span
                                    className={'__checkbox-text'}> {t('myCompanyProfile.form.input.hideLogo.required')}</span>
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="__sub-title">
                                        {t('myCompanyProfile.form.subTitle3')}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                    >

                                        {backgroundPictureUrl ?
                                            <div className={'__image-overlay-form'}>
                                                <img src={backgroundPictureUrl} alt="avatar"
                                                     style={{ width: '100px' }}/>
                                                <div className="__overlay">
                                                    <DeleteOutlined onClick={() => {
                                                        setBackgroundPictureUrl(null);
                                                    }}/>
                                                </div>
                                            </div> : (showImageUploaderBack && <Upload
                                                accept="image/x-png,image/jpg,image/jpeg"
                                                listType="picture"
                                                beforeUpload={(file) => {
                                                    if (file && file.type.toLowerCase().indexOf('image') === -1) {
                                                        notification.error({
                                                            message: t('myCompanyProfile.form.image.invalidImageBack'),
                                                        });
                                                        setShowImageUploaderBack(false);
                                                        setTimeout(() => {
                                                            setShowImageUploaderBack(true);
                                                        });
                                                        return false;
                                                    }
                                                    const reader = new FileReader();
                                                    // eslint-disable-next-line
                                                    const url = reader.readAsDataURL(file);
                                                    reader.onloadend = function() {
                                                        setBackgroundPictureUrl(reader.result);
                                                    };
                                                    setBackgroundPicture(file);
                                                    return false;
                                                }}
                                                // @ts-ignore
                                                maxCount={1}
                                            >
                                                <Button
                                                    className={'__button-grey __theme-text __medium roboto-medium'}
                                                    style={{ fontWeight: 300 }}
                                                    icon={
                                                        <UploadOutlined/>}>{t('myCompanyProfile.form.input.pictureBackground.uploadText')}</Button>
                                                &nbsp;&nbsp;
                                                <Text className={'roboto-regular text-12 color-8c8c8c'}
                                                      type="secondary">{t('myCompanyProfile.form.input.pictureBackground.uploadInfo')}</Text>
                                            </Upload>)}

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="__sub-title">
                                        {t('myCompanyProfile.form.subTitle4')}
                                    </div>
                                    <Editor editorState={editorState} onEditorStateChange={onEditorStateChange}/>
                                </Col>
                            </Row>
                        </>
                    </div>
                    <Col span={24}>
                        <Row gutter={20} className={'__form-footer-parent-row'}>
                            <Col flex={'auto'}>
                                <div/>
                            </Col>
                            <Col className={'__form-footer-row'}>
                                <Row gutter={20}>
                                    <Col>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                ghost
                                                shape="round"
                                                size={'large'}
                                                className="submit"
                                                icon={<FilePdfOutlined/>}
                                                onClick={() => {
                                                    const url = `${BaseUrlConstants.BASE_URL}preview-quote.pdf?X-AUTH-TOKEN=${getUrlFormatedToken()}`;
                                                    window.open(url, '_blank');
                                                }}
                                            >
                                                {t('myCompanyProfile.form.button.preview')}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={savingItem}
                                                shape="round"
                                                size={'large'}
                                                className="submit"
                                            >
                                                {t('myCompanyProfile.form.button.save')}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            }
        </AuthConsumer>

    </>;
};
