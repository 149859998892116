import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`
mutation($input: EmailTemplateInput!){
  company_quoteTemplate(input: $input){
    id
  }
}`;
const updateEmailTemplateQuote = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: {input: data}, mutation: MUTATION }));
};

export default updateEmailTemplateQuote;
