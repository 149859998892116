import * as React from 'react';
import logoIconWhite from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';

import logo from 'shared/images/logo.svg';
import logoIcon from 'shared/images/logoIcon.png';
import { Layout, Steps } from 'antd';
import { useTranslation } from 'react-i18next';

import './Header.scss';

type Props = {
    tab: number;
    subscriptionType: string;
};
const { Header: AntHeader } = Layout;
const { Step } = Steps;

const Header: React.FunctionComponent<Props> = ({ tab, subscriptionType }) => {
    const { t } = useTranslation();
    return (
        <AntHeader className={'__header-wrapper'}>
            <div className="__header">
                <div className="__logo-signup">
                    <img className="__icon-logo" src={logo} alt="Logo" />
                </div>
                <div className="__steps-signup show-till-tablet-only">
                    <Steps progressDot current={tab} size="small">
                        <Step title={t('signup.steps.select')} />
                        <Step title={t('signup.steps.info')} />
                        {subscriptionType !== 'trial' && (
                            <Step title={t('signup.steps.pay')} />
                        )}
                    </Steps>
                </div>
            </div>
        </AntHeader>
    );
};
export default Header;
