import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, Space, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';

const { TabPane } = Tabs;


import './index.scss';
import Title from 'antd/es/typography/Title';
import { generateFullName, useLayoutMedia } from 'shared/services/Utility';
import { useLocation } from 'react-use';
import useCompanyUser from '../../hooks/users/useCompanyUser';
import UserDetails from './UserDetails';
import UserBills from './UserBills';
import NewSubSidebarLayout from '../../components/layout/NewSubSidebarLayout/NewSubSidebarLayout';
import { useState } from 'react';

const { Text } = Typography;
type Props = {}


const Edit: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const RouteParams: any = useParams();
    const [tabsPosition, setTabsPosition] = useState(useLayoutMedia(['Desktop', 'TabletLandscape']) ? 'left' : 'top' as any);
    const { admin_userCompany, refetch: fetchingUser } = useCompanyUser(RouteParams['userId']);
    const history = useHistory();
    const [activeTab, setActiveTab] = React.useState('');
    const isViewMode = RouteParams['pageMode'] && RouteParams['pageMode'].toLowerCase() === 'view';
    let aI = 0;
    const informationHeader = (<div className={'__form-header'}>
        <Title level={5} className={'__form-header-title'}>{t('genDict.Information')}</Title>
    </div>);
    const data = [
        {
            name: 'genDict.Information',
            hash: '#information',
            count: null,
            component: <>
                {
                    informationHeader
                }
                <UserDetails userCompany={admin_userCompany}/></>,
        },
        {
            name: 'genDict.Payments',
            count: null,
            hash: '#payments',
            component: <>
                <UserBills userCompany={admin_userCompany}/>
            </>,
        },
    ];
    if (location['hash']) {
        aI = data.map((d) => d.hash).indexOf(location['hash'].toLowerCase());
    }
    const [activeIndex, setActiveIndex] = React.useState(aI);


    return (
        <NewSubSidebarLayout
            transparentContent={activeIndex === 1}
            pageTitle={t('genDict.page-title', { title: t(isViewMode ? 'pageTitles.viewUser' : 'pageTitles.viewUser') })}
            loading={fetchingUser && !(admin_userCompany && admin_userCompany.primaryUser)}
            title={
                (
                    <Space size={15} align={'center'} direction="horizontal">
                        <Title level={2}>
                            <div className={'__header-title __header-title-margin'}>
                                {generateFullName(admin_userCompany.primaryUser)}
                            </div>
                        </Title>
                        <div>
                            {
                                admin_userCompany.subscription && <span
                                    className={'__theme-tags __default'}>{t(`mySubscription.${admin_userCompany.subscription.type}.title`)}</span>
                            }

                            {
                                !!admin_userCompany.subscription ?
                                    (admin_userCompany.subscription.status === 'active' ? <span
                                            className={'__theme-tags  __success'}><CheckCircleOutlined/> {t(`subscription.active`)}</span> :
                                        <span
                                            className={'__theme-tags __danger'}><ExclamationCircleOutlined/> {t(`subscription.pendingPayment`)}</span>)
                                    : <span
                                        className={'__theme-tags __default'}><CloseCircleOutlined/> {t('subscription.inactive')}</span>
                            }
                        </div>
                    </Space>)
            }

        >
            <Tabs tabPosition={tabsPosition} activeKey={aI + ''} onChange={(e: any) => {
                history.push(data[e].hash);
            }}>
                {
                    data.map((item: any, index: any) => {
                        return <TabPane tab={t(item.name)} key={index}>
                            <Card className={`${aI !== 0 && 'transparent-content'}`}>
                                {item.component}
                            </Card>
                        </TabPane>;
                    })
                }
            </Tabs>

        </NewSubSidebarLayout>
    );
};
export default Edit;
