import * as React from 'react';
import { Suspense, useState, ReactElement, useEffect } from 'react';
import {
    LogoutOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    ArrowLeftOutlined,
    LeftOutlined,
    RightOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {
    Layout, Menu, Row,
    Col,
    Button, Dropdown, Divider, Modal,
} from 'antd';
import personalizePopupImage from 'shared/images/personalizePopup-image.svg';

import { Link, useHistory, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoaderOverlay from 'shared/components/LoaderOverlay';

import '../Layout.scss';
import logo from 'shared/images/logo.svg';
import logoIcon from 'shared/images/logoIcon.png';
import userIcon from 'shared/icons/single-03.svg';
import supportIcon from 'shared/icons/support-17.svg';
import { AuthConsumer, isAdmin, logout } from 'shared/contexts/AuthContext';
import { PlusOutlined } from '@ant-design/icons';
import { generateFullName } from 'shared/services/Utility';
import useCurrentUser from 'shared/hooks/useCurrentUser';
import { hideArticlePersonalizePopup } from 'shared/services/articleService';
import Parameters from 'shared/parameters';
import TutorialPopupComponent from '../TutorialPopupComponent';

const SUPPORT_MAIL = Parameters.SUPPORT_MAIL;
const FAQ_LINK = Parameters.FAQ_LINK;
const { Header, Content } = Layout;

interface MenuItem {
    route: string;
    icon: ReactElement;
    iconAction?: string;
    hideForAdmin?: boolean;
    hideForClient?: boolean;
    iconActive: ReactElement;
    label: string;
    warning?: ReactElement;
    daysBeforeQBExpiration?: number;
}


interface LayoutProps {
    topMenuItems?: MenuItem[];
    bottomMenuItems?: MenuItem[];
    selectedMenu?: string | null;
    onMenuClick?: (route: string) => void;
    children: any;
    hasHeader?: boolean;
    logout?: boolean;
    backLink?: any;
    hasLink?: boolean;
    linkText?: string;
    linkUrl?: string;
}

export default function TabletLandscapeLayout({
                                          topMenuItems,
                                          bottomMenuItems,
                                          children,
                                          backLink,
                                      }: LayoutProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentUser } = useCurrentUser();
    const [menuCollapsed, setMenuCollapsed] = useState(
        localStorage.getItem('sidebar-collapsed') === 'true',
    );
    const [hidePopupPersonalize, setHidePopupPersonalize] = useState(true);
  const [hideTutorialPopup, setHideTutorialPopup] = useState(true);

  useEffect(() => {
      if (currentUser && !currentUser.stopShowTutorial && currentUser.company && currentUser.company?.subscription && !isAdmin() && !localStorage.getItem('hidePopupTutorial')) {
        setHideTutorialPopup(false);
      } else if (currentUser && !currentUser.stopShowPersonalize && currentUser.hasArticlesToPersonalize && currentUser.company?.subscription && !isAdmin()) {
        if (!localStorage.getItem('hidePopupPersonalize')) {
          setHidePopupPersonalize(false);
        }
      }
    }, [currentUser]);
    const [allowTransition, setAllowTransition] = useState(true);

    function onCollapse(collapsed: boolean) {
        setMenuCollapsed(collapsed);
        localStorage.setItem('sidebar-collapsed', collapsed.toString());
    }

    function handleLogout() {
        logout();
    }

    const menu = (
        <Menu className="__layout-menu">
            {
                <Menu.Item>
                    <div className="__menu-item">
                        <NavLink className={'link'} to={'/settings'} activeClassName="active-link">
                           <span className="inner-element">
                                {t('appbar.settings')}
                               <div className="__icon">
                                    <SettingOutlined/>
                             </div>
                           </span>
                        </NavLink>

                    </div>
                </Menu.Item>

            }
            {
                !isAdmin() && <Menu.Item>
                    <div className="__menu-item">
                        <NavLink className={'link'} activeClassName="active-link" to={'/account-settings#my-profile'}>
                           <span className="inner-element">
                            {t('appbar.account')}
                               <div className="__icon">
                                        <UserOutlined/>
                                </div>
                           </span>
                        </NavLink>

                    </div>
                </Menu.Item>
            }
            {
                <Menu.Item>
                    <div className="__menu-item" onClick={() => handleLogout()}>
                        <a rel="noopener noreferrer">
                            {t('appbar.logout')}
                        </a>
                        <div className="__icon">
                            <LogoutOutlined/>
                        </div>
                    </div>
                </Menu.Item>
            }
        </Menu>
    );

    return (
        <Layout
            style={{ minHeight: '100vh' }}
            className={`desktop-layout ${menuCollapsed ? '--collapsed' : ''}`}
        >
          {
            !hideTutorialPopup && <TutorialPopupComponent onFinish={()=>{
              if (currentUser && !currentUser.stopShowPersonalize && currentUser.hasArticlesToPersonalize && currentUser.company?.subscription && !isAdmin()) {
                if (!localStorage.getItem('hidePopupPersonalize')) {
                  setHidePopupPersonalize(false);
                }
              }
            }}/>
          }
            <Modal
                title={t('personalizePrice.title')}
                visible={!hidePopupPersonalize}
                width={800}
                maskClosable={false}
                onCancel={() => {
                    localStorage.setItem('hidePopupPersonalize', 'true');
                    setHidePopupPersonalize(true);
                }}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <div className="__personalize-popup-body-wrapper">
                    <div className="__image">
                        <img src={personalizePopupImage} alt=""/>
                    </div>
                    <div className="__text-section">
                        {
                            t('personalizePrice.welcomeParagraph')
                        }
                    </div>
                </div>
                <Row>
                    <Col span={24}>
                        <Row gutter={20} align={'middle'} className={'__form-footer-parent-row __from-sm'}>
                            <Col>
                                <div className={'flat-button primary'} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    localStorage.setItem('hidePopupPersonalize', 'true');
                                    setHidePopupPersonalize(true);
                                    hideArticlePersonalizePopup();
                                }}>
                                    {t('personalizePrice.button.noLongerDisplay')}
                                </div>
                            </Col>
                            <Col className={'__form-footer-row __from-sm'}>
                                <Row gutter={20}>
                                    <Col>
                                        <Button
                                            type="default"
                                            onClick={async () => {
                                                localStorage.setItem('hidePopupPersonalize', 'true');
                                                setHidePopupPersonalize(true);
                                            }}
                                            disabled={false}
                                            shape="round"
                                            size={'large'}
                                            className="submit"
                                        >
                                            {t('personalizePrice.button.ignore')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={false}
                                            onClick={() => {
                                                localStorage.setItem('hidePopupPersonalize', 'true');
                                                setHidePopupPersonalize(true);
                                                history.push('/customize-price');
                                            }}
                                            shape="round"
                                            size={'large'}
                                            className="submit">
                                            {t('personalizePrice.button.personalizePrice')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
            <Header style={{ position: 'fixed' }}>
                <Link to="/">
                    <div className={`__logo ${menuCollapsed && 'collapsed-logo'}`}>
                        {
                            !menuCollapsed ? <img alt="Accueil" src={logo}/> : <img
                                alt="Accueil"
                                src={logoIcon}/>

                        }
                    </div>
                </Link>
                {backLink && backLink.link &&
                <div className={`list-link ${menuCollapsed && 'collapsed'}`}>
                    {/* For some reason we have to add a 25px margin-top to get the arrow centered with text, just one pixel lower */}
                    <Link to={backLink.link}>
                      <span className="__text-link">
                        <ArrowLeftOutlined style={{ marginTop: '25px', marginRight: '5px' }}/>
                          {/* {linkText} */}
                          {backLink.text}
                      </span>
                    </Link>
                </div>}
                <div className="user-menu">
                    {
                        !isAdmin() && <>
                            <div className="support-icon" onClick={() => {
                                if (typeof window !== 'undefined') {
                                    window.open(SUPPORT_MAIL);
                                }
                            }}>
                                <img alt={'Image'} src={supportIcon}/>
                            </div>
                            <div className="question-icon" onClick={() => {
                                if (typeof window !== 'undefined') {
                                    window.open(FAQ_LINK);
                                }
                            }}>
                                <QuestionCircleOutlined className="icon"/>
                            </div>
                        </>
                    }
                    <AuthConsumer>
                        {({ isAuth, updateAuthToken, user }) => !!user &&
                            <Dropdown overlay={menu}>
                                <div className="__user-card">
                                    <div className="__user-info">
                                        <span className="__user-name">
                                            {generateFullName(user)}
                                        </span>
                                        {
                                            user.company && <span className="__company-name">
                                            {!isAdmin() && user.company.name}
                                        </span>
                                        }
                                    </div>
                                    <div className="__user-icon">
                                        <img src={userIcon}/>
                                    </div>
                                </div>
                            </Dropdown>
                        }
                    </AuthConsumer>
                </div>
            </Header>
            <div
                className={`__main-content-wrapper ${isAdmin() ? 'admin' : ''} ${allowTransition ? '__allow-transition' : ''}`}>
                <div className={`_app-side-bar ${menuCollapsed ? '__collapsed' : '__not-collapsed'}`}>
                    <div className="__menu-wrapper">
                        {
                            topMenuItems && <div className="__upper-menu _app-menu">
                                {
                                    topMenuItems.map((menuItem) => {
                                        if (isAdmin() && menuItem.hideForAdmin) {
                                            return <></>;
                                        }
                                        if (!isAdmin() && menuItem.hideForClient) {
                                            return <></>;
                                        }
                                        return <>
                                            <NavLink to={menuItem.route} className="__menu-item">
                                                <div className="__menu-item-icon">
                                                    {menuItem.icon}
                                                </div>
                                                <div className="__menu-item-icon-active">
                                                    {menuItem.iconActive}
                                                </div>
                                                <div className="__menu-item-label">
                                                    {menuItem.label}
                                                </div>
                                                {menuItem.iconAction &&
                                                <div className="__menu-item-action">
                                                    <NavLink to={menuItem.iconAction || menuItem.route}>
                                                        <PlusOutlined/>
                                                    </NavLink>
                                                </div>
                                                }
                                            </NavLink>
                                        </>;

                                    })
                                }
                            </div>
                        }
                        <Divider style={{ margin: '10px 0', backgroundColor: '#FFFFFF24' }}/>
                        {
                            bottomMenuItems && <div className="__lower-menu _app-menu">
                                {
                                    bottomMenuItems.map((menuItem) => {
                                        if (isAdmin() && menuItem.hideForAdmin) {
                                            return <></>;
                                        }
                                        if (!isAdmin() && menuItem.hideForClient) {
                                            return <></>;
                                        }
                                        return <>
                                            <NavLink to={menuItem.route} className="__menu-item">
                                                <div className="__menu-item-icon">
                                                    {menuItem.icon}
                                                </div>
                                                <div className="__menu-item-icon-active">
                                                    {menuItem.iconActive}
                                                </div>
                                                <div className="__menu-item-label">
                                                    {menuItem.label}
                                                </div>
                                                {menuItem.iconAction &&
                                                <div className="__menu-item-action">
                                                    <NavLink to={menuItem.iconAction || menuItem.route}>
                                                        <PlusOutlined/>
                                                    </NavLink>
                                                </div>
                                                }
                                            </NavLink>
                                        </>;

                                    })
                                }
                            </div>
                        }
                    </div>
                    <div className="__toggle-wrapper __font-bold">
                        <div className="__toggle-opened" onClick={() => onCollapse(true)}>
                            <LeftOutlined/>
                            {t('sideMenu.collapseMenu')}
                        </div>
                        <div className="__toggle-closed" style={{paddingLeft: '10px'}} onClick={() => onCollapse(false)}>
                            <RightOutlined/>
                        </div>
                    </div>
                </div>
                <Content className={'content-layout'}>
                    <div className="inner-content-layout">
                        <Suspense fallback={<LoaderOverlay size="large" loading={true}/>}>
                            {children}
                        </Suspense>
                    </div>
                </Content>
            </div>

        </Layout>
    );
}
