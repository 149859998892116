import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`
mutation($id: ID!, $workTypeId: ID!){
  workSite_addRow(id: $id, workTypeId: $workTypeId){
    id
  }
}`;
const useAddWorkTypeToWorkSite = async (id: string, workTypeId: string) => {
    return clientWithErrors(client.mutate({ variables: { id, workTypeId }, mutation: MUTATION }));
};

export default useAddWorkTypeToWorkSite;
