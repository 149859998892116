import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`
mutation($id: ID!){
  workSite_delete(id: $id)
}`;
const useDeleteWorkSiteById = async (id: string) => {
    return clientWithErrors(client.mutate({ variables: { id }, mutation: MUTATION }));
};

export default useDeleteWorkSiteById;
