import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Tabs } from 'antd';
import {
    Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useHistory } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { useLocation } from 'react-use';

import NewSubSidebarLayout from 'shared/components/layout/NewSubSidebarLayout/NewSubSidebarLayout';
import MyBills from './MyBills';
import MyProfileForm from './MyProfile.form';

import MySubscription from './MySubscription';
import Parameters from 'shared/parameters';
import { useLayoutMedia } from 'shared/services/Utility';
import './index.scss';

const stripePromise = loadStripe(Parameters.StripePublicToken);
const { TabPane } = Tabs;

type Props = {
    t?: Function,
}


const AccountSettings: React.FunctionComponent<Props> = () => {

    const { t } = useTranslation();
    const location = useLocation();
    let aI = 0;
    const history = useHistory();

    const ListItems = [
        {
            name: 'genDict.myProfile',
            hash: '#my-profile',
            count: null,
            component: <MyProfileForm mode={'EDIT'}/>,
        },
        {
            name: 'genDict.mySubscriptions',
            hash: '#my-subscriptions',
            count: null,
            component: <>
                <Elements stripe={stripePromise}>
                    <MySubscription mode={'EDIT'}/>
                </Elements>
            </>,

        },
        {
            name: 'genDict.myBills',
            hash: '#my-bills',
            count: null,
            component: <MyBills/>,

        },
    ];
    if (location['hash']) {
        aI = ListItems.map((d) => d.hash.toLowerCase()).indexOf(location['hash'].toLowerCase());
    }
    const tabsPosition = useLayoutMedia(['Desktop', 'TabletLandscape']) ? 'left' : 'top';

    return (
        <NewSubSidebarLayout
            pageTitle={t('genDict.page-title', { title: t('pageTitles.myProfile') })}
            title={
                (
                    <Row gutter={20} align={'middle'}>
                        <Col>
                            <Title level={2}>
                                <div className={'__header-title __header-title-margin'}>
                                    {t('genDict.AccountSettings')}
                                </div>
                            </Title>
                        </Col>
                    </Row>)
            }
        >
            <Tabs tabPosition={tabsPosition} activeKey={aI + ''} onChange={(e: any) => {
                history.push(ListItems[e].hash);
            }}>
                {
                    ListItems.map((item: any, index: any) => {
                        return <TabPane tab={t(item.name)} key={index}>
                            <Card className={`${aI === 2 && 'transparent-content'}`}>
                                {item.component}
                            </Card>
                        </TabPane>;
                    })
                }
            </Tabs>
        </NewSubSidebarLayout>

    );
};
export default AccountSettings;
