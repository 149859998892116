import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { WorkSiteType } from '../../types/workSite.type';

type ReturnType = {
    items: any[];
    loading: boolean;
    error: any;
    refetch: any
};
const QUERY = gql`
    {
        workSites{
            id
            reference
            name
            customer{
                id
                firstName
                name
                lastName
                type
                mail
                phone
            }
            address{
                address
                additional
                postalCode
                city
            }
            taxRate
            effortCost
            articleCost
            feesCost
            sellingPrice
            margin
            ratio
        }
    }
`;

const useWorkSites: () => ReturnType = () => {
    const { data, loading, error, refetch } = useQuery(QUERY);
    let items = [];
    if (!loading && !error) {
        items = data.workSites;
    }
    return {
        items,
        refetch,
        loading,
        error,
    };
};
export default useWorkSites;
