import * as React from 'react';
import { dotToObject, generateFullName } from '../../services/Utility';
import {
    Form,
    Modal,
    Input,
    Button,
    Skeleton, Row,
    Col,
    Card,
    Select,
    Space,
    notification,
    Typography,
} from 'antd';
import { Customer } from '../../types/customer.type';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import useClients from '../../hooks/clients/useClient';
import { useState } from 'react';
import useWorkSiteCreate from '../../hooks/work-sites/useWorkSiteCreate';
import ClientCreateModal from '../clients/ClientCreateModal';
import ProfessionalClientCreateModal from '../clients/ProfessionalClientCreateModal';
import useWorkSiteUpdate from '../../hooks/work-sites/useWorkSiteUpdate';
import { REGEX_STRING_VERIFY, REGEX_POSTALCODE_VERIFY  } from '../../constants/AppConstants';

interface Props {
    item?: any;
    isEditMode?: boolean;
    loading?: boolean;
    onCancel?: any;
}

const CreateModal: React.FunctionComponent<Props> = ({ item, onCancel, loading, isEditMode }) => {
    const fields: any[] = [];
    const { t } = useTranslation();
    const history = useHistory();
    const [savingWorkSite, setSavingWorkSite] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const { customers, loading: loadingCustomers, refetch: reloadCustomers } = useClients();
    const [activeTab, setActiveTab] = React.useState('priv');
    const [form] = Form.useForm();
    const initialValues = item ? { ...item, customer: item.customer.id } : {
        customer: '',
        taxRate: 20,
    };
    if (loading) {
        return <Card><Skeleton active/></Card>;
    }
    return <Form
        className="client-create-modal"
        size="large"
        form={form}
        fields={fields}
        requiredMark={true}
        initialValues={initialValues}
        onFinish={async (formValues) => {
            const reqObject = dotToObject(formValues);
            setSavingWorkSite(true);
            if (!item) {
                useWorkSiteCreate(reqObject).then((response) => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('workSite.notification.workSiteCreatedSuccess'),
                    });
                    if (response && response.data && response.data.workSite_create) {
                        history.push(`/chantiers/${response.data.workSite_create.id}/view`);
                    }
                    /*
                    if (onCancel) {
                        onCancel(true);
                    } else {
                        history.goBack();
                    }*/
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingWorkSite(false);
                });
            } else {
                useWorkSiteUpdate({ ...reqObject }, item.id).then((response) => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('workSite.notification.workSiteUpdatedSuccess'),
                    });
                    if (onCancel) {
                        onCancel(true);
                    } else {
                        history.goBack();
                    }
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingWorkSite(false);
                });
            }

        }}
        layout="vertical">
        <div style={{ padding: '20px' }}>
            <>
                <Modal
                    title={t('client.button.addClientMenu')}
                    visible={createModalVisible}
                    width={800}
                    maskClosable={false}
                    afterClose={() => {
                        setCreateModalVisible(false);
                    }}
                    onCancel={() => {
                        setCreateModalVisible(false);
                    }}
                    destroyOnClose={true}
                    bodyStyle={{ padding: 0 }}
                    footer={null}
                >
                    {
                        activeTab === 'pro' ?
                            <ProfessionalClientCreateModal onCancel={() => setCreateModalVisible(false)}
                                                           setActiveTab={setActiveTab} onCreate={(data) => {
                                if (data && data.createProfessionalCustomer) {
                                    form.setFieldsValue({ customer: data.createProfessionalCustomer.id });
                                }
                                setCreateModalVisible(false);
                                reloadCustomers();
                            }} mode={'CREATE'}/> :
                            <ClientCreateModal onCancel={() => setCreateModalVisible(false)} setActiveTab={setActiveTab}
                                               onCreate={(data) => {
                                                   if (data && data.createPrivateCustomer) {
                                                       form.setFieldsValue({ customer: data.createPrivateCustomer.id });
                                                   }
                                                   setCreateModalVisible(false);
                                                   reloadCustomers();

                                               }} mode={'CREATE'}/>
                    }
                </Modal>
                <Row gutter={16}>
                    <Col className="gutter-row primary-black" sm={12} xs={24}>
                        <Form.Item
                            label={t('workSite.input.name.label')}
                            name="name"
                            rules={[
                                { required: true, message: t('workSite.input.name.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},								
                            ]}
                        >
                            <Input autoComplete={'off'}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col className="gutter-row primary-black" sm={12} xs={24}>

                        <Form.Item
                            label={t('workSite.input.client.label')}
                            name="customer"
                            rules={[
                                { required: true, message: t('workSite.input.client.required') },
                            ]}
                        >
                            <Select disabled={!customers.length && !loadingCustomers}>
                                {!customers.length && !loadingCustomers && <Select.Option
                                    value={''}>{t(`workSite.noCustomerPresent`)}</Select.Option>}
                                {customers.length && <Select.Option disabled
                                    value={''}>{t(`genDict.Select`)}</Select.Option>}
                                {
                                    customers.map((option: Customer) => {
                                        return <Select.Option
                                            value={option.id}>{generateFullName(option)}</Select.Option>;
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row primary-black" sm={12} xs={24} style={{
                        display: 'flex',
                        paddingTop: '18px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        {
                            !item && <div className={'flat-button primary'} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setCreateModalVisible(true);
                            }}>
                                {t('workSite.button.addClient')}
                            </div>
                        }
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('workSite.input.address.label')}
                            name={['address', 'address']}
                            rules={[
                                { required: true, message: t('workSite.input.address.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},								
                            ]}>
                            <Input autoComplete={'off'}/>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('workSite.input.additionalAddress.label')}
                            name={['address', 'additional']}
                                rules={[
									{
										pattern: REGEX_STRING_VERIFY,
										message: t('errorMessage.stringWrongFormat'),
									},
                                ]}
							>
                            <Input autoComplete={'off'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('workSite.input.postalCode.label')}
                            name={['address', 'postalCode']}

                            rules={[
                                { required: true, message: t('workSite.input.postalCode.required') },
                                {
                                    pattern: REGEX_POSTALCODE_VERIFY,
                                    message: t('workSite.input.postalCode.wrongFormat'),
                                },
                            ]}>
                            <Input autoComplete={'off'}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row __hover-copy-input" sm={12} xs={24}>
                        <Form.Item
                            label={t('workSite.input.city.label')}
                            name={['address', 'city']}
                            rules={[
                                { required: true, message: t('workSite.input.city.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},								
                            ]}
                        >
                            <Input autoComplete={'off'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row primary-black" sm={12} xs={24}>

                        <Form.Item
                            label={t('workSite.input.taxRate.label')}
                            name={['taxRate']}
                            rules={[
                                { required: true, message: t('workSite.input.taxRate.required') },
                            ]}
                        >
                            <Select>
                                {
                                    [20, 10, 5.50, 2.10].map((option: number) => {
                                        return <Select.Option
                                            value={option}>{option}%</Select.Option>;
                                    })
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </>
        </div>
        {
            <Row>
                <Col span={24}>
                    <Space size={20} direction={'horizontal'} className={'__form-footer'}
                           style={{ justifyContent: 'space-between' }}>
                        {
                            <Form.Item>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        if (onCancel) {
                                            onCancel();
                                        } else {
                                            history.goBack();
                                        }
                                    }}
                                    disabled={savingWorkSite}
                                    shape="round"
                                    size={'large'}
                                    className="submit">
                                    {t('genDict.Cancel')}
                                </Button>
                            </Form.Item>
                        }
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={savingWorkSite}
                                shape="round"
                                size={'large'}
                                className="submit">
                                {item ? t('workSite.button.saveWorkSite') : t('workSite.button.addWorkSite')}
                            </Button>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        }
    </Form>;
};
export default CreateModal;
