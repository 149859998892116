// noinspection RequiredAttributes

import React, { useEffect, useState } from 'react';


import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Select,
    Space,
    notification,
    Divider,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { dotToObject, objectToDot } from 'shared/services/Utility';
import { PlusOutlined, MinusCircleFilled, CopyOutlined } from '@ant-design/icons';
import { ConstantTitles, REGEX_STRING_VERIFY, REGEX_PHONE_VERIFY, REGEX_POSTALCODE_VERIFY, REGEX_EMAIL_VERIFY } from 'shared/constants/AppConstants';

import { Supplier } from './Supplier.model';
import { createNewSupplier, editSupplier } from 'shared/hooks/useSuppliers';

const { Text } = Typography;

interface Props {
    item?: Supplier;
    onChange?: (fields: any[]) => void;
    setActiveTab?: (tab: string) => void;
    onCancel?: (refresh?: any) => void;
    loading?: boolean;
    mode: 'CREATE' | 'EDIT' | 'VIEW';
}

export default function SupplierCreateModal({
                                                onCancel,
                                                item,
                                                mode,
                                            }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [fields] = useState([] as any[]);
    const [form] = Form.useForm();
    const optionValues = ConstantTitles;
    const [savingCustomer, setSavingCustomer] = React.useState(false);
    const [initialValues, setInitialValues] = useState(item ? objectToDot(item) : {});
    useEffect(() => {
        setInitialValues(item ? objectToDot(item) : {});
    }, [item]);
    return (<Form
        className="supplier-create-modal"
        size="large"
        form={form}
        fields={fields}
        onValuesChange={(changedValue, formValues) => {
            setInitialValues({ ...formValues, id: initialValues.id });
        }}
        requiredMark={mode !== 'VIEW'}
        initialValues={initialValues}
        onFinish={async (formValues) => {
            const reqObject = dotToObject(formValues);
            reqObject['contacts'] = reqObject['contacts'] ? reqObject['contacts'] : [];
            reqObject['type'] = 'pro';
            setSavingCustomer(true);
            if (reqObject['contacts'][0]) {
                reqObject['contacts'][0].isMain = true;
            }
            if (mode === 'CREATE') {
                createNewSupplier(reqObject).then((response) => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('supplier.notification.supplierCreatedSuccess'),
                    });
                    if (onCancel) {
                        onCancel(response);
                    }
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingCustomer(false);
                });
            } else if (item) {

                editSupplier(reqObject, item.id).then(() => {
                    notification.success({
                        message: t('genDict.success'),
                        description: t('supplier.notification.supplierUpdatedSuccess'),
                    });
                    if (onCancel) {
                        onCancel(true);
                    }
                    history.push('/fournisseurs/' + item.id + '/view');
                }).catch(e => {
                    notification.error({
                        message: t(e),
                    });
                }).finally(() => {
                    setSavingCustomer(false);
                });
            }


        }}
        layout="vertical">
        <div style={{ padding: '20px' }}>
            <>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.name.label')}
                            name="name"
                            key="name"
                            rules={[
                                { required: true, message: t('supplier.input.name.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>

                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.name,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.taxNumber.label')}
                            name="taxNumber"
                            rules={[
                                {
                                    pattern: /(FR)?[0-9]{11}/,
                                    message: t('supplier.input.taxNumber.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.taxNumber,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                </Row>


                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.address.label')}
                            name="address.address"
                            rules={[
                                { required: true, message: t('supplier.input.address.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>

                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.address'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.additionalAddress.label')}
                            name="address.additional"
							rules={[
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},
							]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.additional'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.postalCode.label')}
                            name="address.postalCode"
                            rules={[
                                { required: true, message: t('supplier.input.postalCode.required') },
                                {
                                    pattern: REGEX_POSTALCODE_VERIFY,
                                    message: t('supplier.input.postalCode.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.postalCode'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.city.label')}
                            name="address.city"
                            rules={[
                                { required: true, message: t('supplier.input.city.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues['address.city'],
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.phone.label')}
                            name="phone"
                            rules={[
                                {
                                    pattern: REGEX_PHONE_VERIFY,
                                    message: t('supplier.input.phone.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.phone,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>
                    <Col className="gutter-row __hover-copy-input" md={12} sm={24} xs={24}>
                        <Form.Item
                            label={t('supplier.input.emailAddress.label')}
                            name="mail"
                            rules={[
                                {
                                    pattern: REGEX_EMAIL_VERIFY,
                                    message: t('supplier.input.emailAddress.wrongFormat'),
                                },
                            ]}
                        >
                            <Input autoComplete={'off'} disabled={mode === 'VIEW'}
                            />
                        </Form.Item>
                        {
                            initialValues && initialValues.id && (mode === 'VIEW') &&
                            <Text className={'__copy-to-clipboard-button'}
                                  copyable={{
                                      text: initialValues.mail,
                                      icon: <CopyOutlined/>,
                                      tooltips: [t('genDict.copy'), t('genDict.copied')],
                                  }}/>
                        }
                    </Col>

                </Row>
                {
                    !item &&
                    <Divider/>
                }
                {
                    !item && <Form.List name="contacts">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(field => (
                                    <>
                                        <Row gutter={16} className={'__add-contacts-wrapper'}>
                                            <Col span={23}>
                                                <Row gutter={16}>
                                                    <Col className="__theme-small-input-wrapper gutter-row" md={8}
                                                         sm={24} xs={24}>
                                                        <Form.Item
                                                            initialValue={ConstantTitles[0]}
                                                            name={[field.name, 'title']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.civility.required'),
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                placeholder={t('client.input.civility.label') + '*'}
                                                                disabled={mode === 'VIEW'}
                                                            >
                                                                {
                                                                    optionValues.map((option) => {
                                                                        return <Select.Option
                                                                            value={option}>{t(`genDict.${option}`)}</Select.Option>;
                                                                    })
                                                                }

                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row __theme-small-input-wrapper" md={8}
                                                         sm={24} xs={24}>
                                                        <Form.Item
                                                            name={[field.name, 'firstName']}
                                                            key="firstName"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.firstName.required'),
                                                                },
																{
																	pattern: REGEX_STRING_VERIFY,
																	message: t('errorMessage.stringWrongFormat'),
																},
                                                            ]}
                                                        >
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.firstName.label') + '*'}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row __theme-small-input-wrapper" md={8}
                                                         sm={24} xs={24}>
                                                        <Form.Item
                                                            name={[field.name, 'lastName']}

                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.lastName.required'),
                                                                },
																{
																	pattern: REGEX_STRING_VERIFY,
																	message: t('errorMessage.stringWrongFormat'),
																},
                                                            ]}>
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.lastName.label') + '*'}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}/>
                                                        </Form.Item>

                                                    </Col>
                                                </Row>
                                                <Row gutter={16}>
                                                    <Col
                                                        className="gutter-row __theme-small-input-wrapper __hover-copy-input"
                                                        md={8} sm={24} xs={24}>

                                                        <Form.Item
                                                            name={[field.name, 'email']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t('client.input.emailAddress.required'),
                                                                },
                                                                {
                                                                    pattern: REGEX_EMAIL_VERIFY,
                                                                    message: t('client.input.emailAddress.wrongFormat'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.emailAddress.label') + '*'}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        className="gutter-row __theme-small-input-wrapper __hover-copy-input"
                                                        md={8} sm={24} xs={24}>
                                                        <Form.Item
                                                            name={[field.name, 'phone']}
                                                            rules={[
                                                                {
                                                                    pattern: REGEX_PHONE_VERIFY,
                                                                    message: t('client.input.phone.wrongFormat'),
                                                                },
                                                            ]}
                                                        >
                                                            <Input className={'__input-small'} size={'small'}
                                                                   placeholder={t('client.input.phone.label')}
                                                                   autoComplete={'off'} disabled={mode === 'VIEW'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={1} sm={24} xs={24} style={{ textAlign: 'center' }}>
                                                <MinusCircleFilled className={'icon-primary __minus-icon'}
                                                                   onClick={() => {
                                                                       remove(field.name);
                                                                   }}/>
                                            </Col>
                                        </Row>

                                    </>
                                ))}

                                {
                                    fields.length === 0 && <Form.Item style={{ marginTop: '20px' }}>
                                        <Button className="__add-contact-btn roboto-regular" type="dashed"
                                                onClick={() => {
                                                    add();
                                                }} block icon={<PlusOutlined/>}>
                                            {
                                                t('supplier.button.addContactToSupplier')
                                            }
                                        </Button>
                                    </Form.Item>
                                }
                            </>
                        )}
                    </Form.List>
                }

            </>

        </div>
        {
            mode !== 'VIEW' && <Row>
                <Col span={24}>
                    <Space direction={'horizontal'} className={'__form-footer'}>
                        {
                            mode === 'CREATE' && <Form.Item>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        if (onCancel) {
                                            onCancel();
                                        }
                                    }}
                                    disabled={savingCustomer}
                                    shape="round"
                                    size={'large'}
                                    className="submit"
                                >
                                    {t('genDict.Cancel')}
                                </Button>
                            </Form.Item>
                        }
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={savingCustomer}
                                shape="round"
                                size={'large'}
                                className="submit"
                            >
                                {mode === 'CREATE' ? t('supplier.button.addSupplier') : t('supplier.button.saveSupplier')}
                            </Button>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        }
    </Form>);
}
