import * as React from 'react';
import { ReactElement } from 'react';
import { Route, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import DesktopLayout from 'shared/components/layout/Desktop/DesktopLayout';
import MobileLayout from 'shared/components/layout/Mobile/MobileLayout';
import useCurrentUser from 'shared/hooks/useCurrentUser';

import constructionsIcon from 'shared/icons/blueprint.svg';
import constructionsIconActive from 'shared/icons/active-icons/blueprint-active.svg';
import worksIcon from 'shared/icons/measure-17.svg';
import worksIconActive from 'shared/icons/active-icons/measure-17.svg';
import articlesIcon from 'shared/icons/box-3d-50.svg';
import articlesIconActive from 'shared/icons/active-icons/box-3d-50.svg';
import usersIconActive from 'shared/icons/active-icons/users-wm.svg';
import usersIcon from 'shared/icons/users-wm.svg';
import providersIconActive from 'shared/icons/active-icons/storage-unit.svg';
import providersIcon from 'shared/icons/storage-unit.svg';
import { useTranslation } from 'react-i18next';
import WorkSites from 'shared/views/work-sites/List';
import WorkSiteCreate from 'shared/views/work-sites/Create';
import WorkSiteEdit from 'shared/views/work-sites/Edit';
// import ClientEdit from 'shared/views/clients/Edit';
// @ts-ignore
import LiveChat from 'react-livechat';

import Clients from 'shared/views/clients/List';
import ClientEdit from 'shared/views/clients/Edit';

import Users from 'views/users/List';
import UserEdit from 'views/users/Edit';

import Suppliers from 'views/suppliers/List';
import SupplierEdit from 'views/suppliers/Edit';

import Articles from 'views/articles/List';
import ArticleEdit from 'views/articles/Edit';

import WorkTypes from 'views/work-types/List';
import WorkTypeView from 'views/work-types/View';

import Store from 'shared/contexts/GlobalContextStore';
import Settings from './settings/List';
import CustomizePrice from './articles/CustomizePrice';
import WorkTypeCreateModal from './work-types/WorkTypeCreateModal';
import AccountSettings from './account-settings/List';
import TabletPortraitLayout from 'shared/components/layout/TabletPortrait/TabletPortraitLayout';
import TabletLandscapeLayout from 'shared/components/layout/TabletLandScape/TabletLandscapeLayout';
import { isAdmin } from 'shared/contexts/AuthContext';
import PageNotFoundWrapper from './pages/404';
import ConnectionTimeoutComponent from 'shared/components/ConnectionTimeoutComponent';
import Parameters from 'shared/parameters';

// @ts-ignore
const ChatBotLisence = Parameters.ChatBotLisence;


interface MenuItem {
    route: string;
    icon: ReactElement;
    iconAction?: string;
    hideForAdmin?: boolean;
    hideForClient?: boolean;
    iconActive: ReactElement;
    label: string;
    warning?: ReactElement;
    daysBeforeQBExpiration?: number;
}

const Layouts = {
    'Mobile': MobileLayout,
    'TabletPortrait': TabletPortraitLayout,
    'TabletLandscape': TabletLandscapeLayout,
    'Desktop': DesktopLayout,
};
type Props = {}

const Layout: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();

    const { pathname, hash } = useLocation();
    const { currentUser } = useCurrentUser();
    if ((pathname + hash).toLowerCase().indexOf('account-settings#my-subscriptions') === -1 && (currentUser && currentUser.company && (!currentUser.company.subscription || currentUser.company.subscription.status === 'unpaid')) && !isAdmin()) {
        window.location.href = 'account-settings#my-subscriptions';
    }
    const history = useHistory();
    let layoutMode: 'Mobile' | 'TabletPortrait' | 'TabletLandscape' | 'Desktop' = 'Desktop';
    if (useMedia('(max-width: 1024px)')) {
        layoutMode = 'TabletLandscape';
    }
    if (useMedia('(max-width: 768px)')) {
        layoutMode = 'TabletPortrait';
    }
    if (useMedia('(max-width: 576px)')) {
        layoutMode = 'Mobile';
    }
    const topMenuItems: MenuItem[] = [
        {
            route: '/chantiers',
            icon: <img src={constructionsIcon} alt="constructions"/>,
            iconAction: '/chantiers/create',
            hideForClient: false,
            hideForAdmin: true,
            iconActive: <img src={constructionsIconActive} alt="constructions"/>,
            label: t('genDict.WorkSites'),
        },
        {
            route: '/ouvrages',
            icon: <img src={worksIcon} alt="works"/>,
            iconAction: '/ouvrages/create',
            iconActive: <img src={worksIconActive} alt="works"/>,
            label: t('genDict.WorkType'),
        },
        {
            route: '/articles',
            iconAction: '/articles#add',
            icon: <img src={articlesIcon} alt="articles"/>,
            iconActive: <img src={articlesIconActive} alt="articles"/>,
            label: t('genDict.Articles'),
        },
    ];

    const bottomMenuItems: MenuItem[] = [
        {
            route: '/clients',
            iconAction: '/clients#add',

            hideForClient: false,
            hideForAdmin: true,
            icon: <img src={usersIcon} alt="users"/>,
            iconActive: <img src={usersIconActive} alt="users"/>,
            label: t('genDict.Clients'),
        },
        {
            route: '/fournisseurs',
            iconAction: '/fournisseurs#add',
            hideForClient: false,
            hideForAdmin: true,
            icon: <img src={providersIcon} alt="blueprint"/>,
            iconActive: <img src={providersIconActive} alt="blueprint"/>,
            label: t('genDict.Suppliers'),
        },
        {
            route: '/utilisateurs',
            iconAction: '',
            hideForClient: true,
            hideForAdmin: false,
            icon: <img src={usersIcon} alt="users"/>,
            iconActive: <img src={usersIconActive} alt="users"/>,
            label: t('genDict.Users'),
        },
    ];
    const selectedMenu = pathname;

    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout = Layouts[layoutMode];

    let backLink = null;
	// eslint-disable-next-line
    if (/\/clients\/[^\/]+/.test(pathname)) {
        backLink = {
            text: t('client.button.listClient'),
            link: '/clients',
        };
    }
	// eslint-disable-next-line
    if (/\/fournisseurs\/[^\/]+/.test(pathname)) {
        backLink = {
            text: t('supplier.button.listSupplier'),
            link: '/fournisseurs',
        };
    }
	// eslint-disable-next-line
    if (/\/articles\/[^\/]+/.test(pathname)) {
        backLink = {
            text: t('article.button.listArticles'),
            link: '/articles',
        };
    }
	// eslint-disable-next-line
    if (/\/ouvrages\/[^\/]+/.test(pathname)) {
        backLink = {
            text: t('workType.button.listWorkTypes'),
            link: '/ouvrages',
        };
    }
	// eslint-disable-next-line
    if (/\/utilisateurs\/[^\/]+/.test(pathname)) {
        backLink = {
            text: t('users.button.listUsers'),
            link: '/utilisateurs',
        };
    }
	// eslint-disable-next-line
    if (/\/chantiers\/[^\/]+/.test(pathname)) {
        backLink = {
            text: t('workSite.button.listWorkSites'),
            link: '/chantiers',
        };
    }

    return (
        <Store>
            <AppLayout
                topMenuItems={topMenuItems}
                bottomMenuItems={bottomMenuItems}
                selectedMenu={selectedMenu}
                backLink={backLink}
                onMenuClick={handleMenuClick}
            >
                <ConnectionTimeoutComponent/>
                <Switch>
                    {/*workSites*/}
                    <Route exact path="/chantiers" component={WorkSites}/>
                    <Route exact path="/chantiers/create" component={WorkSiteCreate}/>
                    <Route exact path="/chantiers/:id/edit" component={WorkSiteCreate}/>
                    <Route exact path="/chantiers/:id/view" component={WorkSiteEdit}/>
                    {/*<Route exact path="/clients/:clientId/:pageMode/:subSection" component={ClientEdit}/>*/}

                    {/*clients*/}
                    <Route exact path="/clients" component={Clients}/>
                    <Route exact path="/clients/:clientId/:pageMode" component={ClientEdit}/>
                    <Route exact path="/clients/:clientId/:pageMode/:subSection" component={ClientEdit}/>

                    {/*users*/}
                    <Route exact path="/utilisateurs" component={Users}/>
                    <Route exact path="/utilisateurs/:userId/:pageMode" component={UserEdit}/>
                    {/*<Route exact path="/clients/:clientId/:pageMode/:subSection" component={ClientEdit}/>*/}

                    {/*suppliers*/}
                    <Route exact path="/fournisseurs" component={Suppliers}/>
                    <Route exact path="/fournisseurs/:supplierId/:pageMode" component={SupplierEdit}/>
                    <Route exact path="/fournisseurs/:supplierId/:pageMode/:subSection" component={SupplierEdit}/>

                    {/*articles*/}
                    <Route exact path="/articles" component={Articles}/>
                    <Route exact path="/articles/:articleId/:pageMode" component={ArticleEdit}/>
                    <Route exact path="/customize-price" component={CustomizePrice}/>
                    {/*<Route exact path="/fournisseurs/:supplierId/:pageMode" component={SupplierEdit}/>*/}

                    {/*work types*/}
                    <Route exact path="/ouvrages" component={WorkTypes}/>
                    <Route exact path="/ouvrages/create" component={WorkTypeCreateModal}/>
                    <Route exact path="/ouvrages/:workTypeId/edit" component={WorkTypeCreateModal}/>
                    <Route exact path="/ouvrages/:workTypeId/view" component={WorkTypeView}/>
                    {/*<Route exact path="/articles/customize-price" component={CustomizePrice}/>*/}
                    {/*<Route exact path="/fournisseurs/:supplierId/:pageMode" component={SupplierEdit}/>*/}

                    {/*Settings*/}
                    <Route exact path="/settings" component={Settings}/>
                    <Route exact path="/account-settings" component={AccountSettings}/>


                    <Route exact path="/">
                        <Redirect to={isAdmin() ? '/utilisateurs' : '/chantiers'}/>
                    </Route>
                    <Route component={PageNotFoundWrapper}/>

                    {/*<Route exact path="/all-components" component={AllComponents}/>*/}
                </Switch>
                <LiveChat license={ChatBotLisence}/>
            </AppLayout>
        </Store>
    );
};

export default Layout;
