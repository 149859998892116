import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Button as BaseButton, Col, Row, Select, Space, Switch, Typography } from 'antd';

import { useTranslation } from 'react-i18next';
import { Table, Tooltip, Modal, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import { ExclamationCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { InfoCircleFilled } from '@ant-design/icons';


import './index.scss';
import { useEffect, useState } from 'react';
import { getDefaultPageSize, paginationParams, sortPrice, useLayoutMedia } from 'shared/services/Utility';
import { deleteWorkTypeById, fetchWorkTypes } from 'shared/services/workService';
import {
    ConstantCeilingPlasterboardFixing, ConstantCeilingSlabsFixing,
    ConstantCenterDistance, ConstantCenterDistanceIfCeiling,
    ConstantPartitionType,
    ConstantCeilingHanger,
    ConstantWorkFamiliesMapping, ConstantWorkTypeConstantsAndKeysMappings,
    ConstantWorkTypeFamily,
} from 'shared/constants/AppConstants';
import { isAdmin } from 'shared/contexts/AuthContext';
import { DIGITS_TO_SHOW_AFTER_DOT } from 'shared/constants/AppConstants';

const { Text } = Typography;
type Props = {
    t: Function,
}

const { confirm } = Modal;


function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const List: React.FunctionComponent<Props> = () => {
    const [items, setItems] = useState([]);
    const [fetchingItems, setFetchingItems] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [deletingItem, setDeletingItem] = React.useState(false);
    const [showMoreFilters, setShowMoreFilters] = React.useState(false);
    const [filtersInputs, setFiltersInputs] = React.useState({} as any);
    const [sort, setSort] = useState(null as any);
    const isDesktop = useLayoutMedia(['Desktop']);
    const history = useHistory();
    const { t } = useTranslation();
    const [filterByFamily, setFilterByFamily] = useState('');
    const [filterCreatedByMe, setFilterCreatedByMe] = useState(false);

    const refresh = () => {
        if (!filterByFamily && !filterCreatedByMe) {
            return;
        }
        setFetchingItems(true);
        let params: any = { limit: getDefaultPageSize(), offset: getDefaultPageSize() * (page - 1 || 0) };
        if (filterByFamily) {
            params['family'] = filterByFamily;
        }
        if (filtersInputs) {
            params = { ...params, ...filtersInputs };
        }

        if (sort) {
            params = { ...params, sort };
        }


        if (filterCreatedByMe && !isAdmin()) {
            params = { ...params, myWorkTypes: filterCreatedByMe };
        }

        fetchWorkTypes(params).then((response) => {
            if (response && response.data && response.data.workTypes.workTypes) {
                setItems(response.data.workTypes.workTypes);
                setTotal(response.data.workTypes.total);
            }
        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        }).finally(() => {
            setFetchingItems(false);
        });
    };

    // eslint-disable-next-line
    useEffect(() => {
        if (page === 1) {
            refresh();
        } else {
            setPage(1);
        }
    }, [filterByFamily, filtersInputs, filterCreatedByMe]);
    useEffect(refresh, [page, sort]);

    const renderFilters = () => {
        if (!showMoreFilters || !filterByFamily) {
            return '';
        }
        const filters: any[] = [];

        if (filterByFamily === 'framePartition' || filterByFamily === 'alveolarPartition'
            || filterByFamily === 'brickPartition') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['partitionType']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'partitionType': e });
                }}
                placeholder={t(`workType.search.partitionType`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantPartitionType[filterByFamily].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }
        if (filterByFamily === 'againstPartition') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['counterPartitionType']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'counterPartitionType': e });
                }}
                placeholder={t(`workType.search.counterPartitionType`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantPartitionType[filterByFamily].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }
        if (ConstantWorkFamiliesMapping.find((e: any) => filterByFamily === e.name)) {
            ConstantWorkFamiliesMapping.find((e: any) => filterByFamily === e.name)!.fields!.forEach((field: any) => {
                filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                    showSearch

                    style={{ width: '100%', marginTop: '10px' }} value={filtersInputs[field]}
                    onChange={(e) => {
                        setFiltersInputs({ ...filtersInputs, [field]: e });
                    }}
                    placeholder={t(`workType.search.${field}`)} allowClear={true}>
                    <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                    {
                        // @ts-ignore
                        ConstantWorkTypeConstantsAndKeysMappings[field] && ConstantWorkTypeConstantsAndKeysMappings[field].map((option: any) => {
                            return <Select.Option value={option}>{option}</Select.Option>;
                        })
                    }
                </Select>
                </Col>);
            });

        }
        if (filterByFamily === 'framePartition') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['centerDistance']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'centerDistance': e });
                }}
                placeholder={t(`workType.search.centerDistance`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCenterDistance.map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        } else if (filterByFamily === 'ceilingPlasterboard') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['centerDistance']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'centerDistance': e });
                }}
                placeholder={t(`workType.search.centerDistance`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCenterDistanceIfCeiling.map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }

        if (filterByFamily === 'ceilingPlasterboard' || filterByFamily === 'ceilingSlabs') {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['ceilingHanger']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'ceilingHanger': e, ceilingFixing: null });
                }}
                placeholder={t(`workType.search.ceilingHanger`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCeilingHanger.map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }

        if (filterByFamily === 'ceilingPlasterboard' && filtersInputs['ceilingHanger'] && filtersInputs['ceilingHanger'] !== t('genDict.Other')) {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['ceilingFixing']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'ceilingFixing': e });
                }}
                placeholder={t(`workType.search.ceilingFixing`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCeilingPlasterboardFixing[filtersInputs['ceilingHanger']] && ConstantCeilingPlasterboardFixing[filtersInputs['ceilingHanger']].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        } else if (filterByFamily === 'ceilingSlabs' && filtersInputs['ceilingHanger'] && filtersInputs['ceilingHanger'] !== t('genDict.Other')) {
            filters.push(<Col lg={8} md={12} xs={24} sm={24}><Select
                showSearch

                style={{ width: '100%', marginTop: '10px' }} value={filtersInputs['ceilingFixing']}
                onChange={(e) => {
                    setFiltersInputs({ ...filtersInputs, 'ceilingFixing': e });
                }}
                placeholder={t(`workType.search.ceilingFixing`)} allowClear={true}>
                <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                {
                    // @ts-ignore
                    ConstantCeilingSlabsFixing[filtersInputs['ceilingHanger']] && ConstantCeilingSlabsFixing[filtersInputs['ceilingHanger']].map((option: any) => {
                        return <Select.Option value={option}>{option}</Select.Option>;
                    })
                }
            </Select>
            </Col>);
        }
        return filters;

    };

    const columns = [
        {
            title: t('workType.listTitle.reference'),
            key: 'reference',
            render: (a: any) => (
                <Link to={`/ouvrages/${a.id}/view`} className={'__theme-text __bold'}>{a.reference}</Link>),
            sorter: (a: any, b: any) => sortAlpha(a.reference, b.reference),
        },
        {
            title: t('workType.listTitle.name'),
            key: 'name',

            render: (a: any) => (
                <Link to={`/ouvrages/${a.id}/view`}>{a.name}</Link>),
            sorter: (a: any, b: any) => sortAlpha(a.name, b.name),
        },
        {
            title: t('workType.listTitle.family'),
            dataIndex: 'family',
            key: 'family',
            render: (a: string) => (<span>{t(`workType.family.${a}`)}</span>),
            sorter: (a: any, b: any) => sortAlpha(a.family, b.family),
        },
        {
            title: t('workType.listTitle.plankType'),
            dataIndex: 'plankType',
            key: 'plankType',

            sorter: (a: any, b: any) => sortAlpha(a.plankType, b.plankType),
        },
        {
            title: t('workType.listTitle.centerDistance'),
            dataIndex: 'centerDistance',
            key: 'centerDistance',

            sorter: (a: any, b: any) => sortAlpha(a.centerDistance, b.centerDistance),
        },
        {
            title: t('workType.listTitle.insulation'),
            dataIndex: 'insulation',
            key: 'insulation',

            sorter: (a: any, b: any) => sortAlpha(a.insulation, b.insulation),
        },
        {
            title: t('workType.listTitle.sellingPrice'),
            render: (a: any) => {
                if (!a.isPersonalized) {
                    return <Space size={7}>
                        <span className={'text-disabled'}>
					{a.sellingPrice ? parseFloat(a.sellingPrice).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
							</span>
                        <span className={'text-disabled'}>
                        <Tooltip placement={'top'} title={t('workType.tooltip.workTypePersonalize')}>
									<InfoCircleFilled className={'icon-disabled'}/>
								</Tooltip>
							</span>
                    </Space>;
                } else {
                    return !a.sellingPrice ? <></> :
                        <span>{parseFloat(a.sellingPrice).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</span>;
                }
            },
            key: 'sellingPrice',
            sorter: (a: any, b: any) => sortPrice(a.sellingPrice, b.sellingPrice),
        },
        {
            title: <div className={'text-center'}>{t('workType.listTitle.isAdminCreated')}</div>,
            render: (a: any) => (
                <div className={'text-center'}>
                    {
                        a.isAdminCreated ? <></> :
                            <CheckOutlined className={'round primary table-icons'}/>
                    }
                </div>),
        },
        {
            fixed: 'right',
            title: t('workType.listTitle.actions'),
            render: (a: any) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('workType.tooltip.editWorkType')}>
                            <div/>
                            <EditButton onClick={() => history.push('/ouvrages/' + a.id + '/view')}/>
                        </Tooltip>

                        <Tooltip placement={'top'} title={a.isDeletable ? t('workType.tooltip.deleteWorkType') : ''}>
                            <div/>
                            <DeleteButton disabled={!a.isDeletable} onClick={() =>
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('workType.confirmation.msg'),
                                    okText: t('workType.button.deleteWorkType'),
                                    cancelText: t('genDict.Cancel'),
                                    content: t('workType.confirmation.deleteWorkType'),// TODO
                                    okButtonProps: { loading: deletingItem, danger: true },
                                    onOk: async () => {
                                        setDeletingItem(true);
                                        deleteWorkTypeById(a.id).then(() => {
                                            notification.success({
                                                message: t('genDict.success'),
                                                description: t('workType.notification.workTypeDeletedSuccess'),
                                            });
                                            refresh();
                                        }).catch(e => {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }).finally(() => {
                                            setDeletingItem(false);
                                        });
                                    },
                                })}/>
                        </Tooltip>
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];

    if (isAdmin()) {
        columns.splice(7, 1);
    }

    return (
        <ListView
            pageTitle={t('genDict.page-title', { title: t('pageTitles.listWorkTypes') })}
            title={t(isAdmin() ? 'genDict.WorkTypeAdmin' : 'genDict.WorkType')}
            paddingBottomRequired={true}
            searchBar={
                (<Space className={'__first-item-flex-1 filter-wrapper last-align-self-auto'}
                        style={{ justifyContent: 'space-between', width: '100%' }}>
                    <div className="filter-item">
                        <div style={{ flex: 1 }}>
                            <div className="margin-in-small full-width-in-small" style={{ display: 'inline-block' }}>
                                <Select style={{ width: '220px' }} value={filterByFamily || undefined}
                                        onChange={(e) => {
                                            setFilterByFamily(e);
                                            setFiltersInputs({});
                                        }}
                                        onClear={() => {
                                            setItems([]);
                                            setTotal(0);
                                        }}
                                        placeholder={t('workType.search.filterByFamily')} allowClear={true}>
                                    {
                                        ConstantWorkTypeFamily.map((option: any) => {
                                            return <Select.Option
                                                value={option}>{t(`workType.family.${option}`)}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </div>
                            &nbsp;
                            &nbsp;							
							<BaseButton
								type={filterByFamily && showMoreFilters ? 'primary' : 'default'}
								disabled={!filterByFamily}
								onClick={() => {
									// setDuplicateItem(false);
									setShowMoreFilters(!showMoreFilters);
								}}
								shape="round"
								size={'middle'}
								className={filterByFamily && showMoreFilters ? '__button-inverted primary submit' : 'submit'}
							>
								{showMoreFilters ? t('workType.button.hideFilters') : t('workType.button.addFilters')}
							</BaseButton>							

                            &nbsp;
                            &nbsp;

                            <div style={{ display: 'inline-block' }} className={'margin-top-small'}>
                                {
                                    !isAdmin() && <>
                                        <Switch defaultChecked={false} checked={filterCreatedByMe}
                                                onChange={setFilterCreatedByMe}/>&nbsp;&nbsp;<span
                                        className={'__theme-text __disabled'}>{t('workType.search.myWorks')}</span>

                                    </>
                                }
                            </div>
                            <Row gutter={16}>
                                {
                                    renderFilters()
                                }
                            </Row>
                        </div>
                    </div>
                </Space>)
            }
            createButton={(
                <BaseButton type="primary"
                            shape="round"
                            size={'large'} onClick={() => {
                    history.push('/ouvrages/create');
                }}>
                    {isDesktop ? t('workType.button.addWorkTypeMenu') : t('genDict.add')}
                </BaseButton>
            )}
        >

            <Table
                loading={fetchingItems}
                locale={{
                    emptyText: (
                        !filterByFamily && !filterCreatedByMe ? <>
                            <Space style={{ padding: '10px' }}>
                                <Text className={'primary-black'}>{t('workType.table.selectFamilyToFilter')}</Text>
                            </Space>
                        </> : <>
                            <Space style={{ padding: '10px' }}>
                                <Text className={'primary-black'}>{t('workType.table.emptyMessage')}</Text>
                                <div className={'flat-button primary'} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    history.push('/ouvrages/create');
                                }}>
                                    {t('workType.button.addWorkTypeMenu')}
                                </div>
                            </Space>
                        </>
                    ),
                }}
                onChange={
                    (pagination, filters, sorter: any, extra) => {
                        if (sorter) {
                            if (sorter.column) {
                                setSort({ field: sorter.columnKey, order: sorter.order === 'ascend' ? 'ASC' : 'DESC' });
                            } else {
                                setSort(null);
                            }
                        } else {
                            setSort(null);
                        }
                    }
                }
				showSorterTooltip ={false}
                pagination={{
                    showSizeChanger: true,
                    current: page,
                    onShowSizeChange: (currentPage, pageSize) => {
                        paginationParams(pageSize);
                        refresh();
                    },
                    onChange: (e: any) => setPage(e),
                    total: (filterByFamily || filterCreatedByMe) ? total : 0,
                    defaultPageSize: getDefaultPageSize(),
                }}
                // @ts-ignore
                columns={columns}
                dataSource={!filterByFamily && !filterCreatedByMe ? [] : items.map((e: any) => {
                    return { ...e, key: e.id };
                })}
            >workTypes
            </Table>
        </ListView>
    );
};
export default List;

