import gql from 'graphql-tag';
import { client, clientWithErrors } from './ApolloClient';

const GET_WORK_TYPES = gql`query ( $ignoreWorkTypes : [String!] , $ceilingFixing: String, $ceilingHanger: String, $ceilingSupport: String, $centerDistance: String, $family: String, $insulation: String, $insulationType: String, $limit: Int, $middleSupport: String, $offset: Int, $partitionType: String, $counterPartitionType: String, $plankLength: String, $plankType: String, $sort: WorkTypeSortInput, $thickness: String, $myWorkTypes: Boolean){
  workTypes(ignoreWorkTypes: $ignoreWorkTypes ceilingFixing: $ceilingFixing,ceilingHanger: $ceilingHanger, ceilingSupport: $ceilingSupport, centerDistance: $centerDistance, family: $family, insulation: $insulation, insulationType: $insulationType, limit: $limit, middleSupport: $middleSupport, offset: $offset, partitionType: $partitionType, counterPartitionType: $counterPartitionType, plankLength: $plankLength, plankType: $plankType, sort: $sort, thickness: $thickness, myWorkTypes: $myWorkTypes){
    total
    workTypes{
      id
      reference
      rows{
        id
        type
        order
        quantity
        unitPrice
        price
        ...on WorkTypeArticleRow{
          article {
            id
            name
            adminPrice
            price
            isPersonalized
            reference
            pictureUrl
            unit
          }
        }

        ...on WorkTypeEffortRow{
          effort {
            id
            name
            adminPrice
            price
            unit
            reference
            isPersonalized
          }
          isPersonalized
          isAdminCreated
        }
      }
      name
      family
      sellingPrice
      cost
      partitionType
      counterPartitionType
      plankType
      plankLength
      centerDistance
      insulation
      isAdminCreated
      isDeletable
      isPersonalized
      isUsed
    }
  }
}  `;
const GET_WORK_TYPE_BY_ID = gql`
  query WorkType($id: ID!){
    workType(id: $id){
      id
      rows{
        id
        type
        order
        name
        quantity
        unitPrice
        price
        ...on WorkTypeArticleRow{
          article {
            id
            reference
            name
            unit
            pictureUrl
            adminPrice
            price
          }
        }

        ...on WorkTypeEffortRow{
          effort {
            id
            name
            reference
            unit
            adminPrice
            price
          }
        }
      }
      reference
      name
      family
      sellingPrice
      cost
      partitionType
      counterPartitionType
      plankType
      plankLength

      ceilingSupport
      insulationType
      centerDistance
      insulation
      middleSupport
      ceilingHanger
      ceilingFixing
      thickness
      isAdminCreated
      isDeletable
      isPersonalized
      isUsed
    }
  }`;
const DELETE_WORK_TYPE_BY_ID = gql`mutation($id: ID!){ workType_delete(id: $id)}`;
const DUPLICATE_WORK_TYPE_BY_ID = gql`mutation($id: ID!, $name: String!){
  workType_duplicate(id: $id, name: $name){
    id
    reference
  }
}`;
const PERSONALIZE_ARTICLE = gql`
  mutation($id: ID!, $price: String!){
    articles_personnalizeOne(input: {id: $id, price: $price}){
      id
      reference
      price
      isPersonalized
    }
  }`;
const PERSONALIZE_EFFORT = gql`
  mutation($id: ID!, $unitPrice: String!){
    workType_editEffortRowPrice(input: {id: $id, unitPrice: $unitPrice}){
      id
      reference
    }
  }`;
const CREATE_WORK_TYPE = gql`mutation($name: String!, $family: String!, $sellingPrice: String,$middleSupport: String, $partitionType: String, $counterPartitionType: String, $plankType: String,$insulationType: String, $ceilingSupport: String,$ceilingHanger: String, $plankLength: String, $centerDistance: String, $ceilingFixing: String, $insulation: String, $thickness: String){
  workType_create(input: {name: $name, family: $family, sellingPrice: $sellingPrice, partitionType: $partitionType, counterPartitionType: $counterPartitionType ,middleSupport: $middleSupport,ceilingSupport: $ceilingSupport,insulationType: $insulationType, plankType: $plankType, ceilingHanger: $ceilingHanger, plankLength: $plankLength, ceilingFixing: $ceilingFixing, centerDistance: $centerDistance, insulation: $insulation, thickness : $thickness}){
    id
    rows{
      id
      type
      order
      quantity
      unitPrice
      price
    }
    name
    family
    sellingPrice
    cost
    partitionType
    counterPartitionType
    plankType
    plankLength
    centerDistance
    insulation
    thickness
    isAdminCreated
  }
}`;
const EDIT_WORK_TYPE = gql` mutation($id: ID!, $name: String!, $family: String!, $sellingPrice: String,$middleSupport: String, $partitionType: String, $counterPartitionType: String, $plankType: String,$insulationType: String, $ceilingSupport: String,$ceilingHanger: String, $plankLength: String, $centerDistance: String, $ceilingFixing: String, $insulation: String, $thickness: String){
  workType_edit(id: $id, input: {name: $name, family: $family, sellingPrice: $sellingPrice, partitionType: $partitionType, counterPartitionType: $counterPartitionType ,middleSupport: $middleSupport,ceilingSupport: $ceilingSupport,insulationType: $insulationType, plankType: $plankType, ceilingHanger: $ceilingHanger, plankLength: $plankLength, ceilingFixing: $ceilingFixing, centerDistance: $centerDistance, insulation: $insulation, thickness : $thickness}){
    id
    reference
    name
    family
    sellingPrice
    cost
    partitionType
    counterPartitionType
    plankType
    plankLength
    centerDistance
    insulation
    ceilingSupport
    insulationType
    ceilingHanger
    middleSupport
    ceilingFixing
    thickness
    isAdminCreated
    isDeletable
  }
}`;
const EDIT_WORK_TYPE_ROW = gql`  mutation($id: ID!, $rowId: ID!, $input: WorkTypeRowInput!){
  workType_editRow(id: $id, rowId: $rowId, input: $input){
    id
    reference
    name
    family
    sellingPrice
    cost
    partitionType
    counterPartitionType
    plankType
    plankLength
    centerDistance
    insulation
    ceilingHanger
    middleSupport
    ceilingFixing
    thickness
    isAdminCreated
    isDeletable
    isPersonalized
  }
}`;
const EDIT_WORK_SITE_WORK_TYPE_ROW = gql`  mutation($id: ID!, $rowId: ID!, $input: PriceUpdatableInput!){
  workSite_editArticleRow(id: $id, rowId: $rowId, input: $input){
    id
  }
}`;

export const fetchWorkTypes = (variables?: any) => {
  return clientWithErrors(client.query({ query: GET_WORK_TYPES, variables }));
};

export const fetchWorkTypeById = (id: any) => {
  return clientWithErrors(client.query({ query: GET_WORK_TYPE_BY_ID, variables: { id } }));
};


export const deleteWorkTypeById = async (id: string) => {
  return clientWithErrors(client.mutate({ variables: { id }, mutation: DELETE_WORK_TYPE_BY_ID }));
};
export const personalizeArticlePrice = async (id: string, price: string) => {
  return clientWithErrors(client.mutate({ variables: { id, price }, mutation: PERSONALIZE_ARTICLE }));
};
export const personalizeEffortPrice = async (id: string, price: string) => {
  return clientWithErrors(client.mutate({ variables: { id, unitPrice: price }, mutation: PERSONALIZE_EFFORT }));
};
// export const saveWorkTypeById = async (id: string) => {
//     return clientWithErrors(client.mutate({ variables: { id }, mutation: SAVE_WORK_TYPE_BY_ID });
// };

export const duplicateWorkTypeById = async (data: any) => {
  return clientWithErrors(client.mutate({ variables: data, mutation: DUPLICATE_WORK_TYPE_BY_ID }));
};

export const createNewWorkType = async (data: any) => {
  return clientWithErrors(client.mutate({ variables: data, mutation: CREATE_WORK_TYPE }));
};

export const updateWorkType = async (data: any) => {
  return clientWithErrors(client.mutate({ variables: data, mutation: EDIT_WORK_TYPE }));
};

export const updateWorkTypeRow = async (data: any) => {
  return clientWithErrors(client.mutate({ variables: data, mutation: EDIT_WORK_TYPE_ROW }));
};

export const updateWorkSiteWorkTypeRow = async (data: any) => {
  return clientWithErrors(client.mutate({ variables: data, mutation: EDIT_WORK_SITE_WORK_TYPE_ROW }));
};
