import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Tabs, Card, Typography, Col, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import './index.scss';
import { useState } from 'react';
import Title from 'antd/es/typography/Title';
import ViewButton from 'shared/components/Ant/Buttons/ViewButton';
import { useEffect } from 'react';
import { generateFullName, useLayoutMedia } from 'shared/services/Utility';
import SupplierCreateModal from './SupplierCreateModal';
import ContactsList from './ContactsList';
import { Supplier } from './Supplier.model';
import { fetchSupplierById } from 'shared/hooks/useSuppliers';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import { useLocation } from 'react-use';
import PurchaseOrderList from './PurchaseOrderList';
import NewSubSidebarLayout from 'shared/components/layout/NewSubSidebarLayout/NewSubSidebarLayout';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

type Props = {
    item?: Supplier,
    t?: Function,
}

const Edit: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();
    const RouteParams: any = useParams();
    const history = useHistory();
    const location = useLocation();

    const [item, setItem] = useState({} as Supplier);
    const [tabsPosition] = useState(useLayoutMedia(['Desktop', 'TabletLandscape']) ? 'left' : 'top' as any);
    const [fetchingItem, setFetchingItem] = React.useState(false);
    const isViewMode = RouteParams['pageMode'] && RouteParams['pageMode'].toLowerCase() === 'view';

    const informationHeader = (<div className={'__form-header'}>
        <Title level={5} className={'__form-header-title'}>{t('genDict.Information')}</Title>
        {isViewMode ? <EditButton tooltipText={t('genDict.tooltip.editMode')} notFilled={true}
                                  style={{ color: '#0033A0', fontSize: '16px' }}
                                  onClick={() => history.push('/fournisseurs/' + item.id + '/edit')}/> :
            <ViewButton tooltipText={t('genDict.tooltip.viewMode')} style={{ color: '#0033A0', fontSize: '16px' }}
                        onClick={() => history.push(`/fournisseurs/${item.id}/view`)}/>}

    </div>);
    const refresh = () => {
        setFetchingItem(true);
        fetchSupplierById(RouteParams['supplierId']).then((response) => {
            if (response && response.data && response.data.provider) {
                setItem(response.data.provider);
            }

        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        }).finally(() => {
            setFetchingItem(false);
        });
    };
    const data = [
        {
            name: 'genDict.Information',
            hash: '#information',
            count: null,
            component: <>
                {
                    informationHeader
                }
                <SupplierCreateModal item={item}
                                     onCancel={(reload) => {
                                         if (reload) {
                                             refresh();
                                         }
                                     }} mode={isViewMode ? 'VIEW' : 'EDIT'}/>
            </>,
        },
        {
            name: 'genDict.Contacts',
            count: 0,
            hash: '#contacts',
            component: <ContactsList onChange={refresh} customer={item}/>,
        },
        {
            name: 'genDict.PurchaseOrder',
            hash: '#purchaseOrder',
            count: null,
            component: <PurchaseOrderList onChange={refresh} provider={item}/>,
        },
    ];

    let aI = 0;
    if (location['hash']) {
        aI = data.map((d) => d.hash.toLowerCase()).indexOf(location['hash'].toLowerCase());
    }

    const getTabName = (index: any) => {
        if (!item || !item.id) {
            return t(data[index].name);
        }
        if (index === 1) {
            return `${t(data[index].name)} (${item.contacts.length})`;
        }
        if (index === 2) {
            return `${t(data[index].name)} (${item.purchaseOrders.length})`;
        }
        return t(data[index].name);
    };
	// eslint-disable-next-line
    useEffect(refresh, []);

    return (
        <NewSubSidebarLayout
            pageTitle={t('genDict.page-title', { title: t(isViewMode ? 'pageTitles.viewSupplier' : 'pageTitles.editSupplier') })}
            loading={fetchingItem && !(item && item.id)}
            title={
                (

                    <Row align={'middle'} gutter={20} style={{width: '100%' }}>
                        <Col flex="auto" style={{ marginBottom: '5px', }}>
                            <Title level={2} style={{ maxWidth: '100%' }}>
                                <div className={'__header-title __header-title-margin'}
                                     style={{ maxWidth: '100%' }}>
                                    <Paragraph style={{ width: '100%' }} ellipsis={{
                                        rows: 2,
                                        expandable: generateFullName(item).length > 10,
                                        symbol: t('genDict.more'),
                                    }}>{generateFullName(item)}</Paragraph>
                                </div>
                            </Title>
                        </Col>
                    </Row>

                )
            }
        >
            <Tabs tabPosition={tabsPosition} activeKey={aI + ''} onChange={(e: any) => {
                history.push(data[e].hash);
            }}>
                {
                    data.map((item: any, index: any) => {
                        return <TabPane tab={getTabName(index)} key={index}>
                            <Card className={`${aI === 1 && 'transparent-content'}`}>
                                {item.component}
                            </Card>
                        </TabPane>;
                    })
                }
            </Tabs>
        </NewSubSidebarLayout>
    );
};
export default Edit;
