import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Space, Card } from 'antd';
import { useHistory } from 'react-router-dom';


import './index.scss';
import Title from 'antd/es/typography/Title';
import NewSubSidebarLayout from 'shared/components/layout/NewSubSidebarLayout/NewSubSidebarLayout';
import { useLocation } from 'react-use';
import ArticleCategories from './ArticleCategories';
import { isAdmin } from 'shared/contexts/AuthContext';
import CompanyProfile from './CompanyProfile.form';
import TemplatesForm from './Templates.form';
import { useLayoutMedia } from 'shared/services/Utility';

const { TabPane } = Tabs;

type Props = {
    t?: Function,
}


const Settings: React.FunctionComponent<Props> = () => {

    const { t } = useTranslation();
    const location = useLocation();
    let aI = 0;
    const history = useHistory();
    const ListItems = isAdmin() ? [
            {
                name: 'article.category.menuText',
                hash: '#categories',
                count: null,
                component: <ArticleCategories/>,

            },
        ]
        :
        [
            {
                name: 'genDict.documents',
                hash: '#documents',
                count: null,
                component: <CompanyProfile mode={'EDIT'}/>,
            },
            {
                name: 'genDict.emails',
                hash: '#emails',
                count: null,
                component: <TemplatesForm/>,

            },
            {
                name: 'article.category.menuText',
                hash: '#categories',
                count: null,
                component: <ArticleCategories/>,

            },
        ];


    if (location['hash']) {
        aI = ListItems.map((d) => d.hash).indexOf(location['hash'].toLowerCase());
    } else if (isAdmin()) {
        aI = 0;
    }

    const tabsPosition = useLayoutMedia(['Desktop', 'TabletLandscape']) ? 'left' : 'top';

    return (
        <NewSubSidebarLayout
            title={
                (
                    <Space size={20} align={'center'} direction="horizontal">
                        <Title level={2}>
                            <div className={'__header-title __header-title-margin'}>
                                {t('genDict.settings')}
                            </div>
                        </Title>
                    </Space>)
            }
            leftSidebarItem={ListItems}
        >
            <Tabs tabPosition={tabsPosition} activeKey={aI + ''} onChange={(e: any) => {
                history.push(ListItems[e].hash);
            }}>
                {
                    ListItems.map((item: any, index: any) => {
                        return <TabPane tab={t(item.name)} key={index}>
                            <Card className={`${aI === 2 && 'transparent-content'}`}>
                                {item.component}
                            </Card>
                        </TabPane>;
                    })
                }
            </Tabs>
        </NewSubSidebarLayout>
    );
};
export default Settings;
