import * as React from 'react';
import {
    Card,
    Row,
    Typography,
    Col, Skeleton,
} from 'antd';
import Title from 'antd/es/typography/Title';

import {
    SaveFilled,
    RedoOutlined,
} from '@ant-design/icons';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useWorkSiteById from '../../hooks/work-sites/useWorkSiteById';
import { useParams } from 'react-router';
import { useState } from 'react';
import BasicInfo from './components/BasicInfo';
import Suppliers from './components/Suppliers';
import InfoGraph from './components/InfoGraph';
import SecondRow from './components/SecondRow';
import { useLayoutMedia } from '../../services/Utility';

const { Paragraph } = Typography;
const View: React.FunctionComponent<any> = () => {
    const { t } = useTranslation();

    const RouteParams: any = useParams();
    const id = RouteParams['id'];
    const { item: workSite, refetch: refresh, loading } = useWorkSiteById(id);

    const [updating, setUpdating] = useState(false);

    const isLandscape = useLayoutMedia(['TabletLandscape']);
    if (loading || !workSite) {
        return <Card><Skeleton active/></Card>;
    }
    return <div className={'__work-site-wrapper'}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>{t('genDict.page-title', { title: t('pageTitles.viewWorkSites') })}</title>
        </Helmet>
        <div className={'__title-wrapper'}>
            <Row align={'top'} wrap={false} gutter={20} style={{ margin: 0, width: '100%' }}>
                <Col flex="auto" style={{paddingLeft: '0'}}>
                    <Row align={'middle'} gutter={20}>
                        <Col style={{ marginBottom: '5px' }}>
                            <Title level={2} style={{ maxWidth: '100%' }}>
                                <div className={'__header-title __header-title-margin'} style={{ maxWidth: '100%' }}>
                                    <Paragraph style={{ width: '100%' }} ellipsis={{
                                        rows: 2,
                                        expandable: workSite && workSite.name && workSite.name.length > 10,
                                        symbol: t('genDict.more'),
                                    }}>{workSite.name}</Paragraph>
                                </div>
                            </Title>
                        </Col>
                        <Col style={{ marginBottom: '5px' }}>
                            <div style={{ display: 'inline-block' }}>
                                <div
                                    className={'__theme-tags __default'}>{workSite.reference}</div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col flex={updating ? '120px' : '30px'}>
                    <div style={{ height: '43px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div>
                            {
                                updating ? <>{t('workType.text.backup')} &nbsp;
                                    <RedoOutlined/></> : <>
                                    <SaveFilled/></>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        <div className="__content-body-wrapper">
            {
                isLandscape ? <Row gutter={16} className="__first-row-section">
                    <Col md={14} sm={24} style={{ width: '100%' }} className={'__card-outer-wrapper'}>
                        <Row gutter={16}>
                            <Col sm={24} style={{ width: '100%' }} className={'__card-outer-wrapper add-margin'}>
                                <BasicInfo workSite={workSite} refresh={refresh}/>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col sm={24} style={{ width: '100%' }} className={'__card-outer-wrapper'}>
                                <InfoGraph workSite={workSite}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={10} sm={24} style={{ width: '100%' }} className={'__card-outer-wrapper'}>
                        <Suppliers workSite={workSite} refresh={refresh}/>
                    </Col>

                </Row> : <Row gutter={16} className="__first-row-section">

                    <Col lg={8} md={24} xs={24} sm={24} style={{ width: '100%' }}
                         className={'__card-outer-wrapper add-margin'}>
                        <BasicInfo workSite={workSite} refresh={refresh}/>
                    </Col>
                    <Col lg={8} md={24} xs={24} sm={24} style={{ width: '100%' }}
                         className={'__card-outer-wrapper add-margin'}>
                        <Suppliers workSite={workSite} refresh={refresh}/>
                    </Col>
                    <Col lg={8} md={24} xs={24} sm={24} style={{ width: '100%' }} className={'__card-outer-wrapper'}>
                        <InfoGraph workSite={workSite}/>
                    </Col>
                </Row>
            }

            <Row gutter={16} className="__second-row-section">
                <Col md={24} sm={24}>
                    <SecondRow setUpdating={setUpdating} refresh={refresh} loading={loading} workSite={workSite}/>
                </Col>
            </Row>
        </div>
    </div>;
};
export default View;
