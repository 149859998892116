import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Customer } from '../../types/customer.type';

type ReturnType = {
    items: any[];
    loading: boolean;
    error: any;
    refetch: any
};
const QUERY = gql`
  {
  providers{
    id
    mail
    phone
    address{
    address
    additional
    postalCode
    city
  }
      contacts{
    id
    title
    firstName
    lastName
    email
    phone
    isMain
  }
    name
    taxNumber
    isDeletable
  }
  }
`;

const useSuppliers: () => ReturnType = () => {
    const { data, loading, error, refetch } = useQuery(QUERY);
    let items = [];
    if (!loading && !error) {
        items = data.providers;
    }
    return {
        items,
        refetch,
        loading,
        error,
    };
};
export default useSuppliers;
