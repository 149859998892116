// @ts-ignore
import React, { useState, useCallback, useEffect } from 'react';
import { notification } from 'antd';
import Parameters from '../parameters';
import { gql } from '@apollo/client';
import { useQuery } from '@apollo/react-hooks';

let AUTH_TOKEN: any = null;
let listener: any = null;

interface AuthToken {
  user: User;
  value: string;
}

interface User {
  id: number;
  isSuperAdmin: boolean;
  firstName: string;
  lastName: string;
  userType: string;
  company: any;
  roles: string[];
}

interface AuthContextProps {
  authToken: AuthToken | null;
  user: User | undefined;
  updateUser: () => void;
  isAuth: boolean;
  updateAuthToken: (authToken: AuthToken | null) => void;
}

const GET_USER = gql`
  query {
    currentUser{
      id
      firstName
      lastName
      username
      email
      reference
      phoneNumber
      enabled
      roles
      isSuperAdmin
      company{
        id
        footer
        name
        address{
          address
          additional
          postalCode
          city
        }
        billingAddress{
          address
          additional
          postalCode
          city
          firstName
          lastName
          company
        }
        bankName
        accountName
        IBAN
        BIC
        displayBankInformationOnQuote
        hideLogo
        pictureBackgroundUrl
        pictureUrl
        footer
        subscription{
          type
          status
          nextPaymentAt
          autoRenewalAt
          price
        }
        purchaseOrderTemplate{
          object
          template
        }
        quoteTemplate{
          object
          template
        }
      }
      emailChangementPending
      newEmail
      address{
        address
        additional
        postalCode
        city
      }
      stopShowTutorial
      stopShowPersonalize
      hasArticlesToPersonalize
    }
  }
`;

const AuthContext = React.createContext<AuthContextProps>({
  authToken: null,
  user: undefined,
  isAuth: false,
  updateAuthToken: () => {
  },
  updateUser: () => {
  },
});

function AuthProvider({ children }: any) {
  const [authToken, setAuthToken] = useState(() => {
    const authTokenStr = localStorage.getItem('auth-token');
    const lAuthToken = authTokenStr ? JSON.parse(authTokenStr) : undefined;
    AUTH_TOKEN = lAuthToken;
    return lAuthToken;
  });
  const [user, setUser] = useState<User | undefined>(
    authToken && authToken.user,
  );
  const [userCompanyObject, setUserCompanyObject] = useState<any | undefined>(undefined);

  const { data, refetch: refetchUser } = useQuery(GET_USER);

  useEffect(() => {
    if (data && data.currentUser) {
      setUser(data.currentUser);
    }
  }, [data]);

  const updateAuthToken = useCallback((pAuthToken: any) => {
    if (pAuthToken) {
      localStorage.setItem('auth-token', JSON.stringify(pAuthToken));
    } else {
      localStorage.removeItem('auth-token');
    }

    AUTH_TOKEN = pAuthToken;
    setAuthToken(pAuthToken);
    refetchUser();
  }, []);


  const updateUser = useCallback(() => {
    refetchUser().then();
  }, []);

  useEffect(() => {
    function forceLogout() {
      notification.error({
        message: 'Vous avez été déconnecté',
      });
      updateAuthToken(null);
    }

    function checkToken() {
      fetch(`${Parameters.ApiUrl}/auth-tokens/check`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-AUTH-TOKEN': authToken.value,
        },
      })
        .then((res) => {
          if (!res.ok) {
            forceLogout();
          }
          //refetch();
        })
        .catch(forceLogout);
    }

    if (authToken) {
      const intervalId = setInterval(checkToken, 2 * 60000);
      checkToken();

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{ updateAuthToken, updateUser, authToken, user, isAuth: !!authToken }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;

/**
 * We use this function to be able to access
 * the auth token from outside React components.
 */
function getAuthToken() {
  return AUTH_TOKEN;
}

function getUrlFormatedToken() {
  const token = getAuthToken();
  return token ? encodeURIComponent(token.value) : '';
}

function logout() {
  if (listener != null) {
    listener(null);
  }
  localStorage.clear();
  //notification.error({ message: t('auth.notification.logoutmsg') });
  notification.success({ message: 'Vous êtes déconnecté' });
  window.location.href = '/login';
}

function isAdmin() {
  return Parameters.IsAdmin;
}

export {
  AuthProvider,
  AuthConsumer,
  AuthContext,
  getAuthToken,
  getUrlFormatedToken,
  logout,
  isAdmin,
};
