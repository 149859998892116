import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
    Table,
    notification,
    Modal,
    Button as BaseButton,
    Typography,
    InputNumber,
    Row,
    Col,
    Button,
    Space,
} from 'antd';
import {
    ExclamationCircleOutlined,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import './index.scss';
import { useEffect, useState } from 'react';
import { Route_WorkTypesView } from '../../../../constants/Routes.constants';
import ListView from '../../../../components/layout/ListView';
import usePersonalizeWorkType from '../../../../hooks/work-sites/usePersonalizeWorkType';
import {
    DIGITS_TO_SHOW_AFTER_DOT,
    MAX_INPUT_NUMBER,
    MIN_INPUT_NUMBER,
    STEP_INCREASE,
} from '../../../../constants/AppConstants';
import { getDefaultPageSize, paginationParams } from '../../../../services/Utility';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const { Text } = Typography;

const GET_WORK_TYPES = gql`query getWorkTypes($workTypeIds: [ID!]!) {
    workTypes(workTypeIds: $workTypeIds){
        workTypes {
            id
            reference
            name
            sellingPrice
            family
            rows {
                id
                price
            }
        }
    }
}`;

interface WorkType {
    id: string;
    reference: string;
    name: string;
    sellingPrice: string;
    family: string;
    rows: {
        id: string;
        price: string;
    }[]
}

interface WorkTypeInput {
    id: string;
    sellingPrice: string;
}

function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

type Props = {
    onSubmit?: any,
    workSite?: any,
    workTypes: any[],
    onCancel?: any,
}

const WorkTypesToPersonalize: React.FunctionComponent<Props> = ({ workSite, workTypes, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    const [addingWorkTypesToWork, setAddingWorkTypesToWork] = useState(false);
    const [workTypesToP, setWorkTypesToP] = useState<WorkTypeInput[]>([]);

    const { data } = useQuery<{workTypes: {workTypes: WorkType[]}}>(GET_WORK_TYPES, {variables: {workTypeIds: workTypes.map(w => w.id)}});
    const initialWorkTypes = data?.workTypes?.workTypes ?? [];
    const columns = [

        {
            title: <span style={{ paddingLeft: '10px' }}>{t('workSite.workTypes.listTitle.reference')}</span>,
            render: (a: any) => (
                <Link style={{ paddingLeft: '10px' }} to={Route_WorkTypesView(a)}
                      className={'__theme-text __medium'}>{a.reference}</Link>),
            sorter: (a: any, b: any) => sortAlpha(a.reference, b.reference),
        },
        {
            title: t('workSite.workTypes.listTitle.name'),
            render: (a: any) => (
                <Link to={Route_WorkTypesView(a)} className={'__theme-text __medium'}>{a.name}</Link>),
            sorter: (a: any, b: any) => sortAlpha(a.name, b.name),
        },
        {
            title: t('workSite.workTypes.listTitle.family'),
            render: (a: any) => (
                <div className={'text-left'}>
                    {t(`workType.family.${a.family}`)}
                </div>),
        },
        {
            title: t('workSite.workTypes.listTitle.price'),
            render: (a: any) => {
                const value = workTypesToP.find(w => w.id === a.id)?.sellingPrice;
                return <InputNumber
                    value={value ? parseFloat(value) : null}
                    step={STEP_INCREASE}
                    min={MIN_INPUT_NUMBER}
                    max={MAX_INPUT_NUMBER}
                    precision={DIGITS_TO_SHOW_AFTER_DOT}
                    placeholder={a.sellingPrice}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    className={'text-right __input-height-35'}
                    style={{ width: '120px', height: '35px !important' }}
                    onChange={(value) => {
                        const newPrice=parseFloat((value || 0.0) + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT);
                        setWorkTypesToP((prev) => {
                            let newWorkTypes = [...prev];
                            const index = prev.findIndex(p => p.id === a.id);
                            if(index > -1) {
                                newWorkTypes[index] = {...newWorkTypes[index], sellingPrice: newPrice};
                            } else {
                                newWorkTypes.push({id: a.id, sellingPrice: newPrice});
                            }
                            return newWorkTypes;
                        });
                    }}
                />;
            },
        },
        {
            title: t('workSite.workTypes.listTitle.costPrice'),
            width: 120,
            render: (a: WorkType) => {
                return <div className="align-right">{a.rows.reduce((res, cur) => res + parseFloat(cur.price), 0).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>;
            },
        },
        {
            title: t('workSite.workTypes.listTitle.marginCoef'),
            width: 160,
            render: (a: WorkType) => {
                const sellingPrice = parseFloat(workTypesToP.find(w => w.id === a.id)?.sellingPrice ?? a.sellingPrice ?? '0.00');
                const cost = a.rows.reduce((res, cur) => res + parseFloat(cur.price), 0);
                const coeff = cost !== 0 ? sellingPrice / cost : 0;
                return <div className="align-right">{coeff.toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</div>;
            },
        },
    ];

    return (
        <Modal
            title={t('workSite.workTypes.workTypesToPersonalizePriceEdit')}
            visible={true}
            width={'70%'}
            maskClosable={false}
            onCancel={onCancel}
            destroyOnClose={true}
            bodyStyle={{ padding: 0 }}
            footer={null}
        >
            <div className="__work-type-article-selection-list">
                <ListView
                    title={''}
                    noLayoutP={true}
                    hideSearchSection={true}
                    subTitle={
                        <div className="__sub-title">
                            <Space><ExclamationCircleOutlined className="__icon-themed __icon-yellow"/> <span
                                className="__theme-text __black-shade">
                                    {t('workSite.workTypes.workTypeEditPricePersonalizeSubtitle', { quantiy: workTypesToP.length })}
                                </span>
                            </Space>
                        </div>
                    }>
                    <Table
						showSorterTooltip ={false}
                        pagination={{
                            hideOnSinglePage: true,
                            showSizeChanger: true,
                            onShowSizeChange: (currentPage, pageSize) => {
                                paginationParams(pageSize);
                            },
                            defaultPageSize: getDefaultPageSize(),
                        }}
                        locale={{
                            emptyText: (
                                <>
                                    <Space style={{ padding: '10px' }}>
                                        <Text
                                            className="primary-black">{t('workSite.workTypes.personaliseTableEmptyMessage')}</Text>
                                    </Space>
                                </>
                            ),
                        }}

                        columns={columns}
                        dataSource={initialWorkTypes}
                    />
                </ListView>
                <Row>
                    <Col span={24}>
                        <Space direction="horizontal" className="__form-footer-parent">
                            <div/>
                            <Space direction="horizontal" className="__form-footer">
                                <Button
                                    type="default"
                                    style={{ marginBottom: '8px' }}
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    shape="round"
                                    size="large"
                                    className="submit">
                                    {t('genDict.Cancel')}
                                </Button>
                                <BaseButton
                                    type="primary"
                                    loading={addingWorkTypesToWork}
                                    onClick={async () => {
                                        setAddingWorkTypesToWork(true);
                                        try {
                                            let allFilled = true;
                                            workTypesToP.forEach((item) => {
                                                const initialSellingPrice = initialWorkTypes.find(w => w.id === item.id)?.sellingPrice ?? '0.00';
                                                if (parseFloat(initialSellingPrice) !== 0) {
                                                    if (!item.sellingPrice && parseFloat(item.sellingPrice) !== 0) {
                                                        allFilled = false;
                                                    } else {
                                                        item.sellingPrice = initialSellingPrice;
                                                    }
                                                }
                                            });
                                            if (!allFilled) {
                                                throw 'workSite.workTypes.personalize.priceRequired';
                                            }
                                            await Promise.all(
                                                workTypesToP.map((item) => {
                                                    return usePersonalizeWorkType(item.id, parseFloat(item.sellingPrice || '0.00').toFixed(DIGITS_TO_SHOW_AFTER_DOT));
                                                }),
                                            );
                                            onSubmit(workTypes);
                                        } catch (e) {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }
                                        setAddingWorkTypesToWork(false);
                                    }}
                                    shape="round"
                                    size="large"
                                    className="submit"
                                >
                                    {t('workSite.workTypes.button.register')}
                                </BaseButton>
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </div>
        </Modal>

    );
};
export default WorkTypesToPersonalize;
