import { client, clientWithErrors } from '../../services/ApolloClient';
import gql from 'graphql-tag';

const MUTATION = gql`
mutation($workSiteId: ID!, $showBankInfo: Boolean , $comment: String){
  workSite_addQuote(workSiteId: $workSiteId, comment: $comment, showBankInfo: $showBankInfo){
    id
    title
    type
    mime
    extension
    fileName
    fileSize
    url
    createdAt
    updatedAt
    companyId
     customer{
      id
      type
      mail
      firstName
      lastName
      name
      phone
      contacts{
        id
        title
        firstName
        lastName
        email
        phone
        isMain
      }
    }
    isEmailSent
	receiverEmail
  }
}`;
const useAddQuote = async (data: any) => {
    return clientWithErrors(client.mutate({ variables: data, mutation: MUTATION }));
};

export default useAddQuote;
