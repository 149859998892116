import * as React from 'react';
import { ReactElement } from 'react';
import { Card, Skeleton } from 'antd';
// @ts-ignore
import { Helmet } from 'react-helmet';


import './index.scss';
import { useLayoutMedia } from '../../../services/Utility';

type Props = {
    title?: ReactElement;
    pageTitle?: ReactElement;
    transparentContent?: boolean;
    transparentBackground?: boolean;
    leftSidebar?: ReactElement;
    loading?: Boolean;
}

const SubSideViewLayout: React.FunctionComponent<Props> = ({
                                                               title,
                                                               pageTitle,
                                                               transparentBackground,
                                                               transparentContent,
                                                               loading,
                                                               leftSidebar,
                                                               children,
                                                           }) => {


    if (loading)
        return <>
            <Skeleton.Button active={true} size={'large'}/>
            <Skeleton avatar paragraph={{ rows: 4 }}/>
        </>;
    return (
        <div className="__editView">

            <Helmet>
                <meta charSet="utf-8"/>
                <title>{pageTitle}</title>
            </Helmet>
            <div className={'__inner-list-view'}>
                {
                    title && <div className="__title-inner-section" style={{ maxWidth: '100%' }}>
                        <div className="__title-section" style={{ maxWidth: '100%' }}>
                            <div className="__title" style={{ maxWidth: '100%' }}>
                                {title}
                            </div>
                        </div>
                    </div>
                }
                <div className="__body-inner-section">
                    <div className={'__content'}>
                        {
                            leftSidebar && <div className="__leftSidebar">
                                {leftSidebar}
                            </div>
                        }
                        <div className={'__body'}>
                            <Card
                                className={` ${transparentContent ? 'transparent-content' : ''} ${transparentBackground ? 'transparent-background' : ''}`}>
                                {children}
                            </Card>
                            {/*<div style={{ height: '90px' }}/>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SubSideViewLayout;
