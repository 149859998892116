import * as React from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';

import {
    PlusOutlined,
} from '@ant-design/icons';
import ListView from '../../../../components/layout/ListView';
import QuoteList from './QuoteList';

const Quotes: React.FunctionComponent<any> = ({ callQuoteCreate, setSelectedQuote, workSite }) => {
    const { t } = useTranslation();

    return <Card title={<div className="__header-title ">
        {
            t('workSite.quotes.headerText')
        } &nbsp;
        <div className="__button-add-icon " onClick={() => {
            callQuoteCreate();
        }}>
            <PlusOutlined/>
        </div>
    </div>} className={'__purchase-order-wrapper'}>
        <div className={'__table-pagination-padded'}>
            <ListView
                title={''}
                noP={true}
                pageTitle={t('genDict.page-title', { title: t('pageTitles.listQuotes') })}
                cardTitleStyle={{
                    padding: 0,
                }}>

                <QuoteList workSite={workSite} showSendEmail={(e: any) => {
                    setSelectedQuote({ ...e, direct: true });
                }}/>
            </ListView>
        </div>
    </Card>;
};
export default Quotes;
