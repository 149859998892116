import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button,Alert, Card, Col, Form, Input, Row } from 'antd';
import { Customer } from './InfoTab';
import { UserOffer } from './PriceContainer';
import { useState } from 'react';
import { DIGITS_TO_SHOW_AFTER_DOT, REGEX_STRING_VERIFY, REGEX_POSTALCODE_VERIFY } from '../../../constants/AppConstants';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';


interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onSignUp: (values: any) => void;
    offer: UserOffer | null;
    customer: Customer,
    loading?: boolean,
}

const PaymentForm: React.FunctionComponent<Props> = ({ fields, loading, onChange, onSignUp, offer, customer }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardNumberError, setCardNumberError] = useState<string | null>(null);
    const [cardCvcError, setCardCvcError] = useState<string | null>(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    return (
        offer && <Form
            layout="vertical"
            size="large"
            form={form}
            fields={fields}
            initialValues={customer.billingAddress}
            onFieldsChange={(_, allFields) => {
                onChange(allFields);
            }}
            onFinish={async (event) => {
                if (!stripe || !elements || !cardComplete) {
                    // Stripe.js has not loaded yet. Make sure to disable
                    // form submission until Stripe.js has loaded.
                    return;
                }

                setProcessing(true);
                try {
                    const payload = await stripe.createPaymentMethod({
                        type: 'card',
                        // @ts-ignore
                        card: elements.getElement(CardNumberElement),
                        billing_details: {
                            name: event['billing.name'],
                        },
                    });

                    setProcessing(false);

                    if (payload.error) {
                        // @ts-ignore
                        setError(payload.error);

                    } else {
                        // @ts-ignore
                        setPaymentMethod(payload.paymentMethod);
                        const billingAddress = { ...event };
                        delete billingAddress['billing.name'];
                        delete billingAddress['cardNumber'];
                        delete billingAddress['cardCvc'];
                        onSignUp({ billingAddress: billingAddress, stripePaymentMethodId: payload.paymentMethod.id });
                    }
                } catch (e) {
                    // @ts-ignore
                    setError('Something went wrong!!');
                    setProcessing(false);
                }
            }}
            requiredMark={false}
        >
            {
                error && <Alert
                    message={t('signup.billing.paymentFailedTitle')}
                    description={t('signup.billing.paymentFailedDescription')}
                    type="error"
                    closable
                    onClose={()=>{setError(null)}}
                />
            }
            <fieldset className="__billingAddress">
                <div className={'__billing-title __billing-title1 '}>{t('signup.billing.title')}</div>
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="firstName"
                            label={t('signup.input.firstName.label')}
                            rules={[
                                ({getFieldValue}) => {
                                    const required = !getFieldValue('company');
                                    return {
                                        required, message: t('signup.input.firstName.required')
                                    };
                                }
                            ]}
                        >
                            <Input onBlur={() => form.validateFields(['company'])}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="lastName"
                            label={t('signup.input.lastName.label')}
                            rules={[
                                ({getFieldValue}) => {
                                    const required = !getFieldValue('company');
                                    return {
                                        required, message: t('signup.input.lastName.required')
                                    };
                                }
                            ]}
                        >
                            <Input onBlur={() => form.validateFields(['company'])}/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>

                        <Form.Item
                            name="company"
                            label={t('signup.input.company.label')}
                            rules={[
                                ({getFieldValue}) => {
                                    const required = !getFieldValue('firstName') && !getFieldValue('lastName');
                                    return {
                                        required, message: t('signup.input.company.required')
                                    };
                                }
                            ]}
                        >
                            <Input onBlur={() => form.validateFields(['firstName', 'lastName'])}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="address"
                            label={t('signup.input.address.label')}
                            rules={[
								{ required: true, message: t('signup.input.address.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},
							]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="additional"
                            label={t('signup.input.additionalAddress.label')}
                                rules={[
									{
										pattern: REGEX_STRING_VERIFY,
										message: t('errorMessage.stringWrongFormat'),
									},
                                ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="postalCode"
                            label={t('signup.input.postalCode.label')}
                            rules={[
								{ required: true, message: t('signup.input.postalCode.required') },
								{
									pattern: REGEX_POSTALCODE_VERIFY,
									message: t('signup.input.postalCode.wrongFormat'),
								},
							]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="city"
                            label={t('signup.input.city.label')}
                            rules={[
								{ required: true, message: t('signup.input.city.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},
							]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                </Row>
            </fieldset>
            <fieldset className="__cardForm" style={{ marginTop: '30px' }}>
                <div className={'__billing-title'}>{t('signup.card.title')}</div>
                <Row gutter={10}>
                    <Col xs={24} sm={24} md={12}>

                        {/*<CheckoutForm assignSubmitAction={setPaymentProcessHandle}/>*/}
                        <Form.Item
                            name="billing.name"
                            label={t('payment.input.name.label')}
                            rules={[
								{ required: true, message: t('payment.input.name.required') },
								{
									pattern: REGEX_STRING_VERIFY,
									message: t('errorMessage.stringWrongFormat'),
								},
							]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={t('payment.input.details.label')}
                            name="cardNumber"
                            rules={[
                            {
                                validator: () => cardNumberError? Promise.reject(new Error(cardNumberError)) : Promise.resolve()
                            }]}
                        >
                            <CardField
                                onChange={(e: any) => {
                                    setCardNumberError(e.error?.message);
                                    setCardComplete(e.complete);
                                    form.validateFields(['cardNumber']);
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('payment.input.securityInfo.label')}
                            name="cardCvc"
                            rules={[{
                                validator: () => cardCvcError? Promise.reject(new Error(cardCvcError)) : Promise.resolve()
                            }]}
                        >
                            <CardCvcField
                                onChange={(e: any) => {
                                    setCardCvcError(e.error?.message);
                                    setCardComplete(e.complete);
                                    form.validateFields(['cardCvc']);
                                }}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Card className="__PayementSummary">
                            <div className={'__summary-title'}>{t('payment.summary.title')}</div>
                            <span className={'__price-details'}>

                            <Trans
                                i18nKey={`payment.summary.${offer.type}`}
                                components={{ price: <span className={'__price'}/> }}
                                values={{ price: offer.amount }}
                            />
                                                       </span>
                            <br/>
                            <span className={'__price-details bold'}>
                            <Trans
                                i18nKey={'payment.summary.total'}
                                components={{ price: <span className={'__price'}/> }}
                                values={{ price: (offer.amount + (offer.amount * .20)).toFixed(DIGITS_TO_SHOW_AFTER_DOT) }}
                            />
                            </span>
                        </Card>
                    </Col>
                </Row>
            </fieldset>
            <Form.Item>
                <Button
                    type="primary"
                    loading={loading || processing}
                    htmlType="submit"
                    shape="round"
                    style={{ marginTop: 16 }}
                >
                    {t('payment.validate')}
                </Button>
            </Form.Item>
        </Form>
    );
};
export default PaymentForm;

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#BFBFBF',
            color: '#212121',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '17px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#fce883',
            },
            '::placeholder': {
                color: '#BFBFBF',
            },
        },
        invalid: {
            iconColor: '#DC3545',
            color: '#DC3545',
        },
    },
};

const CardField = ({ onChange }: any) => {
    return <div className="__payment-FormRow">
        {/*@ts-ignore*/}
        <CardNumberElement options={CARD_OPTIONS} onChange={onChange}/>
        {/*@ts-ignore*/}
        <CardExpiryElement options={CARD_OPTIONS} onChange={onChange} className="__card-expiry"/>
    </div>;
}

const CardCvcField = ({ onChange }: any) => (
    <div className="__payment-FormRow">
        {/*@ts-ignore*/}
        <CardCvcElement options={CARD_OPTIONS} onChange={onChange}/>
    </div>
);
