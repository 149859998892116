// noinspection RequiredAttributes

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  notification,
  Typography,
  Space,
  Row,
  Col,
  Table,
  Tooltip,
  Image,
  Modal,
  Button as BaseButton, Form, Input, Select, Switch, Button, Skeleton, InputNumber, Tag,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
// @ts-ignore
import './index.scss';
import { useState } from 'react';
import Title from 'antd/es/typography/Title';
import { useEffect } from 'react';
import { dotToObject, filteredList, getDefaultPageSize, paginationParams, sortPrice } from 'shared/services/Utility';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import {
  deleteWorkTypeById, duplicateWorkTypeById,
  fetchWorkTypeById,
  personalizeArticlePrice, personalizeEffortPrice, updateWorkType, updateWorkTypeRow,
} from 'shared/services/workService';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import { CheckOutlined, InfoCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { Article } from '../articles/Article.model';
import {
  addArticleToWorkType, deleteArticleByIdFromWork,
  useArticles,
  useCategories,
} from 'shared/services/articleService';
// @ts-ignore
import DonutChart from 'react-d3-donut';

import {
  PlusOutlined,
  EditFilled,
  SaveFilled,
  RedoOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import WorkTypeCreateModal from './WorkTypeCreateModal';
import ListView from 'shared/components/layout/ListView';
import ListSearchBar from 'shared/components/Ant/ListSearchBar/ListSearchBar';
// @ts-ignore
import { Helmet } from 'react-helmet';
import useDebounce from 'shared/hooks/useDebounce';
import {
  ConstantImageData,
  ConstantWorkFamiliesMapping,
  DIGITS_TO_SHOW_AFTER_DOT,
  THREE_DIGITS_TO_SHOW_AFTER_DOT,
  MAX_INPUT_NUMBER,
  MIN_INPUT_NUMBER,
  STEP_INCREASE,
  REGEX_STRING_VERIFY, DEBOUNCE_IN_MILISECONDS,
} from 'shared/constants/AppConstants';
// @ts-ignore
import { isAdmin } from 'shared/contexts/AuthContext';
// @ts-ignore
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import MoveArticleRowInWorkType from 'shared/hooks/work-sites/useMoveArticleRowInWorkType';
import personalizeWorkType from 'shared/hooks/work-sites/personalizeWorkType';

// @ts-ignore

function sortAlpha(a: string, b: string) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

const { confirm } = Modal;
const { Text, Paragraph } = Typography;

type Props = {
  item?: any,
  t?: Function,
}

const SortableItem = sortableElement((props: any) => <tr {...props} />);
const SortableContainer = sortableContainer((props: any) => <tbody {...props} />);

const CHARTComponent = (props: any) => {
  const datad = [{
    count: parseFloat(props.data.supplies).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
    color: '#0033A0',
    name: props.t('workType.pieChart.supplies'),
  }, {
    count: parseFloat(props.data.workForce).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
    color: '#DC3545',
    name: props.t('workType.pieChart.workForce'),
  }, {
    count: parseFloat(props.data.margin).toFixed(DIGITS_TO_SHOW_AFTER_DOT),
    color: '#0FD3A0',
    name: props.t('workType.pieChart.margin'),
  }];
  return <DonutChart
    innerRadius={20}
    outerRadius={65}
    transition={true}
    svgClass="example6"
    pieClass="pie6"
    displayTooltip={true}
    strokeWidth={3}
    data={datad}/>;
};

const CHART = React.memo(CHARTComponent);

const View: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false as any);

  const RouteParams: any = useParams();
  const history = useHistory();
  const { articleCategories } = useCategories();
  const { articles, refetch: refetchArticles } = useArticles(true);
  const [articlesFlat, setArticlesFlat] = useState([] as any);
  const [movingItem, setMovingItem] = useState(false);
  useEffect(() => {
    setArticlesFlat(articles);
  }, [articles]);
  // noinspection JSMismatchedCollectionQueryUpdate
  const fields: any[] = [];
  const [item, setItem] = useState({} as any);
  const [rows, setRows] = useState([] as any[]);
  const [duplicateItemModal, setDuplicateItemModal] = useState(false);
  const [updatedTimes, setUpdatedTimes] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [createModalVisiblePriceEdit, setCreateModalVisiblePriceEdit] = useState(false);
  const [deletingItem, setDeletingItem] = useState(false);
  const [createModalVisiblePersonalizePriceEdit, setCreateModalVisiblePersonalizePriceEdit] = useState(false);
  const [duplicatingItem, setDuplicatingItem] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [fetchingItem, setFetchingItem] = React.useState(false);

  const [calculations, setCalculations] = useState({
    supplies: 0,
    workForce: 0,
    costOfWork: 0, sellingPrice: 0, margin: 0, cofficient: 0,
  });
  const refresh = () => {
    setFetchingItem(true);
    fetchWorkTypeById(RouteParams['workTypeId']).then((response) => {
      if (response && response.data && response.data.workType) {
        response.data.workType.rows = response.data.workType.rows.map((r: any) => {
          return { ...r, index: parseInt(r.id), item: r.effort || r.article };
        });
        setRows(JSON.parse(JSON.stringify(response.data.workType.rows)));
        setItem(JSON.parse(JSON.stringify(response.data.workType)));
      }
      refreshCount(JSON.parse(JSON.stringify(response.data.workType)));

    }).catch(() => {
      notification.error({
        message: t('genDict.errorWhileFetchingContent'),
      });
    }).finally(() => {
      setFetchingItem(false);

    });
  };

  const refreshCount = (i = null) => {
    const ITEM = i || item;
    const c = {
      supplies: 0,
      workForce: 0,
      costOfWork: 0, sellingPrice: parseFloat(ITEM.sellingPrice), margin: 0, cofficient: 0,
    };
    if (ITEM && ITEM.rows) {
      ITEM.rows.forEach((a: any) => {
        if (a.type === 'article') {
          c.supplies += (a.unitPrice * a.quantity);
        }
        if (a.type === 'effort') {
          c.workForce += (a.unitPrice * a.quantity);
        }
        c.costOfWork += (a.unitPrice * a.quantity);
      });
    }
    c.margin = c.sellingPrice - c.costOfWork;
    c.cofficient = c.sellingPrice / c.costOfWork;
    setCalculations(c);
  };
  // eslint-disable-next-line
  useEffect(refresh, []);

  const [filterByType, setFilterByType] = useState('');
  const [filterCreatedByMe, setFilterCreatedByMe] = useState(false);
  const [articlesToEditPriceOf, setArticlesToEditPriceOf] = useState([] as any[]);
  const [articlesToEditPersonalizePriceOf, setArticlesToEditPersonalizePriceOf] = useState([] as any[]);
  const [articleSelectSearchKey, setArticleSelectSearchKey] = useState('');
  const [addingArticlesToWork, setAddingArticlesToWork] = useState(false);

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    // @ts-ignore
    setMovingItem(true);
    MoveArticleRowInWorkType({ new: newIndex, old: oldIndex, id: item.id }).then(() => {
      refresh();
    }).catch(e => {
      notification.error({
        message: t(e),
      });
    }).finally(() => {
      setMovingItem(false);
    });

  };

  const debouncedSellingPrice = useDebounce(item ? item.sellingPrice : null, 500);
  const [changedRowIndex, setChangedRowIndex] = useState(null as any);
  const [changedRowIndexEffort, setChangedRowIndexEffort] = useState(null as any);
  const [editWork, setEditWork] = useState(false);
  const debouncedRows = useDebounce(changedRowIndex, DEBOUNCE_IN_MILISECONDS);
  const debouncedRowsEffort = useDebounce(changedRowIndexEffort, DEBOUNCE_IN_MILISECONDS);

  useEffect(
    () => {
      if (updatedTimes > 1 && item.sellingPrice) {
        setUpdating(true);
        if (!isAdmin() && item.isAdminCreated) {
          personalizeWorkType(item.id, item.sellingPrice).then(() => {

          }).catch(e => {
            notification.error({
              message: t(e),
            });
          }).finally(() => {
            setUpdating(false);
            setLoading(false);
            refreshCount();
          });
        } else {
          updateWorkType(item).then(() => {

          }).catch(e => {
            notification.error({
              message: t(e),
            });
          }).finally(() => {
            setUpdating(false);
            setLoading(false);
            refreshCount();
          });
        }
      }
      setUpdatedTimes(updatedTimes + 1);

    },
    // eslint-disable-next-line
    [debouncedSellingPrice],
  );
  useEffect(
    () => {
      if (changedRowIndexEffort && (!isNaN(parseFloat(changedRowIndexEffort.unitPrice)))) {
        setUpdating(true);
        setLoading(true);
        setChangedRowIndexEffort(null);

        personalizeEffortPrice(
          changedRowIndexEffort.id,
          changedRowIndexEffort.unitPrice,
        ).then(() => {
          refresh();
        }).catch((e: any) => {
          notification.error({
            message: t(e),
          });
        }).finally(() => {
          //personalizeEffort(articles, index + 1);
          setUpdating(false);
          setLoading(false);
        });
      }
    },
    [debouncedRowsEffort],
  );

  useEffect(
    () => {
      if (changedRowIndex && (!isNaN(parseFloat(changedRowIndex.quantity)))) {
        setUpdating(true);
        setLoading(true);
        setChangedRowIndex(null);

        updateWorkTypeRow({
          id: item.id,
          rowId: changedRowIndex.id,
          input: { quantity: changedRowIndex.quantity },
        }).then(() => {
          refresh();
        }).catch((e: any) => {
          notification.error({
            message: t(e),
          });
        }).finally(() => {
          setUpdating(false);
          setLoading(false);
        });
      }
    },
    [debouncedRows],
  );
  const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }}/>);
  const firstColumn = !isAdmin() && item.isAdminCreated ? [
    {
      title: t('workType.listTitle.reference'),
      render: (a: any) => (
        a.type === 'effort' ? <span className={'__theme-text text-primary __bold'}>{a.item.reference}</span> :
          <span className={'__theme-text text-primary __bold'}>{a.item.reference}</span>),
    }] : [{
    title: t('genDict.sort'),
    dataIndex: 'sort',
    width: 30,
    className: 'drag-visible',
    render: () => <div className={'text-center'}><DragHandle/></div>,
  },
    {
      title: t('workType.listTitle.reference'),
      render: (a: any) => (
        a.type === 'effort' ? <span className={'__theme-text text-primary __bold'}>{a.item.reference}</span> :
          <span className={'__theme-text text-primary __bold'}>{a.item.reference}</span>),
    }];
  const columns = [
    ...firstColumn,

    {
      title: t('workType.listTitle.nameArticle'),
      render: (a: Article) => {
        return a.type === 'effort' ? <><span>{a.name}</span>&nbsp;&nbsp;<Tooltip
          title={t('workType.tooltip.effort')}>
          <InfoCircleFilled
            className={'icon-disabled'}/>
        </Tooltip></> : <><span>{a.name}</span></>;
      },
    },
    {
      title: <div className={'text-center'}>{t('workType.listTitle.unit')}</div>,
      render: (a: any) => (
        a.type !== 'total' ?
          <div className={'text-center'}>
            {a.item.unit}
          </div> : <></>),
    },

    {
      title: <div className={'text-right'}
                  style={{ paddingRight: '2px' }}> {t('workType.listTitle.quantity')}</div>,
      className: '__vertical-initial',
      render: (a: any, values: any, index: number) => {
        return {

          children: (
            !isAdmin() && item.isAdminCreated ?
			<div className={'__vertical-initial text-right'}>
				<span className={'__theme-text light'}>{a.quantity}</span>
            </div>
			:
            <div className={'__vertical-initial text-right'}>                    
              <InputWithDebounce loading={loading} value={a.quantity} disable={!isAdmin() && item.isAdminCreated}
                                 onChange={(e: any) => {
                                   setLoading(true);
                                   const newItem = JSON.parse(JSON.stringify(item));
                                   newItem.rows[index].quantity = e;
                                   setItem(JSON.parse(JSON.stringify(newItem)));
                                   setChangedRowIndex(JSON.parse(JSON.stringify(newItem.rows[index])));
                                 }}/>
            </div>),
        };
      },
    },
    {
      title: t('workType.listTitle.unitPrice'),
      render: (a: any, values: any, index: number) => (
        a.type === 'effort' ? <div className={'__vertical-initial text-right'}>
            <InputWithDebounce loading={loading} value={a.unitPrice} disable={false} onChange={(e: any) => {
              setLoading(true);
              const newItem = JSON.parse(JSON.stringify(item));
              newItem.rows[index].unitPrice = e;
              setItem(JSON.parse(JSON.stringify(newItem)));
              setChangedRowIndexEffort(JSON.parse(JSON.stringify(newItem.rows[index])));
            }}/>
          </div> :
          <div className={'text-right __theme-text __disabled'}>
            {parseFloat(a.unitPrice).toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}&nbsp;&nbsp;
            <EditFilled className={'__icon-themed __hover-effect'}
                        onClick={() => {
                          setArticlesToEditPriceOf([a]);
                          setCreateModalVisiblePriceEdit(true);
                        }}/>
          </div>),
    },

    {
      title: t('workType.listTitle.total'),
      render: (a: any) => (
        <div className={'text-right __theme-text __disabled'}>
          {parseFloat(a.unitPrice * (a.quantity !== 0 && !a.quantity ? 1 : a.quantity) + '').toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
        </div>),
    },

    {
      title: t('workType.listTitle.actions'),
      fixed: 'right',
      render: (a: any, values: any, index: number) => (
        (item.isAdminCreated && !isAdmin()) ? <></> :
          <div className="__actionButtons">
            <Space size={10}>

              <Tooltip placement={'top'} title={t('article.tooltip.deleteArticle')}>
                <div/>
                <DeleteButton
                  disabled={(a.type === 'effort' && (parseFloat(a.quantity) === 0)) && a.type !== 'article'}
                  onClick={() => {

                    if (a.type === 'effort') {
                      confirm({
                        icon: <ExclamationCircleOutlined/>,
                        title: t('article.confirmation.msg'),
                        okText: t('article.button.deleteEffort'),
                        cancelText: t('genDict.Cancel'),
                        content: t('article.confirmation.deleteEffort'),// TODO
                        okButtonProps: { loading: deletingItem, danger: true },
                        onOk: async () => {
                          a.quantity = 0;
                          setItem(JSON.parse(JSON.stringify(item)));
                          setChangedRowIndex(JSON.parse(JSON.stringify(item.rows[index])));
                          return;
                        },
                      });
                    } else {


                      confirm({
                        icon: <ExclamationCircleOutlined/>,
                        title: t('article.confirmation.msg'),
                        okText: t('article.button.deleteArticle'),
                        cancelText: t('genDict.Cancel'),
                        content: t('article.confirmation.deleteArticle'),// TODO
                        okButtonProps: { loading: deletingItem, danger: true },
                        onOk: async () => {
                          setDeletingItem(true);
                          deleteArticleByIdFromWork(item.id, a.id).then(() => {
                            notification.success({
                              message: t('genDict.success'),
                              description: t('article.notification.articleDeletedSuccess'),
                            });
                            refresh();
                          }).catch(e => {
                            notification.error({
                              message: t(e),
                            });
                          }).finally(() => {
                            setDeletingItem(false);
                          });
                        },
                      });
                    }
                  }}/>
              </Tooltip>
            </Space>
          </div>

      ),
      width: '150px',
    },
  ];
  const articleSelectColumns = [
    {
      title: '',
      render: (a: any) => (
        a.selected ? <CheckCircleFilled className={'checked-icon'}/> : (
          <CheckCircleFilled className={'checked-icon'} style={{ opacity: 0 }}/>)
      ),
    },
    {
      title: t('article.listTitle.reference'),
      render: (a: Article) => (
        //<Link to={`/articles/${a.id}/view`}>{a.reference}</Link>),
        <span className={'__theme-text text-primary __bold'}> {a.reference}</span>),
      sorter: (a: Article, b: Article) => sortAlpha(a.reference, b.reference),
    },

    {
      title: t('article.listTitle.image'),
      render: (a: Article) => (
        <Image
          width={'40px'}
          height={'40px'}
          src={a.pictureUrl || ConstantImageData}
          fallback={ConstantImageData}
        />),
    },
    {
      title: t('article.listTitle.name'),
      render: (a: Article) => (
        //<Link to={`/articles/${a.id}/view`}>{a.name}</Link>),
        <span className={'__theme-text text-primary'}> {a.name} </span>),
      sorter: (a: Article, b: Article) => sortAlpha(a.name, b.name),
    },
    {
      title: t('article.listTitle.price'),
      render: (a: any) => (a.isPersonalized ? <span>{a.price}</span> : <Space size={7}>
                        <span className={'text-disabled'}>
                                {
                                  parseFloat(a.price).toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                }
							</span>
        <span className={'text-disabled'}>
                        <Tooltip placement={'top'} title={t('article.tooltip.articlePersonalize')}>
									<InfoCircleFilled className={'icon-disabled'}/>
								</Tooltip>
							</span>
      </Space>),
      sorter: (a: any, b: any) => sortPrice(a.price, b.price),
    },
    {
      title: <div className={'text-center'}>{t('article.listTitle.unit')}</div>,
      render: (a: Article) => (
        <div className={'text-center'}>
          {a.unit}
        </div>),
    },

    {
      title: <div className={'text-center'}>{t('article.listTitle.isHeightSensitive')}</div>,
      render: (a: Article) => (
        <div className={'text-center'}>
          {
            !a.isHeightSensitive ? <CloseOutlined className={'round table-icons disabled'}/> :
              <CheckOutlined className={'round table-icons'}/>
          }
        </div>),
    },
    {
      title: <div className={'text-center'}>{t('article.listTitle.isAdminCreated')}</div>,
      render: (a: Article) => (
        <div className={'text-center'}>
          {
            a.isAdminCreated ? <></> :
              <CheckOutlined className={'round primary table-icons'}/>
          }
        </div>),
    },
  ];

  const articlePriceColumns = [
    {
      title: <span style={{ paddingLeft: '10px' }}>{t('article.listTitle.reference')}</span>,
      render: (a: any) => (
        <span style={{ paddingLeft: '10px' }}
              className={'__theme-text text-primary  __bold'}>{a.item.reference}</span>),
    },
    {
      title: t('article.listTitle.image'),
      render: (a: any) => (<Image
        width={'40px'}
        height={'40px'}
        src={a.item.pictureUrl || ConstantImageData}
        fallback={ConstantImageData}
      />),
    },
    {
      title: t('article.listTitle.name'),
      render: (a: Article) => (
        <span className={'text-primary'}>{a.name}</span>),
      sorter: (a: Article, b: Article) => sortAlpha(a.name, b.name),
    }, {
      title: t('article.listTitle.unit'),
      render: (a: any) => (
        <div className={'text-left'}>
          {a.item.unit}
        </div>),
    },
    {
      title: t('article.listTitle.price'),
      render: (a: any, values: any, index: number) => {
        return <InputNumber
          value={a.unitPrice} className={'text-right __input-height-35'}
          style={{ width: '120px', height: '35px !important' }}
          step={STEP_INCREASE}
          min={MIN_INPUT_NUMBER}
          max={MAX_INPUT_NUMBER}
          precision={THREE_DIGITS_TO_SHOW_AFTER_DOT}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          onChange={(value) => {
            articlesToEditPriceOf[index].unitPrice = parseFloat(parseFloat((value || 0.0) + '').toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT));
            setArticlesToEditPriceOf(JSON.parse(JSON.stringify(articlesToEditPriceOf)));
          }}
        />;
      },
    },
  ];
  const articlePriceColumnsPersonalize = [
    {
      title: <span style={{ paddingLeft: '10px' }}>{t('article.listTitle.reference')}</span>,
      render: (a: any) => (
        <span style={{ paddingLeft: '10px' }}
              className={'__theme-text text-primary  __bold'}>{a.reference}</span>),
    },
    {
      title: t('article.listTitle.image'),
      render: (a: any) => (<Image
        width={'40px'}
        height={'40px'}
        src={a.pictureUrl || ConstantImageData}
        fallback={ConstantImageData}
      />),
    },
    {
      title: t('article.listTitle.name'),
      render: (a: Article) => (
        <span className={'text-primary'}>{a.name}</span>),
      sorter: (a: Article, b: Article) => sortAlpha(a.name, b.name),
    }, {
      title: t('article.listTitle.unit'),
      render: (a: any) => (
        <div className={'text-left'}>
          {a.unit}
        </div>),
    },
    {
      title: t('article.listTitle.price'),
      render: (a: any, values: any, index: number) => {
        return <InputNumber
          value={a.price}
          step={STEP_INCREASE}
          min={MIN_INPUT_NUMBER}
          max={MAX_INPUT_NUMBER}
          precision={THREE_DIGITS_TO_SHOW_AFTER_DOT}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          className={'text-right __input-height-35'}
          style={{ width: '120px', height: '35px !important' }}
          onChange={(value) => {
            const newArticlesToEditPersonalizePriceOf = JSON.parse(JSON.stringify(articlesToEditPersonalizePriceOf));
            newArticlesToEditPersonalizePriceOf.filter((a: any) => !a.isPersonalized)[index].price = parseFloat(parseFloat((value || 0.0) + '').toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT));
            setArticlesToEditPersonalizePriceOf(newArticlesToEditPersonalizePriceOf);
          }}
        />;
      },
    },
  ];
  const articlePriceColumnsEffort = [
    {
      title: <span style={{ paddingLeft: '10px' }}>{t('article.listTitle.reference')}</span>,
      render: (a: any) => (
        <span style={{ paddingLeft: '10px' }}
              className={'__theme-text text-primary  __bold'}>{a.item.reference}</span>),
    },

    {
      title: t('article.listTitle.name'),
      render: (a: Article) => (
        <span className={'text-primary'}>{a.name}</span>),
      sorter: (a: Article, b: Article) => sortAlpha(a.name, b.name),
    },
    {
      title: t('article.listTitle.price'),
      render: (a: any, values: any, index: number) => {
        return <InputNumber
          value={a.unitPrice} className={'text-right __input-height-35'}
          step={STEP_INCREASE}
          min={MIN_INPUT_NUMBER}
          max={MAX_INPUT_NUMBER}
          precision={THREE_DIGITS_TO_SHOW_AFTER_DOT}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          style={{ width: '120px', height: '35px !important' }}
          onChange={(value) => {
            const newArticlesToEditPriceOf = JSON.parse(JSON.stringify(articlesToEditPriceOf));
            newArticlesToEditPriceOf[index].unitPrice = parseFloat(parseFloat((value || 0.0) + '').toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT));

            setArticlesToEditPriceOf(newArticlesToEditPriceOf);
          }}
        />;
      },
    },
  ];


  const DraggableContainer = (props: any) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
    const index = rows.findIndex((x: any) => x.index === restProps['data-row-key']);
    return movingItem ? <div>
      <Skeleton.Button active={true} size={'large'}/>
    </div> : <SortableItem index={index} {...restProps} />;
  };

  if (fetchingItem && !(item && item.id))
    return <>
      <Skeleton.Button active={true} size={'large'}/>
      <Skeleton avatar paragraph={{ rows: 4 }}/>
    </>;

  const filterArticlesFromRow = (array: any[], noInvert = true) => {
    const result: any[] = [];
    array.forEach((priceOuter: any) => {
      let found = false;
      if (!noInvert && priceOuter.selected) {
        result.push(priceOuter);
      } else if (noInvert) {
        item.rows.forEach((priceInner: any) => {
          if (priceInner.item.id === priceOuter.id) {
            found = true;
          }
        });
        if (noInvert && !found) {
          result.push(priceOuter);
        } else if (!noInvert && found) {
          result.push(priceOuter);
        }
      }

    });
    return result;
  };
  // @ts-ignore
  return (
    <div className={'__work-type-wrapper'}>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>{t('genDict.page-title', { title: t('pageTitles.viewWorkTypes') })}</title>
      </Helmet>
      <Modal
        title={t('workType.editWorkTypeInformation')}
        visible={editWork}
        maskClosable={false}
        width={1000}
        onCancel={() => {
          setEditWork(false);
          refresh();
        }}
        destroyOnClose={true}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <WorkTypeCreateModal workType={item} onClose={() => {
          setEditWork(false);
          refresh();
        }} fromPopup={true}/>
      </Modal>
      <div className={'__title-wrapper'}>
        <Row align={'top'} wrap={false} gutter={20} style={{ margin: 0, width: '100%' }}>
          <Col flex="auto" style={{ paddingLeft: '0' }}>
            <Row align={'middle'} gutter={20}>
              <Col style={{ marginBottom: '5px' }}>
                <Title level={2} style={{ maxWidth: '100%' }}>
                  <div className={'__header-title __header-title-margin'}
                       style={{ maxWidth: '100%' }}>
                    <Paragraph style={{ width: '100%' }} ellipsis={{
                      rows: 2,
                      expandable: item && item.name && item.name.length > 10,
                      symbol: t('genDict.more'),
                    }}>{item.name}</Paragraph>
                  </div>
                </Title>
              </Col>
              <Col flex={!(item.isAdminCreated && !isAdmin()) ? 'auto' : ''}
                   style={{ marginBottom: '5px', paddingLeft: 0 }}

              >
                <div style={{ display: 'inline-block' }}
                     className={'__theme-tags __default'}>{item.reference}</div>
              </Col>
              {
                item.isAdminCreated && !isAdmin() &&
                <Col flex="auto" style={{ marginBottom: '5px', paddingLeft: '0' }}>
                  <div style={{ display: 'inline-block' }}>
                    <div
                      className={'__theme-tags __default'}>{t('genDict.Model')}</div>
                  </div>
                </Col>}
            </Row>
          </Col>
          <Col flex={updating ? '130px' : '30px'}>
            <div style={{
              height: '43px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
              <div className={'text-right'}>
                {
                  updating ? <>{t('workType.text.backup')} &nbsp;
                    <RedoOutlined/></> : <>{updatedTimes < 2 ? <>{t('workType.saved')}&nbsp;</> : ''}
                    <SaveFilled/></>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="__body-wrapper">
        <Row gutter={30}>
          <Col xl={16} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '30px' }}>
            <div className="__info-card" style={{ height: '100%' }}>
              <h4 className={'__form-header-title'}>
                {t('workType.information')}
                <div className="__header-icon">
                  {
                    item.isAdminCreated && !isAdmin() ? <></> :
                      <EditButton tooltipText={t('workType.tooltip.editWorkType')}
                                  notFilled={true}
                                  style={{ color: '#0033A0', fontSize: '16px' }}
                                  onClick={() => {
                                    setEditWork(true);
                                  }}/>
                  }
                </div>
              </h4>
              <div className="__body" style={{ maxWidth: '700px' }}>
                <div className="__item-row">
                  <Row gutter={16}>
                    <Col span={12}>
                      <div className="__items">
                        <div className="__key">
                          {t('workType.input.family.label')} :
                        </div>
                        <div className="__value">
                          <span>{item.family ? t(`workType.family.${item.family}`) : ''}</span>
                        </div>
                      </div>
                    </Col>
                    {

                      ConstantWorkFamiliesMapping.find((e: any) => item.family === e.name) && ConstantWorkFamiliesMapping.find((e: any) => item.family === e.name)!.fields!.map((mapping: any) => {
                        return !!item[mapping] ? <Col span={12}>
                          <div className="__items">
                            <div className="__key">
                              {t(`workType.input.${mapping}.label`)} :
                            </div>
                            <div className="__value">
                              <span>{item[mapping]}</span>
                            </div>
                          </div>
                        </Col> : <></>;
                      })
                    }
                    {
                      ['partitionType', 'ceilingHanger', 'ceilingFixing', 'counterPartitionType'].map((mapping: any) => {
                        return !!item[mapping] ? <Col span={12}>
                          <div className="__items">
                            <div className="__key">
                              {t(`workType.input.${mapping}.label`)} :
                            </div>
                            <div className="__value">
                              <span>{item[mapping]}</span>
                            </div>
                          </div>
                        </Col> : <></>;
                      })
                    }
                  </Row>
                </div>


              </div>
            </div>
          </Col>
          <Col xl={8} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '30px' }}>
            <div className="__info-card graph">
              <h4 className={'__form-header-title'}>
                {t('workType.workReport')}
              </h4>
              <div className="__body">
                <div className="__item-row">
                  <div className="__items">
                    <div className="__color primary">
                    </div>
                    <div className="__key">
                      {t('workType.pieChart.supplies')}
                    </div>
                    <div className="__value">
                      {calculations.supplies ? parseFloat(calculations.supplies.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                    </div>
                  </div>
                  <div className="__items">
                    <div className="__color danger">
                    </div>
                    <div className="__key">
                      {t('workType.pieChart.workForce')}
                    </div>
                    <div className="__value">
                      {calculations.workForce ? parseFloat(calculations.workForce.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}

                    </div>
                  </div>
                  <div className="__items">
                    <div className="__color">
                    </div>
                    <div className="__key">
                      {t('workType.pieChart.costOfWork')}
                    </div>
                    <div className="__value">
                      {calculations.costOfWork ? parseFloat(calculations.costOfWork + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                    </div>
                  </div>
                  <div className="__items">
                    <div className="__color ">
                    </div>
                    <div className="__key">
                      <strong> {t('workType.pieChart.sellingPrice')}</strong>
                    </div>
                    <div className="__value">
                      <strong>{calculations.sellingPrice ? parseFloat(calculations.sellingPrice.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}</strong>
                    </div>
                  </div>
                  <div className="__items">
                    <div className="__color secondary">
                    </div>
                    <div className="__key">
                      {t('workType.pieChart.margin')}
                    </div>
                    <div className="__value">
                      {calculations.margin ? parseFloat(calculations.margin.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                    </div>
                  </div>
                  <div className="__items">
                    <div className="__color ">
                    </div>
                    <div className="__key">
                      {t('workType.pieChart.coefficient')}
                    </div>
                    <div className="__value">
                      {calculations.cofficient ? parseFloat(calculations.cofficient.toString()).toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''}
                    </div>
                  </div>
                </div>
                <div className="__graph">
                  <CHART data={calculations} t={t}/>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row gutter={30} style={{ margin: '0 0 5px 0' }} className={'__bottom-table'}>
          <Col span={24} style={{ padding: 0 }}>
            <div className="__title">
              {
                t('workType.table.article.title')
              } &nbsp;
              {
                !(item.isAdminCreated && !isAdmin()) &&
                <div className="__button-add-icon " onClick={() => {
                  setArticlesFlat(articlesFlat.map((a: any) => {
                    return { ...a, selected: false };
                  }));
                  // setArticlesFlat(articlesFlat.map((a: any)=>{return {...a , selected: false}}));
                  setCreateModalVisible(true);
                }}>
                  <PlusOutlined/>
                </div>
              }
            </div>
          </Col>
          <Col span={24} style={{ padding: 0 }} className={'__table-pagination-padded '}>
            <Table
              showSorterTooltip={false}
              pagination={{
                // Set this value in pagiation to remove pagination
                hideOnSinglePage: true,
                //showSizeChanger: true,
                pageSize: 10000,
              }}
              bordered
              locale={{
                emptyText: (
                  <>
                    <Space style={{ padding: '10px' }} className={'flat-button primary'}>
                      <Text className={'primary-black'}>{t('article.table.emptyMessage')}</Text>
                      <div className={'flat-button primary'} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}>
                        {t('workType.button.addArticle')}
                      </div>
                    </Space>
                  </>
                ),
              }}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              summary={pageData => {
                let sum = 0;
                pageData.forEach(({ quantity, unitPrice }) => {
                  sum += parseFloat(quantity || 0) * parseFloat(unitPrice || 0);
                });
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell className={'__theme-text __medium light text-right'}
                                          colSpan={6}
                                          index={0}>{t('workSite.workTypes.listTitle.costPrice')}</Table.Summary.Cell>
                      <Table.Summary.Cell index={0} className={'text-right'}>
                        <Text
                          className={'__theme-text light text-right'}>{parseFloat(sum + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
              rowKey="index"
              // @ts-ignore
              columns={columns}
              dataSource={item.rows}
            >

            </Table>
          </Col>
          <Col span={24} style={{ padding: 0, borderTop: '1px solid rgb(233, 233, 233)' }}>
            <Row gutter={20} align={'middle'}
                 className={'__form-footer-parent-row __single-layer __from-sm'}
            >
              <Col style={{ height: '46px', paddingTop: '5px' }}>
                <div>
                  {
                    !(item.isAdminCreated && !isAdmin()) && <BaseButton type="primary"
                                                                        shape="round"
                                                                        size={'middle'}
                                                                        onClick={() => {
                                                                          setArticlesFlat(articlesFlat.map((a: any) => {
                                                                            return {
                                                                              ...a,
                                                                              selected: false,
                                                                            };
                                                                          }));
                                                                          setCreateModalVisible(true);

                                                                        }}>
                      {t('workType.button.addArticle')}
                    </BaseButton>
                  }
                </div>
              </Col>
              <Col>
                                <span className={'__theme-text __disabled_14px __medium'}
                                >{t('workType.text.sellingPrice')}</span>
                <InputWithDebounce  style={{ width: '165px', marginLeft: '20px', height: '40px !important' }} loading={loading} value={item.sellingPrice} disable={false} onChange={(e: any) => {
                  setLoading(true);
                  const newItem = JSON.parse(JSON.stringify(item));
                  newItem.sellingPrice = e;
                  setItem(newItem);
                }}/>
                {/*<InputNumber*/}
                {/*    step={STEP_INCREASE}*/}
                {/*    min={MIN_INPUT_NUMBER}*/}
                {/*    max={MAX_INPUT_NUMBER}*/}
                {/*    precision={DIGITS_TO_SHOW_AFTER_DOT}*/}
                {/*    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}*/}
                {/*    style={{ width: '165px', marginLeft: '20px', height: '40px !important' }}*/}
                {/*    className={'text-right __input-height-35'}*/}
                {/*    value={item.sellingPrice}*/}
                {/*    onChange={(value) => {*/}
                {/*     */}
                {/*    }}*/}
                {/*/>*/}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={20} align={'middle'} style={{ padding: '30px 0' }}
             className={'__form-footer-parent-row __single-layer'}>
          <Col style={{ paddingLeft: 0, lineHeight: (item.isAdminCreated && !isAdmin()) ? 0 : '46px' }}>
            {
              (item.isAdminCreated && !isAdmin()) ? <div/> :
                <div className={'flat-button primary'} onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  confirm({
                    icon: <ExclamationCircleOutlined/>,
                    title: t('workType.confirmation.msg'),
                    okText: t('workType.button.deleteWorkType'),
                    cancelText: t('genDict.Cancel'),
                    content: t('workType.confirmation.deleteWorkType'),// TODO
                    okButtonProps: { loading: deletingItem, danger: true },
                    onOk: async () => {

                      setDeletingItem(true);
                      deleteWorkTypeById(item.id).then(() => {
                        notification.success({
                          message: t('genDict.success'),
                          description: t('workType.notification.workTypeDeletedSuccess'),
                        });
                        history.push('/ouvrages');
                      }).catch(e => {
                        notification.error({
                          message: t(e),
                        });
                      }).finally(() => {
                        setDeletingItem(false);
                      });
                    },
                  });
                }
                }>
                  {t('workType.button.deleteWorkType')}
                </div>
            }
          </Col>
          <Col style={{ paddingRight: 0 }}>
            {
              !updating && <BaseButton type="primary"
                                       shape="round"
                                       className={'__button-inverted primary'}
                                       size={'large'} onClick={() => {
                if (duplicatingItem) {
                  return;
                }
                setDuplicateItemModal(true);
              }}>
                {t('workType.button.duplicateWorkType')}
              </BaseButton>
            }
          </Col>

        </Row>
        <Modal
          title={t('workType.duplicate.title')}
          visible={duplicateItemModal}
          width={500}
          maskClosable={false}
          onCancel={() => {
            setDuplicateItemModal(false);
          }}
          destroyOnClose={true}
          bodyStyle={{ padding: 0 }}
          footer={null}
        ><Form
          size="large"
          fields={fields}
          initialValues={{ name: item.name + ' - ' + t('genDict.copy_noun') }}
          onFinish={async (formValues) => {
            const reqObject = dotToObject(formValues);
            setDeletingItem(true);
            duplicateWorkTypeById({ id: item.id, ...reqObject }).then((success) => {
              if (success && success.data && success.data.workType_duplicate) {
                setDuplicatingItem(false);
                history.push(`/ouvrages/${success.data.workType_duplicate.id}/view`);
                window.location.reload();
              }
              notification.success({
                message: t('genDict.success'),
                description: t('workType.notification.workTypeDuplicatedSuccess'),
              });
            }).catch(e => {
              notification.error({
                message: t(e),
              });
            }).finally(() => {
              setDuplicatingItem(false);
            });
          }}
          layout="vertical"
        >
          <Row gutter={16} style={{ padding: '20px' }}>
            <Col span={24}>
              <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    label={t('workType.duplicate.input.name.label')}
                    name={'name'}
                    key="name"
                    rules={[
                      { required: true, message: t('workType.duplicate.input.name.required') },
                      {
                        pattern: REGEX_STRING_VERIFY,
                        message: t('errorMessage.stringWrongFormat'),
                      },
                    ]}
                  >
                    <Input
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Space direction={'horizontal'} className={'__form-footer'}>
                <Form.Item>
                  <BaseButton
                    type="default"
                    onClick={() => {
                      setDuplicateItemModal(false);
                    }}
                    disabled={duplicatingItem}
                    shape="round"
                    size={'large'}
                    className="submit"
                  >
                    {t('genDict.Cancel')}
                  </BaseButton>
                </Form.Item>
                <Form.Item>
                  <BaseButton
                    type="primary"
                    htmlType="submit"
                    loading={duplicatingItem}
                    shape="round"
                    size={'large'}
                    className="submit"
                  >
                    {t('workType.duplicate.button.addWorkType')}
                  </BaseButton>
                </Form.Item>
              </Space>
            </Col>
          </Row>
        </Form>
        </Modal>
        <Modal
          title={t('workType.addArticlesInWorkType')}
          visible={createModalVisible}
          width={1000}
          maskClosable={false}
          style={{ top: 10 }}
          onCancel={() => {
            setCreateModalVisible(false);
            setArticleSelectSearchKey('');
          }}
          destroyOnClose={true}
          bodyStyle={{ padding: 0 }}
          footer={null}
        >
          <div className={'__work-type-article-selection-list highlighted-select'}>
            {filterArticlesFromRow(articlesFlat.map((e: any) => {
              return { ...e, key: e.id };
            }), false).length ?
              <Row style={{ paddingLeft: '20px', marginTop: '10px' }}>
                <Col span={24}>
                  {
                    filterArticlesFromRow(articlesFlat.map((e: any) => {
                      return { ...e, key: e.id };
                    }), false).map((a: any) => {
                      return <Tag
                        style={{ marginBottom: '10px' }}
                        className="edit-tag"
                        closable={false}
                      >
                        {a.name}
                      </Tag>;
                    })
                  }
                </Col>
              </Row> : <></>
            }
            <ListView
              title={''}
              noLayoutP={true}
              searchBar={
                (<Space className={'filter-wrapper'}
                        style={{ justifyContent: 'space-between', width: '100%' }}>
                  <div className="filter-item">
                    <Space direction={'horizontal'} className={'filter-wrapper'} size={20}>
                      <div className="filter-item">

                        <ListSearchBar onChange={(e: string) => {
                          setArticleSelectSearchKey(e);
                        }} text={t('genDict.Search')}/>
                      </div>
                      <div className="filter-item">

                        <Select style={{ width: '180px' }}
                                showSearch

                                filterOption={(inputValue, option) =>
                                  option?.children?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                                value={filterByType || undefined} onChange={(e) => {
                          setFilterByType(e);
                        }} placeholder={t('article.search.filterByCategory')}>
                          <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                          {
                            articleCategories && articleCategories.map((option: any) => {
                              return <Select.Option
                                value={option.id}>{option.name}</Select.Option>;
                            })
                          }
                        </Select>
                      </div>
                      <div className="filter-item"
                           style={{ minWidth: '130px' }}>

                        <Switch defaultChecked={false} checked={filterCreatedByMe}
                                onChange={setFilterCreatedByMe}/>&nbsp;{t('article.search.myArticles')}
                      </div>

                    </Space>
                  </div>
                </Space>)
              }
            >
              <Table
                // @ts-ignore
                rowClassName={(record) => record.selected ? 'ant-table-row-selected' : ''}
                showSorterTooltip={false}
                pagination={{
                  showSizeChanger: true,
                  onShowSizeChange: (currentPage, pageSize) => {
                    paginationParams(pageSize);
                  },
                  defaultPageSize: getDefaultPageSize(),
                }}
                // rowSelection={rowSelection}
                // @ts-ignore
                onRow={(r) => ({
                  onClick: () => {
                    let foundItemIndex = -1;
                    articlesFlat.forEach((aa: any, i: number) => {
                      if (aa.id === r.id) {
                        foundItemIndex = i;
                      }
                    });
                    articlesFlat[foundItemIndex].selected = !articlesFlat[foundItemIndex].selected;
                    setArticlesFlat(JSON.parse(JSON.stringify(articlesFlat)));
                  },
                })}
                locale={{
                  emptyText: (
                    <>
                      <Space style={{ padding: '10px' }}>
                        <Text
                          className={'primary-black'}>{t('article.table.emptyMessage')}</Text>
                      </Space>
                    </>
                  ),
                }}
                columns={articleSelectColumns}
                dataSource={filterArticlesFromRow(filteredList(articleSelectSearchKey, articlesFlat, {
                  'category.id': filterByType,
                  'isAdminCreated': filterCreatedByMe ? false : null,
                }).map((e) => {
                  return { ...e, key: e.id };
                }), true)}
              >articles
              </Table>
            </ListView>

            <Row>
              <Col span={24}>
                <Space direction={'horizontal'} className={'__form-footer-parent'}>
                  <div/>

                  <Space direction={'horizontal'} className={'__form-footer'}>
                    <Button
                      type="default"
                      loading={addingArticlesToWork}
                      onClick={async () => {
                        const s: any = [];
                        articlesFlat.forEach((r: any) => {
                          if (r.selected) {
                            s.push(r);
                          }
                        });
                        if (!s.filter((a: any) => !a.isPersonalized).length) {
                          setAddingArticlesToWork(true);
                          await new Promise(((resolve) => {
                            const addArticles = (articles: any[], index: number) => {
                              if (!articles[index]) {
                                refresh();
                                resolve(null);
                                return;
                              }
                              addArticleToWorkType(articles[index].id, item.id).then(() => {
                              }).catch(e => {
                                notification.error({
                                  message: t(e),
                                });
                              }).finally(() => {
                                addArticles(articles, index + 1);
                              });
                            };
                            addArticles(s, 0);

                          }));
                          setCreateModalVisible(false);
                          setArticleSelectSearchKey('');
                          setAddingArticlesToWork(false);
                        } else {
                          setArticlesToEditPersonalizePriceOf(s);
                          setCreateModalVisible(false);
                          setArticleSelectSearchKey('');
                          setCreateModalVisiblePersonalizePriceEdit(true);
                        }
                      }}
                      shape="round"
                      size={'large'}
                      className="submit">
                      {t('workType.button.toClose')}
                    </Button>
                  </Space>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          title={t('workType.articlePriceEdit')}
          visible={createModalVisiblePriceEdit}
          maskClosable={false}
          width={660}
          onCancel={() => {
            setCreateModalVisiblePriceEdit(false);
          }}
          destroyOnClose={true}
          bodyStyle={{ padding: 0 }}
          footer={null}
        >
          <div className={'__work-type-article-selection-list'}>
            <ListView
              title={''}
              noLayoutP={true}
              hideSearchSection={true}
              subTitle={
                <div className={'__sub-title'}>
                  <Space><ExclamationCircleOutlined className={'__icon-themed __icon-blue'}/> <span
                    className="__theme-text __black-shade">
                                    {t('workType.articleEditPriceSubtitle')}
                                </span>
                  </Space>
                </div>
              }>
              <Table
                showSorterTooltip={false}
                pagination={{
                  hideOnSinglePage: true,
                  showSizeChanger: true,
                  onShowSizeChange: (currentPage, pageSize) => {
                    paginationParams(pageSize);
                  },
                  defaultPageSize: getDefaultPageSize(),
                }}
                locale={{
                  emptyText: (
                    <>
                      <Space style={{ padding: '10px' }}>
                        <Text
                          className={'primary-black'}>{t('genDict.emptyMessage')}</Text>
                      </Space>
                    </>
                  ),
                }}

                columns={articlesToEditPriceOf && articlesToEditPriceOf.length && articlesToEditPriceOf[0].type === 'effort' ? articlePriceColumnsEffort : articlePriceColumns}
                dataSource={articlesToEditPriceOf}
              >articles
              </Table>
            </ListView>
            <Row>
              <Col span={24}>
                <Space direction={'horizontal'} className={'__form-footer-parent'}>
                  <div/>
                  <Space direction={'horizontal'} className={'__form-footer'}>
                    <Button
                      type="default"
                      onClick={() => {
                        setCreateModalVisiblePriceEdit(false);
                        refresh();
                      }}
                      shape="round"
                      size={'large'}
                      className="submit">
                      {t('genDict.Cancel')}
                    </Button>
                    <BaseButton
                      type="primary"
                      onClick={async () => {
                        const articlesToUpdate: any[] = [];
                        const effortsToUpdate: any[] = [];
                        articlesToEditPriceOf.forEach((priceOuter: any) => {
                          item.rows.forEach((priceInner: any) => {
                            if (priceInner.id === priceOuter.id) {
                              priceInner.unitPrice = parseFloat(priceOuter.unitPrice).toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT);
                              if (priceInner.effort) {
                                effortsToUpdate.push(priceInner);
                              } else {
                                articlesToUpdate.push(priceInner);
                              }
                            }
                          });
                        });
                        await new Promise((resolve) => {
                          const personalizeArticle = (articles: any[], index: number) => {
                            if (!articles[index]) {
                              return resolve(null);
                            }
                            personalizeArticlePrice(articles[index].item.id, articles[index].unitPrice).then(() => {
                              refetchArticles();
                            }).catch(e => {
                              notification.error({
                                message: t(e),
                              });
                            }).finally(() => {
                              personalizeArticle(articles, index + 1);
                            });
                          };
                          personalizeArticle(articlesToUpdate, 0);
                        });
                        await new Promise((resolve) => {
                          const personalizeEffort = (articles: any[], index: number) => {
                            if (!articles[index]) {
                              return resolve(null);
                            }
                            personalizeEffortPrice(articles[index].id, articles[index].unitPrice).then(() => {

                            }).catch((e: any) => {
                              notification.error({
                                message: t(e),
                              });
                            }).finally(() => {
                              personalizeEffort(articles, index + 1);
                            });
                          };
                          personalizeEffort(effortsToUpdate, 0);
                        });
                        setCreateModalVisiblePriceEdit(false);
                        refresh();
                      }}
                      shape="round"
                      size={'large'}
                      className="submit"
                    >
                      {t('workType.button.register')}
                    </BaseButton>
                  </Space>
                </Space>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          title={t('workType.articleToPersonalizePriceEdit')}
          visible={createModalVisiblePersonalizePriceEdit}
          width={700}
          maskClosable={false}
          onCancel={() => {
            setCreateModalVisiblePersonalizePriceEdit(false);
          }}
          destroyOnClose={true}
          bodyStyle={{ padding: 0 }}
          footer={null}
        >
          <div className={'__work-type-article-selection-list'}>
            <ListView
              title={''}
              noLayoutP={true}
              hideSearchSection={true}
              subTitle={
                <div className={'__sub-title'}>
                  <Space><ExclamationCircleOutlined className={'__icon-themed __icon-yellow'}/> <span
                    className="__theme-text __black-shade">
                                    {t('workType.articleEditPricePersonalizeSubtitle', {
                                      quantity: articlesToEditPersonalizePriceOf.filter((a: any) => {
                                        return !a.isPersonalized;
                                      }).length,
                                    })}
                                </span>
                  </Space>
                </div>
              }>
              <Table
                showSorterTooltip={false}
                pagination={{
                  hideOnSinglePage: true,
                  showSizeChanger: true,
                  onShowSizeChange: (currentPage, pageSize) => {
                    paginationParams(pageSize);
                  },
                  defaultPageSize: getDefaultPageSize(),
                }}
                locale={{
                  emptyText: (
                    <>
                      <Space style={{ padding: '10px' }}>
                        <Text
                          className={'primary-black'}>{t('genDict.emptyMessage')}</Text>
                      </Space>
                    </>
                  ),
                }}

                columns={articlePriceColumnsPersonalize}
                dataSource={articlesToEditPersonalizePriceOf.filter((a: any) => {
                  return !a.isPersonalized;
                })}
              >articles
              </Table>
            </ListView>
            <Row gutter={20} align={'middle'} className={'__form-footer-parent-row __single-layer'}>
              <Col style={{ height: '46px' }}>
                {
                  <Button
                    type="default"
                    onClick={() => {
                      setCreateModalVisiblePersonalizePriceEdit(false);
                      refresh();
                    }}
                    shape="round"
                    size={'large'}
                    className="submit">
                    {t('genDict.Cancel')}
                  </Button>
                }
              </Col>
              <Col>
                <BaseButton
                  type="primary"
                  loading={addingArticlesToWork}
                  onClick={async () => {
                    const articlesToUpdate: any[] = [];
                    articlesToEditPersonalizePriceOf.filter((a: any) => {
                      return !a.isPersonalized;
                    }).forEach((priceOuter: any) => {
                      articlesToUpdate.push(priceOuter);

                    });

                    setAddingArticlesToWork(true);

                    try {
                      await Promise.all(
                        articlesToUpdate.map((article) => {
                          return personalizeArticlePrice(article.id, article.price);
                        }),
                      );

                      await Promise.all(articlesToEditPersonalizePriceOf.map((article) => {
                        return addArticleToWorkType(article.id, item.id);
                      }));

                      setCreateModalVisible(false);
                      setCreateModalVisiblePersonalizePriceEdit(false);
                    } catch (e) {
                      notification.error({
                        message: t(e as any),
                      });
                    }

                    refresh();
                    refetchArticles();
                    setAddingArticlesToWork(false);
                  }}
                  shape="round"
                  size={'large'}
                  className="submit"
                >
                  {t('workType.button.register')}
                </BaseButton>
              </Col>
            </Row>
          </div>
        </Modal>
        {/*<9iv style={{ height: '90px' }}/>*/}
      </div>
    </div>
  );
};


function InputWithDebounce({ value, disable,style, onChange, loading }: any) {
  const [localValue, setLocalValue] = useState(value);

  return (
    <InputNumber
      className={'__input-height-35 __theme-text text-right __disabled'}
      value={localValue}
      disabled={loading || disable}
      onBlur={
        () => {
          if (localValue !== value)
            onChange(localValue);
        }
      }
      step={STEP_INCREASE}
      min={MIN_INPUT_NUMBER}
      max={MAX_INPUT_NUMBER}
      precision={DIGITS_TO_SHOW_AFTER_DOT}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
      style={style || { width: '120px', height: '35px !important' }}
      onChange={(v) => {
        setLocalValue(parseFloat(parseFloat((v || 0.0) + '').toFixed(DIGITS_TO_SHOW_AFTER_DOT)));
      }}
    />
  );
}


export default View;
