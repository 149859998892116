import * as React from 'react';

import { useTranslation } from 'react-i18next';
import {
    Checkbox,
    Modal,
    Form,
    Row,
    Col,
    Button,
    Input,
} from 'antd';

const { TextArea } = Input;

import './index.scss';
// @ts-ignore
import useSuppliers from '../../../../hooks/suppliers/useSuppliers';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { Redirect } from 'react-router-dom';
import { AuthConsumer } from '../../../../contexts/AuthContext';

type Props = {
    onSubmit?: any,
    workSite?: any,
    downloading?: any,
    loading?: any,
    onDownload?: any,
    onBack?: any,
    onCancel?: any,
}

const ClientDetails: React.FunctionComponent<Props> = ({ downloading, loading, onDownload, onSubmit, onCancel }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const fields: any[] = [];

    return (
        <AuthConsumer>
            {({ user }) =>
                <Modal
                    title={t('workSite.quotes.createQuote.title')}
                    visible={true}
                    width={960}
                    maskClosable={false}
                    afterClose={
                        onCancel
                    }
                    onCancel={onCancel}
                    destroyOnClose={true}
                    bodyStyle={{ padding: 0 }}
                    footer={null}>
                    <div>
                        <Form
                            size="large"
                            form={form}
                            fields={fields}
                            initialValues={{ showBankInfo: user && user.company && !!user.company.displayBankInformationOnQuote }}
                            onFinish={(values) => {
                                onSubmit(values);
                            }}
                            layout="vertical"
                            requiredMark={false}>
                            <Row gutter={16} style={{ padding: '0px 25px 0px 25px' }}>
                                <Col sm={24}>
                                    <Form.Item
                                        valuePropName="checked"
                                        style={{ paddingTop: '14px' }}
                                        name={'showBankInfo'}>
                                        <Checkbox>
                                        <span
                                            className={'__theme-checkbox'}>{t('workSite.quotes.createQuote.input.showBankInfo.label')}</span>
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ padding: '0px 25px 0px 25px' }}>
                                <Col xs={24} sm={24}>
                                    <Form.Item
                                        name={'comment'}
                                        label={t('workSite.quotes.createQuote.input.comment.label')}
                                        rules={[
                                            {
                                                //required: true,
                                                message: t('workSite.quotes.createQuote.input.comment.required'),
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4}/>
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Form.Item>
                                <Row gutter={20} align={'middle'} className={'__form-footer-parent-row __from-sm'}>

                                    <Col/>
                                    <Col className={'__form-footer-row __from-sm'}>
                                        <Row gutter={20}>

                                            <Col>
                                                <Button
                                                    type="default"
                                                    disabled={downloading || loading}
                                                    onClick={async () => {
                                                        onCancel();
                                                    }}
                                                    shape="round"
                                                    size={'large'}
                                                    className="submit">
                                                    {t('workSite.quotes.createQuote.button.cancel')}
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    loading={downloading}
                                                    disabled={loading}
                                                    onClick={async () => {
                                                        form.validateFields(['providerId']).then((d) => {
                                                            onDownload(form.getFieldsValue());
                                                        }).catch((e) => {
                                                        });
                                                    }}
                                                    shape="round"
                                                    className={'__button-inverted primary'}
                                                    size={'large'}>
                                                    {t('workSite.quotes.createQuote.button.download')}
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    loading={loading}
                                                    disabled={downloading || loading}
                                                    style={{ width: 'auto' }}
                                                    shape="round"
                                                    size={'large'}
                                                    className="submit"
                                                >
                                                    {t('workSite.quotes.createQuote.button.next')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            }
        </AuthConsumer>
    );
};
export default ClientDetails;
