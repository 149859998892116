import * as React from 'react';
import { Button as BaseButton, Tooltip } from 'antd';
import { EditFilled, EditOutlined } from '@ant-design/icons';

type Props = {
    onClick: () => void;
    style?: any;
    tooltipText?: string;
    disabled?: boolean;
    notFilled?: boolean
}

const EditButton: React.FunctionComponent<Props> = ({ tooltipText, style, disabled, onClick, notFilled }) => {
    return (
        <Tooltip placement="top" title={tooltipText}>

            {
                notFilled ? <EditFilled
                        className={'_hover-primary-icon'}
                        style={style || {}}
                        onClick={onClick}
                    /> :
                    <BaseButton
                        type={'primary'}
                        shape="circle"
                        disabled={disabled}
                        style={style || {}}
                        onClick={onClick}
                        icon={<EditFilled/>}
                    />
            }
        </Tooltip>

    );
};
export default EditButton;
