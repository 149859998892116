import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { UserCompany } from '../../types/user.type';

type ReturnType = {
    admin_billsByCompany: any[];
    loading: boolean;
    error: any;
    refetch: any
};
const GET_USER_COMPANY_BILLS = gql`
    query AdminBillsByCompany($companyId: ID!){
  admin_billsByCompany(companyId: $companyId){
    id
    reference
     document{
      id
      title
      type
      mime
      extension
      fileName
      fileSize
      url
      createdAt
      updatedAt
      companyId
    }
    startPeriod
    endPeriod
    isPaid
    paymentDate
    type
    stripeInvoiceId
    amount
  }
} 
`;

const useCompanyUserBills: (companyId: any) => ReturnType = (companyId: any) => {
    const { data, loading, error, refetch } = useQuery(GET_USER_COMPANY_BILLS, {
        variables: { companyId },
    });
    let admin_billsByCompany = [];
    if (!loading && !error) {
        admin_billsByCompany = data.admin_billsByCompany;
    }
    return {
        admin_billsByCompany,
        refetch,
        loading,
        error,
    };
};
export default useCompanyUserBills;
