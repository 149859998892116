import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import {
    Button,
    Button as BaseButton,
    Col,
    Image,
    InputNumber,
    Row,
    Select,
    Space,
    Switch,
    Typography,
} from 'antd';

import { useTranslation } from 'react-i18next';
import ListSearchBar from 'shared/components/Ant/ListSearchBar/ListSearchBar';
import { Table, Tooltip, Modal, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import EditButton from 'shared/components/Ant/Buttons/EditButton';
import DeleteButton from 'shared/components/Ant/Buttons/DeleteButton';
import {
    ExclamationCircleOutlined,
    InfoCircleFilled,
    CheckCircleFilled,
    CloseCircleFilled,
} from '@ant-design/icons';


import './index.scss';
import { useEffect, useState } from 'react';
import {
    filteredList,
    flatArticles,
    getDefaultPageSize,
    paginationParams,
    sortPrice,
    useLayoutMedia,
} from 'shared/services/Utility';
import { Article } from './Article.model';
import {
    articlesPersonalize,
    deleteArticleById,
    fetchArticles, updateArticlesBulk2,
    useCategories,
} from 'shared/services/articleService';
import ArticleCreateModal from './ArticleCreateModal';
import { useLocation } from 'react-use';

import {
    ConstantImageData,
    THREE_DIGITS_TO_SHOW_AFTER_DOT,
    MAX_INPUT_NUMBER,
    MIN_INPUT_NUMBER,
    STEP_INCREASE,
} from 'shared/constants/AppConstants';
import { isAdmin } from 'shared/contexts/AuthContext';

const { Text } = Typography;
type Props = {
    t: Function,
}

const { confirm } = Modal;

function sortAlpha(a: string, b: string) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
}

const List: React.FunctionComponent<Props> = () => {
    const [items, setItems] = useState([] as any[]);
    const [createModalVisible, setCreateModalVisible] = React.useState(false);
    const [searchKey, setSearchKey] = React.useState('');
    const [fetchingItems, setFetchingItems] = React.useState(false);
    const [modifyBulk, setModifyBulk] = React.useState([] as any);
    const [deletingItem, setDeletingItem] = React.useState(false);
    const [showImagePreview, setShowImagePreview] = React.useState(-1);
    const { articleCategories, loadingCategories } = useCategories();

    const isDesktop = useLayoutMedia(['Desktop']);
    const history = useHistory();
    const { t } = useTranslation();
    const refresh = () => {
        setFetchingItems(true);
        fetchArticles().then((response) => {
            if (response && response.data && response.data.articles) {
                setItems(response.data.articles);
                setCreateModalVisible(false);
            }
        }).catch(() => {
            notification.error({
                message: t('genDict.errorWhileFetchingContent'),
            });
        }).finally(() => {
            setSelectedRowKeys([]);
            setFetchingItems(false);
        });
    };
    // eslint-disable-next-line
    useEffect(refresh, []);
    const location = useLocation();

    useEffect(() => {
        if (location['hash'] && location['hash'].toLowerCase() && location['hash'].toLowerCase() === '#add') {
            setCreateModalVisible(true);
        }
    }, [items, location]);

    const handleCancel = () => {
        setCreateModalVisible(false);
        history.push('/articles');
    };
    const columns = [
        {
            title: t('article.listTitle.reference'),
            render: (a: Article) => (
                <Link to={`/articles/${a.id}/view`} className={'__theme-text __bold'}>{a.reference}</Link>),
            sorter: (a: Article, b: Article) => sortAlpha(a.reference, b.reference),
        },
        {
            title: t('article.listTitle.image'),
            render: (a: Article, data: any, index: number) => (
                <Image
                    width={'40px'}
                    height={'40px'}
                    preview={
                        {
                            visible: showImagePreview === index,
                            onVisibleChange: () => {
                                setShowImagePreview(showImagePreview === index ? -1 : index);
                            },
                        }
                    }
                    src={(showImagePreview === index ? (a.pictureUrl + '&format=full') : a.pictureUrl) || ConstantImageData}
                    fallback={ConstantImageData}
                />),
        },
        {
            title: t('article.listTitle.name'),
            render: (a: Article) => (
                <Link to={`/articles/${a.id}/view`}>{a.name}</Link>),
            sorter: (a: Article, b: Article) => sortAlpha(a.name, b.name),
        },
        {
            title: t('article.listTitle.price'),
            render: (a: any) => {
                if (!a.isPersonalized) {
                    return <><span className={'text-disabled'}>{a.price} &nbsp;
                        <Tooltip placement={'top'} title={t('article.tooltip.articlePersonalize')}>
									<InfoCircleFilled className={'icon-disabled'}/>
								</Tooltip>
							</span></>;
                } else {
                    return <span>{a.price}</span>;
                }
            },
            sorter: (a: any, b: any) => sortPrice(a.price, b.price),
        },
        {
            title: <div className={'text-center'}>{t('article.listTitle.unit')}</div>,
            render: (a: Article) => (
                <div className={'text-center'}>
                    {a.unit}
                </div>),
        },

        {
            title: <div className={'text-center'}>{t('article.listTitle.isHeightSensitive')}</div>,
            render: (a: Article) => (
                <div className={'text-center'}>
                    {
                        !a.isHeightSensitive ? <CloseCircleFilled className={'icon-disabled'}/> :
                            <CheckCircleFilled className={''}/>
                    }
                </div>),
        },
        {
            title: t('article.listTitle.category'),
            render: (a: any) => (
                <span>{a.category.name}</span>),
        },
        isAdmin() ? {
            title: <div className={'text-center'}>{t('article.listTitle.isPrioritize')}</div>,
            render: (a: Article) => (
                <div className={'text-center'}>
                    {
                        !a.priority ? <></> :
                            <CheckCircleFilled className={'round icon-primary-no-hover'}/>
                    }
                </div>),
        } : {
            title: <div className={'text-center'}>{t('article.listTitle.isAdminCreated')}</div>,
            render: (a: Article) => (
                <div className={'text-center'}>
                    {
                        a.isAdminCreated ? <></> :
                            <CheckCircleFilled className={'round icon-primary-no-hover'}/>
                    }
                </div>),
        },
        {
            fixed: 'right',
            title: t('article.listTitle.actions'),
            render: (a: Article) => (
                <div className="__actionButtons">
                    <Space size={10}>
                        <Tooltip placement={'top'} title={t('article.tooltip.editArticle')}>
                            <div/>
                            <EditButton onClick={() => history.push('/articles/' + a.id + '/view')}/>
                        </Tooltip>
                        <Tooltip placement={'top'} title={a.isDeletable ? t('article.tooltip.deleteArticle') : ''}>
                            <div/>
                            <DeleteButton disabled={!a.isDeletable} onClick={() =>
                                confirm({
                                    icon: <ExclamationCircleOutlined/>,
                                    title: t('article.confirmation.msg'),
                                    okText: t('article.button.deleteArticle'),
                                    cancelText: t('genDict.Cancel'),
                                    content: t('article.confirmation.deleteArticle'),// TODO
                                    okButtonProps: { loading: deletingItem, danger: true },
                                    onOk: async () => {
                                        setDeletingItem(true);
                                        deleteArticleById(a.id).then(() => {
                                            notification.success({
                                                message: t('genDict.success'),
                                                description: t('article.notification.articleDeletedSuccess'),
                                            });
                                            refresh();
                                        }).catch(e => {
                                            notification.error({
                                                message: t(e),
                                            });
                                        }).finally(() => {
                                            setDeletingItem(false);
                                        });
                                    },
                                })}/>
                        </Tooltip>
                    </Space>
                </div>
            ),
            width: '150px',
        },
    ];
    const [filterByType, setFilterByType] = useState('');
    const [savingBulk, setSavingBulk] = useState(false);
    const [filterCreatedByMe, setFilterCreatedByMe] = useState(false);
    const [filterPriority, setFilterPriority] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([] as any[]);
    const rowSelection = {
        selectedRowKeys,
        onChange: (e: any) => {
            setSelectedRowKeys(e);
        },
    };
    const articlePriceColumns = [
        {
            title: t('article.listTitle.reference'),
            render: (a: any) => (
                <span className={'__theme-text text-primary  __bold'}>{a.reference}</span>),
        },
        {
            title: t('article.listTitle.image'),
            render: (a: any) => (<Image
                width={'40px'}
                height={'40px'}
                src={a.pictureUrl || ConstantImageData}
                fallback={ConstantImageData}
            />),
        },
        {
            title: t('article.listTitle.name'),
            render: (a: Article) => (
                <span>{a.name}</span>),
            sorter: (a: Article, b: Article) => sortAlpha(a.name, b.name),
        },
        {
            title: t('article.listTitle.isAdminCreated'),
            render: (a: Article) => (
                <div className={'text-center'}>
                    {
                        a.isAdminCreated ? <></> :
                            <CheckCircleFilled className={'round icon-primary-no-hover'}/>
                    }
                </div>),
        },
        {
            title: t('article.listTitle.unit'),
            render: (a: Article) => (
                <span>{a.unit}</span>),
            sorter: (a: Article, b: Article) => sortAlpha(a.unit, b.unit),
        },
        {
            title: t('article.listTitle.price'),
            render: (a: any) => {

                return <InputNumber
                    value={a.price} className={'text-right __input-height-35'}
                    step={STEP_INCREASE}
                    min={MIN_INPUT_NUMBER}
                    max={MAX_INPUT_NUMBER}
                    precision={THREE_DIGITS_TO_SHOW_AFTER_DOT}
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                    style={{ width: '120px', height: '35px !important' }}
                    onChange={(value) => {
                        modifyBulk.forEach((b: any) => {
                            if (b.id === a.id) {
                                b.price = parseFloat((value || 0.00) + '').toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT);
                                a.price = parseFloat((value || 0.00) + '').toFixed(THREE_DIGITS_TO_SHOW_AFTER_DOT);

                            }
                        });
                        setModifyBulk(JSON.parse(JSON.stringify(modifyBulk)));
                    }}
                />;
            },
        },
        {
            title: t('article.listTitle.category'),
            render: (a: any) => {
                return <Select
                    className={'__small-select'}
                    showSearch
                    onChange={(e) => {
                        modifyBulk.forEach((b: any) => {
                            if (b.id === a.id) {
                                b.categoryId = e;
                            }
                        });
                        setModifyBulk(JSON.parse(JSON.stringify(modifyBulk)));
                    }}
                    value={a.categoryId || a.category!.id}
                    filterOption={(inputValue, option) =>
                        (option?.children as string | undefined)?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    placeholder={t('genDict.SelectArticleCategory')}
                    disabled={loadingCategories || !a.isArticle || a.isAdminCreated}>
                    {
                        articleCategories.map((option: any) => {
                            return <Select.Option value={option.id}>{t(option.name)}</Select.Option>;
                        })
                    }
                </Select>;
            },
        },
    ];
    const filteredItems = () => {
        return flatArticles(items.filter((i: any) => {
            return selectedRowKeys.indexOf(i.id) > -1;
        }));
    };

    return (
        <ListView
            title={t(isAdmin() ? 'genDict.ArticlesAdmin' : 'genDict.Articles')}
            pageTitle={t('genDict.page-title', { title: t('pageTitles.listArticles') })}
            loading={fetchingItems}
            paddingBottomRequired={true}
            searchBar={
                (<Space className={'filter-wrapper'} style={{ justifyContent: 'space-between', width: '100%' }}>
                    <div className="filter-item">
                        <Space direction={'horizontal'} className={'filter-wrapper'} size={20}>
                            <div className="filter-item">

                                <ListSearchBar onChange={(e: string) => {
                                    setSearchKey(e);
                                }} text={t('genDict.Search')}/>
                            </div>
                            <div className="filter-item">

                                <Select style={{ width: '180px' }}
                                        showSearch

                                        filterOption={(inputValue, option) =>
                                            option?.children?.toString().toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        value={filterByType || undefined} onChange={(e) => {
                                    setFilterByType(e);
                                }} placeholder={t('article.search.filterByCategory')}>
                                    <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                                    {
                                        articleCategories && articleCategories.map((option: any) => {
                                            return <Select.Option value={option.id}>{option.name}</Select.Option>;
                                        })
                                    }
                                </Select>
                            </div>
                            <div className="filter-item" style={{ minWidth: isAdmin() ? '168px' : '130px' }}>


                                {
                                    isAdmin() ? <>
                                        <Switch defaultChecked={false} checked={filterPriority}
                                                onChange={setFilterPriority}/>&nbsp;{t('article.search.isPrioritize')}
                                    </> : <>
                                        <Switch defaultChecked={false} checked={filterCreatedByMe}
                                                onChange={setFilterCreatedByMe}/>&nbsp;{t('article.search.myArticles')}
                                    </>
                                }
                            </div>

                        </Space>
                    </div>
                    <div className="filter-item">
                        <Button
                            style={{ height: '33px' }}
                            type="default"
                            disabled={!selectedRowKeys.length}
                            onClick={() => {
                                setModifyBulk(filteredItems());
                            }}
                            shape="round"
                            size={'middle'}
                            className="submit"
                        >
                            {t('article.button.modificationRapid')}
                        </Button>
                    </div>
                </Space>)
            }
            createButton={(
                <BaseButton type="primary"
                            shape="round"
                            size={'large'}
                            onClick={() => {
                                setCreateModalVisible(true);
                            }}>
                    {isDesktop ? t('article.button.addArticleMenu') : t('genDict.add')}
                </BaseButton>
            )}

        >
            <Modal
                title={t('article.bulkPageTitle')}
                visible={modifyBulk.length}
                maskClosable={false}
                width={1000}
                onCancel={() => {
                    refresh();
                    setModifyBulk([]);
                }}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <div className={'__work-type-article-selection-list'}>
                    <ListView
                        title={''}
                        hideSearchSection={true}
                        subTitle={
                            ''
                        }>
                        <Table
							showSorterTooltip ={false}
                            pagination={{
                                hideOnSinglePage: true,
                                onShowSizeChange: (currentPage, pageSize) => {
                                    paginationParams(pageSize);
                                },
                                defaultPageSize: getDefaultPageSize(),
                            }}
                            locale={{
                                emptyText: (
                                    <>
                                        <Space style={{ padding: '10px' }}>
                                            <Text
                                                className={'primary-black'}>{t('genDict.emptyMessage')}</Text>
                                        </Space>
                                    </>
                                ),
                            }}

                            columns={articlePriceColumns}
                            dataSource={modifyBulk}
                        >articles
                        </Table>
                    </ListView>

                </div>
                <Row>
                    <Col span={24}>
                        <Space direction={'horizontal'} className={'__form-footer-parent'}>
                            <div/>
                            <Space direction={'horizontal'} className={'__form-footer'}>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        setModifyBulk([]);
                                        refresh();
                                    }}
                                    disabled={savingBulk}
                                    shape="round"
                                    size={'large'}
                                    className="submit">
                                    {t('genDict.Cancel')}
                                </Button>
                                <BaseButton
                                    type="primary"
                                    disabled={savingBulk}
                                    onClick={async () => {
                                        const articlesToUpdate: any[] = modifyBulk.filter((f: any) => {
                                            return (!isAdmin() && !f.isAdminCreated) || (isAdmin() && f.isAdminCreated);
                                        });
                                        const articlesToPersonalize: any[] = modifyBulk.filter((f: any) => {
                                            return !((!isAdmin() && !f.isAdminCreated) || (isAdmin() && f.isAdminCreated));
                                        });
                                        setSavingBulk(true);

                                        updateArticlesBulk2(articlesToUpdate.map((a: any) => {
                                            return {
                                                article: a.id,
                                                price: a.price,
                                                category: a.categoryId || a.category.id,
                                            };
                                        })).then(async () => {
                                            await articlesPersonalize({
                                                articles: articlesToPersonalize.map((e: any) => {
                                                    return { id: e.id, price: e.price };
                                                }),
                                            });
                                        }).catch(() => {
                                            notification.error({
                                                message: t('article.notification.bulkEditSuccess'),
                                            });
                                        }).finally(() => {
                                            setModifyBulk([]);
                                            setSavingBulk(false);
                                            refresh();
                                        });

                                    }}
                                    shape="round"
                                    size={'large'}
                                    className="submit"
                                >
                                    {t('article.button.bulkSave')}
                                </BaseButton>
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </Modal>
            <Modal
                title={t(!isAdmin() ? 'article.button.addArticleMenu' : 'article.button.addArticleMenuAdmin')}
                visible={createModalVisible}
                width={1000}
                maskClosable={false}
                afterClose={handleCancel}
                onCancel={handleCancel}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                <ArticleCreateModal onCancel={(reload) => {
                    if (reload) {
                        refresh();
                    }
                    handleCancel();
                }} mode={'CREATE'}/>
            </Modal>
            <Table
                rowSelection={rowSelection}
                locale={{
                    emptyText: (
                        searchKey || filterByType || filterCreatedByMe ? <>
                            <Space style={{ padding: '10px' }}>
                                <Text className={'primary-black'}>{t('article.table.noSearchResultFound')}</Text>
                            </Space>
                        </> : <>
                            <Space style={{ padding: '10px' }}>
                                <Text className={'primary-black'}>{t('article.table.emptyMessage')}</Text>
                                <div className={'flat-button primary'} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setCreateModalVisible(true);
                                }}>
                                    {t('article.button.addArticle')}
                                </div>
                            </Space>
                        </>
                    ),
                }}
				showSorterTooltip ={false}
                pagination={{
                    showSizeChanger: true,
                    onShowSizeChange: (currentPage, pageSize) => {
                        paginationParams(pageSize);
                    },
                    defaultPageSize: getDefaultPageSize(),
                }}
                // @ts-ignore
                columns={columns}
                dataSource={filteredList(searchKey, items, {
                    'category.id': filterByType,
                    'isAdminCreated': filterCreatedByMe ? false : null,
                    'priority': filterPriority ? true : null,
                }).map((e) => {
                    return { ...e, key: e.id };
                })}
            >articles
            </Table>
        </ListView>
    );
};
export default List;


