import { useState } from 'react';
import {
  DIGITS_TO_SHOW_AFTER_DOT,
  THREE_DIGITS_TO_SHOW_AFTER_DOT,
  MAX_INPUT_NUMBER,
  MIN_INPUT_NUMBER,
  STEP_INCREASE,
} from '../../../../constants/AppConstants';
import * as React from 'react';
import { InputNumber } from 'antd';

function InputWithDebounce({fromEffort, value, onChange, loading }: any) {
  const [localValue, setLocalValue] = useState(value);

  return (
    <InputNumber
      className={'__input-height-35 __theme-text text-right __disabled'}
      value={localValue}
      disabled={loading}
      onBlur={
        () => {
          if (localValue !== value)
            onChange(localValue);
        }
      }
      step={STEP_INCREASE}
      min={MIN_INPUT_NUMBER}
      max={MAX_INPUT_NUMBER}
      precision={fromEffort === 'article' ? THREE_DIGITS_TO_SHOW_AFTER_DOT : DIGITS_TO_SHOW_AFTER_DOT}
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
      style={{ width: '120px', height: '35px !important' }}
      onChange={(v) => {
        setLocalValue(parseFloat(v));
      }}
    />
  );
}
export default InputWithDebounce;
