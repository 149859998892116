import * as React from 'react';
import ListView from 'shared/components/layout/ListView';
import { Button as BaseButton, Select, Space, Modal } from 'antd';

import { useTranslation } from 'react-i18next';
import ListSearchBar from 'shared/components/Ant/ListSearchBar/ListSearchBar';

import { useHistory } from 'react-router-dom';
import ClientCreateModal from './ClientCreateModal';
import ClientsTable from './ClientsTable';
import { useEffect, useState } from 'react';
import ProfessionalClientCreateModal from './ProfessionalClientCreateModal';
import { useLocation } from 'react-use';
import useClients from '../../hooks/clients/useClient';
import { Route_ClientsList } from '../../constants/Routes.constants';

import { useLayoutMedia } from 'shared/services/Utility';
import './index.scss';

type Props = {}
const List: React.FunctionComponent<Props> = () => {
    const { customers, refetch: refresh, loading: fetchingClients } = useClients();
    const [createModalVisible, setCreateModalVisible] = React.useState(false);
    const [searchKey, setSearchKey] = React.useState('');
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();

    const [activeTab, setActiveTab] = React.useState('priv');
    const isDesktop = useLayoutMedia(['Desktop']);

    useEffect(() => {
        if (location['hash'] && location['hash'].toLowerCase() && location['hash'].toLowerCase() === '#add') {
            setCreateModalVisible(true);
        }
    }, [customers, location]);

    const handleCancel = () => {
        setCreateModalVisible(false);
        history.push(Route_ClientsList);
    };

    const [filterByType, setFilterByType] = useState('');
    return (
        <ListView
            pageTitle={t('genDict.page-title', { title: t('pageTitles.listClients') })}
            title={t('genDict.Clients')}
            loading={fetchingClients}
            paddingBottomRequired={true}
            searchBar={
                (<Space direction={'horizontal'} className={'filter-wrapper'} size={20}>
                    <div className="filter-item">
                        <ListSearchBar onChange={(e: string) => {
                            setSearchKey(e);
                        }} text={t('genDict.Search')}/>
                    </div>
                    <div className="filter-item">
                        <Select style={{ width: '180px' }} value={filterByType || undefined} onChange={(e) => {
                            setFilterByType(e);
                        }} placeholder={t('genDict.filterByType')}>
                            <Select.Option value={''}>{t('genDict.all')}</Select.Option>
                            <Select.Option value={'priv'}>{t('genDict.Private').toLowerCase()}</Select.Option>
                            <Select.Option value={'pro'}>{t('genDict.Professional').toLowerCase()}</Select.Option>
                        </Select>
                    </div>


                </Space>)
            }
            createButton={(

                <BaseButton type="primary"
                            shape="round"
                            size={'large'} onClick={() => {
                    setCreateModalVisible(true);
                }}>
                    {isDesktop ? t('client.button.addClientMenu') : t('genDict.add')}
                </BaseButton>
            )}
        >
            <Modal
                title={t('client.button.addClientMenu')}
                visible={createModalVisible}
                width={800}
                maskClosable={false}
                afterClose={handleCancel}
                onCancel={handleCancel}
                destroyOnClose={true}
                bodyStyle={{ padding: 0 }}
                footer={null}
            >
                {
                    activeTab === 'pro' ?
                        <ProfessionalClientCreateModal setActiveTab={setActiveTab} onCancel={(reload) => {
                            if (reload) {
                                refresh();
                            } else {
                                setCreateModalVisible(false);
                            }
                            handleCancel();

                        }} mode={'CREATE'}/> :
                        <ClientCreateModal setActiveTab={setActiveTab} onCancel={(reload) => {
                            if (reload) {
                                refresh();
                            } else {
                                setCreateModalVisible(false);
                            }
                            handleCancel();

                        }} mode={'CREATE'}/>
                }
            </Modal>
            <ClientsTable refresh={() => {
                refresh();
                setCreateModalVisible(false);
            }} searchKey={searchKey} onCreateCalled={() => setCreateModalVisible(true)} filterByType={filterByType}
                          customers={customers}/>
        </ListView>
    );
};
export default List;
